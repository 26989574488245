import React, { useState } from "react";
import { FaImage, FaFilePdf, FaMicrophone, FaVideo } from "react-icons/fa";
import { Grid, Theme } from "@mui/material";
import { createStyles, withStyles } from "@mui/styles";
import AttachmentInput from "./textfield";
import FileLoader from "./uploader";
import Shaper from "../../Edit/Post/Featured/shaper";
import Acrobat from "../../../../../components/Feature/media/document";
import LocalAudio from "../../../../../components/Feature/media/localaudio";
import LocalPhoto from "../../../../../components/Feature/media/localphoto";
import LocalVideo from "../../../../../components/Feature/media/localvideo";
import Soundcloud from "../../../../../components/Feature/media/soundcloud";
import Instagram from "../../../../../components/Feature/media/instagram";
import Twitter from "../../../../../components/Feature/media/twitter";
import YouTube from "../../../../../components/Feature/media/youtube";
import FacebookPost from "../../../../../components/Feature/media/facebookpost";
import FacebookVideo from "../../../../../components/Feature/media/facebookvideo";
import Vimeo from "../../../../../components/Feature/media/vimeo";
//import { useUser } from "../../../../../../../../../context/User/userContext";
import { useAttachment } from "../../../../../context/Post/attachmentContext";
//import { useNewPost } from "../../../../../../../../../context/Post/newPostContext";
//var _ = require("lodash");
const styles = (theme: Theme) =>
  createStyles({
    form: {
      [theme.breakpoints.up("sm")]: {
        minHeight: "calc(100vh - 114px)",
      },
      [theme.breakpoints.down("sm")]: {
        minHeight: "calc(100vh - 106px)",
      },
    },
    formField: {
      width: "100%",
    },
    body: {
      flex: 1,
    },
    button: {
      borderColor: theme.palette.fonts.main,
      color: theme.palette.fonts.main,
      width: "100%",
    },
    buttonTextField: {
      maxWidth: 320,
      width: "100%",
    },
    handle: {
      flex: "none",
      width: 120,
    },
    icon: {
      marginLeft: theme.spacing(1),
    },
    textButton: {
      textShadow: "0px 2px 3px rgba(0,0,0,0.4)",
    },
    acrobat: {
      color: theme.palette.icons.acrobat,
    },
    facebook: {
      color: theme.palette.icons.facebook,
    },
    instagram: {
      color: theme.palette.icons.instagram,
    },
    soundcloud: {
      color: theme.palette.icons.soundcloud,
    },
    twitter: {
      color: theme.palette.icons.twitter,
    },
    vimeo: {
      color: theme.palette.icons.vimeo,
    },
    youtube: {
      color: theme.palette.icons.youtube,
    },
  });

const AttachedMedia: React.FC<{ classes: any }> = ({ classes }) => {
  //const { newPost } = useNewPost();

  const { attachment, handleChange, loadAttachment, updateAttachment } =
    useAttachment();
  // const [attachmentState, setAttachmentState] = useState({
  //   ...attachment,
  //   //  source: "https://instagr.am/p/COTQbGmDwGk",
  // });
  const [editing, setEditing] = useState(true);

  const { postType, shape, template } = attachment;
  const type = postType[template];

  // useEffect(() => {
  //   if (!_.isEqual(attachmentState, attachment)) {
  //     setAttachmentState(attachment);
  //   }
  // }, [attachment, attachmentState]);

  const sourceCheck = () => {
    const exist = !!attachment.source;
    //  console.log("exist", exist);
    return exist;
  };
  const nameCheck = () => {
    const exist = attachment.name !== "";
    return exist;
  };

  const showClear = () => {
    const { form } = formType[type];
    if (form === "button") {
      return !formType[type].showDemo;
    } else if (form === "textfield") {
      return editing;
    }
  };

  const handleShapeChange = (shape: string) => {
    //setAttachmentState({ ...attachmentState, shape });
    handleChange("shape", shape);
  };

  function handleAttachmentSourceChange(event: any) {
    console.log("changing source", event.target.value);
    // setAttachmentState({
    //   ...attachmentState,
    //   name: event.target.value,
    //   source: event.target.value,
    // });
    handleChange("source", event.target.value);
  }

  // function handleInstagramAttachmentIDChange(event: any) {
  //   setAttachmentState({
  //     ...attachmentState,
  //     name: event.target.value,
  //     source: "https://instagram.com/p/" + event.target.value,
  //   });
  // }

  function handleVimeoAttachmentIDChange(event: any) {
    loadAttachment({
      ...attachment,
      name: event.target.value,
      source: "https://vimeo.com/" + event.target.value,
    });
  }

  function handleYoutubeAttachmentIDChange(event: any) {
    loadAttachment({
      ...attachment,
      name: event.target.value,
      source: "https://youtu.be/" + event.target.value,
    });
  }

  const blurPush = () => {
    loadAttachment({ ...attachment, postType, type });
    setEditing(false);
  };

  const submitFile = (changes: any) => {
    const newAttachmentState = { ...attachment, ...changes };
    //setAttachmentState(newAttachmentState);
    updateAttachment(newAttachmentState);
    //toggleManager("attachmentManager");
  };

  const buttonTextField = () => {
    if (type) {
      if (formType[type].form === "textfield") {
        const {
          helperText,
          info,
          onBlurFunction,
          onChangeFunction,
          onFocusFunction,
          sourceLabel,
          stateModifier,
        } = formType[type];
        return (
          <AttachmentInput
            onChangeFunction={onChangeFunction}
            stateModifier={stateModifier}
            helperText={helperText}
            info={info} // Value of Concern is name
            onBlurFunction={onBlurFunction}
            onFocusFunction={onFocusFunction}
            sourceLabel={sourceLabel}
          />
        );
      } else {
        const { header, preset } = formType[type];
        return (
          <FileLoader header={header} preset={preset} submitFile={submitFile} />
          // <Button
          //   variant="outlined"
          //   classes={{ root: classes.button }}
          //   onClick={() => toggleManager("attachmentManager")}
          // >
          //   {showDemo ? activeButtonText : buttonText}
          //   {icon}
          // </Button>
        );
      }
    }
  };

  const formType: { [key: string]: any } = {
    document: {
      form: "button",
      buttonText: "Upload PDF",
      activeButtonText: "Change PDF",
      icon: <FaFilePdf className={classes.icon} />,
      header: "PDF Document",
      preset: "SentinelDocument",
      shape: "portrait",
      showShape: false,
      showDemo: sourceCheck(),
    },
    facebookpost: {
      form: "textfield",
      onChangeFunction: handleAttachmentSourceChange,
      stateModifier: "source",
      helperText: "Copy & Paste Full Facebook Post URL (link)",
      helperTextBrief: "Facebook",
      info: attachment.source,
      onBlurFunction: blurPush,
      onFocusFunction: () => setEditing(true),
      sourceLabel: "Post URL",
      showShape: false,
      showDemo: sourceCheck(),
    },
    facebookvideo: {
      form: "textfield",
      onChangeFunction: handleAttachmentSourceChange,
      stateModifier: "source",
      helperText: "Copy & Paste Full Facebook Video URL (link)",
      helperTextBrief: "Facebook",
      info: attachment.source,
      onBlurFunction: blurPush,
      onFocusFunction: () => setEditing(true),
      sourceLabel: "Video URL",
      showDemo: sourceCheck(),
      showShape: true,
    },
    instagram: {
      form: "textfield",
      onChangeFunction: handleAttachmentSourceChange,
      helperText: "Copy & Paste Instagram Post ID",
      helperTextBrief: "Instagram",
      info: attachment.source,
      onBlurFunction: blurPush,
      onFocusFunction: () => setEditing(true),
      sourceLabel: "Post ID",
      showDemo: !!attachment.source,
      showShape: false,
    },
    localaudio: {
      form: "button",
      buttonText: "Upload Audio",
      activeButtonText: "Change Audio",
      icon: <FaMicrophone className={classes.icon} />,
      header: "Audio",
      preset: "SentinelAudio",
      shape: "landscape",
      showDemo: sourceCheck(),
      showShape: false,
    },
    localphoto: {
      form: "button",
      buttonText: "Upload Photo",
      activeButtonText: "Change Photo",
      icon: <FaImage className={classes.icon} />,
      header: "Photo",
      preset: "SentinelPhoto",
      shape: "portrait",
      showDemo: sourceCheck(),
      showShape: false,
    },
    localvideo: {
      form: "button",
      buttonText: "Upload Video",
      activeButtonText: "Change Video",
      icon: <FaVideo className={classes.icon} />,
      header: "Video",
      preset: "SentinelVideo",
      shape: "landscape",
      showDemo: sourceCheck(),
      showShape: true,
    },
    photoalbum: {
      form: "button",
      header: "Photo",
      preset: "SentinelPhoto",
      shape: "landscape",
      showDemo: sourceCheck(),
      showShape: false,
    },
    soundcloud: {
      form: "textfield",
      onChangeFunction: handleAttachmentSourceChange,
      stateModifier: "source",
      helperText: "Copy & Paste Full Soundcloud Audio URL (link)",
      helperTextBrief: "Soundcloud",
      info: attachment.source,
      onBlurFunction: blurPush,
      onFocusFunction: () => setEditing(true),
      sourceLabel: "Audio URL",
      showDemo: sourceCheck(),
      showShape: false,
    },
    twitter: {
      form: "textfield",
      onChangeFunction: handleAttachmentSourceChange,
      stateModifier: "source",
      helperText: "Copy & Paste Full Twitter Tweet URL (link)",
      helperTextBrief: "Twitter",
      info: attachment.name,
      onBlurFunction: blurPush,
      onFocusFunction: () => setEditing(true),
      sourceLabel: "Tweet ID",
      showDemo: !!attachment.name,
      showShape: false,
    },
    vimeo: {
      form: "textfield",
      onChangeFunction: handleVimeoAttachmentIDChange,
      stateModifier: "name",
      helperText: "Copy & Paste Vimeo Video ID",
      helperTextBrief: "Vimeo",
      info: attachment.name,
      onBlurFunction: blurPush,
      onFocusFunction: () => setEditing(true),
      sourceLabel: "Video ID",
      showDemo: nameCheck(),
      showShape: false,
    },
    youtube: {
      form: "textfield",
      onChangeFunction: handleYoutubeAttachmentIDChange,
      stateModifier: "name",
      helperText: "Copy & Paste YouTube Video ID",
      helperTextBrief: "YouTube",
      info: attachment.name,
      onBlurFunction: blurPush,
      onFocusFunction: () => setEditing(true),
      sourceLabel: "Video ID",
      showDemo: nameCheck(),
      showShape: false,
    },
  };

  const createType: { [key: string]: any } = {
    document: <Acrobat document={attachment} />,
    facebookpost: <FacebookPost facebookpost={attachment} />,
    facebookvideo: <FacebookVideo start={false} facebookvideo={attachment} />,
    instagram: <Instagram instagram={attachment} />,
    localaudio: <LocalAudio localaudio={attachment} />,
    localphoto: <LocalPhoto localphoto={attachment} />,
    localvideo: <LocalVideo localvideo={attachment} />,
    soundcloud: <Soundcloud soundcloud={attachment} />,
    twitter: <Twitter twitter={attachment} />,
    vimeo: <Vimeo vimeo={attachment} />,
    youtube: <YouTube start={false} youtube={attachment} />,
  };

  return (
    <React.Fragment>
      <Grid container style={{ marginTop: 2 }} spacing={3}>
        {formType[type].showDemo && ( // Selected Media
          <Grid item xs={12}>
            {createType[type]}
          </Grid>
        )}
        <Grid item xs={12}>
          {showClear() ? (
            <Grid
              container
              direction="row-reverse"
              justifyContent="center"
              alignItems="center"
            >
              <Grid
                item
                classes={{ root: classes.buttonTextField }}
                style={{ marginBottom: -8 }}
              >
                {buttonTextField()}
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={2}>
              {shape && formType[type].showShape && (
                <Grid item xs={12} sm>
                  <Shaper shape={shape} handleShapeChange={handleShapeChange} />
                </Grid>
              )}
              <Grid item xs={12} sm>
                {buttonTextField()}
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
      {/* <AttachmentPicker
        attachmentObject={attachment}
        closeManager={() => toggleManager("attachmentManager")}
        header={formType[type].header}
        open={attachmentManager}
        preset={formType[type].preset}
        submitFile={submitFile}
      /> */}
    </React.Fragment>
  );
};

export default withStyles(styles)(AttachedMedia);
