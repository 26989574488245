import React from "react";
import { FormattedMessage } from "react-intl";
import { Theme } from "@mui/material";
import { createStyles, withStyles } from "@mui/styles";
// import PostCardS from "./postCard_s";
// import PostCardMS from "./postCard_ms";
import MediumLarge from "./medium_large";
import MediumLargeMobile from "./medium_large_mobile";
//import PostCardL from "./postCard_l";
import { useTrans } from "../../../../../context/Trans/translationContext";
import { useUser } from "../../../../../context/User/userContext";

var _ = require("lodash");
const styles = (theme: Theme) =>
  createStyles({
    postHolder: {
      animation: "fading 1.8s infinite",
      background: theme.palette.secondary.main,
      marginBottom: theme.spacing(3),
      width: "100%",
    },
    small: {
      minHeight: 60,
    },
    mediumsmall: {
      minHeight: 120,
    },
    mediumlarge: {
      minHeight: 120,
    },
    large: {
      minHeight: 180,
    },
  });
const PostCard: React.FC<{ thisPost: any }> = ({ thisPost }) => {
  const { language, RouteTrans } = useTrans();
  const { isMobile } = useUser();
  const feed_display = "medium_large";

  const { category, postID, title } = thisPost;

  const transCat = <FormattedMessage id={category.name || "Uncategorized"} />;
  const transDiv = (
    <FormattedMessage id={category.division.name || "Uncategorized"} />
  );
  const transTitle = title[language];

  const getCategory = (url: string) => {
    function toUnder(a: string) {
      return RouteTrans[a][language];
    }
    const categoryUrl = _.join(_.map(_.split(url, "/"), toUnder), "/");
    return categoryUrl;
  };

  const postURL = `/${language}/${RouteTrans["post"][language]}/${getCategory(
    category.url
  )}/${postID}`;

  // const cardSize = {
  // small: (
  //   <PostCardS
  //     thisPost={thisPost}
  //     postURL={postURL}
  //     title={transTitle}
  //     category={transCat}
  //     division={transDiv}
  //   />
  // ),
  // mediumsmall: (
  //   <PostCardMS
  //     thisPost={thisPost}
  //     postURL={postURL}
  //     title={transTitle}
  //     category={transCat}
  //     division={transDiv}
  //   />
  // ),
  // mediumlarge: (
  //   <MediumLarge
  //     thisPost={thisPost}
  //     postURL={postURL}
  //     title={transTitle}
  //     category={transCat}
  //     division={transDiv}
  //   />
  // ),
  // large: (
  //   <PostCardL
  //     thisPost={thisPost}
  //     postURL={postURL}
  //     title={transTitle}
  //     category={transCat}
  //     division={transDiv}
  //   />
  // ),
  //};

  const cover_dimensions: { [key: string]: any } = {
    medium_large: {
      h: 184,
      w: 350,
    },
  };

  const getCover = (cover: any, version = 2) => {
    const base = "https://res.cloudinary.com/defendhaiti/image/upload/";
    const transform = `h_${cover_dimensions[feed_display].h},w_${cover_dimensions[feed_display].w},c_fill/g_auto/v1549853661/`;
    const file = version === 1 ? `Version_1/${cover.source}` : cover.name;
    return base + transform + file;
  };

  const cardSize: { [key: string]: any } = {
    medium_large: (
      <MediumLarge
        thisPost={thisPost}
        postURL={postURL}
        title={transTitle}
        category={transCat}
        division={transDiv}
        getCover={getCover}
      />
    ),
    medium_large_mobile: (
      <MediumLargeMobile
        thisPost={thisPost}
        postURL={postURL}
        title={transTitle}
        category={transCat}
        division={transDiv}
        getCover={getCover}
      />
    ),
  };

  const display_key = feed_display + (isMobile ? "_mobile" : "");

  return <>{cardSize[display_key]}</>;
};

export default withStyles(styles, { withTheme: true })(PostCard);
