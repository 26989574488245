import React from "react";
import { createStyles, SvgIcon, Theme } from "@mui/material";
import { withStyles } from "@mui/styles";

const styles = (theme: Theme) =>
  createStyles({
    logo_title: {
      color: theme.palette.color.blue,
      borderRadius: 6.5,
      fontSize: 32,
      marginRight: theme.spacing(1),
      padding: 20,
    },
  });

const Logo: React.FC<{ classes: any }> = ({ classes }) => {
  function SVGFile() {
    return (
      <SvgIcon
        className={classes.logo_title}
        viewBox="-603.4 -1474.5 745 769.3"
      >
        <g>
          <path d="M-341.4-1378.2c0,0.1,0.1,0.2,0.1,0.3C-341.2-1378-341.4-1378-341.4-1378.2L-341.4-1378.2z" />
          <path d="M-332-1380.8c0,0,0,0-0.1,0C-332.1-1380.8-332-1380.8-332-1380.8z" />
          <path
            d="M-316.7-1359.5c0,0-1.8,0.4-2.3,0.3c-0.6-0.1-0.6,0.3,0.1,1.7c0.7,1.4,0,0.8-0.4,2.2c-0.4,1.4,0.8,0.8,1.8-0.3
		c1-1.1,1.1-0.8,0.3-1.7c-0.8-0.8,2.6-3,2.6-3s0,0.3-0.7,3c-0.5,2.1-2,3.5-2.8,4.1c0.8-0.4,2.3-1.2,3.4-2.9c1.7-2.6,1.7-5.6,0.8-5.8
		C-314.8-1362-316.7-1359.5-316.7-1359.5z"
          />
          <path d="M-318.8-1352.9c0.1,0,0.3-0.1,0.6-0.3C-318.7-1353-318.9-1352.9-318.8-1352.9z" />
          <path d="M37.6-779.6l-174.5-5.8C-75.7-772.8,37.6-779.6,37.6-779.6z" />
          <path d="M61.2-768.7l-174.5-5.8C-52.2-761.9,61.2-768.7,61.2-768.7z" />
          <path d="M-104-869.9c15.3,8.7,102.7,21.2,102.7,21.2L-104-869.9z" />
          <path
            d="M126.6-780.9c-1-6.8-15.3-27-21.6-38.2c-0.1-0.3-0.3-0.5-0.4-0.8c-0.2-0.4-0.5-0.8-0.8-1.3c-6-8.6-26.9-28.4-30.7-31
		c-3.2-2.2-13-5.6-17-7c-0.5-0.2-1.1-0.4-1.7-0.6l-187.8-41.9c-2.6-4-5.5-7.4-8.3-8.1c-7.3-1.8-21.8-11-28.9-28.3
		c-7.2-17.2-17.9-34.2-18.6-39.3c-0.7-5.1,3.9-13.1-1.4-21.4c-5.2-8.3,2.8-12.1,2.8-12.1s2.1,10.1,10.1,10.1
		c7.9,0,14.1-4.8,15.4-7.3c1.4-2.5,0-5.9,2.8-8c2.8-2.1,5.5-3.2,11-1.4c5.5,1.8,5.9-3.2,7.6-4.8c1.7-1.7,16.5-13.8,16.5-18.3
		c0-2.6-1.7-6.1-3.4-8.5c2.1-0.6,3.7-1.4,4.8-2.2c6.2-4.8,24.1-14.2,27.6-11.4c3.4,2.8,15.6,35.8,14.9,42.4
		c-0.7,6.6,0.7,16.1,2.1,20.1c0,0,4,9.9,1,10.1c-3.2,0.1-9.9-4.5-11.2-3s-0.4,3.9-4.5,5.7c-4.1,1.8-8.5,11-9.6,20.7
		s4,23.2,11.6,26.9c1.8,0.9,4.2,0.7,6.8-0.2l0,0c0,0,6.1-1.9,12-6.4c5.3-4.1,8.1-5.2,9.7-11.3c2.1-3.3,2.4-7.2,3.1-12.3
		c2.3-5,6.4-14,8.2-19.4c0.7-1.9,1.2-4,1.7-6.1c0.9-2.4,1.9-4.6,2.6-6.1c2.3-4.5-0.8-7.4-0.8-7.4l0,0c-0.4-0.9-1.1-1.9-1.9-3.1
		c-6.2-8.3-13.8-34-14.9-56.1c-1.1-22,2.5-29.4-9.6-31.7c-12.1-2.3-23.7,1.8-34,1.4c-10.3-0.4-15.2-3.3-24-4.1s-10.2-0.4-10.2-0.4
		s4.7-12.5,6.3-32.5c1.7-20-7.9-25.9-9.5-30c-1.7-4.1-3.6-9.2-7.3-12.5c-1.8-1.5-4.4-2.5-6.9-4.5c0,0-0.6-0.4-3.2-3.6
		c-0.4-0.7-0.8-1.5-1.2-2.3c-1.1-3.6-0.6-9.1-0.3-14.3c0.4-6.6-3.2-14.5-3.2-14.5s20.8,5.1,24.8,2.2c4-2.9,5.4-3.9,9.4-5.8
		c4-1.9,4.1-6.5,3.7-10.7c-0.4-4.3,0-3.9,3.6-2.6c3.6,1.1,11.6,3.3,14.7-1.2c3.2-4.5,1.7-4.7,2.5-6.6c1-1.8,9.2-4.3,10.1-10.5
		c0.8-6.2,1.1-7-2.9-14.6c-4-7.6-14.9-52.9-14.9-52.9s4.4-8.5,1.8-11.7c-2.5-3.2-5.1-6.2-8.3-14.7c-3.2-8.5-4.8-14.9-9.2-18.3
		c-4.4-3.4-4.5-9.2-4.5-9.2s6.9-10.1,2.8-14.7c-4.1-4.7-30.6-7.9-38.9-7.9c-8.3,0-9.6,2.1-10.3,2.1c-0.7,0-14.7-13.4-17.5-11.3
		c-2.8,2.1,3.4,16.1,3.4,16.1s-25.8,10.6-49.9,34.7c-24.1,24.1-28.9,56.2-28.9,56.2s-8.7-13.5-13.4-13.8c-4.5-0.3-4.5,1-4.5,1
		s-5.5-12.4-11.7-15.6s-12-4.4-11.3-4.5c0.7-0.3,7.3-29.5,5.9-36.9c-0.4-2-0.8-3.9-1.1-5.6c0,0,0,0,0,0c0-1.9-0.6-5.6-0.9-7.5
		c2.6-0.3,9.8-1.8,8.5-11.2c-1.7-11.4-0.7-18.1-5.8-23.6c-5.1-5.5-8.4-4-11.4-8.7c-0.7-1.1-1.7-2.2-2.9-3.3
		c-0.1-0.1-0.1-0.1-0.2-0.2c0,0,0,0,0,0c-1.7-1.6-3.7-3.1-5.5-4.4c-0.1,0-0.1-0.1-0.1-0.1c-0.1,0-0.1-0.1-0.2-0.1
		c-0.7-0.5-1.4-0.9-2.1-1.4c-0.1,0-0.1-0.1-0.1-0.1l0,0c-1.3-0.8-2.5-1.4-3.2-1.7c-2.6-1-3.7,1.8-8.3,0c-4.5-1.8-13.1,1.9-15.4,6.8
		c-2.3,4.8-0.3,10.1-5,13.8c-4.7,3.7-6.9,8.7-11.2,13.6c-4.3,5-16.5,0.4-19.6,5.6c-3,5.2,3.7,10.5,12.1,14.1s11,13.8,10.7,19.7
		c-0.3,5.9,1.7,9.5,5,12.1c3.3,2.6,3.2,3.2,0.7,8.1c-2.5,5.1,1.1,5.7-1.1,8.4s-1.1,5.4-4.8,4.5c-3.7-1-6.5-3.9-9.2-9.8
		c-2.8-5.9-14.9-10.1-21.1-9c-6.1,1.1-5.9,3.2-12.1-1.5c-1.9-1.4-3.8-2.4-5.7-3.4c-2.3-2.1-4.9-5-6.6-8.1c-1-1.8-1.8-4.7-2.5-8
		c0-0.4-0.1-0.8-0.1-1.2c0-0.5-0.1-1.1-0.1-1.6c-0.8-10-1.4-22.2-0.6-24.9c1-3.4,2.5-4.5,1.8-11.4c-0.7-7-0.8-10.2-1.9-10.9
		c-1.1-0.7-1.8,0.4-1.8-2.1s0.6-8.5,1.5-12.5c1-4,2.5-4.5,2.8-9c0.3-4.4,1.8-8.1,2.2-10.5c0.4-2.3-2.1-3.2-5.5-2.3
		c-3.4,1-2.9,8.7-5,8c-2.1-0.7-1.7-8.5-1.5-11.6c0-3,1.2-7.9-0.4-8.5c-1.8-0.6-2.9-0.8-3.2-0.6c-0.4,0.1-1.1,1.1-1.1,1.1
		s1.5-5.1-1.2-6.5c-2.8-1.4-4.5,1.9-4.5,1.9s-1.2-2.1-3.7-1.7c-2.5,0.4-3,5.8-3,5.8s-1-2.3-3.4,1.1c-2.5,3.4-3,15.3-1.7,22.6
		s5.1,7.3,4.1,11.9s1.2,11.2-1.2,12c-2.5,0.8-6.1-0.4-6.1,3.2s-2.2,16.7,0,19.3c2.2,2.6,2.5,2.2,2.5,4.7c0,2.5,1.2,39,4.3,47.3
		s33.9,34.6,33.9,34.6s17.2,16.1,20.3,21.8c3.2,5.8,16.4,32.2,22.5,45.3c5.9,13.1,15.4,26.5,14.7,32.8c-0.7,6.5-3.7,15.4-4.4,28
		c-0.7,12.7-3.9,21.4-3.9,28.9c0,7.6,4.1,11-0.7,14.2c-4.8,3.2-42.7,18.3-52.8,29.2c-10.1,10.7-23,31.7-31,45.1
		c-8,13.4-2.1,28.7-1,34.2c1.1,5.5-9.6,37.6-14.2,49.1c-4.5,11.4-5.5,18.6-12.4,24.1s2.5,27.3,8.3,40c5.8,12.7,21.2,30.9,25.6,33.6
		c4.4,2.8,5.2,8.3,14.2,14.2c9,5.9,13.4,12.4,13.1,18.6c-0.3,6.2-5.5,3.7-6.2,8.5c-0.7,4.7,0.4,17.5,0.3,20.9l-92.7,31.1
		c-1.9,0.6-3.5,1.8-4.7,3.4c-11.1,8-39.5,28.6-53.7,39.7c-18,14-21,43.7-21,43.7h745C141.6-705.2,127.6-773.9,126.6-780.9z
		 M-84.6-1074.7c0,0-0.3,10.3,0,14.7c0.3,4.4,0.6,5.8,0.6,5.8S-89.2-1065.1-84.6-1074.7z M-77.8-1082.3c0,0-2.6,17.2,0,29.9
		c2.6,12.7,3,14.6,3,14.6S-90-1064.5-77.8-1082.3z M-77.8-964.6c7.3-1.8,11.7-5.2,11.7-5.2c0.4,7.3-19,7-19,7
		S-85.1-962.8-77.8-964.6z M-67.1-954c-3,4.7-20.4,10.5-20.4,10.5s3.3-1.5,8.4-5.2s10.2-10.6,10.2-10.6s-0.6,0.3-4.8,1
		c-4.3,0.7-11.3-0.4-11.6-0.4c-0.3,0,4.3-1.1,11.6-2.9s9.4-6.5,9.4-6.5C-61.9-966.8-64.1-958.7-67.1-954z M-72.9-1095
		c5.4,5.2,4.5,19,4.4,34.7c-0.3,15.6,10.7,39.3,13.9,43.8s4.8,10.6,3.4,10.1c-1.4-0.6-4.4,1-4.4,1s3.4,0.8,4.5,3.8
		c-1.5,4.4-6.2,16.5-8.9,23.4c0.6-2,1.6-5,3-8.7c2.6-6.9,0-16.1,0-16.1s0,0-0.6,5.1c-0.6,5.1-3.7,11.6-3.7,11.6s0.4-7-0.3-15.3
		s1.2-9.4,3.2-10.3c1.8-1.1-10.2-20.5-10.2-20.5c-13-27.3-6.2-45.8-6.8-53.5c-0.6-7.9-13.9-8.7-13.9-8.7S-78.3-1100.2-72.9-1095z
		 M-168.2-895.9c4.9,0.9,7.8,2.5,8.8,6.1c1.1,3.6,3.4,15.5,5,16.9s1.5-18,1.5-18s90,17,117,23s86.5,19.5,111,28s34.5,39.5,34.5,39.5
		s-13-7-28.5-17s-240.2-47.2-244.5-50.2c-1.7-1.2-4.9-0.5-8.3,0.7c-1.3,0.5-2.6,1-3.6,1.3c-2.6,1-6.5,3.2-9.2,4.9l2.6,11.9l4,57.3
		l-11.1-66.5l-4.3,1.9c-0.2,0.7-0.5,1.4-0.8,2.1c-4,10.7,0,62,0,62l-11.3-45.6c0,0,8,88.3,8,90.3c0,0.4-0.5-1.8-1.4-5.7v0
		c0,0,0,0-3.6-8.6c-3.6-8.6-24.3-9.7-24.3-9.7l18-9.7l-42.3,5.3c0,0,17.7-6,29-11.3c2.9-1.4,5.1-3.3,6.7-5.3
		c-18.1,3.4-52.2,9.8-51,9.3c1.7-0.7,48.7-16.3,48.3-21c-3,0.3-129.3,26.3-131.3,25.9c-1.1-0.3,45.7-14.5,85-26.4
		c-6,1.4-12.4,2.7-19.1,4C-322-792.9-508-752.6-508-752.6l222-62.7l-81.3,9.3l154-48l9.5-4.3c7.4-5.3,14.2-10.2,16.5-11.7
		c3.5-2.3,9.5-4,14.1-4.9c-0.4-7-2.1-21.1-2.1-21.1C-175.6-897.8-173.1-896.8-168.2-895.9z M-191.3-1015.4c-3.7,2.1-6.6,6.6-8.1,5.2
		c-1.5-1.4-9.1-23.4-9.1-23.4s-0.8-6.1,1-9.1c1.8-3,4.8-0.6,9.4,7C-193.7-1028.1-187.7-1017.5-191.3-1015.4z M-223.1-1083.2
		c-3.9-7.6-11.7-17.5-11.7-17.5s10.9,7.6,14.5,13.2c3.6,5.7,3.6,5.7,3.6,5.7s-1.2-9.8-12.1-22.2c-2.8-3.2,18.3,12.7,18.5,20.8
		c0.1,8.1-5.5,19.3-4.7,35.7s6.3,28,6.3,28s-10.5-11.9-11.6-27.3C-221.5-1062.3-219.3-1075.7-223.1-1083.2z M-222.3-1069.2l-9-22
		c0,0,6.2,6.3,7.4,13.6C-222.6-1070.3-222.3-1069.2-222.3-1069.2z M-213.1-1151.5c-4-5.9-4-5.9-4-5.9s6.5,3.2,9.4,8.7
		c2.9,5.5,2.9,5.5,2.9,5.5s1.8-10.9-17.6-31.1c0,0,16,9.4,20,17.8c4,8.5,14.9,6.6,13.5,9.9c-1.4,3.2-16,16.5-16.7,29.4
		c-0.7,12.8-0.7,13.6-0.7,12.8c-0.1-0.6-5.9-11.7-5.7-23.2C-211.6-1139.1-209-1145.4-213.1-1151.5z M-207.7-1166.5
		c-4.8-6.6-18.9-14.7-19.3-14.9c0,0,12.1,1.7,17.4,6.3s9.1,18.2,9.1,18.2S-202.9-1159.9-207.7-1166.5z M-213.5-1093.3
		c0,0,0,0-5.7-6.1s-19.7-17.9-19.7-17.9C-218.3-1111.2-213.5-1093.3-213.5-1093.3z M-214.9-1135.9c0,0.1-1.5,17.4,2.6,26
		c4.1,8.8,5.4,9,4.1,8.8C-209.4-1101.2-224.4-1107.8-214.9-1135.9z M-188.7-1090.4c-5.7,3-11.9,2.8-11.9,2.8s8.5-1.8,11.2-12.7
		c2.6-10.9,2.8-11.7,2.8-11.7l-10.1,13.5c0,0-1.2-15.6,2.6-26.9c3.9-11.3,7.9-16.5,7.9-16.5l1.1,4.3c0,0,2.9,2.5,3.7,15.7
		C-180.7-1108.7-183-1093.3-188.7-1090.4z M-162.6-1013.8c-1.4,1.1-11.7,4.5-11.9,0C-174.5-1014.1-161.3-1014.9-162.6-1013.8z
		 M-164.2-1109.2c-0.7-4.3-0.4-10.1-0.4-10.1s1.1,1.4,1.2,2.6c0,0,1.7,11.6,1.1,15.6c-0.7,4-4.8,8.1-4.8,8.1
		S-163.5-1104.9-164.2-1109.2z M-146.2-1019.3c-2.9,0-19.7-1.1-15.8-6.6c0,0,8,1.5,11.2,3.2
		C-147.8-1021.2-143.4-1019.3-146.2-1019.3z M-150.7-1027c0,0,9.1-1.7,8.5,0C-142.7-1025.2-152.5-1024.5-150.7-1027z M-139.4-1046.5
		c-2.3,1.4-5.2,2.6-8.5,3.4c-9.6,2.5-14.9,1.8-11-0.4C-156.7-1044.4-147.9-1045.2-139.4-1046.5z M-143.2-1151.5
		c3.6,4.1,3.2,17.2,3.2,17.2l-3.4-3.6c0,0,0,0,1.5,9c1.5,9-5,17.6-5,17.6l-5-13.1c0,0,0,0,2.1,13.8s-11.2,13.9-11.2,13.9
		c2.1-5.2,0.6-12.4-0.1-18.7s-5.2-18.5-5.2-18.5s5,2.2,10.3-0.1c5.4-2.3,7-12.3,7-12.3l-15.6,9c0,0,0,0,4.3-4.5
		c4.3-4.5,12.1-15,12.1-15S-146.8-1155.6-143.2-1151.5z M-155.5-1160.6c-2.5,5.7-2.5,5.7-2.5,5.7l9.6-10.3c0,0,0.1,5.2-7.6,12.4
		s-9.6,9.8-12.5,9.5c-2.9-0.3-5.9-1.1-5.9-1.1s4.8,6.2,4.5,11.4c-0.3,5.2-3.2,30.3-8.4,35c0,0,3.3-28.5,0.8-36.9
		c-2.5-8.3-0.6-23.4,1.8-22c2.2,1.4,1.9,7.6,1.9,7.6s3.3-4.1,9.6-8.4c6.3-4.3,10.9-14.9,10.9-14.9S-153-1166.3-155.5-1160.6z
		 M-168.3-1228.8c-1.8,0.1-1.9-4.7-1.9-4.7c4.3-2.3,5.9,1.1,5,2.3C-166.2-1229.9-166.6-1228.8-168.3-1228.8z M-181.1-1243.5
		c4.5-0.7,2.9,3.3,8.7,1.7c5.9-1.8,10.9-1.8,13.2,1.9s1.8,6.6-1.5,5.5s-5.8-3.3-9.1-2.1c-3.3,1.2-6.3-1.5-9-1.5
		c-2.6,0-5.5,1.1-5.2-0.7C-184-1238.7-185.7-1242.8-181.1-1243.5z M-175.5-1275.2c2.8,2.8,0.1,5.4,2.5,6.3s2.9,2.8,1.1,2.8
		c-1.8,0-15.3,2.6-11.3-2.8C-183.3-1268.7-178.2-1278-175.5-1275.2z M-178.1-1382.1c0,0,8,6.9,9.8,11c1.8,4.1,1.8,4.1,1.8,4.1
		L-178.1-1382.1z M-178.4-1259.5c5.1,1.4,13.5-0.7,15.2,4c1.7,4.7-3.9,8.7-8.5,6.3c-4.7-2.3-5.7-4-7.7-3.9c-2.1,0.1-7.9,1.1-6.2-1.8
		C-185.8-1254.7-183.5-1260.9-178.4-1259.5z M-181.1-1227.7c1.2-3.6,2.3-5.9,5.7-1.7c3.3,4.3,6.3,3.6,6.3,5.2c0,1.7-4.8,1.1-5.4,2.2
		c-0.6,1.1-2.6,2.6-3.3,1.8c-0.1-0.1-0.6-0.6-1.1-1.1C-180.1-1222.7-181.9-1225.2-181.1-1227.7z M-147.8-1225.3
		c-2.2,0.4-7.3-1.4-9.5-0.4c-2.2,1.1-9.2,3.2-7.9,0c0.1-0.3,7.7-9.4,12.5-6.5C-147.8-1229.3-145.6-1225.7-147.8-1225.3z
		 M-156.4-1356.8c0,0,1.2,5.2,4.3,8.3c3,3,4.7,3.7,4.7,3.7S-156.6-1345.9-156.4-1356.8z M-139.6-1222.3c-0.4,1.2-3.9,3.3-4.7,2.8
		c-0.8-0.7-1.7-2.9,1.9-5.1C-141.2-1225.5-139.2-1223.5-139.6-1222.3z M-136.5-1225.7c-1.2,0.3-3.2-2.9-5.7-3
		c-2.5-0.1-5.2-2.5-1.8-5c0.3-0.3,7.3,0.3,8,2.2C-135.2-1229.6-135.2-1226-136.5-1225.7z M-146.8-1325.2c0,0,0.7,9.1,9,15.7
		c8.3,6.6,8.3,6.6,8.3,6.6S-148.3-1305.9-146.8-1325.2z M-131.4-1282c0,0,5.4,8.8,10.6,14.9c5.2,6.1,5.2,6.1,5.2,6.1
		S-125.4-1262.1-131.4-1282z M-109.2-1245.6c-0.3,1.8-2.9,4.3-5.5,6.6c-2.5,2.3-8.1,2.3-8.1,2.3s4.8-2.5,5.9-5
		c1.1-2.5,1.1-2.5,1.1-2.5s-3.4,4.3-5.9,3.2c-2.5-1.1,1.1-3.7,0.4-6.3c-0.7-2.6-1.4-4.5-1.4-4.5s6.3,4.3,7.7,3.2
		c1.4-1.1,3.3-2.5,3.3-2.5s-3.2,4.1-1.4,4.5S-108.9-1247.4-109.2-1245.6z M-172.3-1385.7c6.9,4.1,8,5.5,8,5.5l-5.7-8.4
		c0,0,8.1,4.3,16,8c7.9,3.7,10.5,5.4,10.5,5.4l-5.5-5.2l7.3,2.6c0,0,3.9-3.6-1.2-7.3c-5.1-3.7-13.5-6.2-13.5-6.2s16,3.4,19,5.5
		c3,2.1,5,1.5,2.5,5.2c-2.5,3.7-8.1,7.3-4.7,11.7c3.4,4.4,1.4,11.4,2.1,14.9c0.7,3.4,0.7,3.4,0.7,3.4l1.5-11.2c0,0,6.2,5,10.5,14.9
		s5.8,14.6,5.4,21.4c-0.4,6.8,5.7,29.2,5.7,29.2s4.5,20.3,7.7,26.5c3.2,6.2,4.7,10.6,4.1,12.1s-2.3,5.4-2.5,5.9
		c-0.3,0.4-0.3,0.4-0.3,0.4l0.6-3.6c0,0-2.9,4.5-5,3.7c-1.9-1,2.3-3.6-1.4-8c-3.7-4.4-9.6-7.6-14.6-16.1c-5-8.7-6.1-15.6-6.1-15.6
		l9.6,13.1l-3.4-10.1c0,0,4.8,7.4,8,11.3c-3.4-13.1-11-41.1-11-41.1l12.1,34.7c-3.6-13.2-9.4-34.6-11.6-40.4
		c-3.2-8.5-5.5-20.9-5.5-20.9s-4.4,10.7-4.4,16.3s9,24.1,9,24.1s-9.9-7.4-13.8-14.3c-3.9-6.9-4.5-11.2-4.5-11.2s3.3,6.6,3.9,0.4
		c0.6-6.2,1.9-13.8-2.5-15.2c-4.3-1.5-7.6-5.9-9.2-11.4c-1.4-4.8-1.7-5.4-1.8-5.5c0.2,0.3,12.4,15.9,13.6,10.9
		c1.2-5.1,0.8-13.5-5.4-17.2s-15.7-10.1-16-6.1s2.2,11,2.2,11s-7-15.2-13.8-19.3c-6.8-4.1-11.6-8.1-11.6-8.1
		S-179.2-1389.9-172.3-1385.7z M-194.8-1193.5c3.9,12.4,11.9,25.6,11.9,25.6s2.1-5.2-1.5-22.9c-3.6-17.6-9.4-28.5-9.4-28.5
		s10.7,8.3,13.5,14.3c2.8,6.1,2.8,6.1,2.8,6.1s-0.7-9.4-5.9-15.2c-5.2-5.8-5.2-5.8-5.2-5.8s15.3,1.8,18.9,6.3
		c3.6,4.5,1.8,15.3,2.5,22.5c0.8,7.2,7.6,12.5,9.1,16s-2.3,8-2.3,8s0.6-5.5-2.5-8.7c-3-3.2-3-3.2-3-3.2s4.3,9.2,2.1,14.1
		c-2.2,4.8-6.9,7.9-6.9,7.9s0.7-4.8-1.1-10.3c-1.8-5.5-1.8-5.5-1.8-5.5s-1.5,9.5-4.8,13.5c-3.3,3.9-1.8,7.2-1.8,7.2l-5-3
		c0,0,4.3,6.2,5.1,9.8c0.8,3.6,0.8,3.6,0.8,3.6s-7.4-6.6-13.8-12c-6.3-5.4-8.4-17-14.5-21.8c-6.1-4.8-9.6-8.8-9.6-8.8l-8-6.8l10.7,1
		c0.6,0,8.7-0.6,12.3,8.8c3.6,9.4,16.5,23.7,16.5,23.7s-6.8-12.1-11.2-21.1c-4.4-9-4.3-18.2-10.5-28.4s-6.3-10.9-6.3-10.9
		S-198.6-1205.9-194.8-1193.5z M-216.8-1218.7c0,0,7.4,10.7,9,18.6c0.7,3.7,1.1,5.8,1.2,7c0.1,0.6,0.3,0.8,0.3,1c0,0.4,0,0.4-0.3-1
		C-208.3-1197.8-216.8-1218-216.8-1218.7z M-212.8-1374.6c-34,21.2-48.6,59.7-48.6,59.7C-248.8-1364.1-212.8-1374.6-212.8-1374.6z
		 M-258.4-1010.1c0,0,6.8,22.6,8,34c1.1,11.4,2.8,34.9,4.8,36.5c2.1,1.7,11,3.9,8.1-8.4c-2.9-12.3-12.3-64.5-12.7-66.6
		c-0.4-2.2,4.8-4.7,6.1-3.3c1.2,1.4,13.6,21.2,13.4,28.9c-0.3,7.7,8,20.5,11.3,23.4c3.2,2.9,8.1,7.7,10.1,14.2
		c2.3,6.3,17.1,44.4,15.3,47.8c-0.1,0.1-0.1,0.4-0.3,0.6l-91.4,31l-32.5,10.9c-4.4-5.8-9-14.3-7.4-24c2.8-18.2,14.9-17.6,22.5-33.3
		c7.6-15.6,5.8-31.4,16.5-43.1c1.7-1.9,3.2-4,4.3-6.2c2.2-3.5,4.6-8,5.6-12.4c2-8.7,0-12.3-3.5-17.7l0,0c-0.5-1.7-1.2-2.4-1.9-2.7
		c-0.2-0.2-0.3-0.4-0.5-0.6c1.6-0.5,2.6-0.9,2.6-0.9L-258.4-1010.1z M-336.7-1327.4c2.6-1.7,2.6-1.7,2.6-1.7l-5.4-0.1l5.5-1.4
		c0,0-6.1-0.1-7.4-5.2c-0.1-0.3,7.3,3.4,11.7,2.1c4.5-1.4,4.5-1.4,4.5-1.4s-3.7,6.3-4.8,9.4c-1.1,3-5.4,4-9.1,2.5
		c-3.7-1.7-5.8-3.6-5.8-3.6S-339.5-1325.8-336.7-1327.4z M-342.9-1345.5c0,0,0.3,4.1,1.1,5.2c1,1.1,1,1.1,1,1.1
		S-344.8-1340.7-342.9-1345.5z M-324.7-1319.2c-2.8-5,1.7-8.3,1.7-8.3c-1.4,5.5,0.6,6.2,3.3,11.7c2.8,5.5,3,12.1,2.5,17.6
		c-0.4,5.6-5.4,12.8-5.4,12.8s0,0-1.7-3c-1.7-3,0-5,2.6-11.9C-319.1-1306.9-322-1314.2-324.7-1319.2z M-325.3-1326.9
		c-1.7,3.4-1.2,4-1.7,3.4s0.3-9.4,6.1-12.3C-320.8-1335.7-323.6-1330.3-325.3-1326.9z M-331.6-1322.6c0,0-5.2,3.9-9.2-0.6
		c0,0,3.4,1.9,6.3,1.2S-331.6-1322.6-331.6-1322.6z M-345-1369.6c0,0,0,0,0-2.6c0-2.6-1.1-6.2-1.1-6.2
		C-342.6-1374-345-1369.6-345-1369.6z M-333.4-1314.2c1.9,4.5,1.4,9.1,1.4,9.1l2.5-7.3c0,0,0.1,6.6,0,11.2s0.8,6.3,0.8,6.3
		s-5.9-8.5-8.7-13c-2.8-4.4-9.9-13-9.9-13S-335.3-1318.8-333.4-1314.2z M-348.3-1369.7c0,0,1.4,4.1,0.7,5c-0.7,0.8-1.5-0.3-2.9,0
		c-1.4,0.3,0-1.5,0.6-2.9c0.6-1.4,0.8-2.8,1.4-3.9c0.6-1.1,0.6-1.1,0.6-1.1s0.7-1.1,0.7-0.4
		C-347.3-1372.4-348.5-1370.4-348.3-1369.7z M-325.3-1281.8c0,0-36.2-46-36.7-46c-0.4,0-2.2,2.1-2.2,2.1s-0.7-9.6,2.9-7.6
		C-357.6-1331.3-325.3-1281.8-325.3-1281.8z M-296.5-1233.7c0,0,8.4,0.7,9.2,5.2c0.8,4.5,0.3,4-8,4.4c-8.3,0.4-13,9.5-23,10.2
		c-10.1,0.7-15.7,0-15.7,0s13.6-1.7,17.8-7.2c4.1-5.5,4.1-5.5,4.1-5.5l-12.5,1.7c0,0,9.5-5.8,16.8-5.8c7.3,0,13.4,0,13.4,0
		L-296.5-1233.7z M-284.6-1255.9c1.8,3.9,1.8,3.9,1.8,3.9s-4-1.9-4.7-5.5c-0.7-3.6-5.7-1.4-3.2-6.1c0,0,3.3-8.8,6.2-5.1
		C-281.6-1265-286.4-1259.8-284.6-1255.9z M-283.2-992.1c1.4,3.8,1.8,6.6,1.9,8c-3,10-7.2,17.7-11.4,24.1c-0.4,0.6-0.7,1.1-1,1.7
		c-1.3,1.5-2.3,2.9-2.9,4c-2.2,4.7-13.8,35.1-13.8,35.1s-1.2-29.6,10.3-44c11.6-14.3,14.5-20.8,14.6-25.9c0-1.8,0.1-2.9,0.1-3.6
		c-0.1,2.9-1.2,9.8-7,13c-7.4,4-7.4,4-7.4,4s7.2-11.7,7.4-16c0.3-4.3-4.4-4.7-4.4-4.7c0.3,0,4.7-1.5,8.9-2.9
		C-285.8-996.9-284-994.3-283.2-992.1z M-333.3-1210.4c0,0,0,0,9.6,0.1c9.6,0.1,21.5-8,21.5-8
		C-314-1196.7-333.3-1210.4-333.3-1210.4z M-334.6-975.5v-11.7C-326.5-979.6-334.6-975.5-334.6-975.5z M-326.6-1255.8
		c2.5,2.4,2.9,2.6,3,2.6c-0.2,0-7.4-0.2-6.3-8.5C-329.9-1261.7-329.5-1258.5-326.6-1255.8z M-328.8-1268.5c0,0,1.8,8,5.2,11.2
		c3.4,3.2,3.4,3.3,3.4,3.3S-330.9-1255.8-328.8-1268.5z M-326.1-1271.5c0,0,0.4,4.8,8.4,9.9c8,5.1,11.4,7.7,11.4,7.7
		S-325-1254.7-326.1-1271.5z M-361-968c0-0.6,1.9-12.3,6.3-14.3c4.5-2.1,8.7-4.8,8.7-4.8s-4.8,16-7.4,19.2
		c-2.5,2.9-11.6,4.1-12.4,4.3C-365.2-963.9-361-968-361-968z M-348.7-972.9c1.5-6.6,8.3-14.3,8.3-14.3s1,13.4-2.8,18.5
		c-3.9,5.2-9.6,6.6-9.6,6.6S-350.2-966.2-348.7-972.9z M-356-1314.9l17.5,24.4l4.5,5.2C-333.9-1285.3-351.6-1299.7-356-1314.9z
		 M-337.9-882.9c0,0,2.1,12.3,5.2,15.7c3.2,3.4,3.2,3.4,3.2,3.4S-341.5-864.5-337.9-882.9z M-333.9-873.2
		c-2.2-5.7-1.1-15.2-1.1-15.2l1.4,5.1c-0.6-4.1,0-10.6,6.5-16.8c10.9-10.3,15.3-14.2,15.3-14.2s-15.3,16.1-15.8,22.5
		c0,0-1.5,13.5,0,20.4c1.5,6.9,3.4,9.1,3.4,9.1S-331.6-867.6-333.9-873.2z M-281-985.2c0-0.1,0-0.1,0-0.2c0,0,0,0,0,0.1
		C-281-985.3-281-985.2-281-985.2z M-297.4-1309.5c4.4,12,8,19.3,8,19.3l-3.7-14.9c0,0,11.9,6.9,13.8,12.8
		c1.9,5.9-4.1,14.1-4.1,14.1s1.8-8.7-0.1-11.4c-1.8-2.8-2.2-3.3-2.2-3.3s-0.8,12-3.4,18.2c-2.6,6.2-5,9-8.3,10.6
		c-3.3,1.7-9.9,0.8-9,1.2c1,0.4,8.5,1.5,11.3,3.7c2.8,2.2,5.9,5.9,8.3,6.9c2.3,1-5.7,9.2-9.6,9.8c-4,0.6-2.8-9.2-9-13
		c-6.3-3.9-20.7-15.2-19.8-19.8c0.8-4.7,19.7-10.9,20.8-23.4c1.1-12.5,1.1-17.5-8.5-27.3C-313-1326.2-301.7-1321.4-297.4-1309.5z
		 M-316.2-1295.6c0,0,0.3-1.8,0.6-7.3c0.3-5.4-3.2-14.9-3.2-14.9C-309.8-1306.9-316.2-1295.6-316.2-1295.6z M-313.1-1363.8
		c1.2,0.7,1.2,4.1-0.3,9.1c-1.5,5.1-6.3,6.5-7.4,6.3c-1.1-0.1-3-5.4-3-5.4s0,0.7-0.3,3.2c-0.3,2.5,1.9,2.8,2.8,5.4
		c0.8,2.6-1.7,4.4-5.2,4.5c-3.6,0.1-12,1.4-13.8,0.7c-1.8-0.7-1.5-6.9-1.1-8.4c0.4-1.5,4.4-0.4,4.7-0.4c0.3,0-0.6-0.7-1.9-1.8
		c-1.4-1.1-3-2.9-2.3-3.4c0.8-0.6,3,0.3,4.8,0.8c1.8,0.6,3,4.4,3,4.4s0.7-4-0.6-5.4c-1.2-1.4-4.1-0.4-5.4-1.7
		c-1.2-1.2-2.5-5.7-2.5-5.5c0,0,3.4-0.4,5.9,0.4c2.5,0.8,4.1,5.2,5.8,4.5c0.7-0.3,0.6-1.4,0.1-2.6c-0.3-0.4-0.6-0.7-0.7-1.1
		c-1-1.9-2.5-3-3.7-4.7c-0.1-0.3-0.4-0.4-0.6-0.7c-1.7-0.1-2.5,1.2-3.9,1.1c-1.8-0.3-2.3,1.5-4.1,1.5c-1.8,0-1.2-2.9-0.7-6.3
		c0.6-3.4-0.7-9-1.4-11c-0.7-2.1,2.2,0.1,3.4,2.1c-0.1-0.2-0.2-0.4-0.3-0.6c0,0,4.1,3.2,4.1,3.4c0.1,0.1-2.2-0.6-3.6-2.5
		c1.2,1.9,3.6,4.4,3.6,5.5c0,1.2,4.3,3.9,6.8,5.2c1.1,0.7,1.9,1.4,2.5,2.1c0.3,0,0.4-0.1,0.6-0.1c1.2-0.4-2.2-2.3-3-3.4
		c-0.8-1.1-2.1-3.7-0.6-3.7c1.7,0,6.8-1.4,6.8-1.4s-2.8-0.4-4.7-1.1c-1.9-0.7-2.1-1.8-2.1-3.2c0-1.4-2.5,0-4.1-0.6
		c-1.7-0.6-2.1-3-2.1-3s4.6,0.6,5.4,0.7c-0.7-0.2-4.4-1.6-7.6-3.1c-3.3-1.7-2.5-6.3-2.5-6.3l-0.1-3.2c0,0,1.4,0.4,3.2,0.4
		c1.8,0,8.7,2.9,8.7,2.9s0,0-1.4-1.2c-1.4-1.2-8.7-2.9-8.7-2.9s0.7,0,5,0.6c4.3,0.6,5.4,1.2,9,3.2c3.6,1.9,6.9,11.9,6.9,11.9
		l-3.2-2.1c0,0,1.2,2.3,3.4,5.5c0.3,0.4,0.4,0.8,0.7,1.2c0.3,2.6,0.6,6.2,0.1,7.7c-0.6,2.5-0.6,2.5-0.6,2.5l1.8-3.4
		c0,0.1,0,0.1,0,0.3c-0.4,1.2-1.1,3.9-0.8,3.7c0.1-0.1,1-0.6,1.7-1C-315.6-1364.4-313.7-1364.6-313.1-1363.8z M-342.2-1386.6
		c1.2,2.3,1.2,2.3,1.2,2.3s-2.6-2.1-3.2-4.8c-0.6-2.8,1-4.1,1-4.1S-343.4-1388.9-342.2-1386.6z M-345-1434.4c3-1.1,9.8,1.2,15,3.1
		c1.8,1.1,3.5,2.3,4.3,3.5c0.6,0.9,1.2,1.8,1.8,2.6c0.2,0.7,0.5,1.4,0.8,2.1c1.7,3.6,4.8,5.4,6.5,7.2c1.7,1.8,2.3,3.3,2.9,6.5
		c0.6,3.2,0.4,0.8,1.8-1.2c1.4-2.1-4.7-7.9-4.7-7.9s5.4,3.2,7.2,7.7s2.9,14.2,1.9,19.8c-1,5.5-7,6.1-7,6.1s0.7-0.4,2.5-3
		c1.9-2.8,1.8-8.1,1.8-8.1s-2.9,6.1-3.3,6.6c-0.4,0.6,0.4-2.8,1.2-9c0.8-6.2-1.7-8.5-1.7-8.5s-4.1,4.5-6.3,4.8
		c-2.3,0.3-0.7-3.7-0.4-6.6c0.4-2.9-2.1,1.4-3.4,0.1c-1.4-1.1,2.6-10.1,2.6-10.1c-5.8,9-12,7.7-12,7.7s7.3-5.2,8.1-7.2
		c1-1.9-1.4-0.4-1.4-0.4s-0.4,3-5.2,3.2c-4.8,0.1-8.4,5.2-8.4,5.2s1.1-2.9,1.2-5.9c0.3-3.2,5.4-3.9,3.2-4.4
		c-2.3-0.6,2.2-6.1,2.2-8.5c0-2.3-7.6-3.7-8.8-3.7c-1.2,0-0.6,14.9-0.6,14.9S-348.3-1433.1-345-1434.4z M-347.3-1433.3
		c0,0-1.1,5,0.4,8.3s2.2,5.5,2.2,5.5S-353.2-1426.1-347.3-1433.3z M-358.6-1427.3c0.4-2.5,5.2-5.9,5.2-5.9s-2.6,4.5-3.6,6.1
		c-1,1.5-3.6,8.8-3.6,8.8S-358.9-1425-358.6-1427.3z M-390.2-1391.7c0,0,3.3,3.3,6.8,4.7c3.4,1.4,3.9,1.2,3.9,1.2
		S-385.2-1382.3-390.2-1391.7z M-366.2-1389.6c-11.7,1.7-22.5,1.7-22.9-5.2c0,0,0.4-4.3,6.5,0c5.9,4.3,12.4-1,22.5-3.7
		c10.1-2.8,11.4-2.8,11.4-2.8s-10.7-0.1-17.5,2.2c-6.6,2.3-6.6,2.3-6.6,2.3s6.8-9.4,26.3-9.6c19.6-0.3,21.5,3.3,25.5,9.9
		c0.6,0.8,1,1.8,1.4,2.8c1.7,2.6,2.9,5.2,4,9c0.3,1,0.5,2.2,0.8,3.5c0,0.5,0.1,1.1,0.3,1.9c0.6,4.7,0.9,10.1,0.9,10.6
		c0,0.7-1-4.3-2.2-8.1c-0.4-1.4-1.1-3.3-1.7-5.2c-2.2-4.8-7.2-14.1-14.6-16.7C-342.2-1402.1-354.5-1391.1-366.2-1389.6z
		 M-391.3-1337.8c3.2,2.9,3.2,2.9,3.2,2.9s-1.1-3.4-5.7-5.4c-4.5-1.9-4.5-1.9-4.5-1.9s9.8,0.3,12.5,6.5c2.8,6.2,6.6,7.6,11.9,9.4
		s5.2,1.8,5.2,1.8s-11.6,0.4-16.8-3.9c-5.2-4.4-10.7-11.7-16.8-13C-402.3-1341.4-394.6-1340.7-391.3-1337.8z M-441.2-1467.4
		c0,0,1.8,0.1,1.8,1.9s-0.6,10.9-0.4,12.4c0.1,1.4,0.9,4.2,1,4.4c-0.1-0.2-2.5-1.3-2.2-2.9c0.3-1.7,1.4-13.1,0.6-14.3
		c-0.8-1.2-1.7-1.9-1.7-1.9L-441.2-1467.4z M-445.3-1471.6c1.4,1.2,1.1,6.5,1.1,9.8s1.4,9-0.6,8.5c-1.9-0.4-2.1-4.5-1.5-9.1
		c0.6-4.5,0-7.9,0-7.9c0.6,0-1.1-2.5-1.1-2.5S-446.7-1472.7-445.3-1471.6z M-455.8-1463.2c0,0-0.1,9,0.7,10.1c0.8,1,2.2,1.5,2.2,0
		c0-1.5,0.8-11.3,0.8-14.6c-0.1-3.3,1.5-2.5,1.9,0.7c0.4,3.2,0.3,10.3,1.4,11.9s-0.4,1.9-0.6,2.6c-0.1,0.7,2.9,1.5,3.7,3
		s-0.8,7.7,1.5,11c2.3,3.3,1.1,9.5,1.1,11s2.5-8.3,2.1-10.7s0-2.2,1-3.9c1-1.7-4.3-3.7-3.6-5.1c0.7-1.4,3.2,1.1,5.4,1.4
		c2.2,0.3,2.5,1.4,3.2,2.2c0.8,0.8,3.3-3.2,3-6.2c-0.3-3,0.7-3.3,0-3.9c-0.7-0.6-1.1-0.6-1.1-0.6s0.8-1.5,2.6-1.1
		c1.8,0.4,2.2,0,2.1,1.1c-0.1,1.1-2.9,8.3-2.9,10.2c0,1.9,0.4,5.1-0.8,5.4c-1.2,0.3-3.2,0.1-3.2,0.1s1.9,1.2,1.9,3.2
		c0,1.9-2.5,9.1-1.8,11.3c0.7,2.2-1.1,3.3-3.6,3.3c-2.5,0-9.4-0.8-9.4-0.8s3.7-8.7,0.7-14.7s-4-6.2-6.5-8.7c-2.5-2.5-4.7-3.3-4.7-7
		S-457.8-1461.2-455.8-1463.2z M-459.5-1449c0,0,1.9,3.4,5.1,5.9s3.2,2.5,3.2,2.5S-458.7-1440.3-459.5-1449z M-457.6-1404.5
		c0,0,0,0,2.8-1c2.8-1,11.3-3.3,11.3-3.3C-446.9-1404.7-457.6-1404.5-457.6-1404.5z M-440.9-1410c2.2-4.3,2.2-4.3,2.2-4.3
		s-6.9,3.7-6.5,3.2c0.4-0.6,6.1-5.7,6.1-5.7s-13.5-0.4-20.4,1.1c0,0,2.8-3.3,4.5-3.3c1.8,0,20.3-1.9,21.2,1
		c1.1,2.9,2.5,11.4,1.5,13.2c-1,1.8-24.9,2.2-24.9,2.2S-443.1-1405.7-440.9-1410z M-414.3-1326.2c-3.9-9.4-8.7-19.6-8.7-19.6
		s-4,10.2-1.2,16.3c2.8,6.1,2.8,6.1,2.8,6.1s-10.1-9.8-10.1-16.5c0-6.8,0-9.4,0-9.4s-4.7,3.6-4.3,2.9c0.4-0.7,4.5-6.6,4.5-10.1
		s0-3.4,0-3.4s-0.6,5.2-6.8,8c-6.2,2.8-6.2,9.8-6.2,9.8s-5.2-8.5,0-14.9c5.2-6.3,7.7-9.5,7.6-15.2c-0.1-5.6-0.1-5.6-0.1-5.6
		s-5.4,9.8-8.8,13.5c-3.4,3.7-3.4,3.6-3.4,3.6s4.8-16.7,6.8-25.5c0.8-3.6,1.4-6.2,1.9-8.1l-9.1,0.7l9.6-4.7l-12.5,1.7
		c0,0,13.2-5.4,16.3-4.1c3,1.2,0.7,6.8,0.7,12.5c0,2.7,0.6,5.3,1.3,7.8c0.3,2.3,0.6,4.9,0.9,6.9c0.7,4.6,2.1,15.7,7.6,20
		c0,0,0,0,0,0c1.8,2.1,3.4,3.3,3.4,3.3c2.3,3,2.6,3.6,2.6,3.6l-0.8-4.5l3.3,5.7c0,0,3.9-0.6,12.5,10.5c8.7,11,17.6,16,17.6,16
		s-13.1-4.1-18.7-12.1c-5.7-8-6.2-8.8-6.2-8.8s2.8,9.4,8.4,16.7c5.7,7.3,6.8,8.4,6.8,8.4S-410.6-1317-414.3-1326.2z M-394.2-1284.4
		c0,0,9.8,11.7,13.2,20.9c3.4,9.2,15.6,39.7,15.6,39.7L-394.2-1284.4z M-354.3-926.6c0.4,14.7-6.9,32-13.1,41.3
		c-6.2,9.4-3.2,17,3.7,23.7c4,3.9,9.8,7.9,14.2,10.7l-44.2,15.2c-0.7-3-1.5-6.3-1.9-9.9c-1-7.3-7.3-8.3-9.4-15.2
		c-1.4-4.5,2.3-10.6,2.6-16.3c-2.2-0.8-4.5-1.8-7-2.9c1.5,2.2,2.5,4.7,2.6,7.3c0.4,8.7-1,14.2,4.1,20.3c5.1,6.1,5,9.8,5,9.8
		s-7.9,1.2-11.7-3.2c-3.9-4.4-5.5-11.6-5.5-11.6l8.8,7.6c-1-0.8-7.9-7.2-6.9-15c1-8-0.6-13.6-4.4-19c-3-1.5-5.8-3.2-8-4.8
		c-9.6-7.3-9-11.7-5.2-25.9s4.5-16.1,4.5-16.1l-0.7,23c0,0,13.4-25.1,10.3-36.8c-3-11.7,11.7-21.1,14.9-27.1c3.2-5.9,4.8-16,4.8-16
		s3.4,4,2.5,12.5c-1,8.5-10.7,26.2-16.1,39.6s-9.2,30.3-7.2,40c1.1,5.4,8.4,11.3,14.9,15.6c-0.4-2.1-1.5-4-3.9-5.8
		c-9.2-6.9-9-13.5-7.9-23c1.1-9.4,15.2-42.3,18.3-50c3.2-7.9,10.3,1.7,22.5,8C-361.5-944.2-354.8-941.3-354.3-926.6z M-398-827.8
		c0,0-9.9,3.2-14.9,5c-5,1.8-5,1.8-5,1.8s9.2-3.9,9.2-9.2c0-5.2,0.7-9.2,0.7-9.2s7.7,1.5,9.4,4.5C-396.9-831.9-398-827.8-398-827.8z
		 M-392.9-988.3c0,0,4.1,8.1,2.1,15.3c-2.1,7.2-2.1,7.2-2.1,7.2S-392.5-979.5-392.9-988.3z M-433.3-1100.5c0,0,5.1-12,25.2-20.9
		c20.3-9,34.9-15.4,34.9-15.4s-38.9,20.7-46.6,26.6c-7.9,5.9-27.3,35.8-27.3,35.8L-433.3-1100.5z M-455.6-961.1
		c-1-6.2,0-12.3,1.8-16.8c0,0-1.5,5.8,0.6,15.6c2.1,9.8,5,20.1,5,20.1S-454.7-954.9-455.6-961.1z M-442.3-949.8
		c-2.5-4.7-2.8-5.2-2.8-5.2l1.1,8.7c0,0-7.3-12.4-7.4-19.4c-0.3-7-0.8-22.5-0.8-22.5s2.6,12.1,5.5,17c2.9,4.8,3.3,6.5,3.3,6.5
		l-3.3-15c0,0,11.3,7.9,12,15.3c0.7,7.4-4.5,39.8-4.5,39.8S-439.9-945.2-442.3-949.8z M-438.4-905.6c0,0,1.1,13.5,10.7,19.7
		c9.6,6.2,9.6,6.2,9.6,6.2S-442.8-876.3-438.4-905.6z M-551-765c19.5-17,50.6-21.4,50.6-21.4c29-10.3,264.4-93.6,269.6-93.6
		c5.5,0,45.3-17.1,45.3-17.1s-14.8,20.1-24.8,24.1c-7.6,3-213.1,73.4-281.1,90.9c0,0,0,0,0,0c-33.8,9.6-81.1,40-81.1,40
		S-570.5-748-551-765z M-588-723.9c-3.4-1.2,192-23.3,192-23.3S-558.7-713.3-588-723.9z M-177.9-734.5l-1.1,7c0.7,0,1.1,0,1.1,0
		l-1.2,0.2l-1.8,11.4l-83.5,1.5c0,0,56.5-5,70.2-10.1l-13.8,2.6l-202,10.3l-37.3-4.7c0,0,0.4-0.5,1.7-1.2
		c-37.2,1.8-71.4,1.9-94.7-1.5l309.5-20c0,0-57.1,8.1-124.5,14.6c58.8-1.3,135.5-2.6,165.1-3.1c10.3-11.8,12.4-50.7,12.4-50.7
		l7,66.5L-177.9-734.5z M126.1-714.4c-2-0.5-34.5,0-34.5,0l-1.5-59c0,0-4,56.5-7.5,59c-3.5,2.5-110.5,0-110.5,0l-10.1-48v43.5
		c0,0-1.9,2.7-7.9,5.6c-6,2.9-89.5,0-89.5,0l-2-51.3c0,0-4,59.1-12.7,52.6c-8.7-6.6-8.8-32.9-7.8-45.4s-6.5-32.5-6.5-32.5l14.5,14.5
		c0,0-14.5-19.5-14.5-25c0-3.2,6.6-11,12.1-16.9c-4.5,4-9.8,7.3-12.1,3.9c-4.5-6.5-4.5-40.5-9-43s-4.5-2.5-4.5-2.5s9,0.5,14.5,0
		s8,0,8,0l-9,6.5c0,0,6.5,0.5,6.5,9.5s0,18,0,18s10-12.1,7-23.8c-3-11.7,7,0.2,22.5,0c15.5-0.2,28-6.2,30,9.3c2,15.5,4,23.6,4,23.6
		s1.5-28.1,3-28.6S33-823.1,45-815.8s12,40.6,12,40.6l2.3-33.3c0,0,40.3-5.3,46.3,11.2S128.1-713.9,126.1-714.4z"
          />
        </g>
      </SvgIcon>
    );
  }

  return <SVGFile />;
};

export default withStyles(styles)(Logo);
