import React, { createContext, useState, useContext } from "react";

//var dateFormat = require("dateformat");

const defaultManagers = {
  attachmentManager: false,
  attachmentsManager: false,
  bodyPreview: false,
  closeEditorDialogOpen: false,
  coverManager: false,
  featureManager: false,
  loadDraft: true,
  saving: false,
  postPreview: false,
};

export const ManagersContext = createContext<{
  managers: { [key: string]: boolean };
  setManagersState: (stateName: string, value: boolean) => void;
  toggleManager: (manager: string) => void;
}>({
  managers: defaultManagers,
  setManagersState: () => {},
  toggleManager: () => {},
});
export const useManagers = () => useContext(ManagersContext);
const ManagersContextProvider: React.FC<{ children: any }> = ({ children }) => {
  const [managers, setManagers] = useState<{ [key: string]: any }>(
    defaultManagers
  );

  const toggleManager = (manager: string) => {
    const flip = !managers[manager];
    setManagers({ ...managers, [manager]: flip });
  };

  const setManagersState = (stateName: string, value: boolean) => {
    setManagers({ ...managers, [stateName]: value });
  };

  return (
    <ManagersContext.Provider
      value={{ managers, setManagersState, toggleManager }}
    >
      {children}
    </ManagersContext.Provider>
  );
};
export default ManagersContextProvider;
