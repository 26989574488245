import React from "react";
import { Grid, Theme } from "@mui/material";
import { createStyles, withStyles } from "@mui/styles";
import AdComponent from "../../../../components/Advertisements/adsense";
import PostAdministration from "./admin";
import PublishDate from "./date";
import Title from "./title";
import Subtitle from "./subtitle";
import Info from "./Info";
import Category from "./category";
import Cover from "./Cover";
//import { useAdminPost } from "../../../../context/Post/adminPostContext";

const styles = (theme: Theme) =>
  createStyles({
    pageTitle: {
      zIndex: 1,
      //background:
      //"linear-gradient(to bottom, rgba(250,250,250,1) 0%,rgba(250,250,250,1) 90%,rgba(250,250,250,0) 95%, rgba(250,250,250,0) 100%)",
      padding: "0 40px",
      //marginBottom: -theme.spacing(1),
      [theme.breakpoints.up("md")]: {
        marginTop: theme.spacing(3),
      },
      [theme.breakpoints.down("md")]: {
        padding: "0 24px",
      },
    },
  });

const Head: React.FC<{ classes: any }> = ({ classes }) => {
  //const { view } = useAdminPost();
  //const notPreview = view !== "preview";
  return (
    <React.Fragment>
      {false && <AdComponent />}
      <Grid container classes={{ root: classes.pageTitle }}>
        <PostAdministration />
        <PublishDate />
        <Category />
        <Title />
      </Grid>
      <Info />
      <Cover />
      <Grid container classes={{ root: classes.pageTitle }}>
        <Subtitle />
      </Grid>
    </React.Fragment>
  );
};

export default withStyles(styles)(Head);
