import React from "react";
import { Drawer, Grid, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import Admin from "./Admin";
import Controls from "./Controls";
import Network from "./Network";
import Posts from "./Posts";
import { useUser } from "../../../context/User/userContext";
import styles from "./styles";

const MainMenu: React.FC<{ classes: any }> = ({ classes }) => {
  const { dashboard, toggleDashboard } = useUser();
  const { main } = dashboard;

  const drawer = (
    <div className={classes.wrapper}>
      <Grid
        direction="column"
        container
        justifyContent="flex-start"
        spacing={4}
      >
        <Controls />
        <Network />
        <Posts />
        <Admin />
        <Grid item xs={12}>
          <Typography variant="h6" classes={{ root: classes.menu_item }}>
            About
          </Typography>
        </Grid>
      </Grid>
    </div>
  );

  return (
    <Drawer
      anchor="left"
      classes={{
        paper: classes.drawerPaper,
      }}
      open={main}
      onClose={() => toggleDashboard("main")}
    >
      {drawer}
    </Drawer>
  );
};

export default withStyles(styles)(MainMenu);
