import React, { useState } from "react";
import { FaSortDown, FaSortUp } from "react-icons/fa";
import {
  Avatar,
  Badge,
  Button,
  Grid,
  IconButton,
  Theme,
  Typography,
} from "@mui/material";
import { createStyles, withStyles } from "@mui/styles";
// import {
//   updateUserMutation,
//   createMessageMutation,
// } from "../../../../Admin/Helpers/adminQueries";
//import { useAuth } from "../../../../../context/Auth/authContext";
import Biography from "./biography";
// import SupportMember from "./support";
// import { useTrans } from "../../../context/Trans/translationContext";
// import { useCurrentProfile } from "../../../context/Profile/currentProfileContext";
//var _ = require("lodash");
const styles = (theme: Theme) =>
  createStyles({
    adminCon: {
      width: "100%",
      textAlign: "right",
    },
    actionButton: {
      margin: "0 8px",
      color: "white",
    },
    active: {
      // backgroundColor: theme.palette.primary.two,
      color: theme.palette.fonts.focus,
    },
    arrow: {
      color: theme.palette.fonts.detail,
    },
    avatar: {
      [theme.breakpoints.down("sm")]: {
        height: theme.spacing(9),
        width: theme.spacing(9),
      },
      [theme.breakpoints.up("sm")]: {
        height: theme.spacing(4.5),
        width: theme.spacing(4.5),
      },
      //  border: "1px solid " + theme.palette.secondary.main,
      boxShadow: theme.shadows[3], //"0 1px 5px 1px lightgrey"
    },
    avatarButton: {
      [theme.breakpoints.down("sm")]: {
        margin: "0 0 0 8px",
      },
      [theme.breakpoints.up("sm")]: {
        margin: "0 8px",
      },
      padding: 0,
    },
    badge: {
      "& svg": { color: theme.palette.primary.dark },
      "& span": {
        width: 15,
        height: 15,
        fontSize: 8,
        fontWeight: "bold",
        border: "1px solid",
        backgroundColor: theme.palette.color.blue,
        color: theme.palette.secondary.main,
        minWidth: "unset",
        paddingTop: 1.5,
      },
    },
    back: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.fonts.action,
      textShadow: theme.palette.shadow.textShadow,
      transform: "unset",
      boxShadow: theme.palette.shadow.boxShadow1,
      zIndex: 0,
    },
    bottomBar: {
      padding: "8px 0",
    },
    button_icon: {
      marginRight: 6,
    },
    detail: {
      color: theme.palette.fonts.main,
      fontStyle: "italic",
      fontSize: ".6rem",
      padding: "2px 4px",
    },
    user_name: {
      color: theme.palette.primary.main,
      fontFamily: "Gotham Book",
      fontSize: "1.3rem",
      fontWeight: "bold",
      //textShadow: theme.palette.shadow.textShadow,
      textTransform: "none",
    },
    user_headline: {
      color: theme.palette.fonts.focus,
      fontFamily: "Gotham Book",
      fontSize: ".7rem",
      //fontWeight: "bold",
      //textShadow: theme.palette.shadow.textShadow,
      textTransform: "none",
    },

    iconButton: {
      fontSize: "1.2em",
      padding: theme.spacing(1),
    },
    cashapp: {
      background: "linear-gradient(144deg, #28c101,#28c101,#3ca523)",
    },
    cashapp_label: {
      color: theme.palette.background.paper,
    },
    message_box: {
      width: "100%",
    },
    patreon: {
      background: "linear-gradient(144deg, #f96854,#f96854,#d63f2a)",
    },
    patreon_label: {
      color: "#052d49",
    },
    text: {
      color: theme.palette.fonts.main,
      fontSize: ".77rem",
    },
    topBar: {
      minHeight: 62,
    },
    toolbar: {
      // backgroundColor: theme.palette.primary.two,
      // borderBottom: "1px solid " + theme.palette.primary.border,
      boxShadow: theme.palette.shadow.boxShadow,
      zIndex: 1,
    },

    user: {
      height: "100%",
      //marginRight: theme.spacing.unit * 0.5
    },
    user_holder: {
      padding: theme.spacing(2),
    },
  });

interface UserProfileProps {
  classes: any;
  createMessage?: any;
  profile: any;
  updateUser?: any;
}
const ProfileHeader: React.FC<UserProfileProps> = ({ classes, profile }) => {
  //const { isAuthenticated, user } = useAuth();
  const isAuthenticated = false;
  const [open, setOpen] = useState<string | null>(null);
  // const [message, setMesssage] = useState("");
  // const { language } = useTrans();
  // const { currentProfile: profile } = useCurrentProfile();
  const {
    _id,
    avatar,
    name,
    // login,
    role,
  } = profile;

  const { user: user_name } = name;
  const { family_name, given_name } = user_name;
  const isApplicant = role === "Applicant";

  function toggleBio(biomsg: string) {
    if (biomsg === open) {
      setOpen(null);
    } else {
      setOpen(biomsg);
    }
  }
  // console.log("the profile", profile);
  const { login, user: user_avatar } = avatar;
  const userAvatar = user_avatar
    ? `https://res.cloudinary.com/defendhaiti/image/upload/w_80,c_scale/v1566866971/${user_avatar}.jpg`
    : login;

  // const handleMessageChange = (event) => {
  //   setMesssage(event.target.value);
  // };

  // const sendEmail = async (payLoad:any) => {
  //   // const token = await getTokenSilently()

  //   await axios
  //     .post(REACT_APP_MAIL_ENDPOINT, payLoad)
  //     .then((response) => {
  //       console.log("mailer response", response);
  //     })
  //     .catch((error) => {
  //       console.log("there was an  error", error);
  //     });
  // };

  // const sendMessage = async () => {
  //   console.log("sending");
  //   const variables = {
  //     to: _id,
  //     from: user._id,
  //     subject: "Your profile has been approved.",
  //     body: message || "No message.",
  //   };
  //   console.log(variables);
  //   await createMessage({
  //     variables,
  //   }).then((response) => console.log(response));
  // };

  // const reviewApplicant = async (status) => {
  //   //change user status
  //   if (status === "approved") {
  //     await updateUser({
  //       variables: {
  //         role: "Member",
  //         status: "active",
  //       },
  //     });
  //     await createMessage({
  //       to: _id,
  //       from: user._id,
  //       subject: "Your profile has been approved.",
  //       body: message || "No message.",
  //     });
  //     await sendEmail();
  //   } else if (status === "reject") {
  //     await updateUser({
  //       variables: {
  //         role: "Member",
  //         status: "needs-review",
  //       },
  //     });
  //     await createMessage({
  //       to: "zoemagltd@gmail.com", //login,
  //       from: user.login,
  //       subject: "Your profile is missing information.",
  //       body: message || "No message.",
  //     });
  //     await sendEmail();
  //   }
  // };

  return (
    <Grid container classes={{ root: classes.user_holder }}>
      <Grid classes={{ root: classes.topBar }} item xs={12}>
        <Grid container style={{ height: "100%" }} spacing={1}>
          <Grid item>
            <Grid container alignItems="center" className={classes.user}>
              {isAuthenticated && (
                <Grid item>
                  <Button onClick={() => toggleBio("msg")}>
                    {isApplicant ? "Review" : "Message"}
                  </Button>
                </Grid>
              )}
              {false && (
                <Grid item>
                  {open ? (
                    <FaSortUp
                      className={classes.arrow}
                      style={{ marginBottom: -5 }}
                    />
                  ) : (
                    <FaSortDown className={classes.arrow} />
                  )}
                </Grid>
              )}
              <Grid item>
                <IconButton
                  onClick={() => toggleBio("bio")}
                  color="inherit"
                  aria-label="Open Editor"
                  className={classes.avatarButton}
                >
                  <Badge
                    className={classes.buttonBadge}
                    badgeContent={0}
                    color="secondary"
                    invisible={true}
                  >
                    <Avatar
                      alt={_id}
                      src={userAvatar}
                      className={classes.avatar}
                      variant="rounded"
                    />
                  </Badge>
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs>
            <Grid container style={{ height: "100%" }}>
              <Grid item>
                <Typography
                  variant="h1"
                  classes={{ root: classes.user_name }}
                  color="primary"
                >
                  {given_name + " " + family_name}
                </Typography>
                {false && (
                  <Typography
                    variant="h2"
                    classes={{ root: classes.user_headline }}
                    color="primary"
                  >
                    Editor-in-Chief of The Haiti Sentinel
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {open && <Biography open={open} profile={profile} setOpen={setOpen} />}
    </Grid>
  );
};
// t(text)

export default withStyles(styles)(ProfileHeader);
// export default _.flowRight(
//   graphql(updateUserMutation, { name: "updateUser" }),
//   graphql(createMessageMutation, { name: "createMessage" }),
//   withStyles(styles)
// )(ProfileHeader);

//(withRouter(ProfileHeader));

/*

 const handleTabSwitch = (event, newValue) => {
    if (admin_page !== "admin") {
      handleMainTemplateChange("template", newValue);
    } else {
      handleMyPostsChange(newValue);
    }
  };
  const header_action = {
    create: () => history.push("/admin"),
    edit: () => history.push("/admin"),
    admin: () => history.push("/admin/create")
  };

  const header_action_button = {
    create: "x",
    edit: "x",
    admin: "+"
  };
  const header_title = {
    admin: "My Posts",
    create: "Creating Post",
    edit: "Editing Post"
  };
  const header_tabs = {
    admin: [
      { icon: <FaClock />, name: "Pending", value: "pending" },
      { icon: <FaDraftingCompass />, name: "Drafts", value: "draft" },
      {
        icon: <FaGlobeAfrica />,

        name: "Published",
        value: "published"
      }
    ],
    create: [
      {
        icon: <FaNewspaper />,
        name: "text",
        value: "text"
      },
      {
        icon: <FaVideo />,
        name: "video",
        value: "video"
      },
      {
        icon: <FaMicrophone />,
        name: "audio",
        value: "audio"
      },
      {
        icon: <FaComments />,
        name: "social",
        value: "social"
      },
      {
        icon: <FaImage />,
        name: "photo",
        value: "photo"
      }
    ],
    edit: [
      {
        icon: <FaNewspaper />,
        name: "text",
        value: "text"
      },
      {
        icon: <FaVideo />,
        name: "video",
        value: "video"
      },
      {
        icon: <FaMicrophone />,
        name: "audio",
        value: "audio"
      },
      {
        icon: <FaComments />,
        name: "social",
        value: "social"
      },
      {
        icon: <FaImage />,
        name: "photo",
        value: "photo"
      }
    ]
  };
<Grid item xs={4}>
              <div className={classes.adminCon}>
                <ButtonBase
                  className={classes.actionButton}
                  onClick={header_action[admin_page]}
                >
                  <Typography
                    variant="h1"
                    classes={{ root: classes.header }}
                    style={{ fontSize: 40, lineHeight: 1 }}
                  >
                    {header_action_button[admin_page]}
                  </Typography>
                </ButtonBase>
              </div>
            </Grid>

<AppBar classes={{ root: classes.back }} position="static">
          <Tabs
            classes={{
              root: classes.tabs,
              indicator: classes["indicator_" + value]
            }}
            onChange={handleTabSwitch}
            value={value}
            centered
          >
            {header_tabs[admin_page].map(type => (
              <Tab
                key={type.name}
                classes={{ root: classes.tab, selected: classes.active }}
                value={type.value}
                icon={type.icon}
                label={type.name}
              />
            ))}
          </Tabs>
        </AppBar>

*/
