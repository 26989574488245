import React from "react";
import { SvgIcon } from "@mui/material";
import { withStyles } from "@mui/styles";
const styles = theme => ({
  logo_title: {
    color: theme.palette.fonts.detail,
    fontSize: theme.spacing(4),
    transform: "translateY(1px)",
  },
});

class Logo extends React.Component {
  render() {
    const { classes } = this.props;

    function SVGFile(props) {
      return (
        <SvgIcon viewBox="0 0 300 300" {...props}>
          <g>
            <path d="M280.2,136.1c5.4,0.9,11.7-0.3,11.7-0.3s-3.1-0.2-5.5-0.3C284.1,135.5,280.2,136.1,280.2,136.1z" />
            <path
              d="M305.7,133.3c-1.9-2.4-7-3.5-8.7-3.5c-1.3,0-22.1-0.2-31.8-0.4c-0.4-0.7-0.9-1.2-0.9-1.2s1.2-0.3,1.3-0.5
		c0.1-0.2,6.5-0.2,6.5-0.2s0.1-4.9-0.7-5.1c-0.9-0.1-8.7-0.4-8.7-0.4h-1.1l-0.3-12.2h-6.6c0,0,0.7,4.6,0.4,5.3
		c-0.4,0.6-3.1,3.5-3.6,5.1c-0.6,1.6-0.6,3-0.6,3l-17-0.1c0,0-7.3-7.2-10.5-7.9c-3.2-0.6-20.9-0.7-20.9-0.7s-0.2-1.4-3.4-1.8
		c-3.2-0.4-32.6-1.2-32.6-0.5c0,0.7-0.1,2.2-1,1.8c-0.9-0.5-1.8-2.2-3.1-2.3c-1.3-0.1-7.4-0.5-8.8-0.5c-1.4,0-4.5,0.4-6.2,0.3
		c-1.7-0.1-1,1.5-1.8,1.7c-0.8,0.2-1,1.9-1,1.9l-63.9-0.6c0,0-0.6,1.5-1.8,1.8s-3.6,0.8-4.7,2.8c-1.1,1.9,0,2.8-1.7,3.2
		c-1.7,0.4-15.2,4-18.2,3.5c-3.1-0.6-7.1-1.4-12.2-0.8s-40.3,3.5-40.3,3.5s-2.7,0.7-1.6,4.5c0,0,0.6,27.1,1.1,28.1
		c0.5,1,1.4,2.6,3.6,1.8c2.2-0.8,66.5-25,66.5-25s2.6-1.5,4.6-0.6c2,0.9,2.6,3.6,1.4,5.6c-1.2,2-10,14.9-9.7,16.1
		c0.3,1.3,2.3,5.6,6.1,6.2s8.3,1,8.5-1.3c0.2-2.4,9.7-19.1,10.6-19.4c0.9-0.3,1.3-0.6,2.3,0.3c1,0.9,0.5,1.9,4.4,2.3
		c3.9,0.4,10.5,0.2,11.4-0.8s2.1-3.1,2.3-3.1c0.2,0,1.1-0.3,1.3,0.9c0.2,1.3-0.2,3.1,0.6,3.1s0.6-3.3,1.3-4c0.7-0.8,1-1.7,1-3.3
		s0-5.8,0-5.8l12.1,0.1c0,0,4.3,25.2,9.8,33.7c5.6,8.4,18,19.5,18,19.5s0.9,1.3,2.8,0c1.9-1.3,11.1-11.6,9.5-14.1
		s-11.8-9.4-17.2-19.1s-4.9-21.1-4.9-21.1s5.1,0.1,6.2-0.5c0,0,2.6,3,5,2.6c2.4-0.4,9-4,9-4s28.6,0.6,30.4,0.6s2.8-0.9,3.3-1.4
		c0.5-0.5,0.5-0.5,0.5-0.5H228c0,0-0.1,5.7,0.7,6.7s11.1,1.1,17.8,1.1c6.7,0,12.5,0,12.5,0s1.9,1.7,3.6,1.8c1.7,0.1,2.9,1.4,2.9-0.1
		c0-0.4,0.1-0.7,0.2-1.1c8.4,0.2,26.7,0.5,27.8,0.5c1.5,0,0.4,0.6,4-1.6c3.5-2.2,10.2-1.5,10.2-1.5S307.7,135.8,305.7,133.3z
		 M8.4,146.4c-0.4,0.6-1.7,1.7-3.1,1.3c-1.3-0.3-1.9-0.8-0.8-0.9c1.1-0.1,2.5-0.7,2.3-0.7c-0.2,0-1.9,0.1-2.3-0.2
		c-0.4-0.3-0.5-1.3,0.3-1.4c0.3-0.1,3.8-0.2,4.3,0.1C9.6,145,8.8,145.9,8.4,146.4z M30.8,142.5c-4.2,0.6-6.5,0-6.6-0.5
		c-0.1-0.5,1.1,0,3.6,0s9.5-1.4,9.7-1.4C37.4,140.5,35,141.9,30.8,142.5z M40.7,139.5c-2.2,0-6.2,0.8-7.7,1
		c-1.5,0.2-1.3,0.7-3.6,0.7s-5.8,0.4-6.4-0.2c-0.7-0.6,2.2-0.4,3.4-0.4c1.2,0,5.4-0.9,5.4-0.9s-0.2-0.1-2.5-0.3
		c-2.4-0.2-7.5-0.9-8.1-0.8c-0.6,0,1.5,0.8-1.1,1.4s-5.4,0.6-5.5-0.3c-0.1-0.4-0.7-0.8-1.3-1.1c-0.7,0.2-1.4,0.4-1.6,0.7
		c-1,1.1-3.4,1.7-4.7,1.9c-1.3,0.2,3.7,0.9,3.5,0.9s-3.9,0.1-4.5,0.4c-0.6,0.2,1.2,0.7,1.2,0.7s-1.5,0-2.3,0.3
		c-0.8,0.3-0.6-0.1-0.4-0.9c0.2-0.7-0.3-5-0.3-5s7.8-0.2,11.2-0.1c3.1,0,7.1-0.1,9,0c3.3,0.1,22,1.3,23.5,1.9
		C49.5,139.9,42.9,139.5,40.7,139.5z M62.1,128.5c-2.9,0.8-9.5,2.6-10.2,3.6c-0.7,1-27.2,0.5-27.2,0.5s9.3,1,15.9,1
		c6.6,0,10-0.2,14-1.5c4-1.3,5.2-1.1,5.2-1.1s-5.6,2.5-7,3.4c-1.4,0.9-6.8,1-12.7,1s-13.5,0-13.5,0s14.4,0.8,19.5,0.6s10.9,0,10.9,0
		s-3.1,1-8.2,1.4c-5.1,0.4-41.5-1.1-41.5-1.1s-2.7-0.6-3.1-1c-0.4-0.5-0.4-0.5-0.4-0.5s5.5,0.2,10.3,0.2s7.8-0.3,7.8-0.3l-13.3-1
		l7.7-0.9c0,0-9.9-0.4-10.4-0.1s-0.6,0.3-0.6,0.3s-0.8,0.9-1.1,0.2c-0.3-0.7-0.5-1.5-0.5-1.5s-0.8,2-0.6,3.1s0,1.3-0.1,1.1
		c-0.2-0.2-0.8-2.2-0.9-3.1c-0.1-0.9-0.1-1.3-0.1-1.3s0,0.7-0.4,1.9c0,0-0.5-2.2,0.4-3.4c0.9-1.2,30.1-2.9,30.1-2.9s13.4-2.4,16-1.5
		c0.2,0.1,0.3,0.1,0.5,0.2c-4.5,0.5-23.1,2.8-23.1,2.8s10.8-0.1,17.7-1c3.2-0.4,5.5-0.7,7.1-0.8c1.8,1.1,4,2.2,9,0.7
		c7.1-2.2,9.1-2.3,9.9-2.4C69.8,125,65,127.7,62.1,128.5z M76.6,130c-0.4-0.1-1.1-0.5-0.2-1.4c0,0,1-0.3,1.1,0.3
		C77.6,129.3,77,130.1,76.6,130z M77.7,127.1c-0.6-0.4-0.4-0.8-0.2-1.2c0,0,1.1-0.5,1.3,0.2C79,126.9,78.3,127.5,77.7,127.1z
		 M93.7,140.6c0,1.9-4,2.6-4.3,2.6c-0.1,0,0-0.2,0.3-0.4c-1.9,0.1-4.7,0-6.8,0c-3,3.1-10.7,14.2-10.7,14.2l8.8-14.1
		c-0.3,0-0.5,0.1-0.6,0.1c-0.8,0.5-4.1,5.7-3.7,4.1c0,0,2.6-4.3,2.7-6.1s0.3-1.8,2-2c1.7-0.2,1.7-0.2,1.7-0.2s-3.5,0.1-3.6-0.2
		c-0.1-0.3-0.1-0.5,0.4-0.5s13,0,13.4,0c0.4,0,0.7,0.5,0.7,1.7C93.7,140.1,93.7,140.3,93.7,140.6C93.7,140.5,93.7,140.5,93.7,140.6z
		 M86.2,129.1c0-0.2,0.8-1.3,0.5-1.7c-0.3-0.4-0.3-1.9,0.4-2.1c0.7-0.2,1.4-0.2,1.4,0.5c0,0.7,1.3,0.3,1.3,0.8
		c0.1,0.5-0.6,0.9-1.6,1.8c-0.9,0.9-2.8,2.9-2,2.9c0.7,0,2.2-2.3,2.8-1.9c0.6,0.4,1.5,1.3,0.6,1.9c-1,0.6-2.4,0.6-1.8,0.9
		c0.6,0.3,2.1-0.3,2.6,1.2c0.5,1.5,0.1,2.1-0.7,1.6c-0.8-0.5-1-1.2-2.2-1c-1.2,0.2-1.6-0.2-2.2,0.2c-0.6,0.4-2.8,0.1-1.8-0.8
		c1-0.9,1.4-1.3,1.4-2.6C84.7,129.6,86.2,129.1,86.2,129.1z M85.9,123h5.6C91.5,123,86,124.1,85.9,123z M82.1,121.8
		c0,0,35.8-1.4,39.1-1.2c3.3,0.2,7.2,0.2,7.2,0.2s-7.7-0.6-8.4-1.4c-0.7-0.8-4.5-0.7-7.1-0.4c-2.7,0.3-6.2,0.2-11.6,0.4
		c-5.4,0.2-24.6,0.4-23.2-0.7c0,0,0.6-1.2,2.4-1.3s2.2,0,2.2,0s-0.6-0.6,0.3-0.8c0.9-0.2,61.5-0.1,62.1,0.2c0.5,0.3,0.3,0.4,0.7,0.7
		c-0.5,0-1,0.1-1.6,0.1c-2.7,0-17.1,0.2-17.1,0.2s14.3,0.5,18.5,0.5c2,0,4.3-0.2,6.1-0.3c0.9,0,1.5,0.1,1.6,0.2
		c0.3,0.3,0.2,1.8-0.5,1.9c-0.7,0.1-1.9-0.8-3-0.8s-3.2,0.4-5,0.4c-1.8,0-8.6-0.6-8.7,0.4c-0.1,1-1,2.3-1.9,2.3
		S82.1,121.8,82.1,121.8z M94.8,133.7c0,0,1,0.8,0.9,1C95.5,134.8,94.3,134.3,94.8,133.7z M111,144.3c-1.1,1.1-2.3,2.2-7.2,2.1
		c-4.9-0.1-6.9-0.7-7.7-2.5c-0.8-1.8-1.1-5.5-1.1-5.5s-0.4-1.8,0-2.1c0.4-0.3,2.3-0.3,3.2,1.9c0.9,2.2,0.7,3.9,2.6,4.6
		c1.9,0.7,3.1,0.5,2.9,0.4c-0.2-0.2-3-3-2.9-4.8s1.5-2.9,2.6-2.9c1.1,0,7.7-0.6,8-0.2C111.8,135.6,112.1,143.2,111,144.3z
		 M110.6,132.9c0,0,0.5,1.1,0.8,1.2s-0.8,0.2-1.4,0S109.7,132.7,110.6,132.9z M115.2,127.4c-0.5,0.2-0.9,0.2-1.6,0.2
		c-0.7,0,0.2,0.4,0.1,0.6c0,0.2-3.4,0.2-3.4,0.2c-0.4,0-2.3-0.2-2.5-0.5c-0.2-0.3,3.7-0.2,4.1-0.3c0.3-0.1-0.1-0.2-0.5-0.5
		c-0.4-0.2,0.5-0.6,1.4-0.6C113.8,126.6,115.7,127.1,115.2,127.4z M116.9,129.7c0,0.3-0.3,0.6-0.6,0.6h-0.2c-0.3,0-0.6-0.3-0.6-0.6
		v-0.3c0-0.3,0.3-0.6,0.6-0.6h0.2c0.3,0,0.6,0.3,0.6,0.6V129.7z M135.2,140.7c0,0,0.7,5.4,1,6.2c0.3,0.8,0.3,0.8,0.3,0.8
		S134.4,143.3,135.2,140.7z M137,158.6c0,0,1.7,4.5,3.7,7.3C140.7,165.9,138.3,164.8,137,158.6z M136.6,151.8c0,0,2.4,8.6,8.8,17.7
		C145.5,169.5,138.5,163.3,136.6,151.8z M138.3,136.9c0,0,4.3,22.4,14.5,33.9C152.7,170.9,137.6,161.4,138.3,136.9z M144.7,129.5
		c0.3,0.1,2.1,0.4,2.1,1.1c0,0.7-0.7,2.2-1,1.9c-0.2-0.3-0.9-1.6-2.2-1.6c-1.3,0-3.9,1-3.2,0.2c0.1-0.1,0.7-1,1.2-1s1,0.2,1.6-0.5
		c0.6-0.7,2.1-2.1,2.1-1.6C145.3,128.5,144.4,129.3,144.7,129.5z M158.6,170.9c0,0-17.7-9.1-16.4-35.8
		C142.2,135.1,144.4,157.5,158.6,170.9z M156.6,163.7c0,0-11.8-9-10.5-28C146.1,135.7,146.9,152.6,156.6,163.7z M149.5,132.2
		c-0.4,0.2-1.1,0.1-1.4-0.2c-0.3-0.3,2.2-1.3,2.2-1.3C151,130.8,150,132,149.5,132.2z M153.5,128.8c-0.3-0.1-0.5-3.3-2.6-3.5
		c-2.1-0.2-40.4,1.1-41.5-0.8c-0.2-0.3,42.7-0.1,42.7-0.1s1.9-0.2,2.1,0.5S153.8,128.9,153.5,128.8z M176.6,114.9
		C176.6,114.9,176.6,114.9,176.6,114.9c0.2,0,8.6-0.2,11.6-0.1l2,0l-1,0.2c-0.2,0.3-2.3,1-3,1c-0.8,0,0.8,0.8,1.7,1
		c0.9,0.1,2.7,0.6,1.4,0.7c-1.3,0.1-8.6,1-9,0c-0.4-1-0.1-1.5-1.5-1.6c-2.8,0-6.1,0-6.9,0c-1.5-0.1-4.4-1-4.4-1L176.6,114.9z
		 M188.2,122.8c-0.7-0.4-1.3-1.5-2.4-1c-1.1,0.5,2.5,1.5,1.9,2.2c-0.6,0.6-4.4,0.3-6.7,0c-2.2-0.3-9.6-1.3-11.2-1
		c-1.6,0.3-3.2,2.9-3.5,0.2c0,0,1.7,0.1,1.9-1.4c0.2-1.5,4.2-1.9,5.2-0.7c1.1,1.2,7.2,2.4,8.5,1.1c1.3-1.2,5.2-1.7,6.5-1.3
		c1.3,0.4-0.3,1.3,1.2,1.3c1.5,0.1,5.9,1,5.9,1S189,123.2,188.2,122.8z M198.8,118.3c-0.6-0.8-1.7-1.8-3-2.1
		c-1.3-0.3-1.8-1.1-2.9-1.4c0,0,6.7-0.5,6.5,0c-0.2,0.5-1.7,0.6-1.5,0.7c0.2,0.1,1.4,0.2,1.5,0.9
		C199.6,117.1,199.4,119.1,198.8,118.3z M262.9,123.5c0,0,2.5,0.3,2.6,1s-0.6,1.4-1.3,1.7c-0.7,0.3-1.9-0.2-1.8-0.9
		C262.5,124.7,262.9,123.5,262.9,123.5z M253.1,120.5c0,0,0.5-2.2,1.4-2.7c0.9-0.5,3.8-0.4,4.4-0.4c0.6,0,0.3,2.6,0.3,3.1
		C259.3,121,252.3,121.2,253.1,120.5z M202.5,115.6c0,0,15.2,0.2,19.9,0.2c1.6,0.4,3.6,1.1,4.2,2.1c0,0-3.1-0.9-4.2-1
		c-1.1,0-19.9-0.6-19.9-0.6L202.5,115.6z M202.4,118.8c0,0,13.7-0.2,16.4,0.7c2.7,0.9,0.9,3.6,0.9,3.6s-12.6,0.6-13.8,0
		c-1.2-0.6-1.9-1-3.5-1.2C200.8,121.8,202.4,118.8,202.4,118.8z M219.6,128.2c-0.1,0.3-1.5,0-2,0s-12.1-0.1-12.3-0.2
		c-0.2-0.1-0.5-0.8-0.5-0.8s15.3-0.1,15.5,0C220.5,127.2,219.7,127.9,219.6,128.2z M234.1,136.7c-0.5,0-2.8-0.1-2.9-0.5
		c-0.1-0.4,2.8-0.5,2.9-0.7c0.1-0.2,0.4-0.1,0.4-0.1S234.6,136.7,234.1,136.7z M233.4,130.7c0,0,0.1-1,1-1h22.6l0.9,1H233.4z
		 M234.1,128.4l0.6-0.6c0,0,0.5-0.7,1.8-0.8c1.3-0.1,15.4,0.1,15.9,0c0.4-0.1,2.2,1,3.4,0.8c1.2-0.2,1.1,0.6,1.1,0.6H234.1z
		 M242.5,125.3c0,0-35.8,0-36.7,0c-0.9,0-2-0.9-2-0.9s24.7-0.5,25.1-0.3c0.4,0.2-1.5-1.2-1.5-1.2s1-0.1,2.4,0s3.1,1.4,3.1,1.4
		s24.1-0.3,26.9-0.3s2.2,0.9,2.2,0.9L242.5,125.3z M261.5,128.7c-0.3-0.1-0.6-1-0.6-1c0.5-1.5,1.2-0.6,1.5-0.5
		c0.3,0.1,0.5,0.8,0.5,1C262.9,128.4,261.8,128.8,261.5,128.7z M264.6,136.3c-0.5,0.1-1.6-0.1-1.6-0.1s-0.5-1.8,0.4-2
		c0.1,0,1.3,0,1.5,0.5C265.1,135.1,265,136.2,264.6,136.3z M302,134.6c-0.2,0-0.3,0-0.5,0c-2.5,0.2-7.3,2.4-7.5,2.6
		c-0.2,0.1-18.5-0.3-27.6-0.6c0.1-0.1,0.1-0.2,0.2-0.3c0,0,0,0,0,0c3.3,0.1,15.8-1,15.8-1l-15.7-0.3c-0.1-0.7-0.4-1.8-0.6-2.7
		c1.9-0.1,4.6-0.2,5.5-0.2c1.4,0,11-0.7,11-0.7h-16.7c-0.1-0.2-0.1-0.4-0.2-0.6c8.9,0,26,0.1,30.4,0.3c5.2,0.2,8.1,2.7,8.9,3.4
		c-0.7-0.2-2.2-0.5-4-0.4c-2.6,0.2-2.7,0.2-2.7,0.2S300.6,134.4,302,134.6z"
            />
          </g>
          <polygon
            points="57.2,3.8 57.2,13.4 62.2,13.4 60.1,57.3 54.8,60 54.8,70.3 95.6,56.1 95.6,43.4 85,45.8 84.9,50.3 69.6,54.8 
	70.4,37 85.9,33.9 85.9,25.3 71.1,27.8 71.8,11.8 86.7,10 86,15.4 97.1,13.9 97.7,1 "
          />
          <polygon
            points="100.1,1 99.8,9.7 105,9.7 103.2,45.8 97.7,46.5 97.3,55.5 119.3,51.6 119.3,43.4 114.4,43.8 114.6,30.4 130.7,29.8 
	130.7,21.9 114.8,22.1 115.5,8 130.7,8.1 130.7,12 140.7,11.8 140.7,0 "
          />
          <path
            d="M144,0.4v7.9h5.2v34.6h-5.8v7.4c0,0,30.3,0.2,42.7,3.9V42.5l-11.3-2l-0.4,3.7l-14.8-0.8l-0.3-13.9l15.3,0.8l0.1-8l-15.8-0.8
	l0.2-13.8l15.3,0.6l0.1,3.9l9.6,0.8l0.5-11.8L144,0.4z"
          />
          <path
            d="M186.6,1.2v8.4h5.6l1.1,37.6h-4.9l-0.5,7c0,0,16.9,4.2,21.3,6.6v-9l-5.3-2.6l-0.3-25l19.9,40l11.5,5.3l-2.5-56.4l5.1,1.1
	l-0.6-10l-20.4-1.6l0.1,9.6l5.4,0.3l1.8,30L202.2,1.2H186.6z"
          />
          <g>
            <polygon
              points="58.6,221.2 65.2,221.3 65.2,236.2 40.1,236.2 40.1,221.2 46.9,221.2 46.9,213.3 21.4,213.3 21.4,221.2 27.5,221.2 
		28.1,257.8 21.4,257.8 21.4,265.7 47.5,265.7 47.6,258.2 40.4,258.1 40.4,244.3 65,244.2 65.1,257.8 58.6,257.8 58.6,265.7 
		84.1,265.7 84.1,257.7 77.4,257.7 77.4,221.5 84.1,221.5 84.1,213.3 58.6,213.3 	"
            />
            <polygon
              points="151.7,220.7 157.7,220.7 157.7,257.8 151.7,257.8 151.7,265.2 176.7,265.7 176.7,258 170.2,257.7 169.7,221.2 
		176.7,221.1 176.7,213.3 151.7,213.3 	"
            />
            <polygon
              points="179.5,225.1 191.5,225.1 191.5,221.1 204.2,221.4 204.2,257.7 197.5,257.7 197.5,264.9 223,264.9 223,257.6 
		216,257.6 215.9,221.2 228.4,221.1 228.4,224.9 241.2,224.9 241.2,213.3 179.5,213.3 	"
            />
            <polygon
              points="270.5,213.3 245.4,213.3 245.4,221.2 252.1,221.2 252.1,258 245.4,258 245.4,265.8 270.5,265.8 270.5,258 
		263.9,257.7 263.5,221.3 270.5,221.1 	"
            />
            <path
              d="M123.6,213.3h-12.1L93,257.7l-6.6-0.1v8.1H112l0.1-8h-6.8l3.8-7.5l17.8,0.3l3.9,7.1l-7.5,0.4l0.3,7.8h24.9v-8h-6.1
		L123.6,213.3z M111.1,242.3l6.3-13.4l7.2,13.4H111.1z"
            />
          </g>
          <path
            d="M271.4,7.9l-31.2-2.8v9.5l4.8,0.8l1.5,50l-4.6-2.5l0,9.6L272,91.1l7.7-5V20.4L271.4,7.9z M256.4,69.5l-0.9-52.1l14,2.6
	l1.3,57.6L256.4,69.5z"
          />
          <path
            d="M46.9,4.7L15.1,7.6l-0.3,13.3l4.3-1.3l0.2,57.2l-5.4,4.8v12.6l29.8-18.8l8.8-11.8l1.7-51.3L46.9,4.7z M42.5,65.7l-14.4,6.4
	L30,18.2l14-2.8L42.5,65.7z"
          />
        </SvgIcon>
      );
    }

    return <SVGFile className={classes.logo_title} />;
  }
}

export default withStyles(styles)(Logo);
