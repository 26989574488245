import React from "react";
import axios from "axios";
import { FaYoutube } from "react-icons/fa";
import ReactPlayer from "react-player";
import { ButtonBase, Paper } from "@mui/material";
import { withStyles } from "@mui/styles";
const styles = theme => ({
  attachment_inner: {
    padding: theme.spacing(0.5),
  },
  button: { width: "100%", zIndex: 1 },
  container: {
    position: "relative",
  },
  icon: {
    background: "rgba(0,0,0,.3)",
    borderRadius: 2,
    bottom: theme.spacing(1),
    color: "white",
    fontSize: "1.3rem",
    padding: 2,
    position: "absolute",
    right: theme.spacing(1),
    zIndex: 2,
  },
  image: {
    width: "100%",
  },
  media: {
    overflow: "hidden",
    paddingTop: "56.25%",
    position: "relative",
  },
  paper: {
    boxShadow: theme.shadows[10],
  },
  player: {
    height: "100%",
    left: 0,
    position: "absolute",
    top: 0,
    width: "100%",
  },
});

const BASE = "https://www.googleapis.com/youtube/v3/playlists";
const KEY = "AIzaSyC3UvGEaGQy1Xh3XjWE5XEmkzhdNipIvCM";

class YouTubePlaylistPlayer extends React.Component {
  state = {
    thumbnail: "",
  };
  componentDidMount() {
    axios
      .get(`${BASE}?part=snippet&id=${this.props.info.source}&key=${KEY}`)
      .then(res => {
        console.log("The api result", res);
        this.setState({
          thumbnail: res.data.items[0].snippet.thumbnails.default.url,
        });
      });
  }

  render() {
    const { classes, info, open, toggleAttachment } = this.props;

    return (
      <Paper className={classes.paper}>
        <div className={classes.attachment_inner}>
          {open ? (
            <div className={classes.media}>
              <ReactPlayer
                className={classes.player}
                config={{
                  youtube: {
                    playerVars: {
                      loop: 1,
                      playlist: info.source,
                    },
                  },
                }}
                url={"https://www.youtube.com/embed/" + info.source}
                width="100%"
                height="100%"
                playing
              />
            </div>
          ) : (
            <div className={classes.container}>
              <FaYoutube className={classes.icon} />
              <ButtonBase
                classes={{ root: classes.button }}
                onClick={() => toggleAttachment()}
              >
                <img
                  src={this.state.thumbnail}
                  alt="youtube"
                  className={classes.image}
                />
              </ButtonBase>
            </div>
          )}
        </div>
      </Paper>
    );
  }
}

export default withStyles(styles)(YouTubePlaylistPlayer);
