import React from "react";
import classNames from "classnames";
import { Avatar, Grid, Theme, Typography } from "@mui/material";
import { createStyles, withStyles } from "@mui/styles";
var _ = require("lodash");
const styles = (theme: Theme) =>
  createStyles({
    avatar: {
      boxShadow: theme.palette.shadow.boxShadow,
      width: 12,
      height: 12,
    },
    logo: {
      background: "none",
      borderRadius: 0,
      height: 12,
      width: 12,
    },
    writer: {
      fontSize: ".64rem",
      lineHeight: 1,
      textAlign: "left",
      //   marginBottom: 8,
    },
  });

interface PostWriterContext {
  classes: any;
  center?: boolean;
  organization?: any;
  writer?: any;
}

const PostWriter: React.FC<PostWriterContext> = ({
  classes,
  center,
  organization,
  writer,
}) => {
  const User = () => {
    const { avatar, name } = writer;
    const { given_name, family_name } = name.user;
    const avatar_thumb = `https://res.cloudinary.com/defendhaiti/image/upload/w_24/v1566866971/${avatar.user}.jpg`;

    return (
      <React.Fragment>
        <Grid item>
          <Avatar
            classes={{ root: classes.avatar }}
            alt={given_name}
            src={avatar_thumb}
          />
        </Grid>
        <Grid item xs>
          <Typography
            component="p"
            variant="caption"
            classes={{ root: classes.writer }}
          >
            {`${given_name} ${family_name}`}
          </Typography>
        </Grid>
      </React.Fragment>
    );
  };

  const Org = () => {
    const { logo, name } = organization;
    const avatar_thumb = `https://res.cloudinary.com/defendhaiti/image/upload/w_24/v1566866971/${logo}_m`;

    return (
      <React.Fragment>
        <Grid item>
          <Avatar
            classes={{ root: classes.logo }}
            alt={name}
            src={avatar_thumb}
          />
        </Grid>
        <Grid item>
          <Typography
            component="p"
            variant="caption"
            classes={{ root: classNames(classes.writer, "writer") }}
          >
            {name}
          </Typography>
        </Grid>
      </React.Fragment>
    );
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent={center ? "center" : "flex-start"}
      alignItems="flex-start"
      style={{ width: "100%" }}
      spacing={1}
    >
      {organization && !_.isEmpty(organization) ? <Org /> : <User />}
    </Grid>
  );
};

export default withStyles(styles)(PostWriter);
