import React from "react";
import {
  Box,
  createStyles,
  Grid,
  GridSize,
  Theme,
  Typography,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import Avatar from "./Avatar";
import { useFoundation } from "../context/foundationContext";
//import Avatar from "../../Avatar";
//import { useHistory } from "react-router";
// var _ = require("lodash");
const txtSh = "1px 2px 4px rgba(0,0,0,.5)";
const txtSh2 = "1px 2px 5px rgba(0,0,0,.5)";
const styles = (theme: Theme) =>
  createStyles({
    subhead: {
      color: theme.palette.fonts.action,
      fontWeight: "bold",
      fontSize: ".6rem",
      marginLeft: theme.spacing(1.5),
      paddingRight: theme.spacing(0.5),
      textTransform: "uppercase",
    },
    head: {
      "& span": {
        fontSize: "1rem",
        padding: "0 16px 0 8px",
        background: theme.palette.shadow.background1,
        color: "#fafafa",
        textShadow: txtSh,
        textTransform: "uppercase",
        letterSpacing: 1,
        fontWeight: "bold",
        borderRadius: "0 4px 4px 0",
        boxShadow: theme.shadows[4], // "0 4px 12px rgba(4, 4, 0, 0.36)",
      },
    },
    network_heading: {
      textAlign: "right",
    },
    heading: {
      //  marginBottom: theme.spacing(2),
      "& span": {
        fontSize: "1.5rem",
        padding: "0 16px 0 8px",
        background: theme.palette.shadow.background1,
        color: "#fafafa",
        textShadow: txtSh2,
        textTransform: "uppercase",
        letterSpacing: 3,
        fontWeight: "bold",
        borderRadius: "0 4px 4px 0",
        boxShadow: theme.shadows[4], // "0 4px 12px rgba(4, 4, 0, 0.36)",
      },
    },
    holder: { marginBottom: theme.spacing(2), padding: "16px 0" },
    user: {
      color: theme.palette.fonts.focus,
      fontSize: ".5rem",
      lineHeight: 1,
      //  marginTop: 6,
      textAlign: "center",
    },
    userHolder: {
      marginBottom: 12,
      textAlign: "center",
    },
    wrapper: {
      //  marginBottom: 48,
    },
  });

const NetworkActivity: React.FC<{
  classes: any;
  // limit: number;
  wRow: GridSize;
}> = ({ classes, wRow }) => {
  const { latest_users } = useFoundation();

  return (
    <Box className={classes.wrapper}>
      <Typography variant="h6" classes={{ root: classes.network_heading }}>
        Recent<span>Users</span>
      </Typography>
      <Box>
        <Grid container classes={{ root: classes.holder }}>
          {latest_users.map((user: any) => {
            const { _id, name } = user;
            const { given_name, family_name } = name.user || name.login;
            return (
              <Grid
                classes={{ root: classes.userHolder }}
                item
                xs={wRow || 4}
                key={_id}
              >
                <Avatar user={user} button shadow width={24} />
                <Typography component="p" classes={{ root: classes.user }}>
                  {given_name}
                  <br />
                  {family_name}
                </Typography>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </Box>
  );
};

export default withStyles(styles)(NetworkActivity);
