import React, { createContext, useContext, useState } from "react";
//import { useLocation } from "react-router-dom";
import moment from "moment";
import { Post } from "../types";
//import isNode from "detect-node";
//import { useTrans } from "../../../context/Trans/translationContext";
// import { getFeedPostsQuery } from "../Admin/Helpers/adminQueries";
// import { useFoundation } from "../foundationContext";
// import { processCategoryPool } from "./functions";
// import processFeed from "../../utils/feedProcessor";

var _ = require("lodash");

interface ContextValues {
  allPosts: any;
  days: any;
  setDatesObject: (posts: Post[]) => void;
}

interface FeedContextType {
  children: any;
  //initial_posts_grouped_by_date: any[];
}

export const FeedContext = createContext<ContextValues>({
  allPosts: {},
  days: {},
  setDatesObject: () => {},
});
export const useFeed = () => useContext(FeedContext);

const FeedContextProvider: React.FC<FeedContextType> = ({
  children,
  // initial_posts_grouped_by_date,
}) => {
  const [allPosts, setAllPosts] = useState(null);

  // const [days, setDays] = useState({}); // Filtered Grouped Days
  const setDatesObject = (posts: any[]) => {
    const groupedDates = _.groupBy(posts, function (p: any) {
      const theDate = moment.unix(p.publishedAt / 1000);
      const theDay = moment(theDate).startOf("day").format();
      return theDay;
    });

    setAllPosts(groupedDates);
  };

  return (
    <FeedContext.Provider
      value={{
        allPosts,
        // changeDisplay,
        // clearDateFilter,
        // clearFilter,
        // date,
        days: allPosts,
        setDatesObject,
        // feedType,
        // feedDisplay,
        // filter,
        // loadMorePosts,
        // pageInfo,
        // pool,
        // selected,
        // setSelected,
        //  setAllPosts,
        // setFilter,
        // setPageInfo,
        // settingsDashboard,
        // toggleSettingsDashboard,
        // whereObject,
      }}
    >
      {children}
    </FeedContext.Provider>
  );
};

export default FeedContextProvider;
