import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import { useFoundation } from "../foundationContext";
import { useTrans } from "../Trans/translationContext";
import { useOldPost } from "./oldPostContext";
import { NewPostReducer } from "./newPostReducer";
import {
  Attachment,
  defaultPost,
  Language,
  Post,
  // PostType,
  StateEventCompactFunction,
  StateEventFunction,
} from "../types";
import { ObjectID } from "bson";

var _ = require("lodash");
var cloneDeep = require("clone-deep");
// const defaultContent = {
//   en: "Read more",
//   fr: "Lir plus",
//   ht: "Li plis",
// };
const defaultObject = {
  name: {
    en: "Read more",
    fr: "Lir plus",
    ht: "Li plis",
  },
  purpose: "link",
  source: "",
  enabled: false,
};

type TextInput = {
  content: string;
  changes: number;
};

const default_text_input: TextInput = {
  content: "",
  changes: 0,
};

type TransAction = (field: string) => void;
type TransActionDouble = (field: string, lang: string) => void;

type ActionFunc = {
  clearAll: TransAction;
  clearAllLangs: () => void;
  clearAllField: TransAction;
  clearField: TransActionDouble;
  copyAllField: TransAction;
  copyAllLangs: () => void;
  copyField: TransActionDouble;
  saveChanges: (
    field: string,
    content: string,
    reset: (o: any) => void
  ) => void;
  setAllPost: TransActionDouble;
  setEditing: (dev: string) => void;
  translateAll: TransAction;
  translateAllAll: TransAction;
  translateAllField: TransAction;
  translateAllLangs: TransAction;
  translateField: TransActionDouble;
};

type ContentSt = {
  bodyInput: TextInput;
  setBody: (default_text_input: TextInput) => void;
  titleState: TextInput;
  setTitle: (default_text_input: TextInput) => void;
  subtitleState: TextInput;
  setSubtitle: (default_text_input: TextInput) => void;
  linkNameState: TextInput;
  setLinkName: (default_text_input: TextInput) => void;
};

export const NewPostContext = createContext<{
  contentState: ContentSt;
  deleteCover?: Attachment;
  dev: string;
  editing: string;
  handleCategoryChange: (event: any) => void;
  handleDeleteCover: () => void;
  handleInputChange: StateEventFunction;
  handlePostChange: StateEventCompactFunction;
  isTranslating: boolean;
  newPost: Post;
  restoreCover: () => void;
  actionFunctions: ActionFunc;
  showDev: boolean;
  setShowDev: (state: boolean) => void;
  setSplitForm: (state: boolean) => void;
  splitForm: boolean;
}>({
  dev: "en",
  editing: "",
  handleCategoryChange: () => {},
  handleDeleteCover: () => {},
  handleInputChange: () => () => {},
  handlePostChange: () => {},
  isTranslating: false,
  newPost: defaultPost,
  restoreCover: () => {},
  showDev: true,
  setShowDev: () => {},
  setSplitForm: () => {},
  splitForm: false,
  actionFunctions: {
    clearAll: () => {},
    clearAllLangs: () => {},
    clearAllField: () => {},
    clearField: () => {},
    copyAllField: () => {},
    copyAllLangs: () => {},
    copyField: () => {},
    saveChanges: () => {},
    setAllPost: () => {},
    setEditing: () => {},
    translateAll: () => {},
    translateAllAll: () => {},
    translateAllField: () => {},
    translateAllLangs: () => {},
    translateField: () => {},
  },
  contentState: {
    bodyInput: default_text_input,
    titleState: default_text_input,
    subtitleState: default_text_input,
    linkNameState: default_text_input,
    setBody: () => {},
    setTitle: () => {},
    setSubtitle: () => {},
    setLinkName: () => {},
  },
});
export const useNewPost = () => useContext(NewPostContext);

const NewPostContextProvider: React.FC<{ children: any }> = ({ children }) => {
  const { cacheCheck, oldPost } = useOldPost();
  const new_post_from_old = _.omit(oldPost, [
    "attachments",
    "feature",
    "postType",
    "type",
  ]);

  const { fetchTranslation } = useTrans();
  const { getCategoryInfo } = useFoundation();

  const [splitForm, setSplitForm] = useState(true);
  const [newPost, dispatch] = useReducer(NewPostReducer, new_post_from_old);

  const {
    body = {},
    language = "en",
    link,
    subtitle = {},
    title = {},
  } = newPost;

  const [deleteCover, setDeleteCover] = useState();
  const [showDev, setShowDev] = useState(true);
  const [editing, setEditing] = useState<string>(language);

  // **** SET UP NEW POST **** //

  useEffect(() => {
    if (newPost && !_.isEmpty(oldPost) && typeof localStorage !== "undefined") {
      localStorage.setItem("newPost", JSON.stringify(newPost));
    }
  }, [newPost, oldPost]);

  useEffect(() => {
    if (
      ObjectID.isValid(new_post_from_old._id) &&
      new_post_from_old._id !== newPost._id &&
      typeof localStorage !== "undefined"
    ) {
      const new_post_to_load = cacheCheck(new_post_from_old, "newPost");
      loadPost(cloneDeep(new_post_to_load));
      localStorage.setItem("newPost", JSON.stringify(new_post_to_load));
    }
  }, [cacheCheck, new_post_from_old, newPost]);

  // **** EDIT NEW POST **** //

  const devAllText: false | string[] = !_.isEmpty(newPost) &&
    language && [
      title?.[language],
      subtitle?.[language],
      body ? body?.[language] : "",
      link?.title?.[language] || "",
    ];
  const editableLink = link || defaultObject;
  const editingTitle = !_.isEmpty(newPost) && title[editing],
    editingSubtitle = !_.isEmpty(newPost) && subtitle[editing],
    editingBody = !_.isEmpty(newPost) && body[editing],
    editingLinkName = !_.isEmpty(newPost) && editableLink?.title?.[editing];

  // Set Initial Temp States
  const [bodyInput, setBody] = useState({
    content: editingBody,
    changes: 0,
  });

  const [titleState, setTitle] = useState({
    content: editingTitle,
    changes: 0,
  });

  const [subtitleState, setSubtitle] = useState({
    content: editingSubtitle,
    changes: 0,
  });

  const [linkNameState, setLinkName] = useState({
    content: editingLinkName,
    changes: 0,
  });

  // If the Static Text changes, so does Temp
  useEffect(() => {
    setEditing(language);
  }, [language]);

  // useEffect(() => {
  //   setTitle({
  //     content: editingTitle,
  //     changes: 0,
  //   });
  // }, [editingTitle]);

  useEffect(() => {
    setSubtitle({
      content: editingSubtitle,
      changes: 0,
    });
  }, [editingSubtitle]);

  useEffect(() => {
    setBody({
      content: editingBody,
      changes: 0,
    });
  }, [editingBody]);

  useEffect(() => {
    setLinkName({
      content: editingLinkName,
      changes: 0,
    });
  }, [editingLinkName]);

  const postChanges = (field: string, content: string, lang: string) => {
    let postObj: Language;

    if (field === "link") {
      postObj = newPost.link.title;
      postObj[lang] = content;

      handlePostChange(field, { ...newPost.link, title: postObj });
    } else {
      postObj = newPost[field];
      postObj[lang] = content;
      handlePostChange(field, postObj);
    }
  };

  const saveChanges = useCallback(
    (field: string, content: string, reset: (o: any) => void) => {
      function postChanges(field: string, content: string, lang: string) {
        let postObj: Language;

        if (field === "link") {
          postObj = newPost.link.name;
          postObj[lang] = content;

          handlePostChange(field, { ...newPost.link, name: postObj });
        } else {
          postObj = newPost[field];
          postObj[lang] = content;
          handlePostChange(field, postObj);
        }
      }
      postChanges(field, content, editing);
      reset({ content, changes: 0 });
    },
    [editing, newPost]
  );
  // Check for Automatic Updates and Save
  useEffect(() => {
    if (titleState.changes > 12) {
      saveChanges("title", titleState.content, setTitle);
    }
  }, [titleState, saveChanges]);

  useEffect(() => {
    if (subtitleState.changes > 30) {
      saveChanges("subtitle", subtitleState.content, setSubtitle);
    }
  }, [subtitleState, saveChanges]);

  useEffect(() => {
    if (bodyInput.changes > 30) {
      saveChanges("body", bodyInput.content, setBody);
    }
  }, [bodyInput, saveChanges]);

  useEffect(() => {
    if (linkNameState.changes > 15) {
      saveChanges("link", linkNameState.content, setLinkName);
    }
  }, [linkNameState, saveChanges]);

  const handlePostChange: StateEventCompactFunction = (stateName, target) => {
    dispatch({
      type: "HANDLE_CHANGE",
      post: {
        stateName,
        target,
      },
    });
  };

  const handleInputChange: StateEventFunction = stateName => event => {
    console.log("changing", stateName, event.target.value);
    dispatch({
      type: "HANDLE_CHANGE",
      post: {
        stateName,
        target: { _id: event.target.value },
      },
    });
  };

  const handleCategoryChange = (event: any) => {
    const catID = event.target.value;
    const categoryObj = {
      __typeName: "Category",
      _id: catID,
      name: getCategoryInfo("categoryName", catID),
      url: getCategoryInfo("categoryURL", catID),
      division: {
        __typeName: "Division",
        _id: getCategoryInfo("divisionID", catID),
        name: getCategoryInfo("divisionName", catID),
      },
    };
    dispatch({
      type: "HANDLE_CHANGE",
      post: { stateName: "category", target: categoryObj },
    });
  };

  const handleDeleteCover = () => {
    const { cover } = newPost;
    const defaultCover = {
      name: "",
      postType: { photo: "photo" },
      source: "",
      template: "photo",
      type: "photo",
      purpose: "cover",
    };
    if (cover._id) {
      setDeleteCover(cover);
      dispatch({
        type: "HANDLE_CHANGE",
        post: {
          stateName: "cover",
          target: defaultCover,
        },
      });
    } else {
      dispatch({
        type: "HANDLE_CHANGE",
        post: {
          stateName: "cover",
          target: defaultCover,
        },
      });
    }
  };

  const restoreCover = () => {
    dispatch({
      type: "HANDLE_CHANGE",
      post: {
        stateName: "cover",
        target: deleteCover,
      },
    });
    setDeleteCover(undefined);
  };

  const loadPost = (post: Post) => {
    dispatch({
      type: "LOAD_POST",
      post,
    });
  };

  const setPostField = (text: any, lang: string, field: string) => {
    postChanges(field, text, lang);
  };

  const clearAll: TransAction = lang => {
    setAllPost(["", "", "", ""], lang);
  };

  const clearAllLangs = () => {
    _.map(["en", "fr", "ht"], (l: string) => {
      setAllPost(["", "", "", ""], l);
    });
  };

  const clearField: TransActionDouble = (field, lang) => {
    setPostField("", lang || editing, field);
  };

  const clearAllField: TransAction = field => {
    _.map(["en", "fr", "ht"], (l: string) => {
      clearField(field, l);
    });
  };

  const copyField: TransActionDouble = async (field, lang) => {
    const devText =
      field === "link" ? link.name[language] : newPost[field][language];
    setPostField(devText, lang || editing, field);
  };

  const copyAllField: TransAction = field => {
    const langs = _.pull(["en", "fr", "ht"], language);
    _.map(langs, (l: string) => {
      copyField(field, l);
    });
  };

  const setAllPost = (text: string | string[] | null, lang: string) => {
    const fields = ["title", "subtitle", "body", "link"];
    _.map(text || devAllText, (newText: string, idx: number) => {
      postChanges(fields[idx], newText, lang);
    });
  };

  const translateAll: TransAction = async lang => {
    if (devAllText) {
      const all_text_escaped = _.map(devAllText, _.escape);
      const response = await fetchTranslation?.(all_text_escaped, lang);
      if (response) {
        const all_text_unescaped = _.map(response, _.unescape);
        setAllPost(all_text_unescaped, lang);

        console.log(
          "whats returned",
          typeof all_text_unescaped,
          all_text_unescaped
        );
      }
    }
  };

  const copyAllLangs = async () => {
    const langs = ["en", "fr", "ht"];
    _.map(langs, (l: string) => {
      setAllPost("", l);
    });
  };

  const translateField: TransActionDouble = async (field, lang) => {
    const devText =
      field === "link" ? link.name[language] : newPost[field][language];
    const response = await fetchTranslation?.(devText, lang || editing);
    response && setPostField(response, lang || editing, field);
  };

  const translateAllAll: TransAction = async lang => {
    if (devAllText) {
      const all_text_array = _.map(devAllText, _.escape);

      console.log("escaped array", all_text_array);
      //const response =  (await fetchTranslation?.(devAllText, lang));
      //response && setAllPost(response, lang);
    }
  };

  const translateAllLangs: TransAction = async language => {
    const langs = _.pull(["en", "fr", "ht"], language);
    _.map(langs, (l: string) => {
      translateAllAll(l);
    });
  };

  const translateAllField: TransAction = async field => {
    const langs = _.pull(["en", "fr", "ht"], language);
    _.map(langs, (l: string) => {
      translateField(field, l);
    });
  };

  function firstOption() {
    const options = _.without(["en", "fr", "ht"], language);
    return options[0];
  }

  const isTranslating = !(language === editing);
  const editing_ = splitForm && !isTranslating ? firstOption() : editing;

  return (
    <NewPostContext.Provider
      value={{
        actionFunctions: {
          clearField,
          clearAll,
          clearAllField,
          clearAllLangs,
          copyField,
          copyAllField,
          copyAllLangs,
          saveChanges,
          setAllPost,
          setEditing,
          translateAll,
          translateAllAll,
          translateAllLangs,
          translateField,
          translateAllField,
        },
        contentState: {
          bodyInput,
          setBody,
          titleState,
          setTitle,
          subtitleState,
          setSubtitle,
          linkNameState,
          setLinkName,
        },
        deleteCover,
        dev: language,
        editing: editing_,
        handleCategoryChange,
        handleDeleteCover,
        handlePostChange,
        handleInputChange,
        isTranslating,
        // loadPost,
        newPost,
        restoreCover,
        setShowDev,
        setSplitForm,
        showDev,
        splitForm,
      }}
    >
      {children}
    </NewPostContext.Provider>
  );
};

export default NewPostContextProvider;
