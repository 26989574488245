import { Category } from "./types";

export function setCategories(categories: Category[]) {
  const divisionObject: { [key: string]: Category[] } = {
    undivided: [],
    News: [],
    Politics: [],
    Entertainment: [],
    Sports: [],
    Money: [],
    Lifestyle: [],
    World: [],
    History: [],
  };
  const categoryFindArray: any[] = [];

  categories.forEach(category => {
    let categoryObject = {
      _id: category._id,
      name: category.name,
      order: category.order,
      url: category.url,
    };

    let categoryFinderObject = {
      categoryName: category.name,
      categoryID: category._id,
      categoryURL: category.url,
      divisionName: category.division?.name,
      divisionID: category.division?._id,
    };
    category.division?.name &&
      divisionObject[category.division.name].push(categoryObject);
    categoryFindArray.push(categoryFinderObject);
  });
  //postContext.setSiteCategories(divisionObject, categoryFindArray);
  return { divisionObject, categoryFindArray };
}

// export function setWriters( getAllWriters: User[]) {
//   const writersArray:User[] = [];

//     getAllWriters.forEach((writer:User) => {
//       const {
//         _id,
//         order,
//         role,
//         title,
//         name,
//         firstName,
//         middleName,
//         lastName,
//         suffix,
//         email,
//         avatar,
//         avatar_baby,
//         birthDate,
//         biography,
//         cashapp,
//         facebook,
//         instagram,
//         twitter,
//         youtube,
//         handle,
//       } = writer;
//       let writerObject = {
//         _id: _id,
//         order: order,
//         role: role,
//         title: title,
//         name: name,
//         firstName: firstName,
//         middleName: middleName,
//         lastName: lastName,
//         suffix: suffix,
//         email: email,
//         avatar: avatar,
//         avatar_baby: avatar_baby,
//         birthDate: birthDate,
//         biography: biography,
//         cashapp: cashapp,
//         facebook: facebook,
//         instagram: instagram,
//         twitter: twitter,
//         youtube: youtube,
//         handle: handle,
//       };
//       writersArray.push(writer);
//     });
//   //  postContext.setSiteWriters(writersArray);
//     return writersArray;
//   }
// }
