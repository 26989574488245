import { gql } from "@apollo/client";

export const ADD_VIEW = gql`
  mutation ($_id: ID, $views: Int) {
    addView(_id: $_id, views: $views) {
      _id
      views
    }
  }
`;

export const GET_CATEGORIES = gql`
  query {
    getCategories {
      _id
      order
      name
      url
      division {
        _id
        order
        name
        url
      }
    }
  }
`;

export const GET_USERS = gql`
  query ($limit: Int, $where: String) {
    getUsers(limit: $limit, where: $where) {
      _id
      handle
      avatar {
        login
        user
      }
      name {
        login {
          alias
          family_name
          given_name
        }
        user {
          alias
          family_name
          given_name
        }
      }
    }
  }
`;

export const GET_USER = gql`
  query ($handle: String) {
    getUser(handle: $handle) {
      _id
      handle
      login
      log
      role
      status
      updatedAt
      avatar {
        baby
        login
        user
      }
      name {
        login {
          alias
          family_name
          middle_name
          given_name
          name
          prefix
          suffix
          title
        }
        user {
          alias
          family_name
          middle_name
          given_name
          name
          prefix
          suffix
          title
        }
      }
      contact {
        email
        phone
        social
      }
      organizations {
        _id
        name
        logo
      }
      personal {
        birth_date
        biography {
          en
          fr
          ht
        }
      }
      manages {
        _id
        name {
          login {
            alias
            family_name
            middle_name
            given_name
            name
            prefix
            suffix
            title
          }
          user {
            alias
            family_name
            middle_name
            given_name
            name
            prefix
            suffix
            title
          }
        }
      }
    }
  }
`;

export const GET_ENTITIES = gql`
  query ($limit: Int) {
    getEntities(limit: $limit) {
      _id
      alias
      logo_m
      name
    }
  }
`;

export const LOGIN_USER = gql`
  mutation loginUser(
    $given_name: String
    $family_name: String
    $login: String!
    $name: String
    $picture: String
  ) {
    loginUser(
      given_name: $given_name
      family_name: $family_name
      login: $login
      name: $name
      picture: $picture
    ) {
      _id
      handle
      login
      log
      role
      status
      updatedAt
      avatar {
        baby
        login
        user
      }
      name {
        login {
          alias
          family_name
          middle_name
          given_name
          name
          prefix
          suffix
          title
        }
        user {
          alias
          family_name
          middle_name
          given_name
          name
          prefix
          suffix
          title
        }
      }
      contact {
        email
        phone
        social
      }
      organizations {
        _id
        name
        logo
      }
      personal {
        birth_date
        biography {
          en
          fr
          ht
        }
      }
      manages {
        _id
        name {
          login {
            alias
            family_name
            middle_name
            given_name
            name
            prefix
            suffix
            title
          }
          user {
            alias
            family_name
            middle_name
            given_name
            name
            prefix
            suffix
            title
          }
        }
      }
    }
  }
`;

export const GET_FEED_DATES = gql`
  query ($type: String, $value: String) {
    getFeedDatesQuery(type: $type, value: $value) {
      _id
      postID
      postType {
        text
        video
        audio
        social
        photo
      }
      publishedAt
      template
      type
      title {
        en
        fr
        ht
      }
      views
      attachments {
        name
        source
        type
      }
      category {
        _id
        name
        url
        division {
          _id
          name
          order
          url
        }
      }
      cover {
        enabled
        name
        source
      }
      feature {
        name
        source
      }
      writer {
        avatar {
          login
          user
        }
        name {
          login {
            alias
            family_name
            middle_name
            given_name
            name
            prefix
            suffix
            title
          }
          user {
            alias
            family_name
            middle_name
            given_name
            name
            prefix
            suffix
            title
          }
        }
      }
    }
  }
`;

export const SAVE_POST_BY_ID_MUTATION = gql`
  mutation ($newPost: String) {
    savePostByIdMutation(new_post: $newPost) {
      _id
      language
      status
      postID
      publishedAt
      template
      type
      updatedAt
      version
      views
      postType {
        audio
        photo
        social
        text
        video
      }
      body {
        en
        fr
        ht
      }
      subtitle {
        en
        fr
        ht
      }
      title {
        en
        fr
        ht
      }
      attachments {
        _id
        alignment
        autoPlay
        credit
        enabled
        handle
        name
        source
        template
        type
        description {
          en
          fr
          ht
        }
        postType {
          audio
          photo
          social
          text
          video
        }
        title {
          en
          fr
          ht
        }
      }
      category {
        _id
        name
        url
        division {
          _id
          name
          url
        }
      }
      cover {
        _id
        context
        credit
        enabled
        name
        purpose
        source
        template
        type
        description {
          en
          fr
          ht
        }
        title {
          en
          fr
          ht
        }
      }
      feature {
        _id
        context
        credit
        enabled
        name
        postType {
          audio
          photo
          social
          text
          video
        }
        purpose
        source
        template
        type
        description {
          en
          fr
          ht
        }
        title {
          en
          fr
          ht
        }
      }
      link {
        _id
        context
        credit

        enabled
        name
        purpose
        source
        template
        type
        description {
          en
          fr
          ht
        }
        title {
          en
          fr
          ht
        }
      }
      writer {
        _id
        handle
        avatar {
          user
        }
        name {
          user {
            family_name
            given_name
          }
        }
      }
    }
  }
`;

export const GET_POST_QUERY = gql`
  query ($url: String, $postID: Int, $status: String) {
    getPostQuery(url: $url, postID: $postID, status: $status) {
      _id
      postID
      createdAt
      publishedAt
      updatedAt
      template
      type
      version
      views
      postType {
        audio
        photo
        social
        text
        video
      }
      body {
        en
        fr
        ht
      }
      subtitle {
        en
        fr
        ht
      }
      title {
        en
        fr
        ht
      }
      attachments {
        _id
        alignment
        autoPlay
        credit
        enabled
        handle
        name
        source
        template
        type
        postType {
          audio
          photo
          social
          text
          video
        }
        description {
          en
          fr
          ht
        }
        title {
          en
          fr
          ht
        }
      }
      category {
        _id
        order
        name
        url
        division {
          _id
          order
          name
          url
        }
      }
      cover {
        _id
        context
        credit
        enabled
        name
        purpose
        source
        template
        type
        description {
          en
          fr
          ht
        }
        title {
          en
          fr
          ht
        }
      }
      feature {
        _id
        context
        credit
        enabled
        name
        postType {
          audio
          photo
          social
          text
          video
        }
        purpose
        source
        template
        type
        description {
          en
          fr
          ht
        }
        title {
          en
          fr
          ht
        }
      }
      link {
        _id
        context
        credit
        enabled
        name
        purpose
        source
        template
        type
        description {
          en
          fr
          ht
        }
        title {
          en
          fr
          ht
        }
      }
      writer {
        _id
        handle
        avatar {
          user
        }
        name {
          user {
            family_name
            given_name
          }
        }
      }
    }
  }
`;

export const GET_POST_BY_ID_QUERY = gql`
  query ($_id: String) {
    getPostByIdQuery(_id: $_id) {
      _id
      language
      status
      postID
      publishedAt
      updatedAt
      template
      type
      version
      views
      postType {
        audio
        photo
        social
        text
        video
      }
      body {
        en
        fr
        ht
      }
      subtitle {
        en
        fr
        ht
      }
      title {
        en
        fr
        ht
      }
      attachments {
        _id
        alignment
        autoPlay
        credit
        enabled
        handle
        name
        source
        template
        type
        postType {
          audio
          photo
          social
          text
          video
        }
        description {
          en
          fr
          ht
        }
        title {
          en
          fr
          ht
        }
      }
      category {
        _id
        name
        url
        division {
          _id
          name
          url
        }
      }
      cover {
        _id
        context
        credit
        enabled
        name
        purpose
        source
        template
        type
        description {
          en
          fr
          ht
        }
        title {
          en
          fr
          ht
        }
      }
      feature {
        _id
        context
        credit
        enabled
        name
        postType {
          audio
          photo
          social
          text
          video
        }
        purpose
        source
        template
        type
        description {
          en
          fr
          ht
        }
        title {
          en
          fr
          ht
        }
      }
      link {
        _id
        context
        credit

        enabled
        name
        purpose
        source
        template
        type
        description {
          en
          fr
          ht
        }
        title {
          en
          fr
          ht
        }
      }
      writer {
        _id
        handle
        avatar {
          user
        }
        name {
          user {
            family_name
            given_name
          }
        }
      }
    }
  }
`;

export const GET_POSTS_QUERY = gql`
  query ($where: String) {
    getPostsQuery(where: $where) {
      _id
      createdAt
      postID
      publishedAt
      template
      type
      updatedAt
      version
      views
      body {
        en
        fr
        ht
      }
      subtitle {
        en
        fr
        ht
      }
      title {
        en
        fr
        ht
      }
      attachments {
        _id
        alignment
        autoPlay
        credit
        enabled
        handle
        name
        source
        template
        type
        description {
          en
          fr
          ht
        }
        title {
          en
          fr
          ht
        }
      }
      category {
        _id
        order
        name
        url
        division {
          _id
          order
          name
          url
        }
      }
      cover {
        _id
        context
        credit
        description {
          en
          fr
          ht
        }
        enabled
        name
        purpose
        source
        template
        title {
          en
          fr
          ht
        }
        type
      }
      feature {
        _id
        context
        credit
        description {
          en
          fr
          ht
        }
        enabled
        name
        purpose
        source
        template
        title {
          en
          fr
          ht
        }
        type
      }
      link {
        _id
        context
        credit
        description {
          en
          fr
          ht
        }
        enabled
        name
        purpose
        source
        template
        title {
          en
          fr
          ht
        }
        type
      }
      writer {
        _id
        handle
        avatar {
          user
        }
        name {
          user {
            family_name
            given_name
          }
        }
      }
    }
  }
`;
