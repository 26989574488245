var _ = require("lodash");

const rules = {
  user: {
    static: ["edit:profile", "view:notifications"],
  },
  applicant: {
    static: ["create:organization", "edit:profile", "send:message"],
    dynamic: {
      "view:pending:organization": ({ user, founder }) => {
        return user === founder;
      },
    },
  },
  member: {
    static: [
      "create:post",
      "edit:profile",
      "send:message",
      "view:profile",
      "view:drafts",
      "view:notifications",
    ],
    dynamic: {
      "edit:post": ({ manages, writer }) => {
        if (!manages || !writer) return false;
        return _.includes(_.map(manages, "_id"), writer);
      },
    },
  },
  // Writer: {
  //   static: [
  //     "create:post",
  //     "edit:profile",
  //     "view:profile",
  //     "view:drafts",
  //     "view:notifications",
  //   ],
  //   dynamic: {
  //     "edit:post": ({ manages, writer }) => {
  //       if (!manages || !writer) return false;
  //       return _.includes(_.map(manages, "_id"), writer);
  //     },
  //   },
  // },
  administrator: {
    static: [
      "activate:organization",
      "create:post",
      "delete:organization",
      "edit:post",
      "edit:published",
      "edit:profile",
      "edit:translations",
      "delete:post",
      "manage:users",
      "publish:post",
      "send:message",
      "view:profile",
      "view:drafts",
      "view:notifications",
    ],
  },
  founder: {
    static: [
      "activate:organization",
      "create:post",
      "delete:organization",
      "edit:views",
      "edit:post",
      "edit:published",
      "edit:profile",
      "edit:translations",
      "delete:post",
      "manage:users",
      "publish:post",
      "send:message",
      "view:profile",
      "view:drafts",
      "view:notifications",
    ],
  },
};

export default rules;
