import React from "react";
import { Link, useHistory } from "react-router-dom";
import { ButtonBase, Grid, Theme, Typography } from "@mui/material";
import { createStyles, withStyles } from "@mui/styles";
import { useAuth0 } from "@auth0/auth0-react";
import { useUser } from "../../../context/User/userContext";
import { useTrans } from "../../../context/Trans/translationContext";
import UserNotifications from "./userNotifications";
import SentinelLogo from "../../../../styles/graphics/sentinel_logo";
//import TodaysMan from "../../../styles/graphics/todaysman_small.png";

const LogoMobile = SentinelLogo;

//process.env.REACT_APP_TEMPLATE === "sentinel" ? SentinelLogo : TodaysMan;
const styles = (theme: Theme) =>
  createStyles({
    title: {
      fontWeight: 700,
      textTransform: "uppercase",
      [theme.breakpoints.down("sm")]: {
        fontSize: 18,
        height: 8,
        //textShadow: theme.palette.shadow.title,
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: "1.8rem",
        paddingTop: 14,
      },
    },
    title_mini: {
      fontFamily: "Gotham SSm A, Gotham SSm B",
      fontSize: ".9rem",
      fontStyle: "normal",
      fontWeight: 700,
      // fontSize: 12,
      // lineHeight: 1,
      textAlign: "left",
      // textTransform: "uppercase",
      // [theme.breakpoints.up("xs")]: {
      //   fontSize: 15,
      // },
      // [theme.breakpoints.up("sm")]: {
      //   fontSize: 22,
      // },
      // [theme.breakpoints.up("md")]: {
      //   fontSize: 28,
      // },
    },
    title_bar: {
      height: 50,
    },
    title_logo: {
      margin: "0 8px",
      "& a": {
        height: 0,
      },
    },
  });

const TitleBar: React.FC<{ classes: any }> = ({ classes }) => {
  let history = useHistory();
  const { isAuthenticated } = useAuth0();
  const { language, RouteTrans } = useTrans();
  const { isMobile } = useUser();

  return (
    <Grid className={classes.title_bar} item xs>
      <Grid
        container
        direction="row"
        justifyContent={isMobile ? "flex-start" : "center"}
        alignItems={isMobile ? "center" : "flex-start"}
        style={{ height: "100%", paddingTop: 7 }}
      >
        {isMobile && isAuthenticated ? (
          <>
            <Grid item>
              <ButtonBase
                onClick={() =>
                  history.push(`/${language}/${RouteTrans["posts"][language]}`)
                }
              >
                <LogoMobile />
              </ButtonBase>
            </Grid>
            <Grid item xs>
              <ButtonBase
                onClick={() =>
                  history.push(`/${language}/${RouteTrans["posts"][language]}`)
                }
              >
                <Typography className={classes.title_mini} variant="h4">
                  The Haiti Sentinel
                </Typography>
              </ButtonBase>
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs>
              <Typography
                className={classes.title}
                style={{ textAlign: "right" }}
                variant="h1"
              >
                <Link to={`/${language}/${RouteTrans["posts"][language]}`}>
                  The Haiti
                </Link>
              </Typography>
            </Grid>
            <Grid item>
              <Link to={`/${language}/${RouteTrans["posts"][language]}`}>
                <LogoMobile />
              </Link>
            </Grid>
            <Grid item xs>
              <Typography className={classes.title} variant="h1">
                <Link to={`/${language}/${RouteTrans["posts"][language]}`}>
                  Sentinel
                </Link>
              </Typography>
            </Grid>
          </>
        )}
        {isAuthenticated && (
          <Grid
            item
            style={
              isMobile ? {} : { paddingTop: 8, position: "absolute", right: 50 }
            }
          >
            <UserNotifications />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(TitleBar);
