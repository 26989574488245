import React, { useEffect, useState } from "react";
import { useApolloClient } from "@apollo/client";
import PublishedFeed from "./publishedFeed";
import { GET_FEED_DATES } from "../../../utilities/queries";
import { useFeed } from "../../../context/Feed/feedSettingsContext";
import { useTrans } from "../../../context/Trans/translationContext";

var _ = require("lodash");

const FeedFoundation: React.FC<{
  path?: any;
  division?: String;
  category?: String;
  user?: String;
}> = ({ path: route_path, division, category }) => {
  const client = useApolloClient();
  const [searchState, setSearchState] = useState(null);
  const { setDatesObject } = useFeed();
  const { setPathArray } = useTrans();

  useEffect(() => {
    setPathArray(route_path);
  }, [route_path, setPathArray]);

  useEffect(() => {
    function searchValues(category: any, division: any) {
      if (category) {
        const value = `${division}/${category}`;
        return {
          type: "category",
          value,
        };
      } else if (division) {
        return {
          type: "division",
          value: division,
        };
      } else {
        return {};
      }
    }

    function getFeedDates(variables: any) {
      client
        .query({
          query: GET_FEED_DATES,
          variables,
          fetchPolicy: "no-cache",
        })
        .then(({ data }: { data: any }) => {
          const { getFeedDatesQuery } = data;
          setSearchState(variables);
          setDatesObject(getFeedDatesQuery);
        });
    }

    const incoming_state = searchValues(category, division);
    if (!_.isEqual(searchState, incoming_state)) {
      getFeedDates(incoming_state);
    }
  }, [category, client, division, searchState, setDatesObject]);

  return <PublishedFeed />;
};

export default FeedFoundation;
