import React from "react";
import { FaFacebook, FaTwitter, FaSoundcloud, FaYoutube } from "react-icons/fa";
import { Grid, Theme } from "@mui/material";
import { createStyles, withStyles } from "@mui/styles";

const styles = (theme: Theme) =>
  createStyles({
    social: {
      fontSize: "1rem",
    },
    facebook: {
      color: theme.palette.icons.facebook,
    },
    soundcloud: {
      color: theme.palette.icons.soundcloud,
    },
    twitter: {
      color: theme.palette.icons.twitter,
    },
    youtube: {
      color: theme.palette.icons.youtube,
    },
  });

const Social: React.FC<{ classes: any }> = ({ classes }) => {
  return (
    <Grid item xs={12}>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        className={classes.social}
        spacing={2}
      >
        <Grid item>
          <a
            href="https://www.facebook.com/haitisentinel"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaFacebook className={classes.facebook} />
          </a>
        </Grid>
        <Grid item>
          <a
            href="https://www.twitter.com/haitisentinel"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaTwitter className={classes.twitter} />
          </a>
        </Grid>
        <Grid item>
          <a
            href="https://youtube.com/haitisentinel"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaYoutube className={classes.youtube} />
          </a>
        </Grid>
        <Grid item>
          <a
            href="https://soundcloud.com/haitisentinel"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaSoundcloud className={classes.soundcloud} />
          </a>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(Social);
