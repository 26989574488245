import React from "react";
import { FormattedMessage } from "react-intl";
//import ReactDOM from "react-dom";
import {
  FaFacebook,
  FaFilePdf,
  FaImage,
  FaImages,
  FaInstagram,
  FaMicrophone,
  FaSoundcloud,
  FaTwitter,
  FaVideo,
  FaYoutube,
  FaVimeo,
} from "react-icons/fa";

import {
  FormControl,
  //  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Theme,
} from "@mui/material";
import { createStyles, withStyles } from "@mui/styles";
//import PostLoader from "../../../../../../../../components/Loader";
import { useAttachment } from "../../../../context/Post/attachmentContext";

//import { createCoverProcess, createPostProcess } from "../Helpers/adminFunctions";
var _ = require("lodash");
const styles = (theme: Theme) =>
  createStyles({
    formControl: {
      width: "100%",
    },
    typeSelect: {
      textAlign: "right",
    },
    post: {
      color: theme.palette.secondary.main,
      fontSize: "2rem",
      lineHeight: 1,
      fontWeight: "bold",
      textAlign: "left",
      textTransform: "capitalize",
    },
    acrobat: {
      color: theme.palette.icons.acrobat,
    },
    instagram: {
      color: theme.palette.icons.acrobat,
    },
    soundcloud: {
      color: theme.palette.icons.soundcloud,
    },
    twitter: {
      color: theme.palette.icons.twitter,
    },
    facebook: {
      color: theme.palette.icons.facebook,
    },
    vimeo: {
      color: theme.palette.icons.vimeo,
    },
    youtube: {
      color: theme.palette.icons.youtube,
    },
  });

const Type: React.FC<{ classes: any }> = ({ classes }) => {
  const { attachment, handleChangeType } = useAttachment();
  const { postType, template } = attachment;
  // console.log("the attachment", attachment);
  const attachment_types: { [key: string]: any[] } = {
    text: [
      <MenuItem key="document" value="document">
        <FaFilePdf className={classes.acrobat} /> Document
      </MenuItem>,
    ],
    video: [
      <MenuItem key="youtube" value="youtube">
        <FaYoutube className={classes.youtube} /> YouTube
      </MenuItem>,
      <MenuItem key="facebookvideo" value="facebookvideo">
        <FaFacebook className={classes.facebook} /> Facebook Video
      </MenuItem>,
      <MenuItem key="vimeo" value="vimeo">
        <FaVimeo className={classes.vimeo} /> Vimeo
      </MenuItem>,
      <MenuItem key="localvideo" value="localvideo">
        <FaVideo /> Upload Video
      </MenuItem>,
    ],
    audio: [
      <MenuItem key="soundcloud" value="soundcloud">
        <FaSoundcloud className={classes.soundcloud} />
        SoundCloud
      </MenuItem>,
      <MenuItem key="localaudio" value="localaudio">
        <FaMicrophone /> Upload Audio
      </MenuItem>,
    ],
    social: [
      <MenuItem key="twitter" value="twitter">
        <FaTwitter className={classes.twitter} /> Twitter
      </MenuItem>,
      <MenuItem key="instagram" value="instagram">
        <FaInstagram className={classes.instagram} /> Instagram
      </MenuItem>,
      <MenuItem key="facebook" value="facebook">
        <FaFacebook className={classes.facebook} />
        Facebook Post
      </MenuItem>,
    ],
    photo: [
      <MenuItem key="localphoto" value="localphoto">
        <FaImage /> Upload Photo
      </MenuItem>,
      <MenuItem key="photoalbum" value="photoalbum">
        <FaImages /> Photo Album
      </MenuItem>,
    ],
  };

  const createType: { [key: string]: any } = {
    text: (
      <Select
        value={postType.text}
        onChange={handleChangeType("text")}
        input={
          <OutlinedInput
            label={
              <>
                <FormattedMessage id={`Attachment`} />{" "}
                <FormattedMessage id={`${_.upperFirst(template)} Type`} />
              </>
            }
            name="text"
          />
        }
        labelId="attachment-template-type-select"
      >
        {_.map(attachment_types.text)}
      </Select>
    ),
    video: (
      <Select
        value={postType.video}
        onChange={handleChangeType("video")}
        input={
          <OutlinedInput
            label={
              <>
                <FormattedMessage id={`Attachment`} />{" "}
                <FormattedMessage id={`${_.upperFirst(template)} Type`} />
              </>
            }
            name="video"
          />
        }
        labelId="attachment-template-type-select"
      >
        {_.map(attachment_types.video)}
      </Select>
    ),
    audio: (
      <Select
        value={postType.audio}
        onChange={handleChangeType("audio")}
        input={
          <OutlinedInput
            label={
              <>
                <FormattedMessage id={`Attachment`} />{" "}
                <FormattedMessage id={`${_.upperFirst(template)} Type`} />
              </>
            }
            name="audio"
          />
        }
        labelId="attachment-template-type-select"
      >
        {_.map(attachment_types.audio)}
      </Select>
    ),
    social: (
      <Select
        value={postType.social}
        onChange={handleChangeType("social")}
        input={
          <OutlinedInput
            label={
              <>
                <FormattedMessage id={`Attachment`} />{" "}
                <FormattedMessage id={`${_.upperFirst(template)} Type`} />
              </>
            }
            name="social"
          />
        }
        labelId="attachment-template-type-select"
      >
        {" "}
        {_.map(attachment_types.social)}
      </Select>
    ),
    photo: (
      <Select
        value={postType.photo}
        onChange={handleChangeType("photo")}
        input={
          <OutlinedInput
            label={
              <>
                <FormattedMessage id={`Attachment`} />{" "}
                <FormattedMessage id={`${_.upperFirst(template)} Type`} />
              </>
            }
            name="photo"
          />
        }
        labelId="attachment-template-type-select"
      >
        {_.map(attachment_types.photo)}
      </Select>
    ),
  };
  // if (!postType || !template) {
  //   return <PostLoader />;
  // } else {
  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <InputLabel id="attachment-template-type-select">
        <FormattedMessage id={`Attachment`} />{" "}
        <FormattedMessage id={`${_.upperFirst(template)} Type`} />
      </InputLabel>

      {createType[template]}
    </FormControl>
  );
  // }
};

export default withStyles(styles)(Type);
