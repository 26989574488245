import React from "react";
//import history from "../../context/Auth/history";
import { useHistory, useLocation } from "react-router-dom";
import classNames from "classnames";
import { FaBell, FaEnvelope } from "react-icons/fa";
import { Avatar, Badge, Grid, IconButton, Theme } from "@mui/material";
import { createStyles, withStyles } from "@mui/styles";
//import { MessagesContext } from "../Messages/Context";
//import { NotificationsContext } from "../Notifications/Context";
import { useTrans } from "../../../context/Trans/translationContext";
//import { useAuth } from "../../context/Auth/authContext";
import { useUser } from "../../../context/User/userContext";
var _ = require("lodash");

const styles = (theme: Theme) =>
  createStyles({
    avatar: {
      height: 26,
      width: 26,
      //   border: "1px solid " + theme.palette.secondary.main,
      boxShadow: theme.shadows[2], //"0 1px 5px 1px lightgrey"
    },
    avatarAdmin: {
      [theme.breakpoints.down("sm")]: {
        height: theme.spacing(3.5),
        width: theme.spacing(3.5),
      },
      [theme.breakpoints.up("sm")]: {
        height: 28,
        width: 28,
      },
      //  border: "1px solid " + theme.palette.color.green,
      boxShadow: theme.shadows[2], // "1px 2px 9px 2px rgba(68, 183, 68, 0.4)",
    },
    badge: {
      "& svg": { color: theme.palette.primary.dark },
      "& span": {
        width: 15,
        height: 15,
        fontSize: 8,
        fontWeight: "bold",
        border: "1px solid",
        //   backgroundColor: theme.palette.color.blue,
        color: theme.palette.secondary.main,
        minWidth: "unset",
        paddingTop: 1.5,
      },
    },
    button: {
      fontSize: "1.2em",
    },
    community: {
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(1.5),
        marginRight: theme.spacing(1.5),
      },
    },
    avatarButton: {
      [theme.breakpoints.down("sm")]: {
        margin: "0 0 0 8px",
      },
      [theme.breakpoints.up("sm")]: {
        margin: "0 8px",
      },
      padding: 0,
    },
    iconButton: {
      fontSize: 16,
      padding: theme.spacing(1),
    },
    notifications: {
      maxWidth: 115,
      textAlign: "left",
    },
    user: {
      height: "100%",
      //marginRight: theme.spacing.unit * 0.5
    },
  });

const UserNotifications: React.FC<{ classes: any }> = ({ classes }) => {
  //const { messages } = useContext(MessagesContext);
  // const { unreadMessagesCount } = useUser();
  //const { notifications } = useContext(NotificationsContext);
  const { language, RouteTrans } = useTrans();
  const { Can, user } = useUser();
  const adminDisplay = false;
  //let avatar;
  const history = useHistory();
  const { pathname } = useLocation();

  const Notices = [
    {
      name: "Notifications",
      count: [].length,
      icon: <FaBell />,
      path: "/notifications",
      perform: "view:notifications",
    },
    {
      name: "Messages",
      count: 0,
      // count: unreadMessagesCount(),
      icon: <FaEnvelope />,
      path: `/${language}/${RouteTrans["messages"][language]}`,
      perform: "send:message",
    },
  ];

  // const notProfile = !_.startsWith(
  //   pathname,
  //   `/${language}/${RouteTrans["profile"][language]}`
  // );
  // console.log(
  //   pathname,
  //   `/${language}/${RouteTrans["profile"][language]}`,
  //   notProfile
  // );
  const isDashboard = _.startsWith(
    pathname,
    `/${language}/${RouteTrans["dashboard"][language]}`
  );

  const goAvatar = isDashboard
    ? `/${language}/${RouteTrans["profile"][language]}`
    : `/${language}/${RouteTrans["dashboard"][language]}`;

  function UserAvatar() {
    if (user?.avatar) {
      const { _id, avatar } = user;
      const { login, user: user_avatar } = avatar;

      //  const userAvatar = `https://res.cloudinary.com/defendhaiti/image/upload/v1566866971/users/sam_sm.jpg`;

      // https://res.cloudinary.com/defendhaiti/image/upload/v1581081588/users/999u9v2.jpg
      const userAvatar = user_avatar
        ? `https://res.cloudinary.com/defendhaiti/image/upload/w_80,c_scale/v1566866971/${user_avatar}.jpg`
        : login;

      //console.log("avatar", userAvatar);
      return (
        <Grid item>
          <Grid container alignItems="center" className={classes.user}>
            <Grid item>
              <IconButton
                onClick={() => history.push(goAvatar)}
                color="inherit"
                aria-label="Open Editor"
                className={classes.avatarButton}
              >
                <Badge
                  className={classes.buttonBadge}
                  badgeContent={0}
                  color="secondary"
                  invisible={true}
                >
                  <Avatar
                    alt={_id}
                    src={userAvatar}
                    classes={{
                      root: classes[adminDisplay ? "avatarAdmin" : "avatar"],
                    }}
                  />
                </Badge>
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      );
    } else {
      return null;
    }
  }

  return (
    <Grid container className={classes.user}>
      <Grid item className={classes.community}>
        <Grid
          alignItems="center"
          container
          className={classNames(classes.user, classes.notifications)}
        >
          {Notices.map(notice => {
            const { count, icon, name, path, perform } = notice;
            const notices = count === 0;
            return (
              <Grid item xs key={name}>
                <Can
                  role={user?.role}
                  perform={perform}
                  yes={() => {
                    return (
                      <IconButton
                        color="inherit"
                        aria-label="Open Editor"
                        className={classes.iconButton}
                        onClick={() => history.push(path)}
                      >
                        <Badge
                          classes={{ root: classes.badge }}
                          badgeContent={count}
                          color="secondary"
                          invisible={notices}
                        >
                          {icon}
                        </Badge>
                      </IconButton>
                    );
                  }}
                />
              </Grid>
            );
          })}
        </Grid>
      </Grid>
      <UserAvatar />
    </Grid>
  );
};

export default withStyles(styles)(UserNotifications);
