import React from "react";
import { Grid, Theme, Typography } from "@mui/material";
import { createStyles, withStyles } from "@mui/styles";
import { useTrans } from "../../../../context/Trans/translationContext";
import { useCurrentPost } from "../../../../context/Post/currentPostContext";
const styles = (theme: Theme) =>
  createStyles({
    titleBottom: {
      marginBottom: theme.spacing(6),
    },
    postSubtitle: {
      color: theme.palette.primary.main,
      fontFamily: "Gotham Narrow SSm A, Gotham Narrow SSm B",
      fontSize: "1.45rem",
      fontStyle: "normal",
      fontWeight: 400,
      //  fontWeight: "bold",
      lineHeight: "1.5",
      textTransform: "unset",
      [theme.breakpoints.up("sm")]: {},
      //[theme.breakpoints.up("sm")]: { textShadow: "1px 1px 4px black" }
    },
  });

const Subtitle: React.FC<{ classes: any }> = ({ classes }) => {
  const { language } = useTrans();
  const { currentPost } = useCurrentPost();
  const { subtitle } = currentPost;
  return (
    <Grid item xs={12} className={classes.titleBottom}>
      <Typography variant="h2" className={classes.postSubtitle}>
        {subtitle[language]}
      </Typography>
    </Grid>
  );
};

export default withStyles(styles)(Subtitle);
