import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import {
  ButtonBase,
  Grid,
  // Theme,
  Typography,
  useTheme,
} from "@mui/material";
import { createStyles, withStyles } from "@mui/styles";
import { useTrans } from "../../../../context/Trans/translationContext";
import { useCurrentPost } from "../../../../context/Post/currentPostContext";
import { getBaseURL } from "../../../../utilities/postRenders";
import { useHistory } from "react-router";
import { useFoundation } from "../../../../context/foundationContext";
import { useUser } from "../../../../context/User/userContext";

var _ = require("lodash");

const styles = () =>
  createStyles({
    menu_heading: {
      fontSize: ".8rem",
      textTransform: "uppercase",
    },
    text: {
      "&:hover": {
        color: "black !important",
      },
    },
  });

const PostsMenu: React.FC<{
  classes: any;
}> = ({ classes }) => {
  let history = useHistory();
  const theme = useTheme();

  const { currentPost } = useCurrentPost();
  const { categoryList } = useFoundation();
  const { language, RouteTrans } = useTrans();
  const { Can, pathname, toggleDashboard, user } = useUser();

  const [open, setOpen] = useState<string | null>(null);

  function goToLink(link: string) {
    history.push(link);
    toggleDashboard("main");
  }

  function makeSubListItem(category: any) {
    const { name, url } = category;
    const url_array = _.split(url, "/");
    const div = url_array[0];
    const cat = url_array[1];

    const path = `/${language}/${RouteTrans["posts"][language]}/${RouteTrans[div][language]}/${RouteTrans[cat][language]}`;
    return {
      name,
      path,
    };
  }

  function makeListItem(division: string) {
    const div_path = `/${language}/${RouteTrans["posts"][language]}/${
      RouteTrans[_.toLower(division)][language]
    }`;

    const sub_links = _.map(categoryList[division], makeSubListItem);

    return {
      name: division,
      path: div_path,
      sub_links,
    };
  }
  const links = _.map(
    [
      "News",
      "Politics",
      "Entertainment",
      "Sports",
      "Money",
      "Lifestyle",
      "World",
      "History",
    ],
    makeListItem
  );

  const admin_links = [
    {
      name: "Manage",
      path: `/${language}/${RouteTrans["manage"][language]}/${RouteTrans["posts"][language]}`,
    },
    {
      name: "Create",
      path: `/${language}/${RouteTrans["create"][language]}/${RouteTrans["post"][language]}`,
    },
    {
      name: "Edit",
      path: _.isEmpty(currentPost)
        ? false
        : getBaseURL(currentPost, language, true),
    },
  ];

  function PostLink({ admin, link }: { admin?: boolean; link: any }) {
    const { name, path, sub_links } = link;
    const isOpen = open === name;
    const isActive = _.startsWith(pathname, path);

    return (
      <Grid container>
        <Grid
          item
          xs={12}
          style={{
            borderBottom: isOpen
              ? "1px solid " + theme.palette.fonts.detail
              : "none",
            textAlign: isOpen ? "left" : "right",
          }}
        >
          <ButtonBase
            onClick={() => (isOpen || admin ? goToLink(path) : setOpen(name))}
          >
            <Typography
              classes={{ root: classes.text }}
              variant="h6"
              style={{
                color: isActive
                  ? "black"
                  : admin
                  ? theme.palette.fonts.main
                  : theme.palette.fonts.detail,
              }}
            >
              <FormattedMessage id={name} />
            </Typography>
          </ButtonBase>
        </Grid>
        {isOpen && (
          <Grid item xs={12} style={{ marginBottom: 12 }}>
            <Grid
              container
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-end"
            >
              {sub_links.map((link: any, idx: number) => {
                const { name, path } = link;
                const isActive = _.startsWith(pathname, path);
                return (
                  <Grid key={idx} item xs={12}>
                    <ButtonBase onClick={() => goToLink(path)}>
                      <Typography
                        classes={{ root: classes.text }}
                        style={{
                          color: isActive ? "black" : theme.palette.fonts.main,
                          fontSize: ".76rem",
                        }}
                        variant="caption"
                      >
                        <FormattedMessage id={name} />
                      </Typography>
                    </ButtonBase>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  }

  return (
    <Grid item xs={12}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" classes={{ root: classes.menu_heading }}>
            Posts
          </Typography>
        </Grid>
        <Can
          role={user?.role}
          perform={"create:post"}
          yes={() => {
            return (
              <Grid item xs={12}>
                <Grid container>
                  {admin_links.map((link: any, idx: number) => {
                    return (
                      <Grid key={idx} item xs={12}>
                        <PostLink admin link={link} />
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            );
          }}
        />

        <Grid item xs={12}>
          <Grid container>
            {links.map((link: any, idx: number) => {
              return (
                <Grid key={idx} item xs={12}>
                  <PostLink link={link} />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(PostsMenu);
