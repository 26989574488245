import { createStyles, Theme } from "@mui/material";
const styles = (theme: Theme) =>
  createStyles({
    button: {
      borderColor: theme.palette.fonts.action,
      color: theme.palette.fonts.action,
      // marginTop: theme.spacing(3),
      width: "100%",
    },
    button_holder: {
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
    },
    cardContent: {
      padding: theme.spacing(1),
    },
    icon: {
      marginLeft: theme.spacing(1),
      fontSize: "1rem",
    },
    image: {
      background: theme.palette.background.default,
      boxShadow: theme.shadows[10],
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
      "& img": {
        width: "100%",
      },
    },
    coverCredit: {
      color: theme.palette.fonts.action,
      fontSize: ".8rem",
      fontStyle: "italic",
    },
    coverDescription: {
      color: theme.palette.fonts.action,
      fontSize: ".65rem",
    },
    coverImage: {
      width: "100%",
    },
    coverInfo: {
      padding: "0 4px",
    },
    deletedCoverFile: {
      color: theme.palette.fonts.action,
      fontSize: ".6rem",
      textAlign: "left",
    },
    coverFile: {
      color: theme.palette.fonts.detail,
      fontSize: ".5rem",
      marginBottom: 4,
      textAlign: "left",
    },
    coverTitle: {
      color: theme.palette.fonts.focus,
      fontSize: ".95rem",
      margin: 0,
    },
    deleted: {
      color: theme.palette.color.red,
      fontWeight: "bold",
    },
    deleteCoverTitle: {
      fontSize: ".8rem",
    },
    modify: {
      // width: "100%",
      //textAlign: "right"
    },
    remove: {
      //  width: "100%",
      textAlign: "right",
    },
    selectedPhoto: {
      padding: theme.spacing(1),
    },
  });
export default styles;
