import React from "react";
import {
  FaAngleDoubleRight,
  FaAngleDoubleLeft,
  FaAngleRight,
  FaAngleLeft,
} from "react-icons/fa";
import { IconButton, TableCell, Theme, useTheme } from "@mui/material";
import { createStyles, withStyles } from "@mui/styles";
import { useMyPosts } from "../../../../../context/Feed/myPostsFeedContext";

const styles = (theme: Theme) =>
  createStyles({
    cell: {
      padding: 8,
    },
    cover: {
      textAlign: "center",
      width: 90,
    },
    disabled: {
      "& span": {
        color: theme.palette.fonts.faint + " !important",
      },
    },
    divider: {
      //  backgroundImage: theme.palette.primary.divider,
      marginBottom: theme.spacing(1.5),
    },
    expansion: {
      color: theme.palette.fonts.main,
      fontSize: "1rem",
    },
    head: {
      //  background: theme.palette.secondary.background,
      "& th": {
        color: theme.palette.fonts.focus,
      },
    },
    info: {
      color: theme.palette.fonts.main,
      fontSize: ".64rem",
    },
    loader: {
      textAlign: "center",
      margin: theme.spacing(3),
    },
    media: {
      borderRadius: theme.spacing(0.5),
      position: "relative",
      paddingBottom: "72.25%",
    },
    noPhoto: {
      color: theme.palette.fonts.detail,
      fontSize: ".77rem",
      lineHeight: 1,
      textAlign: "center",
    },
    paginationButton: {
      fontSize: ".9rem",
    },
    pagination: {
      width: "100%",
      textAlign: "center",
      // "& .pagination": {
      //   listStyleType: "none",
      //   margin: "32px auto",
      //   width: 360,
      //   "& li": {
      //     display: "table-cell",
      //     width: 40,
      //     "& a": {
      //       color: theme.palette.fonts.action,
      //     },
      //   },
      //   "& li.active": {
      //     fontWeight: "bold",
      //     "& a": {
      //       color: theme.palette.fonts.focus,
      //     },
      //   },
      // },
    },
    title: {
      color: theme.palette.fonts.focus,
      fontSize: ".77rem",
      fontWeight: "bold",
    },
  });

const Pagination: React.FC<{
  classes: any;
}> = ({ classes }) => {
  const theme = useTheme();
  const { count, pageNumber, rowsPerPage, setPageNumber } = useMyPosts();
  const pages = Math.ceil(count / rowsPerPage);

  const handleFirstPageButtonClick = (event: any) => {
    //  onChangePage(event, 0);
  };

  const handleBackButtonClick = (event: any) => {
    setPageNumber(pageNumber - 1);
  };

  const handleNextButtonClick = (event: any) => {
    setPageNumber(pageNumber + 1);
  };

  const handleLastPageButtonClick = (event: any) => {
    //  onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <TableCell classes={{ root: classes.pagination }} colSpan={4}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        classes={{ root: classes.paginationButton }}
        disabled={pageNumber === 1}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? (
          <FaAngleDoubleRight />
        ) : (
          <FaAngleDoubleLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={pageNumber === 1}
        classes={{ root: classes.paginationButton }}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? <FaAngleRight /> : <FaAngleLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        classes={{ root: classes.paginationButton }}
        disabled={pageNumber >= pages} // && !pageInfo.hasNextPage} // no more to load, current page less
        aria-label="next page"
      >
        {theme.direction === "rtl" ? <FaAngleLeft /> : <FaAngleRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        classes={{ root: classes.paginationButton }}
        disabled={pageNumber >= pages} // && !pageInfo.hasNextPage}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? (
          <FaAngleDoubleLeft />
        ) : (
          <FaAngleDoubleRight />
        )}
      </IconButton>
    </TableCell>
  );
};
export default withStyles(styles)(Pagination);
