import React from "react";
import { Grid } from "@mui/material";
import AttachmentCard from "../../../../../Attachment/attachmentCard";
import { useAttachments } from "../../../../../../../../context/Post/attachmentsContext";
import { Attachment as AttachmentType } from "../../../../../../../../context/types";

var _ = require("lodash");
const Attachments: React.FC<{ bodyInput: string }> = ({ bodyInput }) => {
  const { attachments, deleteAttachments } = useAttachments();
  const allAttachments = _.compact(_.concat(attachments, deleteAttachments));

  return (
    <Grid item xs={12}>
      <Grid container>
        {allAttachments.map((attachment: AttachmentType) => {
          return (
            <Grid item xs={12} key={attachment.handle}>
              <AttachmentCard attachment={attachment} bodyInput={bodyInput} />
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};

export default Attachments;
