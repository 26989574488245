import React from "react";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { Avatar, Badge, IconButton, Theme, Tooltip } from "@mui/material";
import { createStyles, withStyles } from "@mui/styles";
//import { green } from "@mui/material/colors";
import { useTrans } from "../../context/Trans/translationContext";

var _ = require("lodash");

const styles = (theme: Theme) =>
  createStyles({
    avatar: {
      border: "1px solid rgba(0,0,0,.08)",
    },
    avatar_shadow: {
      boxShadow: theme.shadows[1], //"0 1px 5px 1px lightgrey"
    },
    avatarButton: {
      [theme.breakpoints.down("sm")]: {
        //   margin: "0 0 0 8px",
      },
      [theme.breakpoints.up("sm")]: {
        // margin: "0 8px",
      },
      padding: 0,
      "& img.reflect": {
        WebkitBoxReflect:
          "below 8px -webkit-gradient(linear, right top, right bottom, from(transparent), color-stop(40%, transparent), to(rgba(255, 255, 255, 0.1)))",
      },
    },
  });

interface AvatarProps {
  classes: any;
  user: any;
  button: Boolean;
  reflect?: boolean;
  shadow?: boolean;
  width: number;
}

const UserAvatar: React.FC<AvatarProps> = ({
  classes,
  user,
  button,
  reflect,
  shadow,
  width,
}) => {
  let history = useHistory();
  const { language } = useTrans();

  const { _id, avatar, handle, name, role } = user;
  const { login, user: user_avatar } = avatar;
  const { given_name, family_name } = name.user || name.login;
  const userAvatar = user_avatar
    ? `https://res.cloudinary.com/defendhaiti/image/upload/w_${_.ceil(
        width * 1.4
      )},c_scale/v1566866971/${user_avatar}.jpg`
    : login;

  const profile = `/${language}/user/${role === "Applicant" ? _id : handle}`;

  const UserAva = () => {
    return (
      <IconButton
        onClick={() => history.push(profile)}
        color="inherit"
        aria-label="Open Editor"
        className={classes.avatarButton}
        disabled={!button}
      >
        <Badge
          className={classes.buttonBadge}
          badgeContent={0}
          color="secondary"
          invisible={true}
        >
          <Avatar
            alt={avatar.login}
            src={userAvatar}
            classes={{
              root: classNames(
                classes.avatar,
                reflect && "avatar_reflect",
                shadow && classes.avatar_shadow
              ),
            }}
            style={{ height: width, width }}
          />
        </Badge>
      </IconButton>
    );
  };

  return (
    <>
      {button ? (
        <Tooltip title={`${given_name} ${family_name}`}>
          <>
            <UserAva />
          </>
        </Tooltip>
      ) : (
        <UserAva />
      )}
    </>
  );
};

export default withStyles(styles)(UserAvatar);
