import React, { useState } from "react";
import { Grid, Theme } from "@mui/material";
import { createStyles, withStyles } from "@mui/styles";
import Writer from "./writer";
import WriterAvatar from "./avatar";
import Organization from "./organization";
//import Category from "../category";
//import Details from "./Details";
import { useCurrentPost } from "../../../../../context/Post/currentPostContext";

const styles = (theme: Theme) =>
  createStyles({
    info: {
      marginBottom: -22,
      padding: "0 36px",
      zIndex: 3,
    },
    postCategory: {
      //   color: theme.palette.text.main,
      fontWeight: "normal",
      textTransform: "none",
      fontSize: "0.8rem",
    },
    postDivision: {
      fontWeight: "bold",
      textTransform: "uppercase",
      // color: theme.palette.text.main,
      fontSize: "0.8rem",
    },
    organizationAvatar: {
      borderRadius: 0,
      maxHeight: 36,
    },
    writerAvatar: {
      background: theme.palette.background.paper,
      boxShadow: theme.shadows[4],
      height: 52,
      width: 52,
      [theme.breakpoints.up("sm")]: { boxShadow: theme.shadows[8] },
    },
    writerAvatarSM: {
      background: theme.palette.background.paper,
      boxShadow: theme.shadows[1],
      marginRight: theme.spacing(1),
      height: 26,
      width: 26,
      [theme.breakpoints.up("sm")]: { boxShadow: theme.shadows[8] },
    },
  });
const AuthorCategory: React.FC<{ classes: any }> = ({ classes }) => {
  const [showDetails, setShowDetails] = useState(false);
  false && setShowDetails(false);
  const { currentPost: thisPost } = useCurrentPost();
  // const toggleDetails = () => {
  //   const flip = !showDetails;
  //   setShowDetails(flip);
  // };

  const AuthButton = () => {
    return (
      <Grid
        container
        direction="row"
        alignItems="flex-start"
        justifyContent="flex-end"
        classes={{ root: classes.info }}
      >
        {thisPost.organization && <Organization />}
        <Writer thisPost={thisPost} />
        <WriterAvatar
          thisPost={thisPost}
          // showDetails={showDetails}
          // toggleDetails={toggleDetails}
        />
      </Grid>
    );
  };

  return (
    <React.Fragment>
      <AuthButton />
      {showDetails && (
        <Grid item xs={12}>
          {/* <Details thisPost={thisPost} /> */}
        </Grid>
      )}
    </React.Fragment>
  );
};

export default withStyles(styles)(AuthorCategory);
