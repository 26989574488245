import React from "react";
import { FaArrowAltCircleRight } from "react-icons/fa";
import { Button, Grid, Theme, Typography } from "@mui/material";
import { createStyles, withStyles } from "@mui/styles";
import { useNewPost } from "../../../../../../context/Post/newPostContext";
import { useAttachment } from "../../../../../../context/Post/attachmentContext";

const styles = (theme: Theme) =>
  createStyles({
    action_holder: {
      fontSize: ".8rem",
    },
    button: {
      fontSize: ".6rem",
      minWidth: "unset",
      //  padding: "0 12px 0 0",
    },
    dev: {
      color: "#888888",
      background: "rgba(245,255, 250,.5)",
      fontSize: ".75rem",
      fontStyle: "italic",
      marginTop: theme.spacing(1),
      padding: "0 24px",
    },
    disabled: {
      color: theme.palette.fonts.detail,
    },
    active: {
      background: theme.palette.color.lightblue,
      minWidth: "unset",
      padding: theme.spacing(1),
    },
    flag: {
      height: "1.5em !important",
      width: "1.5em !important",
    },
    icon: {
      margin: "0 4px",
    },
    label: {
      fontSize: ".9em",
    },
    langOpts: {
      textAlign: "right",
    },
  });

const FieldActionControls: React.FC<{
  classes: any;
  hide?: boolean;
  item: string;
  field: string;
}> = ({ classes, hide, item, field }) => {
  const { actionFunctions, dev, editing, newPost, showDev, splitForm } =
    useNewPost();
  const { attachment } = useAttachment();
  const {
    clearField,
    clearAllField,
    copyField,
    copyAllField,
    translateField,
    translateAllField,
  } = actionFunctions;
  const isTranslating = dev !== editing;
  const clearFunc = isTranslating ? clearField : clearAllField;
  const copyFunc = isTranslating ? copyField : copyAllField;
  const transFunc = isTranslating ? translateField : translateAllField;

  const dev_field: { [key: string]: any } = {
    "attachment-description": attachment?.description?.[dev],
    "attachment-title": attachment?.title?.[dev],
    body: newPost?.body?.[dev],
    link: newPost?.link?.title?.[dev],
    subtitle: newPost?.subtitle?.[dev],
    title: newPost?.title?.[dev],
  };

  return (
    <Grid item xs={12} style={{ margin: "0 6px" }}>
      <Grid
        container
        direction="row"
        justifyContent={isTranslating ? "flex-end" : "flex-start"}
        alignItems="center"
        style={{ minHeight: 36 }}
      >
        {isTranslating && hide && (
          <React.Fragment>
            {showDev && !splitForm && (
              <Grid item xs={12}>
                <Typography classes={{ root: classes.dev }}>
                  {dev_field[field]}
                </Typography>
              </Grid>
            )}
            <Grid item xs>
              <Button
                classes={{ root: classes.button, text: classes.disabled }}
                disabled
              >
                {item} {dev}
                <FaArrowAltCircleRight className={classes.icon} />
                {editing}
              </Button>
            </Grid>
          </React.Fragment>
        )}
        {!hide && (
          <Grid item style={{ padding: "8px 8px  0" }}>
            <Grid container>
              <Grid item>
                <Button
                  classes={{ root: classes.button }}
                  onClick={() => clearFunc(field, editing)}
                >
                  Clear
                </Button>
              </Grid>
              <Grid item>
                <Button
                  classes={{ root: classes.button }}
                  onClick={() => copyFunc(field, editing)}
                >
                  Copy
                </Button>
              </Grid>
              <Grid item>
                <Button
                  classes={{ root: classes.button }}
                  onClick={() => transFunc(field, editing)}
                >
                  Translate
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
        {!isTranslating && (
          <Grid item xs style={{ textAlign: "right" }}>
            <Button
              classes={{ root: classes.button, text: classes.disabled }}
              disabled
            >
              {item} {dev}
              <FaArrowAltCircleRight className={classes.icon} />
              {"All"}
            </Button>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(FieldActionControls);
