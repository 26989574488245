import React, { useState } from "react";
import { Grid, TextField } from "@mui/material";
import { createStyles, withStyles } from "@mui/styles";
import Tabs from "../Controls/tabs";
import BulkActions from "../Controls/action";
import DefaultForm from "./Context/default";
import SplitForm from "./Context/split";
import { useNewPost } from "../../../../../../context/Post/newPostContext";

const styles = () =>
  createStyles({
    contextField: {
      width: "100%",
    },
  });

const CoverContextForm: React.FC<{ classes: any }> = ({ classes }) => {
  // const { toggleManager } = useManagers();

  const { editing, handlePostChange, isTranslating, newPost, splitForm } =
    useNewPost();
  const { cover } = newPost;
  const { credit, description, title } = cover;

  const [creditState, setCreditState] = useState(credit);
  const [titleState, setTitleState] = useState(title?.[editing]);
  const [descriptionState, setDescriptionState] = useState(
    description?.[editing]
  );

  const handleChange = (field: string, event: any) => {
    const setFunctions: { [key: string]: any } = {
      credit: setCreditState,
      title: setTitleState,
      description: setDescriptionState,
    };

    setFunctions[field](event.target.value);
  };

  function saveChanges(label: string) {
    const setStates: { [key: string]: any } = {
      title: titleState,
      description: descriptionState,
    };

    if (label === "credit") {
      handlePostChange("cover", { ...cover, credit: creditState });
    } else {
      const cover_field = (cover as any)[label];
      const lang_obj = { ...cover_field, [editing]: setStates[label] };
      handlePostChange("cover", { ...cover, [label]: lang_obj });
    }
  }

  return (
    <Grid item xs={12} style={{ margin: "16px 0" }}>
      <Grid container spacing={1}>
        <Tabs />
        <BulkActions />
        <Grid item xs={12} sm={6}>
          <TextField
            id="cover-context-credit"
            onBlur={() => saveChanges("credit")}
            margin="dense"
            variant="outlined"
            multiline
            label={"Credit"}
            classes={{ root: classes.contextField }}
            value={creditState || ""}
            onChange={(e: any) => handleChange("credit", e)}
          />
        </Grid>
        {splitForm ? <SplitForm /> : <DefaultForm isEditing={isTranslating} />}
      </Grid>
    </Grid>
  );
};
export default withStyles(styles)(CoverContextForm);
