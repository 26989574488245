import React from "react";
import { FormattedMessage } from "react-intl";
import { Theme } from "@mui/material";
import { createStyles, withStyles } from "@mui/styles";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import enLocale from "date-fns/locale/en-US";
import frLocale from "date-fns/locale/fr";
//import htLocale from "./ht";
//import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
import { useNewPost } from "../../../../../../context/Post/newPostContext";
import { useTrans } from "../../../../../../context/Trans/translationContext";
/*import {
  
  TimePicker,
  DatePicker
} from "material-ui-pickers";*/

var _ = require("lodash");

const styles = (theme: Theme) =>
  createStyles({
    date: {
      "& fieldset": {
        //      borderRadius: "4px 0 0 4px",
      },
      width: "100%",
    },
    formField: {
      width: "100%",
    },
    label: {
      color: theme.palette.fonts.action,
    },
  });

const EditDate: React.FC<{ classes: any }> = ({ classes }) => {
  const { language } = useTrans();
  const { handlePostChange, newPost } = useNewPost();
  const { publishedAt: published_ } = newPost;
  // const [selectedDate, handleDateChangeX] = useState(
  //   published && _id !== "0a0000000000000000000000" ? published : new Date()
  // ); //published
  const localeMap: { [key: string]: any } = {
    en: enLocale,
    fr: frLocale,
    ht: frLocale,
  };
  //console.log(selectedDate, moment(selectedDate).isValid());
  // useEffect(() => {
  //   handlePostChange("published", selectedDate);
  // }, [selectedDate]);

  const handleDateChange = (event: any) => {
    handlePostChange("publishedAt", moment(event).valueOf());
  };
  const trimmed_p = published_ && _.ceil(published_, 0);
  const now = new Date();
  const p_date = trimmed_p || moment().valueOf();
  const published = moment(p_date || now).toISOString();

  return (
    <MuiPickersUtilsProvider
      //libInstance={moment}
      utils={DateFnsUtils}
      locale={localeMap[language]}
    >
      <DateTimePicker
        className={classes.date}
        inputVariant="outlined"
        //format="full"
        margin="normal"
        label={<FormattedMessage id="Publish Date" />}
        value={published}
        onChange={(e: any) => handleDateChange(e)}
      />
    </MuiPickersUtilsProvider>
  );
};

/*
<MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container className={classes.grid} justifyContent="space-around">
          <Grid item xs={8}>
            <DatePicker
              classes={{ root: classes.date }}
              variant="outlined"
              margin="normal"
              label="Publish Date"
              value={selectedDate}
              onChange={handleDateChange}
            />
          </Grid>
          <Grid item xs={4}>
            <TimePicker
              classes={{ root: classes.time }}
              margin="normal"
              label="Time"
              value={selectedDate}
              variant="outlined"
              onChange={handleDateChange}
            />
          </Grid>
        </Grid>
      </MuiPickersUtilsProvider>
*/

export default withStyles(styles)(EditDate);
