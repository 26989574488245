import React from "react";
import { Divider, Grid, Theme } from "@mui/material";
import { createStyles, withStyles } from "@mui/styles";
import AlbumPhoto from "./albumPhoto";
import AlbumPhotoDeleted from "./albumPhotoDeleted";
import { Attachment, Language } from "../../../../../../../context/types";
//import { useNewPost } from "../../../../../../../context/Post/newPostContext";

const styles = (theme: Theme) =>
  createStyles({
    divider: {
      //  backgroundImage: theme.palette.primary.divider,
    },
  });

const SelectedAlbum: React.FC<{
  classes: any;
  photoalbum: Attachment;
  removeAttachment: (name: string) => void;
  restoreAttachment: (name: string) => void;
  updateAlbumPhotoTitle: (name: string, title: Language) => void;
}> = ({
  classes,
  photoalbum,
  removeAttachment,
  restoreAttachment,
  updateAlbumPhotoTitle,
}) => {
  // const { newPost } = useNewPost();
  // const { language } = newPost;
  const { photos, deletePhotos } = photoalbum;
  return (
    <Grid container>
      {photos &&
        photos.map((photo: Attachment) => {
          return (
            <Grid key={photo.name} item xs={12}>
              <AlbumPhoto
                photo={photo}
                removeAttachment={removeAttachment}
                updateAlbumPhotoTitle={updateAlbumPhotoTitle}
              />
              <Divider classes={{ root: classes.divider }} />
            </Grid>
          );
        })}
      {deletePhotos &&
        deletePhotos.map(photo => {
          return (
            <Grid key={photo.name} item xs={12}>
              <AlbumPhotoDeleted
                photo={photo}
                restoreAttachment={restoreAttachment}
              />
              <Divider classes={{ root: classes.divider }} />
            </Grid>
          );
        })}
    </Grid>
  );
};

export default withStyles(styles)(SelectedAlbum);
