import React from "react";
//import classNames from "classnames";
import { Route, Switch, useHistory } from "react-router-dom";
import {
  //  FaBars,
  FaClock,
  FaComments,
  FaDraftingCompass,
  FaGlobe,
  FaGlobeAfrica,
  FaImage,
  FaMicrophone,
  FaNewspaper,
  FaVideo,
} from "react-icons/fa";
import { Grid, Theme } from "@mui/material";
import { createStyles, withStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";
//import UserProfile from "./User";
import Header from "../../components/Header";
// import Loader from "../../components/Loader";
// import LanguageSelector from "./languageSelector";
// import { useCurrentProfile } from "../../context/Profile/currentProfileContext";
import { useFeature } from "../../context/Post/featureContext";
import { useMyPosts } from "../../context/Feed/myPostsFeedContext";
import { useUser } from "../../context/User/userContext";
import { useTrans } from "../../context/Trans/translationContext";
//import { useFoundation } from "../../context/foundationContext";
//import SentinelLogo from "../../../styles/graphics/sentinel_logo";
// import TitleBar from "./TitleBar";
import { useAdminPost } from "../../context/Post/adminPostContext";

const styles = (theme: Theme) =>
  createStyles({
    appBar: {
      background: "none",
      boxShadow: "none",
      right: "unset",
      width: "100%",
      [theme.breakpoints.up("xs")]: {
        height: theme.spacing(6),
      },
      [theme.breakpoints.up("sm")]: {
        height: 52,
      },
      [theme.breakpoints.up("sm")]: {
        maxWidth: 645,
      },
      [theme.breakpoints.up("md")]: {
        maxWidth: 845,
      },
      [theme.breakpoints.up("lg")]: {
        maxWidth: 960,
      },
      [theme.breakpoints.up("xl")]: {
        maxWidth: 1160,
      },
    },
    appBar_closed: {
      transform: "translateY(-64px)",
      transition: theme.transitions.create("transform", {
        easing: theme.transitions.easing.easeOut,
        duration: 1000, //theme.transitions.duration.enteringScreen
      }),
    },
    appBar_open: {
      transform: "translateY(0px)",
      transition: theme.transitions.create("transform", {
        easing: theme.transitions.easing.easeOut,
        duration: 200, //theme.transitions.duration.enteringScreen
      }),
    },
    appbar_background: {
      background: "rgb(254 254 254 / 85%)",
    },
    brand: {
      // height: 38,
      position: "absolute",
      width: "100%",
      zIndex: 2,
      [theme.breakpoints.up("xs")]: {
        minHeight: 38,
      },
      [theme.breakpoints.up("sm")]: {
        minHeight: 52,
      },
    },
    logo: {
      width: 115,
    },
    logo_svg: {
      paddingBottom: 16,
      width: 0,
      "& svg": {
        height: 18,
      },
    },
    menu: {
      color: theme.palette.primary.main,
      flexBasis: "0 | content",
      fontSize: 18,
      marginLeft: theme.spacing(1),
      [theme.breakpoints.up("xs")]: {
        padding: "8px 8px 24px 24px",
      },
      [theme.breakpoints.up("sm")]: {
        padding: 16,
      },
      /*[theme.breakpoints.up("xl")]: {
      display: "none"
    }*/
    },
    sitebar_holder: {
      height: 62,
      [theme.breakpoints.down("md")]: {
        height: theme.spacing(6),
      },
    },
    site_menu: {
      height: "100%",
    },
    spacer: {},
    title: {
      fontWeight: 700,
      [theme.breakpoints.down("sm")]: {
        fontSize: 18,
        height: 8,
        //textShadow: theme.palette.shadow.title,
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: 28,
        paddingTop: 14,
      },
      // fontWeight: "bold",
      textTransform: "uppercase",
      "& a": {
        //   color: TESTING ? theme.palette.primary.focus : theme.palette.text.focus,
      },
    },
    title_mini: {
      [theme.breakpoints.down("sm")]: {
        fontSize: ".8rem",
        height: 8,
        //textShadow: theme.palette.shadow.title,
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: 28,
        paddingTop: 14,
      },
      // fontWeight: "bold",
      textTransform: "uppercase",
      "& a": {
        //   color: TESTING ? theme.palette.primary.focus : theme.palette.text.focus,
      },
    },
    title_bar: {
      height: 50,
    },
    title_logo: {
      margin: "0 8px",
      "& a": {
        height: 0,
      },
    },
  });

const SiteHeader: React.FC = () => {
  let history = useHistory();
  const { setView, view } = useAdminPost();
  const { feature, handleFeatureChange: handleMainTemplateChange } =
    useFeature();
  //const { ref_header } = useFoundation();
  const { language, RouteTrans } = useTrans();
  const { Can, user } = useUser();
  //const { currentProfile } = useCurrentProfile();
  const { handleManagePostsStatusChange, handlePreviewAction, status } =
    useMyPosts();

  //const [direction, setDirection] = useState(true);

  const { template: feature_template } = feature;

  // useEffect(() => {
  //   let prevScroll = window.scrollY;
  //   let curScroll;

  //   function checkScroll() {
  //     curScroll = window.scrollY;

  //     if (curScroll > prevScroll && curScroll > 50) {
  //       //scrolled up
  //       setDirection(false);
  //     } else if (curScroll < prevScroll) {
  //       //scrolled down
  //       setDirection(true);
  //     }
  //     prevScroll = curScroll;
  //   }

  //   window.addEventListener("scroll", checkScroll);
  //   return () => {
  //     window.removeEventListener("scroll", checkScroll);
  //   };
  // }, []);

  const header_tabs: { [key: string]: any } = {
    admin: [
      {
        icon: <FaGlobe />,
        name: <FormattedMessage id="All" />,
        value: "all",
      },
      {
        icon: <FaClock />,
        name: <FormattedMessage id="Pending" />,
        value: "pending",
      },
      {
        icon: <FaDraftingCompass />,
        name: <FormattedMessage id="Drafts" />,
        value: "draft",
      },
      {
        icon: <FaGlobeAfrica />,
        name: <FormattedMessage id="Published" />,
        value: "published",
      },
    ],
    edit: [
      {
        icon: <FaNewspaper />,
        name: <FormattedMessage id="text" />,
        value: "text",
      },
      {
        icon: <FaVideo />,
        name: <FormattedMessage id="video" />,
        value: "video",
      },
      {
        icon: <FaMicrophone />,
        name: <FormattedMessage id="audio" />,
        value: "audio",
      },
      {
        icon: <FaComments />,
        name: <FormattedMessage id="social" />,
        value: "social",
      },
      {
        icon: <FaImage />,
        name: <FormattedMessage id="photo" />,
        value: "photo",
      },
    ],
    preview: [
      {
        icon: <FaClock />,
        name: <FormattedMessage id="Edit" />,
        value: "edit",
      },
      {
        icon: <FaDraftingCompass />,
        name: <FormattedMessage id="Publish" />,
        value: "publish",
      },
      {
        icon: <FaGlobeAfrica />,
        name: <FormattedMessage id="Clone" />,
        value: "clone",
      },
      {
        icon: <FaGlobeAfrica />,
        name: <FormattedMessage id="Trash" />,
        value: "trash",
      },
      {
        // icon: <FaGlobeAfrica />,
        // name: <FormattedMessage id="Trash" />,
        value: null,
      },
    ],
  };

  const header: { [key: string]: any } = {
    attachment: {
      buttons: [
        {
          action: () =>
            history.push(
              `/${language}/${RouteTrans["manage"][language]}/${RouteTrans["posts"][language]}`
            ),
          button: "<",
        },
      ],
      onChange: handleManagePostsStatusChange,
      tabs: header_tabs.admin,
      title: (
        <FormattedMessage
          id="Editing Attachment"
          defaultMessage="Editing Attachment"
        />
      ),
      value: status,
    },
    create: {
      buttons: [
        {
          action: () =>
            history.push(
              `/${language}/${RouteTrans["manage"][language]}/${RouteTrans["posts"][language]}`
            ),
          button: "x",
        },
      ],
      onChange: handleMainTemplateChange,
      tabs: header_tabs.edit,
      title: <FormattedMessage id="Creating Post" />,
      value: feature_template,
    },
    edit: {
      buttons: [
        {
          action: () =>
            history.push(
              `/${language}/${RouteTrans["manage"][language]}/${RouteTrans["posts"][language]}`
            ),
          button: "x",
        },
      ],
      onChange: handleMainTemplateChange,
      tabs: header_tabs.edit,
      title: <FormattedMessage id="Editing Post" />,
      value: feature_template,
    },
    manage: {
      buttons: [
        {
          action: () =>
            history.push(`/${language}/${RouteTrans["posts"][language]}`),
          button: "<",
        },
        {
          action: () =>
            history.push(
              `/${language}/${RouteTrans["create"][language]}/${RouteTrans["post"][language]}`
            ),
          button: "+",
        },
      ],
      onChange: handleManagePostsStatusChange,
      tabs: header_tabs.admin,
      text: true,
      title: (
        <FormattedMessage id="Manage Posts" defaultMessage="Manage Posts" />
      ),
      value: status,
    },
    preview: {
      buttons: [
        {
          action: () => setView("root"),
          button: "<",
        },
      ],
      onChange: handlePreviewAction,
      tabs: header_tabs.preview,
      text: true,
      title: <FormattedMessage id="Post Preview" />,
      value: null,
    },
  };

  function Preview() {
    return (
      <Can
        role={user?.role}
        perform={"create:post"}
        yes={() => (
          <Grid item xs={12}>
            <Header base_settings={header.preview} />
          </Grid>
        )}
      />
    );
  }

  function Attachment() {
    return (
      <Can
        role={user?.role}
        perform={"create:post"}
        yes={() => (
          <Grid item xs={12}>
            <Header base_settings={header.attachment} />
          </Grid>
        )}
      />
    );
  }

  function RootHeaders() {
    return (
      <Switch>
        <Route
          path={`/${language}/${RouteTrans["create"][language]}/${RouteTrans["post"][language]}`}
          render={() => {
            return (
              <Can
                role={user?.role}
                perform={"create:post"}
                yes={() => (
                  <Grid item xs={12}>
                    <Header base_settings={header.create} />
                  </Grid>
                )}
              />
            );
          }}
        />
        <Route
          path={`/${language}/${RouteTrans["edit"][language]}/${RouteTrans["post"][language]}`}
          render={() => {
            return (
              <Can
                role={user?.role}
                perform={"edit:post"}
                yes={() => (
                  <Grid item xs={12}>
                    <Header base_settings={header.edit} />
                  </Grid>
                )}
              />
            );
          }}
        />
        <Route
          path={`/${language}/${RouteTrans["manage"][language]}/${RouteTrans["posts"][language]}`}
          render={() => {
            return (
              <Can
                role={user?.role}
                perform="view:drafts"
                yes={() => (
                  <Grid item xs={12}>
                    <Header base_settings={header.manage} />
                  </Grid>
                )}
              />
            );
          }}
        />
      </Switch>
    );
  }

  const page_view: { [key: string]: any } = {
    attachment: <Attachment />,
    preview: <Preview />,
    root: <RootHeaders />,
  };

  return <>{page_view[view || "root"]}</>;
};

export default withStyles(styles)(SiteHeader);
