import React from "react";
import { Button, Grid, Theme } from "@mui/material";
import { createStyles, withStyles } from "@mui/styles";
//import Can from '../../../../context/User/Can'
import { FaEdit } from "react-icons/fa";
import { getBaseURL } from "../../../../utilities/postRenders";
import { useTrans } from "../../../../context/Trans/translationContext";
import { useCurrentPost } from "../../../../context/Post/currentPostContext";
import { useHistory } from "react-router-dom";
import { useUser } from "../../../../context/User/userContext";

const styles = (theme: Theme) =>
  createStyles({
    edit_holder: { fontSize: "1.2rem", padding: "24px 0" },
  });

const PostAdministration: React.FC<{
  classes: any;
}> = ({ classes }) => {
  let history = useHistory();
  const { language } = useTrans();
  const { Can, user } = useUser();
  const { currentPost: thisPost } = useCurrentPost();
  const editURL = getBaseURL(thisPost, language, true);

  const userInfo = {
    manages: user?.manages,
    writer: thisPost.writer._id,
  };

  return (
    <>
      <Can
        role={user?.role}
        perform="edit:published"
        data={userInfo}
        yes={() => (
          <Grid classes={{ root: classes.edit_holder }} item xs={12}>
            <Button
              onClick={() => history.push(editURL)}
              variant="outlined"
              startIcon={<FaEdit />}
            >
              Edit Post
            </Button>
          </Grid>
        )}
      />
    </>
  );
};

export default withStyles(styles)(PostAdministration);
