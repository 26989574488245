import React, { useState } from "react";

import { FaTrash } from "react-icons/fa";
import { Button, Grid, TextField, Theme } from "@mui/material";
import { createStyles, withStyles } from "@mui/styles";
import Image from "./cloudinary-image";
import { Attachment, Language } from "../../../../../../../context/types";
import { useNewPost } from "../../../../../../../context/Post/newPostContext";

const styles = (theme: Theme) =>
  createStyles({
    formField: {
      marginTop: theme.spacing(1),
      width: "100%",
    },
    icon: {
      color: theme.palette.fonts.action,
    },
    wrapper: {
      padding: 24,
    },
  });

const SelectedPhoto: React.FC<{
  classes: any;
  photo: Attachment;
  removeAttachment: (name: string) => void;
  updateAlbumPhotoTitle: (name: string, title: Language) => void;
}> = ({ classes, photo, removeAttachment, updateAlbumPhotoTitle }) => {
  const { newPost } = useNewPost();
  const { language } = newPost;
  const { name, title } = photo;
  const [titleState, setTitle] = useState(title);
  const handleTitleChange = () => (event: any) => {
    setTitle({ ...title, [language]: event.target.value });
  };
  const runSaveTitle = (name: string) => {
    titleState && updateAlbumPhotoTitle(name, titleState);
  };

  return (
    <div className={classes.wrapper}>
      <Grid container spacing={2}>
        <Grid item>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            style={{ height: "100%" }}
          >
            <Grid item>
              <Image name={name} width="120" />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            style={{ height: "100%" }}
          >
            <Grid item style={{ width: "100%" }}>
              <TextField
                id="article-title"
                onBlur={() => name && runSaveTitle(name)}
                variant="outlined"
                multiline
                label="Caption"
                classes={{ root: classes.formField }}
                value={titleState}
                onChange={handleTitleChange()}
                margin="normal"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            style={{ height: "100%" }}
          >
            <Grid item>
              <Button onClick={() => name && removeAttachment(name)}>
                <FaTrash className={classes.icon} />
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default withStyles(styles)(SelectedPhoto);
