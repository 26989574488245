type RouteObj = {
  [key: string]: string;
};

const RouteTrans: {
  [key: string]: RouteObj;
} = {
  about: {
    en: "about",
    fr: "a-propos",
    ht: "sou-nou",
  },
  academia: {
    en: "academia",
    fr: "academique",
    ht: "akademik",
  },
  admin: {
    en: "admin",
    fr: "admin",
    ht: "admin",
  },
  africa: {
    en: "africa",
    fr: "afrique",
    ht: "afrik",
  },
  art: {
    en: "art",
    fr: "art",
    ht: "atizay",
  },
  asia: {
    en: "asia",
    fr: "asie",
    ht: "azi",
  },
  basketball: {
    en: "basketball",
    fr: "basketball",
    ht: "basketbol",
  },
  bizarre: {
    en: "bizarre",
    fr: "bizarre",
    ht: "drol",
  },
  business: {
    en: "business",
    fr: "entreprise",
    ht: "biznis",
  },
  "caribbean-and-latin-america": {
    en: "caribbean-and-latin-america",
    fr: "caraibes-et-amerique-latine",
    ht: "karayib-ak-amerik-latin",
  },
  celebrity: {
    en: "celebrity",
    fr: "celebrite",
    ht: "seleb",
  },
  "central-america": {
    en: "central-america",
    fr: "amerique-centrale",
    ht: "amerik-santral",
  },
  civic: {
    en: "civic",
    fr: "civique",
    ht: "sivik",
  },
  combat: {
    en: "combat",
    fr: "combat",
    ht: "konba",
  },
  community: {
    en: "community",
    fr: "communaute",
    ht: "kominote",
  },
  create: {
    en: "create",
    fr: "creer",
    ht: "kreye",
  },
  crime: {
    en: "crime",
    fr: "criminalite",
    ht: "krim",
  },
  dashboard: {
    en: "dashboard",
    fr: "tableau-de-bord",
    ht: "tablodbo",
  },
  day: {
    en: "day",
    fr: "jour",
    ht: "jou",
  },
  defense: {
    en: "defense",
    fr: "defense",
    ht: "defans",
  },
  diplomatic: {
    en: "diplomatic",
    fr: "diplomatique",
    ht: "diplomatik",
  },
  directory: {
    en: "directory",
    fr: "directoire",
    ht: "anye",
  },
  earning: {
    en: "earning",
    fr: "revenus",
    ht: "touche",
  },
  economy: {
    en: "economy",
    fr: "economie",
    ht: "ekonomi",
  },
  edit: {
    en: "edit",
    fr: "editer",
    ht: "modifye",
  },
  education: {
    en: "education",
    fr: "education",
    ht: "edikasyon",
  },
  entertainment: {
    en: "entertainment",
    fr: "divertissement",
    ht: "amizman",
  },
  environment: {
    en: "environment",
    fr: "environnement",
    ht: "anviwonman",
  },
  europe: {
    en: "europe",
    fr: "europe",
    ht: "ewop",
  },
  events: {
    en: "events",
    fr: "evenements",
    ht: "evenman",
  },
  executive: {
    en: "executive",
    fr: "executif",
    ht: "egzekitif",
  },
  "fashion-and-beauty": {
    en: "fashion-and-beauty",
    fr: "mode-et-beaute",
    ht: "mod-ak-bote",
  },
  film: {
    en: "film",
    fr: "film",
    ht: "fim",
  },
  "food-and-drink": {
    en: "food-and-drink",
    fr: "nourriture-et-boisson",
    ht: "manje-ak-bwason",
  },
  football: {
    en: "football",
    fr: "football-americain",
    ht: "foutbol-ameriken",
  },
  "home-and-garden": {
    en: "home-and-garden",
    fr: "maison-et-jardin",
    ht: "kay-ak-jaden",
  },
  judicial: {
    en: "judicial",
    fr: "judiciaire",
    ht: "jidisye",
  },
  history: {
    en: "history",
    fr: "histoire",
    ht: "istwa",
  },
  international: {
    en: "international",
    fr: "internationale",
    ht: "entenasyonal",
  },
  legislative: {
    en: "legislative",
    fr: "legislatif",
    ht: "lejislatif",
  },
  lifestyle: {
    en: "lifestyle",
    fr: "mode-de-vie",
    ht: "mod-lavi",
  },
  literature: {
    en: "literature",
    fr: "litterature",
    ht: "literati",
  },
  "love-and-relationships": {
    en: "love-and-relationships",
    fr: "amour-et-relations",
    ht: "lanmou-ak-relasyon",
  },
  manage: {
    en: "manage",
    fr: "gerer",
    ht: "jere",
  },
  messages: {
    en: "messages",
    fr: "messages",
    ht: "mesaj",
  },
  media: {
    en: "media",
    fr: "medias",
    ht: "medya",
  },
  "middle-east": {
    en: "middle-east",
    fr: "moyen-orient",
    ht: "mwayen-oryan",
  },
  "mind-and-body": {
    en: "mind-and-body",
    fr: "esprit-et-corps",
    ht: "lide-ak-ko",
  },
  money: {
    en: "money",
    fr: "argent",
    ht: "lajan",
  },
  municipal: {
    en: "municipal",
    fr: "municipal",
    ht: "minisipal",
  },
  music: {
    en: "music",
    fr: "musique",
    ht: "mizik",
  },
  network: {
    en: "network",
    fr: "reseau",
    ht: "rezo",
  },
  news: {
    en: "news",
    fr: "nouvelles",
    ht: "nouvel",
  },
  "north-america": {
    en: "north-america",
    fr: "amerique-du-nord",
    ht: "amerik-di-no",
  },
  notifications: {
    en: "notifications",
    fr: "notifications",
    ht: "notifikasyon",
  },
  organization: {
    en: "organization",
    fr: "organisation",
    ht: "oganizasyon",
  },
  patrons: {
    en: "patrons",
    fr: "patrons",
    ht: "patwon",
  },
  people: {
    en: "people",
    fr: "personnes",
    ht: "moun",
  },
  "personal-finances": {
    en: "personal-finances",
    fr: "finances-personnelles",
    ht: "finans-pesonel",
  },
  "performing-arts": {
    en: "performing-arts",
    fr: "arts-performants",
    ht: "art-pefomans",
  },
  political: {
    en: "political",
    fr: "politique",
    ht: "politik",
  },
  politics: {
    en: "politics",
    fr: "politique",
    ht: "politik",
  },
  post: {
    en: "post",
    fr: "affiche",
    ht: "afich",
  },
  posts: {
    en: "posts",
    fr: "affiches",
    ht: "afich-yo",
  },
  presidential: {
    en: "presidential",
    fr: "presidentielle",
    ht: "prezidansyel",
  },
  profile: {
    en: "profile",
    fr: "profil",
    ht: "pwofil",
  },
  promoting: {
    en: "promoting",
    fr: "promotion",
    ht: "pwomosyon",
  },
  soccer: {
    en: "soccer",
    fr: "football",
    ht: "foutbol",
  },
  "south-america": {
    en: "south-america",
    fr: "amerique-du-sud",
    ht: "amerik-sid",
  },
  "spirit-and-religion": {
    en: "spirit-and-religion",
    fr: "esprit-et-religion",
    ht: "lespri-ak-relijyon",
  },
  sports: {
    en: "sports",
    fr: "des-sports",
    ht: "espo",
  },
  technology: {
    en: "technology",
    fr: "technologie",
    ht: "teknoloji",
  },
  television: {
    en: "television",
    fr: "television",
    ht: "televizyon",
  },
  tennis: {
    en: "tennis",
    fr: "tennis",
    ht: "tenis",
  },
  "track-and-field": {
    en: "track-and-field",
    fr: "athletisme",
    ht: "atletik",
  },
  travel: {
    en: "travel",
    fr: "voyage",
    ht: "vwayaje",
  },
  uncategorized: {
    en: "uncategorized",
    fr: "non-classe",
    ht: "san-kategori",
  },
  undivided: {
    en: "undivided",
    fr: "indivis",
    ht: "divize",
  },
  user: {
    en: "user",
    fr: "utilisateur",
    ht: "itilizate",
  },
  users: {
    en: "users",
    fr: "utilisateurs",
    ht: "itilizate-yo",
  },
  watersports: {
    en: "watersports",
    fr: "nautiques",
    ht: "akwatik",
  },
  winter: {
    en: "winter",
    fr: "hiver",
    ht: "sezon-fredi",
  },
  world: {
    en: "world",
    fr: "monde",
    ht: "mond",
  },
  writer: {
    en: "writer",
    fr: "ecrivaine",
    ht: "ekriven",
  },
  writers: {
    en: "writers",
    fr: "écrivaines",
    ht: "ekriven",
  },
};

export default RouteTrans;
