import React from "react";
// import isNode from "detect-node";
// import { Hidden } from "@mui/material";
// import DisqusComments from "./Body/Content/disqusComments";
import Body from "./Body";
import Head from "./Head";
// import Adsense from "../../Advertisements/adsense";
// import Weather from "../../Weather";
//import Network from "../../Network/activity";
//var _ = require("lodash");

const PostPage: React.FC = () => {
  return (
    <>
      <Head />
      <Body />
    </>
  );
};

export default PostPage;
