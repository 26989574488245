import React, {
  createContext,
  useEffect,
  useContext,
  useReducer,
  useState,
} from "react";
import {
  Attachment,
  defaultAttachment,
  defaultPostType,
  Language,
  PostType,
  // StateEventFunction,
  StateEventCompactFunction,
} from "../types";
import { FeatureReducer } from "./featureReducer";
import { useOldPost } from "./oldPostContext";
import { ObjectID } from "bson";
//import { useTrans } from "../../context/Trans/translationContext";

var _ = require("lodash");

export const FeatureContext = createContext<{
  deleteFeature?: Attachment;
  handleDeleteFeature: (postTypeObj: PostType, templateValue: string) => void;
  handleFeatureChange: StateEventCompactFunction;
  handleTypeChange: (event: any) => void;
  feature: Attachment;
  loadFeature: (post: any) => void;
  removeAlbumPhoto: (name: string) => void;
  restoreAlbumPhoto: (name: string) => void;
  restoreFeature: () => void;
  updateAlbumPhotoTitle: (name: string, title: Language) => void;
  updateFeature: (changes: any) => void;
}>({
  feature: defaultAttachment,
  handleDeleteFeature: () => {},
  handleFeatureChange: () => {},
  handleTypeChange: () => {},
  loadFeature: () => {},
  removeAlbumPhoto: () => {},
  restoreAlbumPhoto: () => {},
  restoreFeature: () => {},
  updateAlbumPhotoTitle: () => {},
  updateFeature: () => {},
});

export const useFeature = () => useContext(FeatureContext);
const FeatureContextProvider: React.FC<{ children: any }> = ({ children }) => {
  // const { language } = useTrans();

  const { cacheCheck, oldPost } = useOldPost();

  const defaultObject: Attachment = {
    name: "",
    source: "",
    shape: "portrait",
    template: "text",
    type: "article",
    photos: [],
    post_id: oldPost?._id,
    postType: defaultPostType,
    purpose: "feature",
  };

  // const { newPost } = useNewPost();
  // const { _id: post_id } = newPost;
  const [deleteFeature, setDeleteFeature] = useState<Attachment>(defaultObject);
  const [feature, dispatch] = useReducer<
    (state: any, action: any) => Attachment | null
  >(FeatureReducer, null);

  useEffect(() => {
    if (oldPost) {
      const feature_from_old = {
        ...oldPost.feature,
        ..._.pick(oldPost, ["postType", "template", "type"]),
        post_id: oldPost?._id,
      };

      if (
        ObjectID.isValid(feature_from_old.post_id) &&
        feature_from_old.post_id !== feature?.post_id &&
        typeof localStorage !== "undefined"
      ) {
        const feature_to_load = cacheCheck(feature_from_old, "feature");
        loadFeature(feature_to_load);
        localStorage.setItem("feature", JSON.stringify(feature_to_load));
      }
    }
  }, [cacheCheck, feature, oldPost]);

  // useEffect(() => {
  //   const hasCachedState = !_.isEmpty(cacheState);
  //   if (!feature) {
  //     hasCachedState
  //       ? loadFeature(cacheState.feature)
  //       : loadFeature(defaultAttachment);
  //   }
  // }, [cacheState, feature]);

  useEffect(() => {
    if (oldPost && !_.isEmpty(feature) && typeof localStorage !== "undefined") {
      const { _id: post_id } = oldPost;
      localStorage.setItem("feature", JSON.stringify({ ...feature, post_id }));
    }
  }, [feature, oldPost]);

  const handleFeatureChange = (stateName: string, target: any) => {
    dispatch({
      type: "HANDLE_CHANGE",
      post: {
        stateName,
        target,
      },
    });
  };

  const handleTypeChange = (event: any) => {
    if (feature) {
      const postTypeObj = feature.postType;
      const postTypeObjDelete = feature.postType;
      const templateValue = feature.template;

      handleDeleteFeature(
        postTypeObjDelete,
        templateValue
        //  event.target.value
      );
      postTypeObj[templateValue] = event.target.value;

      dispatch({
        type: "HANDLE_CHANGE",
        post: {
          stateName: "postType",
          target: postTypeObj,
        },
      });
      dispatch({
        type: "HANDLE_CHANGE",
        post: {
          stateName: "type",
          target: event.target.value,
        },
      });
    }
  };

  const handleDeleteFeature = (
    postTypeObj: PostType,
    templateValue: string
  ) => {
    defaultObject.template = templateValue;
    defaultObject.postType = postTypeObj;
    if (feature && feature._id) {
      setDeleteFeature(feature);
      dispatch({
        type: "LOAD_FEATURE",
        post: defaultObject,
      });
    } else {
      dispatch({
        type: "LOAD_FEATURE",
        post: defaultObject,
      });
    }
  };

  const restoreFeature = () => {
    const { template, type } = deleteFeature;

    defaultPostType[template] = type;
    deleteFeature.postType = defaultPostType;
    loadFeature(deleteFeature);
    setDeleteFeature(defaultObject);
  };

  const updateAlbumPhotoTitle = (name: string, title: Language) => {
    // const photoCollection = attachment.photos;
    //  const photoObject = _.find(photoCollection, ["name", name]);
    // photoObject.title = title;
    //  setAttachment({ ...attachment, photos: photoCollection });
  };

  const updateFeature = (changes: any) => {
    const post = { ...feature, ...changes };
    dispatch({
      type: "LOAD_FEATURE",
      post,
    });
  };

  const loadFeature = (post: any) => {
    dispatch({ type: "LOAD_FEATURE", post });
  };

  const removeAlbumPhoto = (name: string) => {
    // const deletedAttachment = _.find(attachment.photos, ["name", name]);
    // const deletePhotos = attachment.deletePhotos;
    // if (deletePhotos && deletedAttachment._id) {
    //   deletePhotos.push(deletedAttachment);
    //   setAttachment({
    //     ...attachment,
    //     deletePhotos,
    //     photos: _.reject(attachment.photos, ["name", name]),
    //   });
    // } else {
    //   setAttachment({
    //     ...attachment,
    //     photos: _.reject(attachment.photos, ["name", name]),
    //   });
    // }
  };

  const restoreAlbumPhoto = (name: string) => {
    // const restoredAttachment = _.find(attachment.deletePhotos, ["name", name]);
    // const newArray = _.concat(attachment.photos, restoredAttachment);
    // setAttachment({
    //   ...attachment,
    //   deletePhotos: _.reject(attachment.deletePhotos, ["name", name]),
    //   photos: newArray,
    // });
  };

  return (
    <FeatureContext.Provider
      value={{
        //      clearFeature,
        deleteFeature,
        feature: feature || defaultAttachment,
        handleDeleteFeature,
        handleFeatureChange,
        handleTypeChange,
        loadFeature,
        removeAlbumPhoto,
        restoreAlbumPhoto,
        restoreFeature,
        updateFeature,
        updateAlbumPhotoTitle,
      }}
    >
      {children}
    </FeatureContext.Provider>
  );
};
export default FeatureContextProvider;
