import React from "react";
//import classNames from "classnames";
import { withStyles } from "@mui/styles";
import ReactCountryFlag from "react-country-flag";
import { createStyles, Theme } from "@mui/material";
//import { FaSortDown } from "react-icons/fa";

const styles = (theme: Theme) =>
  createStyles({
    condense: {
      textAlign: "center",
    },
    flag: {
      marginTop: 1,
      width: 17 + "px !important",
    },
    logo_title: {
      color: theme.palette.fonts.main,
      textShadow: theme.palette.shadow.textShadow,
      //background: "white",
      borderRadius: 2,

      marginRight: theme.spacing(0.25),
      padding: 0,
    },
    translate: {
      color: theme.palette.fonts.main,
      fontSize: "0.8em",
    },
  });

const Translate: React.FC<{
  classes: any;
  countryCode: String;
  title: String;
}> = ({ classes, countryCode, title }) => {
  return (
    <ReactCountryFlag
      countryCode={countryCode}
      style={{ verticalAlign: "unset" }}
      svg
      className={classes.flag}
      title={title}
    />
  );
};

export default withStyles(styles)(Translate);
