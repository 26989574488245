import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableFooter,
  TableRow,
} from "@mui/material";
import { createStyles, withStyles } from "@mui/styles";
import Pagination from "./Feed/pagination";
import TableItem from "./Feed/tableItemRow";
import { useTrans } from "../../../../context/Trans/translationContext";
import { useMyPosts } from "../../../../context/Feed/myPostsFeedContext";

var _ = require("lodash");

const styles = () =>
  createStyles({
    manage_wrapper: {
      padding: "120px 8px 24px",
      width: "100%",
    },
  });

const MyPostsFeed: React.FC<{ classes: any }> = ({ classes }) => {
  const { count, feed, rowsPerPage } = useMyPosts();
  const { setPathArray } = useTrans();

  const [deletePosts, setDeletePosts] = useState([]);

  const deletePostsProcess = async () => {
    _.map(deletePosts, async function (_id: string) {
      console.log("trashing", _id);
      // await trashPost({
      //   variables: {
      //     _id,
      //   },
      //  // refetchQueries,
      // });
    });
  };

  useEffect(() => {
    setPathArray([
      { dir: "manage", type: "route" },
      { dir: "posts", type: "route" },
    ]);
  }, [setPathArray]);

  const canDelete = deletePosts.length > 0;

  return (
    <Box className={classes.manage_wrapper}>
      {canDelete && (
        <Box style={{ margin: "8px 0" }}>
          <Button
            classes={{ disabled: classes.disabled }}
            disabled={canDelete}
            onClick={() => deletePostsProcess()}
            size="small"
            variant="outlined"
          >
            Delete
          </Button>
        </Box>
      )}
      <TableContainer component={Paper}>
        <Table
          className={classes.table}
          aria-label="customized table"
          size="small"
        >
          <TableBody>
            {feed.map((draft: any) => {
              return (
                <TableItem
                  draft={draft}
                  key={draft._id}
                  deletePosts={deletePosts}
                  setDeletePosts={setDeletePosts}
                />
              );
            })}
          </TableBody>
          {feed.length >= rowsPerPage && count > rowsPerPage && (
            <TableFooter>
              <TableRow>
                <Pagination />
              </TableRow>
            </TableFooter>
          )}
        </Table>
      </TableContainer>
    </Box>
  );
};

export default withStyles(styles)(MyPostsFeed);
