import { createStyles, Theme } from "@mui/material";
const drawerWidth = 315;
const speed = 800;
const styles = (theme: Theme) =>
  createStyles({
    "@global": {
      body: {
        color: theme.palette.fonts.focus,
      },
      ".MuiOutlinedInput-root.Mui-focused.MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.fonts.focus,
      },
    },
    root: {
      display: "flex",
      height: "100vh",
    },
    appBar_closed: {
      [theme.breakpoints.down("sm")]: {
        marginTop: -theme.spacing(7),
      },
      [theme.breakpoints.up("sm")]: {
        marginTop: -theme.spacing(8),
      },
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeIn,
        duration: speed, //theme.transitions.duration.leavingScreen
      }),
    },
    appBar_opened: {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: speed, //theme.transitions.duration.enteringScreen
      }),
    },
    avatar: {
      width: 24,
      height: 24,
      "& img": {
        width: 24,
        height: 24,
        borderRadius: 12,
      },
    },
    brand: {
      background: theme.palette.shadow.transparent,
      padding: "8px 8px 8px 0",
      position: "absolute",
      width: "100%",
      zIndex: 2,
      [theme.breakpoints.up("sm")]: {
        minHeight: theme.spacing(8),
      },
    },
    banner: {
      flexGrow: 1,
      marginLeft: theme.spacing(-1),
      marginRight: theme.spacing(1),
      "& h1": {
        fontSize: "1.7rem",
        paddingLeft: theme.spacing(1),
        textTransform: "uppercase",
        fontWeight: "bold",
        [theme.breakpoints.up("sm")]: {
          fontSize: "2.3rem",
          paddingRight: 50,
        },
        "& a": {
          color: theme.palette.background.paper,
        },
      },
    },
    button: {
      color: theme.palette.background.paper,
    },
    canvas: {
      //    maxWidth: 960,
      position: "absolute",
      width: "100vw",
    },
    checked: {
      "& span": {
        color: theme.palette.color.green,
      },
    },
    close: {
      cursor: "pointer",
      fontSize: "1rem",
      textAlign: "right",
      "&:hover": {
        color: "black",
      },
    },
    content: {
      minHeight: "100vh",
      position: "relative",
      //width: `calc(100% + ${drawerWidth}px)`
    },
    copyright: {
      fontSize: "0.5rem",
      color: "gray",
      textAlign: "center",
    },
    dashItem_bottom: { flexGrow: 1, minHeight: "fit-content" },
    dashboard: {
      position: "relative",
      width: 315,
      zIndex: 2,
    },
    dashboardMain_closed: {
      marginLeft: -drawerWidth,
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: speed * 0.5, //theme.transitions.duration.leavingScreen
      }),
    },
    dashboardSettings_closed: {
      marginRight: -drawerWidth,
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: speed * 0.5, //theme.transitions.duration.leavingScreen
      }),
    },
    dashboardMain_opened: {
      marginLeft: 0,
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeIn,
        duration: speed * 0.5, //theme.transitions.duration.leavingScreen
      }),
    },
    dashboardSettings_opened: {
      marginRight: 0,
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeIn,
        duration: speed * 0.5, //theme.transitions.duration.leavingScreen
      }),
    },
    dashItem: {
      //   borderTop: "1px solid " + theme.palette.primary.faint,
      //  "&:last-of-type": {
      //   borderBottom: "1px solid " + theme.palette.primary.faint
      // }
    },
    dashMenu: {
      marginTop: theme.spacing(5),
    },
    divider: {
      backgroundColor: theme.palette.background.default,
      backgroundImage: theme.palette.shadow.dividerLeft,
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      position: "fixed",
    },
    drawerPaper: {
      backgroundColor: theme.palette.background.paper,
      borderRight: "1px solid " + theme.palette.shadow.border,
      boxShadow: theme.shadows[16], //"10px 0px 25px 0px rgba(0, 0, 0, 0.45)",
      position: "inherit",
      width: drawerWidth,
    },

    grid_button: {
      padding: 0,
      width: 44,
      height: 44,
      minWidth: 44,
    },
    grid_label: {
      color: theme.palette.fonts.focus,
      fontSize: "1.5rem",
    },
    grid_image: {
      padding: theme.spacing(0.5),
    },
    header: { color: theme.palette.fonts.detail },
    home: {
      cursor: "pointer",
      fontSize: "1rem",
      "&:hover": {
        color: "black",
      },
    },
    icon: {
      fontSize: "1rem",
      "& span svg": {
        color: theme.palette.fonts.focus,
      },
      "& span.disabled svg": {
        color: theme.palette.fonts.focus,
      },
    },
    listItem: {
      width: "100%",
    },
    loggedIn: {
      "& p": {
        color: theme.palette.fonts.main,
        lineHeight: 1,
        margin: 6,
        textAlign: "center",
      },
      "& p.stat": {
        color: theme.palette.color.green,
      },
      "& p.action": {
        fontSize: ".8rem",
      },
      "& p svg": {
        color: theme.palette.color.green,
      },
    },
    loggedOut: {
      "& p": {
        color: theme.palette.fonts.main,
        lineHeight: 1,
        margin: 6,
        textAlign: "center",
      },
      "& p.stat": {
        color: theme.palette.color.red,
      },
      "& p.action": {
        fontSize: ".8rem",
      },
      "& p svg": {
        color: theme.palette.color.red,
      },
    },
    logo: {
      borderRadius: 5,
      width: "100%",
    },
    logo_button: {
      padding: "16px 0 16px 16px",
    },
    logo_header: {
      flex: 1,
      padding: theme.spacing(0.5),
    },
    logo_text: {
      color: theme.palette.fonts.main,
      fontSize: "1rem",
      fontWeight: "bold",
      lineHeight: 1.3,
      paddingTop: theme.spacing(3),
      textTransform: "uppercase",
    },
    menu: {
      color: theme.palette.secondary.main,
      flexBasis: "0 | content",
      marginLeft: theme.spacing(1),
      padding: theme.spacing(1),
      /*[theme.breakpoints.up("xl")]: {
      display: "none"
    }*/
    },
    menu_item: {
      fontSize: ".8rem",
      textAlign: "right",
      textTransform: "uppercase",
    },
    red: {
      color: theme.palette.color.red + " !important",
    },
    green: {
      color: theme.palette.color.green + " !important",
    },
    menu_list: {
      paddingTop: 0,
      paddingBottom: theme.spacing(2),
    },
    main_shift: {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },
    site_menu: {
      height: "100%",
    },
    spacer: {
      backgroundColor: theme.palette.background.default,
      [theme.breakpoints.down("sm")]: {
        height: theme.spacing(8),
      },
      [theme.breakpoints.up("sm")]: {
        height: theme.spacing(9),
      },
    },
    switch_label: {
      fontSize: ".6rem",
    },
    text: {
      "& span": {
        color: theme.palette.fonts.focus,
      },
      "& span.disabled": {
        color: theme.palette.fonts.focus,
      },
    },
    title_bar: {
      background: theme.palette.shadow.background1,
      border: "1px solid " + theme.palette.shadow.border,
      boxShadow: theme.palette.shadow.boxShadow,
      padding: "4px 8px",
      borderRadius: "0 4px 4px 0",
    },
    title: {
      [theme.breakpoints.down("sm")]: {
        fontSize: 33,
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: 41,
      },
      fontWeight: "bold",
      textShadow: theme.palette.shadow.textShadow,
      textTransform: "uppercase",
      "& a": {
        color: theme.palette.background.default,
      },
    },
    title_logo: {
      fontSize: 4,
    },
    view: {
      background: theme.palette.background.paper,
      boxShadow: theme.shadows[10],
      minHeight: "100vh",
      margin: "0 auto",
      overflow: "hidden",
      //position: "relative",
      width: "100%",
      zIndex: 1,
      [theme.breakpoints.down("md")]: {
        maxWidth: 645,
      },
      [theme.breakpoints.up("md")]: {
        maxWidth: 960,
      },
    },
    version: {
      color: theme.palette.fonts.detail,
    },
    wrapper: {
      height: "100vh",
      flexWrap: "unset",
      padding: 25,
    },
    wrapper_title: {
      marginBottom: theme.spacing(4),
      maxWidth: "100%",
    },
    small: {
      "& span": {
        fontSize: ".8rem",
      },
    },
    dashboardLinks: { paddingLeft: theme.spacing(3), width: "100%" },
  });

export default styles;
