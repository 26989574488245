import React from "react";
import { FormattedMessage } from "react-intl";
import { Grid, Theme, Typography } from "@mui/material";
import { createStyles, withStyles } from "@mui/styles";
import { useOldPost } from "../../../../context/Post/oldPostContext";
import { useUser } from "../../../../context/User/userContext";

const styles = (theme: Theme) =>
  createStyles({
    section_holder: {
      borderBottom: "1px solid lightgray",
      "&:last-child": {
        borderBottom: "none",
      },
    },
    section_text_active: {
      color: theme.palette.primary.main,
      fontWeight: "bold",
      marginBottom: theme.spacing(2),
      textAlign: "right",
      width: "100%",
    },
    section_text: {
      color: theme.palette.fonts.detail,
      textAlign: "left",
      width: "100%",
    },
  });

const AdminPostForm: React.FC<{
  classes: any;
  key_name: string;
  section: any;
}> = ({ classes, key_name, section }) => {
  const { isMobile } = useUser();
  const { form_config, setFormConfig } = useOldPost();

  const key_id: { [key: string]: string } = {
    content: "Content",
    cover: "Cover Image",
    feature: "Featured Media",
    finalize: "Finalize",
    information: "Information",
    submit: "Submit",
    template: "Template",
  };

  const active = !isMobile || (form_config || "template") === key_name;

  return (
    <Grid item xs={12} classes={{ root: classes.section_holder }}>
      <Typography
        variant="h4"
        classes={{
          root: classes[active ? "section_text_active" : "section_text"],
        }}
        onClick={() => setFormConfig(key_name)}
      >
        <FormattedMessage id={key_id[key_name]} />
      </Typography>
      {active && section}
    </Grid>
  );
};

export default withStyles(styles)(AdminPostForm);
