import React from "react";
import { Grid, Theme } from "@mui/material";
import { createStyles, withStyles } from "@mui/styles";
import EditDate from "./editDate";
//import EditTitle from "./titles";
import SelectOrganization from "./selectOrganization";
import SelectWriter from "./selectWriter";
import SelectCategory from "./selectCategory";
import { useNewPost } from "../../../../../../context/Post/newPostContext";
//import { Post } from "../../../../../../context/types";
import PostLoader from "../../../../../../components/Loader";
var _ = require("lodash");

const styles = (theme: Theme) =>
  createStyles({
    form: {
      padding: theme.spacing(3),
      [theme.breakpoints.up("sm")]: {
        minHeight: "calc(100vh - 114px)",
      },
      [theme.breakpoints.down("sm")]: {
        minHeight: "calc(100vh - 106px)",
      },
    },
    formField: {
      width: "100%",
    },
    textButton: {
      textShadow: "0px 2px 3px rgba(0,0,0,0.4)",
    },
  });

const CreateRequired: React.FC<{
  // handleInputChange: () => void;
  // handleCategoryChange: () => void;
  // handleDateChange,
  // preSetState,
  // post: Post;
  //  postContext,
}> = () =>
  // {
  // handleInputChange,
  // handleCategoryChange,
  // handleDateChange,
  // preSetState,
  //  post,
  // postContext,
  //}
  {
    const {
      //  handleCategoryChange,
      handleInputChange,
      newPost: post,
    } = useNewPost();

    if (_.isEmpty(post) || !post.category) {
      return <PostLoader />;
    } else {
      const { organization, writer, category } = post;
      return (
        <Grid container spacing={2}>
          <Grid item sm={6} xs={12}>
            <SelectWriter
              writer={writer}
              // context={postContext}
              //  handleChange={handleInputChange}
              // preSetState={preSetState}
            />
            <SelectOrganization
              organization={organization}
              // context={postContext}
              handleChange={handleInputChange}
              // preSetState={preSetState}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            {/* {category._id ? ( */}
            <SelectCategory
              category={category}
              //  postContext={postContext}
              //    handleChange={handleCategoryChange}
            />
            {/* ) : (
            <PostLoader />
          )} */}
            <EditDate
            //published={published} handleDateChange={handleDateChange}
            />
          </Grid>
        </Grid>
      );
    }
  };

export default withStyles(styles)(CreateRequired);
