import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import CloudinaryCover from "./cloudinary-image";
import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { FaImage } from "react-icons/fa";
//import { ManagersContext } from "../ManagersContext";
import { useNewPost } from "../../../../../../context/Post/newPostContext";
import SelectedCover from "./selectedCover";
import { useUser } from "../../../../../../context/User/userContext";
import styles from "./styles";
var _ = require("lodash");
declare global {
  interface Window {
    cloudinary: any;
  }
}
const {
  REACT_APP_CLOUDINARY_API_KEY,
  //  REACT_APP_CLOUDINARY_API_SECRET,
  REACT_APP_CLOUDINARY_CLOUD_NAME,
  REACT_APP_CLOUDINARY_USER_NAME,
} = process.env;
const useForceUpdate = () => useState()[1];
const EditCover: React.FC<{
  classes: any;
}> = ({ classes }) => {
  const forceUpdate = useForceUpdate();
  const { Can, user } = useUser();
  //const { managers, toggleManager } = useContext(ManagersContext);
  const {
    deleteCover,
    // handleDeleteCover,
    handlePostChange,
    newPost,
    restoreCover,
  } = useNewPost();
  const { cover } = newPost;

  useEffect(() => {
    const defaultObject = {
      enabled: true,
      postType: { photo: "localphoto" },
      purpose: "cover",
      template: "photo",
      type: "localphoto",
      name: "",
      source: "",
    };

    _.isEmpty(cover) && handlePostChange("cover", defaultObject);
  }, [cover, handlePostChange]);

  const { cloudinary } = window || {};
  const [attachment, setAttachment] = useState(cover);
  useEffect(() => {
    if (!_.isEqual(attachment, cover)) {
      setAttachment(cover);
    }
  }, [attachment, cover]);

  const showWidget = () => {
    const widgetSigned = cloudinary.createUploadWidget(
      {
        cloudName: REACT_APP_CLOUDINARY_CLOUD_NAME,
        uploadPreset: "SentinelPhoto",
        cropping: "server",
        cropping_coordinates_mode: "custom",
        sources: ["local", "url", "dropbox"],
      },
      (error: any, result: any) => {
        processResult("upload", result, error);
      }
    );
    widgetSigned.open();
  };

  const processResult = (source: string, result: any, error: any) => {
    if (source === "upload") {
      if (error) {
        console.log(error);
      } else if (result.event === "success") {
        handlePostChange("cover", {
          ...attachment,
          name: result.info.public_id,
          source: result.info.secure_url,
        });
      }
    } else {
      handlePostChange("cover", {
        ...attachment,
        name: result.public_id,
        source: result.secure_url,
      });
    }
    forceUpdate(undefined);
  };

  const showLibrary = (processResult: any) => {
    window.cloudinary.openMediaLibrary(
      {
        cloud_name: REACT_APP_CLOUDINARY_CLOUD_NAME,
        api_key: REACT_APP_CLOUDINARY_API_KEY,
        username: REACT_APP_CLOUDINARY_USER_NAME,
        timestamp: _.floor(_.now() / 1000),
      },
      {
        insertHandler: function (data: any) {
          data.assets.forEach((asset: any) => {
            processResult("library", asset);
          });
        },
      }
    );
  };

  // const removeAttachment = (name) => {
  //   const deletedAttachment = _.find(attachment.photos, ["name", name]);
  //   const deletePhotos = attachment.deletePhotos;
  //   if (deletedAttachment._id) {
  //     deletePhotos.push(deletedAttachment);
  //     setAttachment({
  //       ...attachment,
  //       deletePhotos,
  //       photos: _.reject(attachment.photos, ["name", name]),
  //     });
  //   } else {
  //     setAttachment({
  //       ...attachment,
  //       photos: _.reject(attachment.photos, ["name", name]),
  //     });
  //   }
  // };

  // const restoreAttachment = (name) => {
  //   const restoredAttachment = _.find(attachment.deletePhotos, ["name", name]);
  //   const newArray = _.concat(attachment.photos, restoredAttachment);
  //   setAttachment({
  //     ...attachment,
  //     deletePhotos: _.reject(attachment.deletePhotos, ["name", name]),
  //     photos: newArray,
  //   });
  // };

  return (
    <React.Fragment>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
        spacing={1}
      >
        {cover?.source && cover.source.length > 0 ? (
          <SelectedCover
            // cover={cover}
            // handleDeleteCover={handleDeleteCover}
            showWidget={showWidget}
          />
        ) : (
          <Grid item xs={12} sm={8} style={{ padding: "16px 0" }}>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <Grid item xs={12} classes={{ root: classes.button_holder }}>
                <Button
                  variant="outlined"
                  classes={{ root: classes.button }}
                  onClick={() => showWidget()}
                >
                  <span className={classes.buttonText}>
                    <FormattedMessage
                      id="Upload Photo"
                      defaultMessage="Upload Photo"
                    />
                  </span>
                  <FaImage className={classes.icon} />
                </Button>
              </Grid>
              <Can
                role={user?.role}
                perform="manage:users"
                yes={() => (
                  <Grid item xs={12} classes={{ root: classes.button_holder }}>
                    <Button
                      ref={el2 => el2}
                      variant="outlined"
                      classes={{ root: classes.button }}
                      onClick={() => showLibrary(processResult)}
                    >
                      <span className={classes.buttonText}>
                        <FormattedMessage
                          id="Choose from Library"
                          defaultMessage="Choose from Library"
                        />
                      </span>
                    </Button>
                  </Grid>
                )}
              />
            </Grid>
          </Grid>
        )}

        {deleteCover?.source && deleteCover.source.length > 0 && (
          <Grid item xs={12} sm={8} style={{ marginTop: 24 }}>
            <Card>
              <CardActionArea onClick={restoreCover}>
                <CardContent classes={{ root: classes.cardContent }}>
                  <Grid container spacing={1}>
                    <Grid item xs={3}>
                      <CloudinaryCover
                        //  className={classes.coverImage}
                        name={deleteCover.name}
                        width={650}
                      />
                    </Grid>
                    <Grid item xs={9}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography
                            className={classes.deleteCoverTitle}
                            variant="body1"
                          >
                            <span className={classes.deleted}>
                              <FormattedMessage id="Deleted" />
                            </span>{" "}
                            {deleteCover.title}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            className={classes.deletedCoverFile}
                            variant="caption"
                          >
                            <FormattedMessage id="Click to Restore" />
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        )}
      </Grid>
      {/* <Picker
          attachmentObject={cover}
          closeManager={closeManager}
          header={<FormattedMessage id="Cover" />}
          preset={"SentinelPhoto"}
          type="photo"
          open={open}
          submitFile={submitFile}
          toggleManager={toggleManager}
        /> */}
    </React.Fragment>
  );
};

/*
<CardContent className={classes.content}>
                  <div className={classes.coverInfo}>
                    {coverObj.title && (
                      <Typography className={classes.coverTitle} variant="h5">
                        {coverObj.title}
                      </Typography>
                    )}
                    {coverObj.credit && (
                      <Typography className={classes.coverCredit}>
                        {"Credit: " + coverObj.credit}
                      </Typography>
                    )}
                    {coverObj.description && (
                      <Typography className={classes.coverDescription}>
                        {coverObj.description}
                      </Typography>
                    )}
                  </div>
                </CardContent>
*/
export default withStyles(styles)(EditCover);
