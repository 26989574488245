import React from "react";
import { Box, Card, CardContent, Grid, Theme } from "@mui/material";
import { createStyles, withStyles } from "@mui/styles";
import { Document, Page, pdfjs } from "react-pdf";
import { Attachment } from "../../../context/types";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const styles = (theme: Theme) =>
  createStyles({
    image: {
      background: theme.palette.background.default,
      boxShadow: theme.shadows[10],
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
      "& img": {
        width: "100%",
      },
    },
    video_wrapper: {
      //position: "relative",
      //paddingBottom: "129.41%",
      "& canvas": {
        width: "100% !important",
        height: "100% !important",
      },
      "& .annotationLayer": {
        width: "100% !important",
        height: "100% !important",
      },
    },
  });

const PDFDocument: React.FC<{ classes: any; document: Attachment }> = ({
  document,
  classes,
}) => {
  const { source } = document;

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="flex-start"
    >
      <Grid item xs={12} sm={8} className={classes.formButton}>
        <Card className={classes.image}>
          <CardContent classes={{ root: classes.card_content }}>
            <Box className={classes.video_wrapper}>
              <Document onLoadError={console.error} file={source}>
                <Page pageNumber={1} />
              </Document>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(PDFDocument);
