import React from "react";
import { FaAngleUp } from "react-icons/fa";
import {
  Box,
  Button,
  Checkbox,
  DialogActions,
  FormControlLabel,
  Grid,
  Switch,
  Theme,
  Typography,
} from "@mui/material";
import { createStyles, withStyles } from "@mui/styles";
import PostLoader from "../../../../../components/Loader";
//import { useUser } from "../../../../../context/User/userContext";
import { useNewPost } from "../../../../../context/Post/newPostContext";
import { useAdminPost } from "../../../../../context/Post/adminPostContext";
//import { useManagers } from "../../../../../context/Post/managersContext";
import { useValidation } from "../../../../../context/Post/validationContext";

require("dotenv").config();

var _ = require("lodash");

//const { REACT_APP_TESTING } = process.env;

const styles = (theme: Theme) =>
  createStyles({
    button: {
      background: theme.palette.color.green_gradient,
      color: "white",
    },
    checklist: {
      paddingTop: theme.spacing(2),
    },
    checklistItem: {
      fontSize: ".68rem",
      lineHeight: 1.4,
      textAlign: "left",
      width: "100%",
    },
    dialogLeft: {
      justifyContent: "flex-start",
    },
    disabled: {
      color: theme.palette.fonts.detail + " !important",
    },
    label: {
      color: "unset",
    },
    process: {
      flexGrow: 1,
      height: "fit-content",
    },
    redirect_label: {
      fontSize: ".9rem",
    },
    saved: { background: "none", minWidth: "unset", padding: 0 },
    switch: {
      color: theme.palette.color.red + " !important",
    },
    switch_checked: {
      color: theme.palette.color.green + " !important",
    },
    switch_unchecked: {
      color: theme.palette.color.red + " !important",
    },
    switch_track_checked: {
      backgroundColor: theme.palette.color.green + " !important",
    },
    switch_track_unchecked: {
      backgroundColor: theme.palette.color.red + " !important",
    },
    switch_label: {
      fontSize: ".8rem",
      fontWeight: "bold",
    },
  });

// Published : save, unpublish
// Draft : save, send, publish
// Pending : save, unsend
// none : draft, send, publish
// draft, publish, save, send, unpublish, unsend

const Submit: React.FC<{ classes: any }> = ({ classes }) => {
  // const { Can, user } = useUser();
  const { handlePostChange, newPost } = useNewPost();
  //  const { toggleManager } = useManagers();
  const { validation } = useValidation();
  const { has_changes, redirect_on_publish, setRedirectPublish, submitPost } =
    useAdminPost();
  const { status } = newPost;
  const isPublished = status === "published";
  const errors = _.filter(validation, ["pass", false]);
  const publishErrorsExist = errors.length > 0;

  function changeStatus(e: any) {
    const { checked } = e.target;
    const publish = checked ? "published" : "draft";
    handlePostChange("status", publish);
  }

  // const StatusOptions: { [key: string]: any } = {
  //   draft: [
  //     <Button
  //       onClick={() => toggleManager("postPreview")}
  //       classes={{
  //         root: classes.button,
  //         text: classes.label,
  //         disabled: classes.disabled,
  //       }}
  //       disabled={publishErrorsExist}
  //       autoFocus
  //     >
  //       {"Preview"}
  //     </Button>,
  //     <Button
  //       disabled={!has_changes}
  //       onClick={() => submitPost("save")}
  //       classes={{
  //         root: classes.button,
  //         text: classes.label,
  //         disabled: classes.disabled,
  //       }}
  //       autoFocus
  //     >
  //       {"Save"}
  //     </Button>,
  //     <Button
  //       onClick={() => submitPost("pending")}
  //       classes={{
  //         root: classes.button,
  //         text: classes.label,
  //         disabled: classes.disabled,
  //       }}
  //       disabled={publishErrorsExist}
  //     >
  //       {"Send"}
  //     </Button>,
  //     <Can
  //       role={user.role}
  //       perform="publish:post"
  //       yes={() => (
  //         <Button
  //           onClick={() => submitPost("published")}
  //           classes={{
  //             root: classes.button,
  //             text: classes.label,
  //             disabled: classes.disabled,
  //           }}
  //           disabled={publishErrorsExist}
  //         >
  //           {"Publish"}
  //         </Button>
  //       )}
  //     />,
  //   ],
  //   pending: [
  //     <Button
  //       disabled={!has_changes}
  //       onClick={() => submitPost("save")}
  //       classes={{ root: classes.button, text: classes.label }}
  //     >
  //       {"Save"}
  //     </Button>,
  //     <Button
  //       onClick={() => submitPost("draft")}
  //       classes={{ root: classes.button, text: classes.label }}
  //     >
  //       {"Return"}
  //     </Button>,
  //     <Can
  //       role={user.role}
  //       perform="publish:post"
  //       yes={() => (
  //         <Button
  //           onClick={() => submitPost("published")}
  //           classes={{ root: classes.button, text: classes.label }}
  //           disabled={publishErrorsExist}
  //         >
  //           {"Publish"}
  //         </Button>
  //       )}
  //     />,
  //   ],
  //   published: [
  //     <Button
  //       onClick={() => submitPost("save")}
  //       classes={{ root: classes.button, text: classes.label }}
  //     >
  //       {"Save"}
  //     </Button>,
  //     <Can
  //       role={user.role}
  //       perform="publish:post"
  //       yes={() => (
  //         <Button
  //           onClick={() => submitPost("draft")}
  //           classes={{ root: classes.button, text: classes.label }}
  //           disabled={publishErrorsExist}
  //         >
  //           {"Unpublish"}
  //         </Button>
  //       )}
  //     />,
  //   ],
  // };

  function PublishControls() {
    return (
      <Grid container>
        <Grid item xs>
          <FormControlLabel
            classes={{ root: classes.redirect_label }}
            control={
              <Checkbox
                checked={redirect_on_publish}
                color="primary"
                onChange={(e: any) => setRedirectPublish(e.target.checked)}
              />
            }
            label="Redirect on publish"
          />
        </Grid>
        <Grid item>
          <FormControlLabel
            classes={{ label: classes.switch_label }}
            control={
              <Switch
                checked={isPublished}
                classes={{
                  checked: classes.switch_checked,
                  root: classes.switch,
                  thumb:
                    classes[
                      isPublished ? "switch_checked" : "switch_unchecked"
                    ],
                  track:
                    classes[
                      isPublished
                        ? "switch_track_checked"
                        : "switch_track_unchecked"
                    ],
                }}
                onChange={changeStatus}
                name="Status"
              />
            }
            label={isPublished ? "Published" : "Unpublished"}
            labelPlacement="start"
          />
        </Grid>
      </Grid>
    );
  }

  if (status) {
    return (
      <Grid className={classes.process} container>
        <Grid item xs={12} style={{ textAlign: "right" }}>
          <PublishControls />
        </Grid>
        {publishErrorsExist && (
          <React.Fragment>
            <Grid item xs={12} classes={{ root: classes.checklist }}>
              {errors.map((errorNo: any) => {
                return (
                  <Typography
                    variant="caption"
                    key={errorNo.order}
                    component="p"
                    classes={{ root: classes.checklistItem }}
                  >
                    {errorNo.error}
                  </Typography>
                );
              })}
            </Grid>
            <Grid item xs={12}>
              <DialogActions classes={{ root: classes.dialogLeft }}>
                <Box>
                  <Button>
                    <FaAngleUp /> {`Checklist ${"(" + errors.length + ")"}`}
                  </Button>
                </Box>
              </DialogActions>
            </Grid>
          </React.Fragment>
        )}
        <Grid item xs={12} style={{ margin: "24px 0", textAlign: "right" }}>
          <Button
            classes={{
              root: classes.button,
              disabled: classes.saved,
              text: classes.label,
            }}
            disabled={!has_changes}
            onClick={() => submitPost("save")}
            size="small"
            variant={has_changes ? "contained" : undefined}
          >
            {has_changes ? "Save" : "Saved"}
          </Button>
        </Grid>
      </Grid>
    );
  } else {
    return <PostLoader />;
  }
};

export default withStyles(styles)(Submit);

/* {StatusOptions[
              status === "published-test" ? "published" : status
            ].map((button: any, index: number) => {
              return <Box key={index}>{button}</Box>;
            })} */
