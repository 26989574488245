import React from "react";
//import { useHistory } from "react-router-dom";
import { FaChevronLeft, FaHome } from "react-icons/fa";
import {
  ButtonBase,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import styles from "../styles";
import { useUser } from "../../../../context/User/userContext";

//var _ = require("lodash");

const MainMenu: React.FC<{ classes: any }> = ({ classes }) => {
  // let history = useHistory();
  const { admin_view, Can, setAdminView, toggleDashboard, user } = useUser();

  return (
    <Grid item xs={12}>
      <Grid container>
        {/* <Can
              role={user.role}
              perform="manage:users"
              yes={() => (
                <Grid item>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={adminDisplay}
                        classes={{ checked: classes.checked }}
                        onChange={toggleAdminDisplay}
                        name="Admin"
                        inputProps={{ "aria-label": "secondary checkbox" }}
                      />
                    }
                    label="Admin"
                  />
                </Grid>
              )}
            /> */}
        <Grid item xs>
          <Typography variant="h6" classes={{ root: classes.home }}>
            <FaHome />
          </Typography>
        </Grid>
        <Can
          role={user?.role}
          perform={"manage:users"}
          yes={() => {
            return (
              <Grid item xs>
                <FormControlLabel
                  control={
                    <Switch
                      checked={admin_view}
                      onChange={(e: any) => setAdminView(e.target.checked)}
                      name="Administrator"
                      inputProps={{ "aria-label": "success checkbox" }}
                    />
                  }
                  label="Administration"
                  labelPlacement="bottom"
                  classes={{ label: classes.switch_label }}
                />
              </Grid>
            );
          }}
        />
        <Grid item xs>
          <Typography variant="h6" classes={{ root: classes.close }}>
            <ButtonBase onClick={() => toggleDashboard("main")}>
              <FaChevronLeft />
            </ButtonBase>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(MainMenu);
