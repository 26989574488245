import React from "react";
import { Route, Switch } from "react-router-dom";
import Filtered from "./filtered";
import { useTrans } from "../../../context/Trans/translationContext";
import FeedContextProvider from "../../../context/Feed/feedSettingsContext";

const FeedFoundation: React.FC = () => {
  const { getDev, language, RouteTrans } = useTrans();
  return (
    <FeedContextProvider>
      <Switch>
        <Route // CATEGORY PUBLISHED POSTS FEED
          path={`/${language}/${RouteTrans["posts"][language]}/:division/:category`}
          render={(props: any) => {
            const { category, division } = props.match.params;
            const transDiv = getDev(division, language);
            const transCat = getDev(category, language);
            const path = [
              { dir: "posts", type: "route" },
              { dir: transDiv, type: "route" },
              { dir: transCat, type: "route" },
            ];

            return (
              <Filtered
                {...props}
                path={path}
                category={transCat}
                division={transDiv}
              />
            );
          }}
        />
        <Route // DIVISION PUBLISHED POSTS FEED
          path={`/${language}/${RouteTrans["posts"][language]}/:division`}
          render={props => {
            const { division } = props.match.params;
            const transDiv = getDev(division, language);
            const path = [
              { dir: "posts", type: "route" },
              { dir: transDiv, type: "route" },
            ];

            return <Filtered {...props} division={transDiv} path={path} />;
          }}
        />
        <Route // THE HOME PUBLISHED POSTS FEED
          path={`/${language}/${RouteTrans["posts"][language]}`}
          render={props => {
            const path = [{ dir: "posts", type: "route" }];
            return <Filtered {...props} path={path} />;
          }}
        />
      </Switch>
    </FeedContextProvider>
  );
};

export default FeedFoundation;
