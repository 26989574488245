import React from "react";
import { Divider, Grid, TextField, Theme } from "@mui/material";
import { createStyles, withStyles } from "@mui/styles";
import Action from "../../../Controls/fieldAction";
import { useNewPost } from "../../../../../../../../context/Post/newPostContext";

const styles = (theme: Theme) =>
  createStyles({
    divider: {
      marginTop: theme.spacing(1),
    },
    formField: {
      marginTop: theme.spacing(2),
      width: "100%",
      "& textarea": {
        color: theme.palette.fonts.focus,
      },
    },
    formSection: {
      marginTop: theme.spacing(1),
    },
  });

const FormFields: React.FC<{ classes: any; isEditing?: boolean }> = ({
  classes,
  isEditing,
}) => {
  const { dev, editing, handlePostChange, newPost, splitForm } = useNewPost();
  const { body, subtitle, title } = newPost;

  const editing_language = isEditing ? editing : dev;

  function handleChanges(label: string, value: any) {
    const contentObj = newPost[label];
    contentObj[editing_language] = value;
    handlePostChange(label, contentObj);
  }

  const hide = !isEditing && splitForm;

  return (
    <>
      <Grid item xs={12} classes={{ root: classes.formSection }}>
        <TextField
          key="article-title-input"
          variant="outlined"
          multiline
          label={"Title"}
          classes={{ root: classes.formField }}
          value={title?.[editing_language]}
          onChange={(e: any) => handleChanges("title", e.target.value)}
        />
        <Action hide={hide} item={"Title"} field="title" />
        <Divider classes={{ root: classes.divider }} />
      </Grid>
      <Grid item xs={12} classes={{ root: classes.formSection }}>
        <TextField
          key="article-subtitle-input"
          variant="outlined"
          label={"SubTitle"}
          multiline
          value={subtitle?.[editing_language]}
          classes={{ root: classes.formField }}
          onChange={(e: any) => handleChanges("subtitle", e.target.value)}
        />
        <Action hide={hide} item={"SubTitle"} field="subtitle" />
        <Divider classes={{ root: classes.divider }} />
      </Grid>
      <Grid item xs={12} classes={{ root: classes.formSection }}>
        <TextField
          key="article-body-input"
          variant="outlined"
          label={"Body"}
          multiline
          value={body?.[editing_language]}
          classes={{ root: classes.formField }}
          onChange={(e: any) => handleChanges("body", e.target.value)}
        />
        <Action hide={hide} item={"Body"} field="body" />
      </Grid>
    </>
  );
  // }
};

export default withStyles(styles)(FormFields);
