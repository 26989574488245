import React, { createContext, useContext, useEffect, useState } from "react";
import { Attachment } from "../types";
import { useOldPost } from "./oldPostContext";

var isEqual = require("deep-equal");
var _ = require("lodash");

export const AttachmentsContext = createContext<{
  attachments?: Attachment[];
  deleteAttachments: Attachment[];
  handleAddAttachment: (attachment: Attachment) => void;
  removeAttachment: (handle: string) => void;
  restoreAttachment: (handle: string) => void;
}>({
  attachments: [],
  deleteAttachments: [],
  handleAddAttachment: () => {},
  removeAttachment: () => {},
  restoreAttachment: () => {},
});
export const useAttachments = () => useContext(AttachmentsContext);

const AttachmentsContextProvider: React.FC<{ children: any }> = ({
  children,
}) => {
  const { cacheCheck, oldPost } = useOldPost();
  const attachments_from_old = oldPost?.attachments;
  const [attachments, setAttachments] = useState<Attachment[] | undefined>(
    attachments_from_old
  );
  const [deleteAttachments, setDeleteAttachments] = useState([]);

  useEffect(() => {
    if (oldPost?._id && attachments && typeof localStorage !== "undefined") {
      localStorage.setItem(
        "attachments",
        JSON.stringify({ post_id: oldPost._id, attachments, deleteAttachments })
      );
    }
  }, [attachments, deleteAttachments, oldPost]);

  useEffect(() => {
    if (oldPost) {
      const { attachments: attachments_from_old } = oldPost;
      const attachments_cache_object = {
        post_id: oldPost._id,
        attachments: attachments_from_old,
      };

      // if (attachments_cache_object.post_id !== oldPost._id) {
      const attachments_to_load = cacheCheck(
        attachments_cache_object,
        "attachments"
      );

      if (!isEqual(attachments_to_load.attachments, attachments)) {
        setAttachments(attachments_to_load.attachments);
        attachments_to_load.deleteAttachments &&
          setDeleteAttachments(attachments_to_load.deleteAttachments);
      }
    }
  }, [attachments, cacheCheck, deleteAttachments, oldPost]);

  const handleAddAttachment = (attachment: Attachment) => {
    const newAttachmentsArray = _.concat(attachments, attachment);
    setAttachments(newAttachmentsArray);
  };

  const restoreAttachment = (handle: string) => {
    const restoredAttachment = _.filter(deleteAttachments, ["handle", handle]);
    const newDeleteAttachmentsArray = _.reject(deleteAttachments, [
      "handle",
      handle,
    ]);

    handleAddAttachment(restoredAttachment);
    setDeleteAttachments(newDeleteAttachmentsArray);
  };

  const removeAttachment = (handle: string) => {
    const deletedAttachment = _.filter(attachments, ["handle", handle]);
    const newAttachmentsArray = _.reject(attachments, ["handle", handle]);
    setAttachments(newAttachmentsArray);
    setDeleteAttachments(deletedAttachment);
  };

  return (
    <AttachmentsContext.Provider
      value={{
        attachments,
        deleteAttachments,
        handleAddAttachment,
        removeAttachment,
        restoreAttachment,
      }}
    >
      {children}
    </AttachmentsContext.Provider>
  );
};
export default AttachmentsContextProvider;

// const setPostTypeAttachments = (attachmentsArray: Attachment[]) => {
//   let setArray: Attachment[] = [];
//   attachmentsArray.forEach(attachment => {
//     const pt = getPostType(attachment);
//     attachment.postType = pt;
//     setArray.push(attachment);
//   });
//   return setArray;
// };

// const getPostType = (attachment: Attachment) => {
//   const { template, type } = attachment;
//   const postType: PostType = {
//     text: "document",
//     video: "youtube",
//     audio: "soundcloud",
//     social: "twitter",
//     photo: "localphoto",
//   };

//   postType[template] = type;
//   return postType;
// };
