import React from "react";
import Disqus from "disqus-react";
import { Theme } from "@mui/material";
import { createStyles, withStyles } from "@mui/styles";
import { useCurrentPost } from "../../../../context/Post/currentPostContext";
import { useTrans } from "../../../../context/Trans/translationContext";

const styles = (theme: Theme) =>
  createStyles({
    wrapper: {
      padding: theme.spacing(3),
      width: "100%",
      [theme.breakpoints.down("md")]: {
        padding: 0,
      },
    },
  });

const DisqusComments: React.FC<{ classes: any }> = ({ classes }) => {
  const { currentPost } = useCurrentPost();
  const { language, paths } = useTrans();
  const { postID, title } = currentPost;
  const disqusShortname = "the-haiti-sentinel";

  const path = paths && `https://sentinel.ht${paths[language]}`;
  const disqusConfig = {
    url: path || "https://sentinel.ht",
    identifier: postID?.toString(),
    title: title[language],
  };

  return (
    <div
      id="sentinel-disqus"
      className={classes.wrapper}
      style={{ width: "100%" }}
    >
      {postID && (
        <Disqus.DiscussionEmbed
          shortname={disqusShortname}
          config={disqusConfig}
        />
      )}
    </div>
  );
};

export default withStyles(styles)(DisqusComments);
