import React from "react";
//import { useLocation } from "react-router-dom";
//import { FormattedMessage } from "react-intl";
import {
  Button,
  DialogActions,
  //Grid,
  Theme,
} from "@mui/material";
import { createStyles, withStyles } from "@mui/styles";
// import Information from "./Information/basic";
// import Content from "./Content";
// import Cover from "./Cover";
// import Template from "./Template";

//import MainFeatureMedia from "./Featured";
//import Submit from "./submit";
//import { useNewPost } from "../../../../../context/Post/newPostContext";
//import { useFeature } from "../../../../../context/Post/featureContext";
//import { useAttachments } from "../../../../../context/Post/attachmentsContext";
//import { useTrans } from "../../../../../context/Trans/translationContext";
//import ValidationContextProvider from "../../../../../context/Post/validationContext";
import { useOldPost } from "../../../../../../context/Post/oldPostContext";
import { useAdminPost } from "../../../../../../context/Post/adminPostContext";
//import { useUser } from "../../../../../context/User/userContext";
//import { makeStyles } from "@mui/material/styles";

// const useStyles = makeStyles({
//   "@global": {
//     ".MuiOutlinedInput-notchedOutline": {
//       border: "1px solid #a9a9a9 !important",
//     },
//     ".MuiFormLabel-root.Mui-focused": {
//       color: "#a9a9a9",
//     },
//   },
// });

//var _ = require("lodash");

const styles = (theme: Theme) =>
  createStyles({
    admin: {
      maxWidth: 645,
      position: "fixed",
      width: "100%",
      zIndex: 5,
      overflow: "hidden",
      paddingBottom: theme.spacing(4),
    },
    body: {
      flexGrow: 1,
      minHeight: "fit-content",
      width: "100%",
    },
    form: {
      height: "inherit",
      marginTop: 0,
    },
    root: {
      // position: "inherit",
    },
    type: {
      width: "100%",
    },
    userForm: {
      // backgroundImage: theme.palette.primary.BoxiderLeft,
      marginTop: theme.spacing(3),
      width: "100%",
    },
    section_holder: {
      borderBottom: "1px solid lightgray",
      "&:last-child": {
        borderBottom: "none",
      },
    },
    section_text: {
      color: theme.palette.fonts.detail,
      // margin: "16px 0 ",
      width: "100%",
    },

    wrapper: {
      height: "calc(100vh - 64px)",
    },
  });

const AdminPostForm: React.FC<{ classes: any }> = ({ classes }) => {
  const { blank_post_has_changes, has_changes, reloadOldPost, clearPost } =
    useAdminPost();
  const { oldPost } = useOldPost();
  const isBlankPost = oldPost?._id === "0a0000000000000000000000";

  return (
    <DialogActions>
      <Button
        onClick={() => (has_changes ? reloadOldPost() : clearPost())}
        classes={{ root: classes.button, text: classes.label }}
        disabled={isBlankPost && !blank_post_has_changes}
        variant="outlined"
      >
        {has_changes ? "Revert" : isBlankPost ? "Blank Post" : "Clear All"}
      </Button>
    </DialogActions>
  );
};

export default withStyles(styles, { withTheme: true })(AdminPostForm);
