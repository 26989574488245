import React from "react";
import { withStyles } from "@mui/styles";
import ReactPlayer from "react-player";

const styles = theme => ({
  attachment_inner: {
    padding: theme.spacing(0.5),
  },
});

const Soundcloud = props => {
  const { classes, soundcloud } = props;
  const { source } = soundcloud;
  return (
    <div className={classes.attachment_inner}>
      <ReactPlayer
        url={source}
        width="100%"
        // "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/298221225&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
      />
    </div>
  );
};

export default withStyles(styles)(Soundcloud);
