import React from "react";
import { Route, Switch } from "react-router-dom";
import Attachment from "./Attachment";
import Form from "./Edit";
import MyPosts from "./MyPosts";
import Preview from "./Preview";
import { useUser } from "../../../context/User/userContext";
import { useTrans } from "../../../context/Trans/translationContext";
import { useAdminPost } from "../../../context/Post/adminPostContext";

const Manage: React.FC = () => {
  const { view } = useAdminPost();
  const { language, RouteTrans } = useTrans();
  const { Can, user } = useUser();

  const RootPages = (
    <Switch>
      <Route
        path={`/${language}/${RouteTrans["manage"][language]}/${RouteTrans["posts"][language]}`}
        render={() => (
          <Can
            role={user?.role}
            perform="view:drafts"
            yes={() => <MyPosts />}
            no={() => "You are not authorized to access this route."}
          />
        )}
      />
      <Route
        path={`/${language}/${RouteTrans["create"][language]}/${RouteTrans["post"][language]}`}
        render={props => (
          <Can
            role={user?.role}
            perform={"create:post"}
            yes={() => (
              <Form
                key="create-form"
                create
                route_params={props.match.params}
              />
            )}
            no={() => "You are not authorized to access this route."}
          />
        )}
      />
      <Route
        path={`/${language}/${RouteTrans["edit"][language]}/${RouteTrans["post"][language]}/:docID`}
        render={props => (
          <Can
            role={user?.role}
            perform={"edit:post"}
            yes={() => (
              <Form key="edit-form" route_params={props.match.params} />
            )}
            no={() => "You are not authorized to access this route."}
          />
        )}
      />
    </Switch>
  );

  const page_view: { [key: string]: any } = {
    attachment: <Attachment />,
    preview: <Preview />,
    root: RootPages,
  };

  return <>{page_view[view || "root"]}</>;
};

export default Manage;
