import React, { createContext, useContext } from "react";
import { useNewPost } from "./newPostContext";
import { useFeature } from "./featureContext";
import { Attachment, Category, Language } from "../types";

var _ = require("lodash");

export const ValidationContext = createContext<{ validation: any[] }>({
  validation: [],
});
export const useValidation = () => useContext(ValidationContext);
const ValidationContextProvider: React.FC<{ children: any }> = ({
  children,
}) => {
  const { newPost } = useNewPost();

  const { feature } = useFeature();
  const { postType, source, template } = feature;

  const error_1_validation = (title: Language, language: string) => {
    const error_1_object = {
      order: 1,
      pass: !_.isEmpty(title?.[language]),
      error: "There is no Title for this post. Check Information.",
    };
    return error_1_object;
  };

  const error_2_validation = (subtitle: Language, language: string) => {
    const error_2_object = {
      order: 2,
      pass: !_.isEmpty(subtitle?.[language]),
      error: "There is no Subtitle for this post. Check Information",
    };
    return error_2_object;
  };

  const error_3_validation = (category: Category) => {
    const error_3_object = {
      order: 3,
      pass:
        category && category._id !== "5c610c6f1c9d440000b088a4" ? true : false,
      error: "Posts needs a Category to be published. Check Information.",
    };
    return error_3_object;
  };

  const error_4_validation = () => {
    const hasFeature = postType[template] !== "article";
    const hasSource = source?.length !== 0;
    const error_4_object = {
      order: 4,
      pass: !hasFeature || (hasFeature && hasSource),
      error: `You chose the ${postType[template]} template, but provided no information for the feature.`,
    };
    return error_4_object;
  };

  const error_5_validation = (link: Attachment) => {
    if (link) {
      const isEnabled = link.enabled;
      const hasLink = link?.source?.length !== 0;
      const error_5_object = {
        order: 5,
        pass: !isEnabled || (isEnabled && hasLink),
        error: "You chose to include a link but provided no link URL.",
      };
      return error_5_object;
    } else {
      return {};
    }
  };

  const error_6_validation = (link: Attachment, language: string) => {
    if (link) {
      const isEnabled = link.enabled;
      const hasName = link?.title?.[language]?.length !== 0;
      const error_6_object = {
        order: 6,
        pass: !isEnabled || (isEnabled && hasName),
        error:
          "You chose to include a link but provided no text for the button.",
      };
      return error_6_object;
    } else {
      return {};
    }
  };

  const evaluation = () => {
    if (newPost && postType) {
      const { category, language, link, subtitle, title } = newPost;
      return [
        error_1_validation(title, language),
        error_2_validation(subtitle, language),
        error_3_validation(category),
        error_4_validation(),
        error_5_validation(link),
        error_6_validation(link, language),
      ];
    } else {
      return [];
    }
  };

  return (
    <ValidationContext.Provider value={{ validation: evaluation() }}>
      {children}
    </ValidationContext.Provider>
  );
};
export default ValidationContextProvider;
/*
clearFeature,
        newFeature,
        handleFeatureChange,
        handleGeneralFeatureChange,
        handleChangeType,
        handleInputChange,
        handleTypeChange,
        loadFeature,
        mediaManagerOpen,
        toggleMediaManager,
        updateFeature*/
