import React from "react";
import classNames from "classnames";
//import cloudinary from "cloudinary";
import {
  AppBar,
  Dialog,
  IconButton,
  Theme,
  Toolbar,
  Typography,
} from "@mui/material";
import { createStyles, withStyles } from "@mui/styles";
import { FaChevronLeft } from "react-icons/fa";
import Content from "../../../../../Post/Body/Content";
import { useAttachments } from "../../../../../../../context/Post/attachmentsContext";
import { useManagers } from "../../../../../../../context/Post/managersContext";
import { useNewPost } from "../../../../../../../context/Post/newPostContext";
//var _ = require("lodash");

const styles = (theme: Theme) =>
  createStyles({
    appBar: {
      background: theme.palette.secondary.main,
      //   marginBottom: theme.spacing(3),
      position: "relative",
    },
    button: {
      width: "100%",
      marginBottom: theme.spacing(3),
      marginTop: theme.spacing(1),
    },
    buttonText: {
      textShadow: "0px 4px 4px rgba(0,0,0,0.4)",
    },
    icon: {
      filter: "drop-shadow(0px 4px 4px rgba(0,0,0,0.4))",
      marginLeft: theme.spacing(1),
    },
    formButton: {
      //   paddingLeft: theme.spacing(3),
      // paddingRight: theme.spacing(3)
    },
    flex: {
      flex: 1,
    },
    header: {
      textShadow: "0px 4px 4px rgba(0,0,0,0.4)",
      marginBottom: 0,
    },
  });

// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });

const Previewer: React.FC<{ classes: any; label: string }> = ({
  classes,
  label,
}) => {
  const { managers, toggleManager } = useManagers();
  const { attachments } = useAttachments();
  const { newPost } = useNewPost();
  const { bodyPreview } = managers;

  return (
    <Dialog
      fullScreen
      open={bodyPreview}
      //TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            onClick={() => toggleManager("bodyPreview")}
            aria-label="Close"
          >
            <FaChevronLeft className={classes.icon} />
          </IconButton>
          <Typography
            variant="h6"
            color="inherit"
            className={classNames(classes.flex, classes.header)}
          >
            {label}
          </Typography>
        </Toolbar>
      </AppBar>
      <div className={classes.formButton}>
        <Content attachments={attachments} body={newPost.body} />
      </div>
    </Dialog>
  );
};

export default withStyles(styles)(Previewer);
