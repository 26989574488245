import React, { useEffect } from "react";
import { Grid, Theme } from "@mui/material";
import { createStyles, withStyles } from "@mui/styles";
import Content from "./Content";
import MainMenu from "./Dashboards/MainMenu";
import Header from "./Header";
import Loader from "../components/Loader";
import Updating from "./Content/updating";
import AdminPostContextProvider from "../context/Post/adminPostContext";
import MyPostsFeedContextProvider from "../context/Feed/myPostsFeedContext";
import CurrentProfileProvider from "../context/Profile/currentProfileContext";
import CurrentPostProvider from "../context/Post/currentPostContext";
import { useTrans } from "../context/Trans/translationContext";

const styles = (theme: Theme) =>
  createStyles({
    application_view: {
      background: "#fefefe",
      boxShadow: theme.shadows[1],
      minHeight: "100vh",
      margin: "0 auto",
      overflow: "hidden",
      width: "100%",
      zIndex: 1,
    },
  });

const { REACT_APP_UNDER_DEVELOPMENT } = process.env;

const MainLayout: React.FC<{ classes: any; pathLang: any }> = ({
  classes,
  pathLang,
}) => {
  const { language, setLanguage } = useTrans();

  useEffect(() => {
    if (setLanguage && language !== pathLang) {
      setLanguage(pathLang);
    }
  }, [language, pathLang, setLanguage]);

  const showDev =
    window.location.hostname === "localhost" ||
    REACT_APP_UNDER_DEVELOPMENT === "false";

  if (language === pathLang) {
    return (
      <React.Fragment>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="flex-start"
          //classes={{ root: classes.app }}
        >
          <Grid
            item
            classes={{ root: classes.application_view }}
            id="application_view"
          >
            <CurrentProfileProvider>
              <CurrentPostProvider>
                <MyPostsFeedContextProvider>
                  <AdminPostContextProvider>
                    <Header language={language || pathLang} />
                    {showDev ? <Content /> : <Updating />}
                  </AdminPostContextProvider>
                </MyPostsFeedContextProvider>
              </CurrentPostProvider>
            </CurrentProfileProvider>
          </Grid>
        </Grid>
        {/* <ScrollButton showButton />*/}
        <MainMenu />
      </React.Fragment>
    );
  } else {
    return <Loader status="Loading Language" />;
  }
};
export default withStyles(styles)(MainLayout);

/*
<Route
                        path={`/${useLang}/${RouteTrans["about"][useLang]}/${RouteTrans["writers"][useLang]}`}
                        render={(props) => {
                          const path = [
                            { dir: "about", type: "route" },
                            { dir: "writers", type: "route" },
                          ];
                          setPathArray(path);
                          return (
                            <React.Fragment>
                              <Writers
                                postContext={postContext}
                                userContext={userContext}
                                {...props}
                              />
                            </React.Fragment>
                          );
                        }}
                      />
<Route
                    path="/posts/:division/:category"
                    render={(props) => (
                      <Feeds
                        postContext={postContext}
                        userContext={userContext}
                        {...props}
                      />
                    )}
                  />
                  <Route
                    path="/posts/:division"
                    render={(props) => (
                      <Feeds
                        postContext={postContext}
                        userContext={userContext}
                        {...props}
                      />
                    )}
                  />
                                    <Route
                    path="/about/our-story"
                    render={(props) => (
                      <React.Fragment>
                        <OurStory
                          postContext={postContext}
                          userContext={userContext}
                          {...props}
                        />
                      </React.Fragment>
                    )}
                  />
                  <Route
                    path="/profile_off"
                    render={(props) => (
                      <Can
                        role={user.role}
                        perform="edit:profile"
                        yes={() => (
                          <ProfileContextProvider>
                            <Profile
                              postContext={postContext}
                              user={user}
                              userContext={userContext}
                              {...props}
                            />{" "}
                          </ProfileContextProvider>
                        )}
                        no={() => (
                          <Redirect
                            to="/401"
                            message={
                              "You must be logged in to view your profile."
                            }
                          />
                        )}
                      />
                    )}
                  />
*/
