import React, { useEffect, useState } from "react";
import { Button, Grid, Theme } from "@mui/material";
import { createStyles, withStyles } from "@mui/styles";
import { FaUpload } from "react-icons/fa";
import { useUser } from "../../../../../../../context/User/userContext";
import { Attachment } from "../../../../../../../context/types";
// import SelectedFile from "./selectedFile";
// import Feature from "../../../../../Post/Body/Feature";
import { useFeature } from "../../../../../../../context/Post/featureContext";

var _ = require("lodash");

const {
  REACT_APP_CLOUDINARY_API_KEY,
  //  REACT_APP_CLOUDINARY_API_SECRET,
  REACT_APP_CLOUDINARY_CLOUD_NAME,
  REACT_APP_CLOUDINARY_USER_NAME,
} = process.env;

const timeStamp = _.floor(_.now() / 1000);

const styles = (theme: Theme) =>
  createStyles({
    appBar: {
      background: theme.palette.secondary.main,
      position: "relative",
    },
    button: {
      color: theme.palette.fonts.main,
      maxWidth: 350,
      width: "100%",
      marginTop: theme.spacing(1),
    },
    icon: {
      marginLeft: theme.spacing(1),
    },
    dialog_content: {
      margin: "0 auto",
      maxWidth: 645,
      // padding: theme.spacing(3),
      width: "100%",
    },
    flex: {
      flex: 1,
    },
    header: {
      //  color: theme.palette.primary.default,
      fontWeight: "bold",
      textAlign: "center",
      textShadow: theme.palette.shadow.textShadow,
    },
  });

// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });
const useForceUpdate = () => useState()[1];
const FilePicker: React.FC<{
  attachmentObject: Attachment;
  classes: any;
  closeManager: () => void;
  header: string;
  isFeature?: boolean;
  open: boolean;
  preset: any;
  submitFile: (changes: any) => void;
}> = ({
  attachmentObject,
  classes,
  closeManager,
  header,
  isFeature,
  // open,
  preset,
  //submitFile,
}) => {
  const forceUpdate = useForceUpdate();
  //const { cloudinary } = window;
  const { Can, user } = useUser();

  const { feature, updateFeature } = useFeature();

  const [attachment, setAttachment] = useState(attachmentObject);

  useEffect(() => {
    if (!_.isEqual(attachment, attachmentObject)) {
      setAttachment(attachmentObject);
    }
  }, [attachment, attachmentObject]);

  //const album = preset === "SentinelAlbum";

  // const showWidget = () => {
  //   const widgetSigned = cloudinary.createUploadWidget(
  //     {
  //       cloudName: REACT_APP_CLOUDINARY_CLOUD_NAME,
  //       uploadPreset: preset,
  //       //apiKey: REACT_APP_CLOUDINARY_API_KEY,
  //       //uploadSignature: generateSignature,
  //       multiple: album,
  //       cropping: album ? false : "server",
  //       cropping_coordinates_mode: "custom",
  //       sources: ["local", "url", "dropbox"],
  //     },
  //     (error: any, result: any) => {
  //       if (album) {
  //         processMultipleResult("upload", result, error);
  //       } else {
  //         console.log("showWidget", "result", result, "error", error);
  //         // processResult("upload", result, error);
  //       }
  //     }
  //   );
  //   widgetSigned.open();
  // };

  // const setResultFunction = isFeature ?
  //console.log("isFeature", isFeature);
  const processResult = (asset: any) => {
    const { public_id: name, secure_url: source } = asset;
    if (isFeature) {
      updateFeature({ ...feature, name, source });
    } else {
      setAttachment({
        ...attachment,
        name,
        source,
      });
    }

    forceUpdate(undefined);
  };

  // const processResult = (source: string, result: any, error: any) => {
  //   console.log("the source", source, "the result", result, "the error", error);
  //   if (asset) {
  //     const {public_id: name, secure_url:source} = asset
  //     setAttachment({
  //       ...attachment,
  //       name,
  //       source,
  //     });
  //   }
  //   if (source === "upload") {
  //     if (error) {
  //       console.log(error);
  //     } else if (result.event === "success") {
  //       setAttachment({
  //         ...attachment,
  //         name: result.info.public_id,
  //         source: result.info.secure_url,
  //       });
  //     }
  //   } else {
  //     setAttachment({
  //       ...attachment,
  //       name: result.public_id,
  //       source: result.secure_url,
  //     });
  //   }
  //   forceUpdate(undefined);
  // };

  // const processMultipleResult = (source: string, result: any, error: any) => {
  //   console.log("THe Result", result);
  //   if (source === "upload") {
  //     if (error) {
  //       console.log(error);
  //     } else if (result.event === "queues-end") {
  //       console.log("Before", attachment.photos);
  //       const newArray = _.concat(
  //         attachment.photos,
  //         setPhotosCollection(result.info.files)
  //       );
  //       console.log("The new array", newArray);
  //       setAttachment({
  //         ...attachment,
  //         photos: newArray,
  //       });
  //     }
  //   } else {
  //     const newArray = _.concat(
  //       attachment.photos,
  //       setPhotosCollection(result.info.files)
  //     );
  //     setAttachment({
  //       ...attachment,
  //       photos: newArray,
  //     });
  //   }
  //   forceUpdate(undefined);
  // };

  // const setPhotosCollection = (photos: any[]) => {
  //   let photoCollection: any[] = [];
  //   photos.forEach((photo: any) => {
  //     const { height, public_id, secure_url, width } = photo.uploadInfo;
  //     const photoObj = {
  //       height,
  //       name: public_id,
  //       source: secure_url,
  //       purpose: "photoalbum",
  //       width,
  //     };
  //     console.log("Photo Objects", photoObj);
  //     photoCollection.push(photoObj);
  //   });
  //   return photoCollection;
  // };

  // const changeOrientation = (shape: string) => {
  //   setAttachment({ ...attachment, shape });
  // };

  const showLibrary = (processResult: (asset: any) => void) => {
    window.cloudinary.openMediaLibrary(
      {
        cloud_name: REACT_APP_CLOUDINARY_CLOUD_NAME,
        api_key: REACT_APP_CLOUDINARY_API_KEY,
        username: REACT_APP_CLOUDINARY_USER_NAME,
        timestamp: timeStamp,
      },
      {
        insertHandler: function (data: any) {
          console.log("on insert data ", data);
          data.assets.forEach((asset: any) => {
            //     const { source, result, error } = asset;
            console.log("showLibrary", "asset", asset);
            processResult(asset);
            //processResult(source, result, error);
          });
        },
      }
    );
  };

  // const updateAlbumPhotoTitle = (name: string, title: Language) => {
  //   const photoCollection = attachment.photos;
  //   const photoObject = _.find(photoCollection, ["name", name]);
  //   photoObject.title = title;
  //   setAttachment({ ...attachment, photos: photoCollection });
  // };

  // const removeAttachment = (name: string) => {
  //   const deletedAttachment = _.find(attachment.photos, ["name", name]);
  //   const deletePhotos = attachment.deletePhotos;
  //   if (deletePhotos && deletedAttachment._id) {
  //     deletePhotos.push(deletedAttachment);
  //     setAttachment({
  //       ...attachment,
  //       deletePhotos,
  //       photos: _.reject(attachment.photos, ["name", name]),
  //     });
  //   } else {
  //     setAttachment({
  //       ...attachment,
  //       photos: _.reject(attachment.photos, ["name", name]),
  //     });
  //   }
  // };

  // const restoreAttachment = (name: string) => {
  //   const restoredAttachment = _.find(attachment.deletePhotos, ["name", name]);
  //   const newArray = _.concat(attachment.photos, restoredAttachment);
  //   setAttachment({
  //     ...attachment,
  //     deletePhotos: _.reject(attachment.deletePhotos, ["name", name]),
  //     photos: newArray,
  //   });
  // };

  return (
    <Grid item classes={{ root: classes.dialog_content }}>
      {/* {(attachment.source ||
        (attachment.photos && attachment.photos.length !== 0)) && (
        <SelectedFile
          attachment={attachment}
          // changeOrientation={changeOrientation}
          removeAttachment={removeAttachment}
          restoreAttachment={restoreAttachment}
          updateAlbumPhotoTitle={updateAlbumPhotoTitle}
        />
      )} */}
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
      >
        <Grid item xs={12}>
          <Button
            ref={el => el}
            variant="outlined"
            className={classes.button}
            // onClick={() => showWidget()}
            onClick={() => console.log("show widget clicked")}
          >
            Upload {header}
            <FaUpload className={classes.icon} />
          </Button>
        </Grid>
        <Can
          role={user?.role}
          perform="manage:users"
          yes={() => (
            <Grid item xs={12}>
              <Button
                ref={el2 => el2}
                variant="outlined"
                className={classes.button}
                onClick={() => showLibrary(processResult)}
              >
                Choose from Library
              </Button>
            </Grid>
          )}
        />
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(FilePicker);
