import React from "react";
import { Box, Grid, Theme } from "@mui/material";
import { createStyles, withStyles } from "@mui/styles";
import Tabs from "../Controls/tabs";
import BulkActions from "../Controls/action";
import Form from "./Form";

const styles = (theme: Theme) =>
  createStyles({
    form: {
      paddingBottom: theme.spacing(6),
    },
  });

const PostContent: React.FC<{
  classes: any;
}> = ({ classes }) => {
  return (
    <Box className={classes.form}>
      <Grid container spacing={2}>
        <Tabs />
        <Grid item xs={12}>
          <BulkActions />
          <Form />
        </Grid>
      </Grid>
    </Box>
  );
};

export default withStyles(styles)(PostContent);
