import React from "react";
import { FaDollarSign, FaPatreon } from "react-icons/fa";
import { Button, Grid, Theme, Typography } from "@mui/material";
import { createStyles, withStyles } from "@mui/styles";
// import {
//   updateUserMutation,
//   createMessageMutation,
// } from "../../../../Admin/Helpers/adminQueries";
//import { useAuth } from "../../../../../context/Auth/authContext";
// import { useTrans } from "../../../context/Trans/translationContext";
// import { useCurrentProfile } from "../../../context/Profile/currentProfileContext";
var _ = require("lodash");
const styles = (theme: Theme) =>
  createStyles({
    adminCon: {
      width: "100%",
      textAlign: "right",
    },
    actionButton: {
      margin: "0 8px",
      color: "white",
    },
    active: {
      // backgroundColor: theme.palette.primary.two,
      color: theme.palette.fonts.focus,
    },
    arrow: {
      color: theme.palette.fonts.detail,
    },
    avatar: {
      [theme.breakpoints.down("sm")]: {
        height: theme.spacing(9),
        width: theme.spacing(9),
      },
      [theme.breakpoints.up("sm")]: {
        height: theme.spacing(4.5),
        width: theme.spacing(4.5),
      },
      //  border: "1px solid " + theme.palette.secondary.main,
      boxShadow: theme.shadows[3], //"0 1px 5px 1px lightgrey"
    },
    avatarButton: {
      [theme.breakpoints.down("sm")]: {
        margin: "0 0 0 8px",
      },
      [theme.breakpoints.up("sm")]: {
        margin: "0 8px",
      },
      padding: 0,
    },
    badge: {
      "& svg": { color: theme.palette.primary.dark },
      "& span": {
        width: 15,
        height: 15,
        fontSize: 8,
        fontWeight: "bold",
        border: "1px solid",
        backgroundColor: theme.palette.color.blue,
        color: theme.palette.secondary.main,
        minWidth: "unset",
        paddingTop: 1.5,
      },
    },
    back: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.fonts.action,
      textShadow: theme.palette.shadow.textShadow,
      transform: "unset",
      boxShadow: theme.palette.shadow.boxShadow1,
      zIndex: 0,
    },
    bottomBar: {
      padding: "8px 0",
    },
    button_icon: {
      marginRight: 6,
    },
    detail: {
      color: theme.palette.fonts.main,
      fontStyle: "italic",
      fontSize: ".6rem",
      padding: "2px 4px",
    },
    header: {
      color: theme.palette.primary.main,
      fontFamily: "Gotham Book",
      fontSize: "1.4rem",
      fontWeight: "bold",
      //textShadow: theme.palette.shadow.textShadow,
      textTransform: "none",
    },

    iconButton: {
      fontSize: "1.2em",
      padding: theme.spacing(1),
    },
    cashapp: {
      background: "linear-gradient(144deg, #28c101,#28c101,#3ca523)",
    },
    cashapp_label: {
      color: theme.palette.background.paper,
    },
    message_box: {
      width: "100%",
    },
    patreon: {
      background: "linear-gradient(144deg, #f96854,#f96854,#d63f2a)",
    },
    patreon_label: {
      color: "#052d49",
    },
    text: {
      color: theme.palette.fonts.main,
      fontSize: ".77rem",
    },
    topBar: {
      minHeight: 62,
    },
    toolbar: {
      // backgroundColor: theme.palette.primary.two,
      // borderBottom: "1px solid " + theme.palette.primary.border,
      boxShadow: theme.palette.shadow.boxShadow,
      zIndex: 1,
    },

    user: {
      height: "100%",
      //marginRight: theme.spacing.unit * 0.5
    },
    user_holder: {
      padding: "16px 32px",
    },
  });

const Support: React.FC<{ classes: any; profile: any }> = ({
  classes,
  profile,
}) => {
  function checkParse(item: any, defObj?: any) {
    if (typeof item === "object") {
      return item;
    } else if (typeof item === "string") {
      return JSON.parse(item);
    } else {
      return defObj;
    }
  }

  const social = checkParse(profile.social);
  const hasCashapp = _.has(social, "cashapp");
  const hasPatreon = _.has(social, "patreon");

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="flex-start"
      spacing={3}
      style={{ padding: 24 }}
    >
      {hasCashapp && (
        <Grid item>
          <a
            href={social.cashapp.link}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button
              classes={{
                root: classes.cashapp,
                text: classes.cashapp_label,
              }}
              size="small"
              variant="contained"
            >
              <FaDollarSign />
              {social.cashapp.name}
            </Button>
          </a>

          <Typography classes={{ root: classes.detail }} component="p">
            CashApp
          </Typography>
        </Grid>
      )}
      {hasPatreon && (
        <Grid item>
          <a
            href={social.patreon.link}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button
              classes={{
                root: classes.patreon,
                text: classes.patreon_label,
              }}
              size="small"
              variant="contained"
            >
              <FaPatreon className={classes.button_icon} />{" "}
              {social.patreon.name}
            </Button>
          </a>{" "}
          <Typography classes={{ root: classes.detail }} component="p">
            Patreon
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default withStyles(styles)(Support);
