import React, { useEffect } from "react";
import { Box, Grid, Theme } from "@mui/material";
import { createStyles, withStyles } from "@mui/styles";
import ClearButton from "./Post/ClearButton";
import Information from "./Post/Information/basic";
import Content from "./Post/Content";
import Cover from "./Post/Cover";
import Template from "./Post/Template";
import MainFeatureMedia from "./Post/Featured";
import Submit from "./Post/submit";
import SectionHolder from "./section";
import ValidationContextProvider from "../../../../context/Post/validationContext";
import { GET_POST_BY_ID_QUERY } from "../../../../utilities/queries";
import { useFeature } from "../../../../context/Post/featureContext";
import { useOldPost } from "../../../../context/Post/oldPostContext";
import { useTrans } from "../../../../context/Trans/translationContext";
import { useUser } from "../../../../context/User/userContext";
import { defaultPost, Post } from "../../../../context/types";
import { useApolloClient } from "@apollo/client";
import PostLoader from "../../../../components/Loader";
import { useNewPost } from "../../../../context/Post/newPostContext";

const styles = (theme: Theme) =>
  createStyles({
    form: {
      height: "inherit",
      marginTop: 0,
    },
    section_holder: {
      borderBottom: "1px solid lightgray",
      "&:last-child": {
        borderBottom: "none",
      },
    },
    view: {
      minHeight: "calc(100vh - 64px)",
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      paddingTop: theme.spacing(14),
      zIndex: 1,
      [theme.breakpoints.down("md")]: {
        minHeight: "calc(100vh - 290px)",
      },
    },
  });

const AdminPostForm: React.FC<{
  classes: any;
  route_params: any;
  create?: boolean;
}> = ({ classes, create, route_params }) => {
  const client = useApolloClient();
  const { user } = useUser();
  const { newPost } = useNewPost();
  const { setOldPost } = useOldPost();
  const { feature } = useFeature();
  const { setPathArray } = useTrans();
  const { postType, template } = feature;
  const isFeature = postType && template && postType[template] !== "article";

  useEffect(() => {
    const path = create
      ? [
          { dir: "create", type: "route" },
          { dir: "post", type: "route" },
        ]
      : [
          { dir: "edit", type: "route" },
          { dir: "posts", type: "route" },
        ];

    setPathArray(path);
  }, [create, setPathArray]);

  const isReady =
    route_params.docID === newPost?._id ||
    newPost?._id === "0a0000000000000000000000";

  useEffect(() => {
    function checkFeatureAttachment(post: Post) {
      const { attachments, feature } = post;
      if (!attachments) {
        post.attachments = defaultPost.attachments;
      }
      if (!feature) {
        post.feature = defaultPost.feature;
      }
      return post;
    }

    if (user && (!newPost?._id || !isReady)) {
      const default_post = {
        ...defaultPost,
        writer: user,
      };

      if (
        route_params.docID &&
        route_params.docID !== "0a0000000000000000000000" &&
        user
      ) {
        client
          .query({
            query: GET_POST_BY_ID_QUERY,
            variables: {
              _id: route_params.docID,
            },
            fetchPolicy: "no-cache",
          })
          .then(({ data }: { data: any }) => {
            const result = data.getPostByIdQuery;
            const post = result ? checkFeatureAttachment(result) : default_post;

            setOldPost(post);
          });
      } else if (!newPost?._id) {
        setOldPost(default_post);
      }
    }
  }, [client, isReady, newPost, route_params, setOldPost, user]);

  return (
    <>
      {!isReady ? (
        <PostLoader />
      ) : (
        <Box id="form-view" className={classes.view}>
          <Grid container spacing={3} className={classes.form}>
            <Grid item xs={12} classes={{ root: classes.section_holder }}>
              <ClearButton />
            </Grid>
            <SectionHolder
              key_name={"template"}
              section={<Template attachment={feature} />}
            />
            <SectionHolder key_name={"cover"} section={<Cover />} />
            {isFeature && (
              <SectionHolder
                key_name={"feature"}
                section={<MainFeatureMedia />}
              />
            )}
            <SectionHolder key_name={"information"} section={<Information />} />
            <SectionHolder key_name={"content"} section={<Content />} />
            <SectionHolder
              key_name={"finalize"}
              section={
                <ValidationContextProvider>
                  <Submit />
                </ValidationContextProvider>
              }
            />
          </Grid>
        </Box>
      )}
    </>
  );
};

export default withStyles(styles)(AdminPostForm);
