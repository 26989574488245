import React, { createContext, useContext, useState } from "react";
import axios from "axios";
import moment from "moment";
//import { useHistory } from "react-router-dom";
//import { useUser } from "../../context/User/userContext";
import RouteTrans from "./routeTrans";
import ht_config from "./moment/ht";
import en_config from "./moment/en";
import fr_config from "./moment/fr";
require("dotenv").config();
var _ = require("lodash");

interface TranslationValues {
  fetchTranslation?: (text: string | string[], lang: string) => Promise<string>;
  getDev?: any;
  language: string;
  paths?: any;
  RouteTrans: any;
  setLanguage?: (language: string) => void;
  setPathArray?: any;
  setPaths?: any;
}

export const TranslationContext = createContext<TranslationValues>({
  language: "en",
  RouteTrans: {},
});
export const useTrans = () => useContext(TranslationContext);
export const Langs = {
  ht: ht_config,
  en: en_config,
  fr: fr_config,
};

//const { REACT_APP_TRANS_ENDPOINT } = process.env;

const TranslationContextProvider: React.FC<{ children: any }> = ({
  children,
}) => {
  // const { auth0 } = useUser();

  const [paths, setPaths] = useState({});
  const [language, setLanguage] = useState<string>("en");

  async function fetchTranslation(text: string | string[], lang: string) {
    const response = await axios
      .post("/api/translate", {
        text,
        lang,
      })
      .then((response: any) => {
        const returned_text = response.data;
        return returned_text;
      })
      .catch((error: any) => {
        console.log("there was an  error", error);
      });
    return response;
  }

  const getRouteTrans: (
    term: any, //{ dir: any; type: string },
    lang: string
  ) => void = async (term, lang) => {
    const { dir, type } = term;

    if (type === "route") {
      return RouteTrans[dir][lang];
    } else if (type === "postID") {
      const { postID, title } = dir;
      const titleAlias = `${postID}-${_.kebabCase(title[lang])}`;
      return titleAlias;
    } else if (type === "date") {
      const month = moment(dir).format("M");
      const day = moment(dir).format("D");
      const year = moment(dir).format("YYYY");
      return lang === "en"
        ? `${month}/${day}/${year}`
        : `${day}/${month}/${year}`;
    } else if (type === "docID") {
      return dir;
    }
  };

  async function asyncForEach(array: any[], callback: any) {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  }

  const setPathArray = async (pathArray: any) => {
    const languages = ["en", "fr", "ht"];

    const routesObj: {
      [key: string]: any;
    } = {};

    await asyncForEach(languages, async (lang: string) => {
      async function transDir(term: any) {
        const res = getRouteTrans(term, lang);
        return res;
      }

      const transPath = await Promise.all(_.map(pathArray, transDir));
      const transUrl = _.join(transPath, "/");
      const fullUrl = `/${lang}/${transUrl}`;
      routesObj[lang] = fullUrl;
    });

    // console.log("routesObj", routesObj, paths);
    // console.log("is different", !_.isEqual(routesObj, paths));
    !_.isEqual(routesObj, paths) && setPaths(routesObj);
    // (!_.isEqual(routesObj, paths) || !_.isEmpty(paths)) && setPaths(routesObj);
    //  history.replace(routesObj[language]);
    // console.log("routesobject", routesObj);
    // return routesObj;
    //  !_.isEqual(routesObj, paths) && setPaths(routesObj);
  };

  const getDev: (word: string, lang: string) => string = (word, lang) => {
    const getAKey = _.keyBy(RouteTrans, lang);
    const devWord = getAKey[word].en;
    return devWord;
  };

  return (
    <TranslationContext.Provider
      value={{
        fetchTranslation,
        getDev,
        language,
        paths,
        RouteTrans,
        setLanguage,
        setPathArray,
        setPaths,
      }}
    >
      {children}
    </TranslationContext.Provider>
  );
};

export default TranslationContextProvider;
