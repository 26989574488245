import moment from "moment";
import { ObjectID } from "bson";

export interface Language {
  [key: string]: string;
}

export interface UnprocessedPost {
  _id?: string;
  status: string;
  language: string;
  organization: Entity;
  body: string;
  feature: UnprocessedAttachment;
  postID: number | null;
  published: string | null;
  subtitle: string;
  title: string;
  type: string;
  version: number;
  views: number;
  attachments: UnprocessedAttachment[];
  category: Category;
  cover: UnprocessedAttachment;
  link: UnprocessedAttachment;
  writer: User;
}

export interface Post {
  _id?: string;
  status: string;
  language: string;
  organization: Entity;
  body: Language;
  feature: Attachment;
  postID: number | null;
  publishedAt: number | null;
  subtitle: Language;
  title: Language;
  postType?: PostType;
  template?: string;
  type: string;
  version: number;
  views: number;
  attachments: Attachment[];
  category: Category;
  cover: Attachment;
  link: Attachment;
  writer: User;
  [key: string]: any;
}

export interface Category {
  _id: string;
  order: number;
  name?: string;
  url?: string;
  division?: Division;
}

export interface Division {
  _id: string;
  order: number;
  name: string;
  url: string;
}
export interface Attachment {
  _id?: string;
  alignment?: string;
  autoPlay?: boolean;
  context?: boolean;
  credit?: string;
  date?: string;
  description?: Language;
  deletePhotos?: Attachment[];
  enabled?: boolean;
  handle?: string;
  name?: string;
  photos?: Attachment[];
  post_id?: string;
  postType: PostType;
  purpose: string;
  shape?: string;
  source?: string;
  template: string;
  title?: Language;
  type: string;
  delete?: boolean;
}

export interface UnprocessedAttachment {
  _id?: string;
  alignment?: string;
  autoPlay?: boolean;
  context?: boolean;
  credit?: string;
  date?: string;
  description?: string;
  enabled?: boolean;
  handle?: string;
  name: string;
  deletePhotos?: any[];
  photos?: Attachment[];
  postType?: PostType;
  purpose: string;
  shape?: string;
  source: string;
  template?: string;
  title?: string;
  type: string;
}

export interface Entity {
  _id: string;
  name: string;
  logo?: string;
}

export interface Name {
  alias?: string;
  family_name: string;
  given_name: string;
  middle_name?: string;
  name: string;
  prefix?: string;
  suffix?: string;
  title?: string;
}

export interface Avatar {
  baby: string;
  login: string;
  user: string;
}

export interface Contact {
  email: string;
  phone: string;
  social: any;
}

export interface Personal {
  birth_date: string;
  biography: Language;
}

export interface User {
  _id: string;
  handle?: string;
  log?: string;
  login?: string;
  order?: string;
  role?: string;
  status?: string;
  contact?: Contact;
  name: {
    login: Name;
    user: Name;
  };
  avatar?: Avatar;
  manages?: [User];
  organizations?: [Entity];
  personal?: Personal;
}

export interface PostType {
  [key: string]: string;
}

export type StateEventFunction = (stateName?: string) => (event: any) => void;
export type StateEventCompactFunction = (stateName: string, event: any) => void;
export const defaultAttachment: Attachment = {
  handle: "",
  name: "",
  source: "",
  purpose: "body",
  alignment: "left",
  template: "text",
  type: "document",
  shape: "orientation",
  title: { en: "", fr: "", ht: "" },
  description: { en: "", fr: "", ht: "" },
  credit: "",
  date: "",
  enabled: true,
  postType: {
    text: "document",
    video: "youtube",
    audio: "soundcloud",
    social: "twitter",
    photo: "localphoto",
  },
  delete: false,
};

export const defaultPostType: PostType = {
  text: "article",
  video: "youtube",
  audio: "soundcloud",
  social: "twitter",
  photo: "localphoto",
};

export const defaultUser: User = {
  _id: new ObjectID().toHexString(),
  name: {
    login: { given_name: "", family_name: "", name: "" },
    user: { given_name: "", family_name: "", name: "" },
  },
};

export const defaultPost: Post = {
  _id: "0a0000000000000000000000",
  postID: null,
  status: "draft",
  language: "en",
  title: { en: "", fr: "", ht: "" },
  subtitle: { en: "", fr: "", ht: "" },
  body: { en: "", fr: "", ht: "" },
  writer: defaultUser,
  organization: { _id: "none", name: "none" },
  category: { _id: "5c610c6f1c9d440000b088a4", order: 0 },
  publishedAt: moment().valueOf(),
  postType: defaultPostType,
  template: "text",
  type: "article",
  version: 2,
  views: 0,
  cover: {
    context: false,
    credit: "",
    description: { en: "", fr: "", ht: "" },
    enabled: true,
    postType: { photo: "localphoto" },
    purpose: "cover",
    template: "photo",
    type: "localphoto",
    name: "",
    source: "",
    title: { en: "", fr: "", ht: "" },
  },
  feature: {
    photos: [],
    postType: defaultPostType,
    purpose: "feature",
    name: "",
    source: "",
    shape: "portrait",
    template: "text",
    type: "article",
  },
  attachments: [],
  link: {
    name: "",
    postType: { text: "link" },
    purpose: "link",
    enabled: false,
    source: "",
    title: { en: "Read more", fr: "Lire la suite", ht: "Li piplis" },
    template: "text",
    type: "link",
  },
};
