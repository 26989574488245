import React, { createContext, useContext, useState } from "react";
//import { graphql } from "react-apollo";
// import {
//   getPostQuery,
//   upsertPostMutation,
// } from "../components/Admin/Helpers/adminQueries";
//import { useTrans } from "./Trans/translationContext";
//import { asyncForEach } from "../utils/postRenders";
// import { useEffect } from "react";
// var _ = require("lodash");
// Provider and Consumer are connected through their "parent" context

interface CurrentProfileI {
  currentProfile: any;
  setCurrentProfile?: any;
}

export const CurrentProfileContext = createContext<CurrentProfileI>({
  currentProfile: {},
});
export const useCurrentProfile = () => useContext(CurrentProfileContext);
// Provider will be exported wrapped in ConfigProvider component.
const CurrentProfileProvider = ({ children }: { children: any }) => {
  // const { language } = useTrans();
  const [currentProfile, setCurrentProfile] = useState<any>({});
  // const [pathInfo, setPathInfo] = useState({});
  // const [currentProcessedPost, setCurrentProcessedPost] = useState({});
  // const [currentRawPost, setCurrentRawPost] = useState({});
  // const [currentBaseUrl, setCurrentBaseUrl] = useState("");
  // const [currentPostUrl, setCurrentProfileUrl] = useState("");

  // function checkParse(item) {
  //   if (typeof item === "object") {
  //     return item;
  //   } else if (typeof item === "string") {
  //     return JSON.parse(item);
  //   } else {
  //     return { en: "missing", fr: "missing", ht: "missing" };
  //   }
  // }

  // function getPostObjects(postJSON) {
  //   const title = checkParse(postJSON.title);
  //   const subtitle = checkParse(postJSON.subtitle);
  //   const body = checkParse(postJSON.body);
  //   const link = checkParse(postJSON.link);

  //   const postObjects = {
  //     title,
  //     subtitle,
  //     body,
  //     link,
  //   };
  //   return postObjects;
  // }

  // const baseUrls = () => {
  //   return {
  //     en: `https://sentinel.ht/${"en"}/${
  //       RouteTrans["post"]["en"]
  //     }/${RouteTrans[langDivDev]["en"]}/${
  //       RouteTrans[langCatDev]["en"]
  //     }/${postID}`,
  //     fr: `https://sentinel.ht/${"fr"}/${
  //       RouteTrans["post"]["fr"]
  //     }/${RouteTrans[langDivDev]["fr"]}/${
  //       RouteTrans[langCatDev]["fr"]
  //     }/${postID}`,
  //     ht: `https://sentinel.ht/${"ht"}/${
  //       RouteTrans["post"]["ht"]
  //     }/${RouteTrans[langDivDev]["ht"]}/${
  //       RouteTrans[langCatDev]["ht"]
  //     }/${postID}`,
  //   };
  // };

  // function getTranslationCollection(postObjects, devLang) {
  //   const { title, subtitle, body, link } = postObjects;
  //   const langsToCheck = _.pull(["en", "fr", "ht"], devLang);

  //   function getMissing(k) {
  //     return _.filter(langsToCheck, function (l) {
  //       return !_.has(k, l);
  //     });
  //   }
  //   const doesntHave = {
  //     title: getMissing(title),
  //     subtitle: getMissing(subtitle),
  //     body: getMissing(body),
  //     link: getMissing(link),
  //   };
  //   return doesntHave;
  // }

  // async function getTranslations(transColl, postObj, lang) {
  //   const text = postObj.title[lang];
  //   async function getObject(field) {
  //     let transObj = {};
  //     await asyncForEach(transColl[field], async function (l) {
  //       const transText = await fetchTranslation(text, l);
  //       transObj = { ...transObj, [l]: transText };
  //     });
  //     return transObj;
  //   }
  //   const nowHas = {
  //     title: await getObject("title"),
  //     subtitle: await getObject("subtitle"),
  //     body: await getObject("body"),
  //     link: await getObject("link"),
  //   };
  //   return nowHas;
  // }

  // async function saveTranslations(nowHas, postObjects) {
  //   const { upsertPost } = props;

  //   const { _id } = currentRawPost;

  //   const refetchQueries = {
  //     query: getPostQuery,
  //     variables: {
  //       whereObject: {
  //         _id,
  //       },
  //     },
  //   };

  //   const variables = {
  //     variables: {
  //       _id,
  //       body: JSON.stringify({ ...nowHas.body, ...postObjects.body }),
  //       subtitle: JSON.stringify({ ...nowHas.body, ...postObjects.body }),
  //       title: JSON.stringify({ ...nowHas.body, ...postObjects.body }),
  //     },
  //     refetchQueries,
  //   };
  //   console.log(variables);
  //   // await upsertPost(variables)
  // }

  // useEffect(() => {
  // when we get a raw post, parse and get objects
  // if (!_.isEmpty(currentRawPost)) {
  //   const { title, subtitle, body, link } = currentRawPost;

  //   const postObjects = getPostObjects({
  //     title,
  //     subtitle,
  //     body,
  //     link: link && link.name,
  //   });

  //   setCurrentProcessedPost({
  //     ...currentRawPost,
  //     title: postObjects.title,
  //     subtitle: postObjects.subtitle,
  //     body: postObjects.body,
  //     link: { ...link, name: postObjects.link },
  //   });

  //console.log(currentRawPost);
  // const translationCollection = getTranslationCollection(
  //   postObjects,
  //   language
  // );

  // const nowHas = getTranslations(
  //   translationCollection,
  //   postObjects,
  //   language
  // );

  // console.log("nowHous", nowHas);
  // saveTranslations(nowHas, postObjects);
  // ensure context exists for the objects other languages

  // setCurrentProfile({
  //   ...currentRawPost,
  //   title: postObjects.title,
  //   subtitle: postObjects.subtitle,
  //   body: postObjects.body,
  //   link: { ...link, name: postObjects.link },
  // });
  //   }
  // }, [currentRawPost]);

  // useEffect(() => {
  //   if (!_.isEmpty(currentProcessedPost)) {
  //     const { title, subtitle, body, link } = currentProcessedPost;
  //     const transTitle = title[language];
  //     const transSub = subtitle[language];
  //     const transBod = body[language];
  //     const transLinkName = link && link.name[language];

  //     const currentPost = {
  //       ...currentProcessedPost,
  //       title: transTitle,
  //       subtitle: transSub,
  //       body: transBod,
  //       link: { ...link, name: transLinkName },
  //     };
  //     setCurrentProfile(currentPost);
  //   }
  // }, [currentProcessedPost]);

  // useEffect(() => {
  //   if (!_.isEmpty(currentPost)) {
  //     const { title } = currentPost;
  //     const titleAlias = _.kebabCase(title); // alias from current title - make sure language/id

  //     setCurrentProfileUrl({
  //       alias: titleAlias,
  //       base: currentBaseUrl,
  //       full: `${currentBaseUrl}-${titleAlias}`,
  //     });
  //   }
  // }, [currentBaseUrl, currentPost]);

  return (
    <CurrentProfileContext.Provider
      value={{
        // adminAction: adminAction,
        // adminCreateOpen: adminCreateOpen,
        // adminDialogOpen: adminDialogOpen,
        // adminEditAction: adminEditAction,
        // adminEditOpen: adminEditOpen,
        // writersCollection: writersCollection,
        // categoryCollection: categoryCollection,
        // categoryList: categoryList,
        // count: count,
        // hits: hits,
        // adminDialog: adminDialog,
        // page: page,
        // postChanged: postChanged,
        //  currentPostUrl,
        currentProfile,
        setCurrentProfile,
        //   setPathInfo,
        //setCurrentBaseUrl,
        //setCurrentRawPost,
        // postDraft: postDraft,
        // postsIsLoading: postsIsLoading,
        // showShare: showShare,
        // viewComments: viewComments,
        // feed: this.feed,
        // getCategoryInfo: this.getCategoryInfo,
        // handleAdminActionCancel: this.handleAdminActionCancel,
        // handleAdminDialogClose: this.handleAdminDialogClose,
        // handleAdminEditClick: this.handleAdminEditClick,
        // handleDrawerClose: this.handleDrawerClose,
        // handleTextFieldChange: this.handleTextFieldChange,
        // handleFABActionClick: this.handleFABActionClick,
        // handleFABClick: this.handleFABClick,
        // handleFABClose: this.handleFABClose,
        // handleShowShare: this.handleShowShare,
        // onInitialSearch: this.onInitialSearch,
        // onPaginatedSearch: this.onPaginatedSearch,
        // postClearCurrent: this.postClearCurrent,
        //   postSetCurrent: this.postSetCurrent,
        // postSetDraft: this.postSetDraft,
        // setSiteWriters: this.setSiteWriters,
        // setSiteCategories: this.setSiteCategories,
        // submitPostDraftChange: this.submitPostDraftChange,
        // editField: this.editField,
      }}
    >
      {children}
    </CurrentProfileContext.Provider>
  );
};

export default CurrentProfileProvider;

// I make this default since it will probably be exported most often.
//export default PostContext;
