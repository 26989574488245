import React from "react";
import { useLocation } from "react-router-dom";
import classNames from "classnames";
import { createStyles, withStyles } from "@mui/styles";
import { useCurrentPost } from "../../context/Post/currentPostContext";
import { useTrans } from "../../context/Trans/translationContext";
import { FormattedMessage } from "react-intl";
import { Theme } from "@mui/material";

var _ = require("lodash");

const styles = (theme: Theme) =>
  createStyles({
    adText: {
      color: theme.palette.ads.text,
      fontSize: "xx-small",
      margin: "6px 0",
      textTransform: "uppercase",
    },
    display: {
      background: theme.palette.ads.background,
      borderBottom: theme.palette.ads.border,
      borderTop: theme.palette.ads.border,
      marginBottom: 48,
      padding: "0 24px 24px",
      textAlign: "center",
      "& .adsbygoogle": {
        border: theme.palette.ads.border,
        //boxShadow: theme.shadows[4],
        [theme.breakpoints.down("xs")]: {
          marginBottom: 12,
          minHeight: 50,
        },
        minHeight: 250,
      },
    },
  });

const AdComponent: React.FC<{ classes: any }> = ({ classes }) => {
  // const dev = process.env.NODE_ENV === "development";
  const { pathname } = useLocation();
  const { language, RouteTrans } = useTrans();
  const { currentPost } = useCurrentPost();
  // useEffect(() => {
  //   if (!dev && typeof window !== "undefined") {
  //     ((window as any).adsbygoogle = (window as any).adsbygoogle || []).push(
  //       {}
  //     );
  //   }
  // });

  const isPost = _.startsWith(
    pathname,
    `/${language}/${RouteTrans["post"][language]}`
  );

  const defaultAdSlotID = "4867105414";

  const Ad = ({ adSlotID }: { adSlotID: string }) => {
    return (
      <div
        className={classNames(classes.display, "ad_component")}
        key={pathname}
      >
        <p className={classes.adText}>
          <FormattedMessage id="Advertisement" defaultMessage="Advertisement" />
        </p>
        {/* <ins
          className="adsbygoogle"
          style={{ display: "block", height: "100%", width: "100%" }}
          data-ad-client="ca-pub-3528391273300886"
          data-ad-slot={adSlotID}
          data-ad-format="auto"
          data-full-width-responsive="true"
        /> */}
      </div>
    );
  };

  if (isPost && !_.isEmpty(currentPost)) {
    const { writer } = currentPost;
    const userAdSlotID = writer.adsense ? writer.adsense : defaultAdSlotID;
    return <Ad adSlotID={userAdSlotID} />;
  } else {
    return <Ad adSlotID={defaultAdSlotID} />;
  }
};

export default withStyles(styles)(AdComponent);
