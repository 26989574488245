import React from "react";
import { useHistory } from "react-router-dom";
import ReactHTMLParser from "react-html-parser";
import { createStyles, withStyles } from "@mui/styles";
import {
  Card,
  CardContent,
  CardActionArea,
  CardMedia,
  Theme,
} from "@mui/material";
import { Avatar, Grid, Typography } from "@mui/material";
import PostStatistics from "./post_stats";
import Legend from "./legend";
//import { FaCircle } from "react-icons/fa";
import { Avatar as AvatarType } from "../../../../../context/types";

var _ = require("lodash");

const styles = (theme: Theme) =>
  createStyles({
    avatar: {
      height: 38,
      width: 38,
      //   border: "1px solid " + theme.palette.secondary.main,
      boxShadow: theme.shadows[2], //"0 1px 5px 1px lightgrey"
      marginRight: theme.spacing(1.5),
    },
    card_holder: {
      maxWidth: "100%",
      background: "none",
      //   borderRadius: 0,
      //   borderBottom: "1px solid lightgray",
      boxShadow: "none",
      overflow: "visible",
      //padding: "0px 4px",
    },
    card: {
      borderRadius: "0px !important",
      boxShadow: "none !important",
    },
    cover: {
      overflow: "hidden",
    },
    cardAction: {
      //   overflow: "visible",
    },
    post_details: {
      fontSize: ".5rem",
      letterSpacing: "normal",
      lineHeight: 1.21,
      textTransform: "none",
      "& span": {
        letterSpacing: "normal",
      },
    },
    divider: {
      //  backgroundColor: theme.palette.primary.default,
      // backgroundImage: theme.palette.primary.dividerRight,
    },
    media: {
      backgroundSize: "102%",
      // borderRadius: theme.spacing(0.5),
      position: "relative",
      paddingBottom: "52.5%",
    },
    legend: {
      //borderRight: "1px solid " + theme.palette.secondary.main,
      marginLeft: -5,
      // paddingRight: theme.spacing(0.75),
    },
    title: {
      color: "#2b2b2b !important",
      marginBottom: 2,
    },
    title_holder: {
      padding: "8px 16px 20px !important",
    },
    seperator: {
      fontSize: ".1rem",
      margin: "0 4px",
    },
    stats: {
      //   color: theme.palette.text.main,
      textAlign: "right",
      fontSize: ".6rem",
      lineHeight: 1,
      paddingTop: 2,
    },
    views_holder: {
      paddingBottom: 4,
    },
    writer: {
      fontSize: ".5rem",
      letterSpacing: "normal",
      lineHeight: 1.21,
      textTransform: "none",
    },
  });

interface CardProps {
  classes: any;
  category: any;
  division: any;
  getCover: any;
  postURL: string;
  thisPost: any;
  title: string;
}

const PostCard: React.FC<CardProps> = ({
  classes,
  category,
  division,
  getCover,
  postURL,
  thisPost,
  title,
}) => {
  // const { adminDisplay, user } = useAuth();

  const { attachments, cover, feature, version, writer } = thisPost;
  const { _id, avatar, name } = writer;
  const { given_name, family_name } = name.user;
  const history = useHistory();
  // const coverExist = !_.isEmpty(cover);

  const legendAtt = feature ? _.concat(attachments, feature) : attachments;
  //console.log('postUrl', postURL)

  function UserAvatar({ _id, avatar }: { _id: string; avatar: AvatarType }) {
    const { login, user: user_avatar } = avatar;
    const userAvatar = user_avatar
      ? `https://res.cloudinary.com/defendhaiti/image/upload/w_80,c_scale/v1566866971/${user_avatar}.jpg`
      : login;

    return (
      <Avatar
        alt={_id}
        src={userAvatar}
        classes={{
          root: classes.avatar,
        }}
      />
    );
  }

  return (
    <Grid item xs={12} sm={4} classes={{ root: classes.card_holder }}>
      <Card classes={{ root: classes.card }}>
        <CardActionArea
          onClick={() => history.push(postURL)}
          classes={{ root: classes.cardAction }}
        >
          <CardMedia
            classes={{
              root: classes.media,
            }}
            image={getCover(cover, version)}
          ></CardMedia>

          {/* <Grid container>
          {coverExist && (
            <Grid item xs={12} style={{ marginBottom: 4, padding: 0 }}>
              <Paper classes={{ root: classes.cover }} elevation={6}>
                <CardMedia
                  classes={{
                    root: classes.media,
                  }}
                  image={getCover()}
                ></CardMedia>
              </Paper>
            </Grid>
          )} */}
          <CardContent classes={{ root: classes.title_holder }}>
            <Grid container>
              <Grid item>
                <UserAvatar _id={_id} avatar={avatar} />
              </Grid>
              <Grid item xs>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography classes={{ root: classes.title }} variant="h5">
                      {ReactHTMLParser(title)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid
                      container
                      direction="row"
                      alignItems="flex-start"
                      justifyContent="flex-start"
                      spacing={1}
                    >
                      <Grid item>
                        <Typography
                          classes={{ root: classes.post_details }}
                          variant="h6"
                        >
                          {`${given_name} ${family_name}`}
                        </Typography>
                      </Grid>
                      {/* <Grid item>
                        <FaCircle className={classes.seperator} />
                      </Grid> */}
                      <Grid item>
                        <Typography
                          classes={{ root: classes.post_details }}
                          variant="h6"
                        >
                          {category}
                          <span>{division}</span>
                        </Typography>
                      </Grid>
                      {/* <Grid item>
                        <FaCircle className={classes.seperator} />
                      </Grid> */}
                      <Grid item>
                        <PostStatistics thisPost={thisPost} />
                      </Grid>
                      {legendAtt.length > 0 && (
                        <>
                          {/* <Grid item>
                            <FaCircle className={classes.seperator} />
                          </Grid> */}
                          <Grid item classes={{ root: classes.legend }}>
                            <Legend attachments={legendAtt} />
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
};

export default withStyles(styles, { withTheme: true })(PostCard);

/* {adminDisplay && (
                <Can
                  role={user.role}
                  perform="edit:views"
                  yes={() => (
                    <ApolloConsumer>
                      {(client) => <ViewCtl _id={_id} client={client} />}
                    </ApolloConsumer>
                  )}
                />
              )} */
