import React from "react";
import CloudinaryImage from "./cloudinary-image";
import {
  Button,
  Card,
  CardActionArea,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Theme,
  Typography,
} from "@mui/material";
import { createStyles, withStyles } from "@mui/styles";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import CoverContextForm from "./contextForm";
import { useNewPost } from "../../../../../../context/Post/newPostContext";

const styles = (theme: Theme) =>
  createStyles({
    button: {
      borderColor: theme.palette.fonts.action,
      color: theme.palette.fonts.action,
      marginTop: theme.spacing(3),
      width: "100%",
    },
    checkbox: {
      borderColor: theme.palette.fonts.action,
      color: theme.palette.fonts.action,
      width: "unset",
    },
    cardContent: {
      padding: theme.spacing(1),
    },
    contextField: {
      width: "100%",
    },
    icon: {
      marginLeft: theme.spacing(1),
      fontSize: "1rem",
    },
    image: {
      background: theme.palette.background.default,
      boxShadow: theme.shadows[10],
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
      "& img": {
        width: "100%",
      },
    },
    coverCredit: {
      color: theme.palette.fonts.action,
      fontSize: ".8rem",
      fontStyle: "italic",
    },
    coverDescription: {
      color: theme.palette.fonts.action,
      fontSize: ".65rem",
    },
    coverImage: {
      width: "100%",
    },
    coverInfo: {
      padding: "0 4px",
    },
    deletedCoverFile: {
      color: theme.palette.fonts.action,
      fontSize: ".6rem",
      textAlign: "left",
    },
    coverFile: {
      color: theme.palette.fonts.detail,
      fontSize: ".5rem",
      marginBottom: 4,
      textAlign: "left",
    },
    coverTitle: {
      color: theme.palette.fonts.focus,
      fontSize: ".95rem",
      margin: 0,
    },
    deleted: {
      color: theme.palette.color.red,
      fontWeight: "bold",
    },
    deleteCoverTitle: {
      fontSize: ".8rem",
    },
    modify: {
      // width: "100%",
      //textAlign: "right"
    },
    remove: {
      //  width: "100%",
      textAlign: "right",
    },
    selectedPhoto: {
      padding: theme.spacing(1),
    },
  });

const SelectedCover: React.FC<{ classes: any; showWidget: () => void }> = ({
  classes,
  showWidget,
}) => {
  const { handleDeleteCover, handlePostChange, newPost } = useNewPost();
  const { cover } = newPost;
  const { enabled, context } = cover;

  function toggleCover(field: string) {
    const covObj: { [key: string]: any } = cover;
    const flip = !covObj[field];
    covObj[field] = flip;
    handlePostChange("cover", covObj);
  }

  return (
    <React.Fragment>
      <Grid item xs={12} sm={8}>
        <Card className={classes.image}>
          <CardActionArea className={classes.selectedPhoto}>
            <CloudinaryImage name={cover.name} width={650} />
            <Typography className={classes.coverFile} variant="caption">
              {"Public ID: " + cover.name}
            </Typography>
          </CardActionArea>
          <Grid container>
            <Grid item xs={6} className={classes.modify}>
              <IconButton onClick={() => showWidget()}>
                <FaEdit className={classes.icon} />
              </IconButton>
            </Grid>
            <Grid item xs={6} className={classes.remove}>
              <IconButton onClick={handleDeleteCover}>
                <FaTrashAlt className={classes.icon} />
              </IconButton>
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={6}>
                <FormControlLabel
                  classes={{ root: classes.disable }}
                  control={
                    <Checkbox
                      checked={!!context}
                      onChange={() => toggleCover("context")}
                      value={context || false}
                      classes={{ root: classes.checkbox }}
                      style={{
                        color: context ? "#565656" : "#a9a9a9",
                        padding: 0,
                      }}
                    />
                  }
                  label={
                    <Button
                      disabled
                      variant="text"
                      className={classes.checkbox}
                    >
                      Context
                    </Button>
                  }
                  labelPlacement="end"
                  style={{
                    color: context ? "#565656" : "#a9a9a9",
                    margin: 0,
                  }}
                />
              </Grid>
              <Grid item xs={6} style={{ textAlign: "right" }}>
                <FormControlLabel
                  classes={{ root: classes.disable }}
                  control={
                    <Checkbox
                      checked={!enabled}
                      onChange={() => toggleCover("enabled")}
                      value={!enabled}
                      classes={{ root: classes.checkbox }}
                      style={{
                        color: !enabled ? "#565656" : "#a9a9a9",
                        padding: 0,
                      }}
                    />
                  }
                  label={
                    <Button
                      disabled
                      variant="text"
                      className={classes.checkbox}
                    >
                      Disabled
                    </Button>
                  }
                  labelPlacement="start"
                  style={{
                    color: !enabled ? "#565656" : "#a9a9a9",
                    margin: 0,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          {context && <CoverContextForm />}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
export default withStyles(styles)(SelectedCover);
