import React from "react";
import { Route } from "react-router";
import { Grid, Theme } from "@mui/material";
import { createStyles, withStyles } from "@mui/styles";
import DisqusComments from "./disqusComments";
import Interactions from "./interactions";
import Content from "./Content";
import Link from "./Source";
import Feature from "../../../../components/Feature";
import AdComponent from "../../../../components/Advertisements/adsense";
import { useTrans } from "../../../../context/Trans/translationContext";
import { useCurrentPost } from "../../../../context/Post/currentPostContext";
//import { useAdminPost } from "../../../../context/Post/adminPostContext";
//import Adsense from "../../../Advertisements/adsense";

//var _ = require("lodash");

const styles = (theme: Theme) =>
  createStyles({
    attToDelete: {
      padding: "0 24px",
    },
    container: { display: "flex", minHeight: "calc(100vh - 64px)" },
    loader: { flexGrow: 1 },
    postBody: {
      padding: "0 40px",
      position: "relative",
      zIndex: 1,
      [theme.breakpoints.down("md")]: {
        padding: "0 24px",
      },
    },
    postBodyHolder: {},
    text: {
      color: theme.palette.secondary.main,
      fontSize: ".8rem",
      fontWeight: "bold",
      marginTop: theme.spacing(1),
    },
  });

const Page: React.FC<{ classes: any }> = ({ classes }) => {
  const { language, RouteTrans } = useTrans();
  // const { view } = useAdminPost();
  const { currentPost: thisPost } = useCurrentPost();
  const { attachments, body, feature, link, type } = thisPost;
  const notPreview = false; //view !== "preview";
  return (
    <>
      <div id="InlineShare" className={classes.postBody}>
        <div className={classes.postBodyHolder}>
          <Grid container>
            {type && type !== "article" && <Feature attachment={feature} />}
            <Route
              path={`/${language}/${RouteTrans["post"][language]}`}
              render={() => <Interactions thisPost={thisPost} />}
            />
          </Grid>
        </div>
      </div>{" "}
      {notPreview && <AdComponent />}
      <div className={classes.postBody}>
        <div className={classes.postBodyHolder}>
          <Grid container>
            <Content attachments={attachments} body={body} />
            {link.source && <Link thisPost={thisPost} />}
          </Grid>
        </div>
      </div>
      {notPreview && <AdComponent />}
      <div className={classes.postBody}>
        <div className={classes.postBodyHolder}>
          <Grid container>
            <DisqusComments />
          </Grid>
        </div>
      </div>
      {notPreview && <AdComponent />}
    </>
  );
};

export default withStyles(styles)(Page);
