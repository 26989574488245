import React from "react";
import { Grid, Theme, Typography } from "@mui/material";
import { createStyles, withStyles } from "@mui/styles";
import { CommentCount } from "disqus-react";
import { useTrans } from "../../../../../context/Trans/translationContext";
import { getBaseURL } from "../../../../../utilities/postRenders";
//import { FaCircle } from "react-icons/fa";

var _ = require("lodash");

const styles = (theme: Theme) =>
  createStyles({
    post_details: {
      fontSize: ".5rem",
      letterSpacing: "normal",
      lineHeight: 1.21,
      textTransform: "none",
      "& span": {
        letterSpacing: "normal",
      },
    },
    seperator: {
      fontSize: ".1rem",
      margin: "0 4px",
    },
  });

const PostStatistics: React.FC<{
  classes: any;
  thisPost: any;
}> = ({ classes, thisPost }) => {
  const { language } = useTrans();
  const { postID, title, views } = thisPost;
  const postURL = getBaseURL(thisPost, language);

  const disqusShortname = "the-haiti-sentinel";
  const disqusConfig = {
    url: `https://sentinel.ht${postURL}`,
    identifier: postID ? postID.toString() : "unavailable",
    title: title[language],
  };

  // const checkDisqus =
  //   !_.isEmpty(disqusConfig) &&
  //   typeof Object.keys(disqusConfig) !== "undefined" &&
  //   Object.keys(disqusConfig).length > 0;

  const checkDisqus =
    disqusConfig.url &&
    disqusConfig.identifier &&
    disqusConfig.title &&
    typeof disqusConfig.url === "string" &&
    typeof disqusConfig.identifier === "string" &&
    typeof disqusConfig.title === "string";

  // console.log(
  //   typeof title,
  //   "disqusConfig",
  //   typeof disqusConfig,
  //   "checking",
  //   checkDisqus,
  //   disqusConfig
  // );

  return (
    <>
      {false &&
        !_.isEmpty(disqusConfig) &&
        typeof disqusConfig === "object" &&
        checkDisqus && (
          <Grid item classes={{ root: classes.legend }}>
            <Typography classes={{ root: classes.post_details }} variant="h6">
              <CommentCount shortname={disqusShortname} config={disqusConfig} />
            </Typography>
          </Grid>
        )}
      {/* <Grid item>
        <FaCircle className={classes.seperator} />
      </Grid> */}
      <Grid item>
        <Typography classes={{ root: classes.post_details }} variant="h6">
          {views ? views : 0}
          {" Views"}
        </Typography>
      </Grid>
    </>
  );
};

export default withStyles(styles)(PostStatistics);
