import React from "react";
import { FaEye, FaLink, FaPlus } from "react-icons/fa";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Theme,
  useMediaQuery,
} from "@mui/material";
import { createStyles, withStyles } from "@mui/styles";
import { useNewPost } from "../../../../../../../../context/Post/newPostContext";
import { useAdminPost } from "../../../../../../../../context/Post/adminPostContext";
//var _ = require("lodash");
const styles = (theme: Theme) =>
  createStyles({
    button: {
      borderColor: theme.palette.fonts.action,
      color: theme.palette.fonts.action,
      width: "100%",
    },
    buttonPreview: {
      width: "100%",
    },
    buttonText: {
      //  textShadow: "0px 4px 4px rgba(0,0,0,0.2)"
    },
    controls: {
      marginTop: 48,
    },
    divider: {
      //  background: theme.palette.primary.divider,
      marginTop: theme.spacing(1),
    },
    form: {
      [theme.breakpoints.up("sm")]: {
        minHeight: "calc(100vh - 114px)",
      },
      [theme.breakpoints.down("sm")]: {
        minHeight: "calc(100vh - 106px)",
      },
    },
    formField: {
      marginTop: theme.spacing(2),
      width: "100%",
      "& textarea": {
        color: theme.palette.fonts.focus,
      },
    },
    formSection: {
      marginTop: theme.spacing(1),
    },
    icon: {
      // filter: "drop-shadow(0px 4px 4px rgba(0,0,0,0.2))",
      //  color: "white",
      fontSize: ".9rem",
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    linkText: {
      flexGrow: 1,
      height: "fit-content",
    },
    body: {
      flex: 1,
    },
    handle: {
      flex: "none",
      width: 120,
    },
    textButton: {
      textShadow: "0px 2px 3px rgba(0,0,0,0.2)",
    },
    bodySection: {
      //  backgroundColor: theme.palette.primary.faint,
      margin: "24px 0",
      width: "100%",
    },
  });

// There is not a need for a "link" type of post, essential it is an article

const ContentBodyControls: React.FC<{ classes: any }> = ({ classes }) => {
  const { setPreview, setView } = useAdminPost();
  const { handlePostChange, newPost } = useNewPost();

  const { _id, link: link_ } = newPost;

  const link = link_ || { enabled: false };

  const toggleReadMoreLink = () => {
    const flip = !newPost.link.enabled;
    const newLink = { ...newPost.link, enabled: flip, purpose: "link" };
    handlePostChange("link", newLink);
  };

  const isMini = useMediaQuery("(max-width:525px)");

  return (
    <Grid item xs={12} classes={{ root: classes.controls }}>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Button
            onClick={() => setView("attachment")}
            variant="outlined"
            className={classes.button}
          >
            <FaPlus className={classes.icon} />
            {!isMini && (
              <span className={classes.buttonText}>{"Add Media"}</span>
            )}
          </Button>
        </Grid>
        <Grid item xs={4}>
          <Button
            onClick={() => {
              _id && setPreview(_id);
              setView("preview");
            }}
            variant="outlined"
            className={classes.button}
          >
            {!isMini && (
              <span className={classes.buttonText}>{"Preview"} </span>
            )}
            <FaEye className={classes.icon} />
          </Button>
        </Grid>
        <Grid item xs={4} style={{ textAlign: "right" }}>
          <FormControlLabel
            classes={{ root: classes.source }}
            control={
              <Checkbox
                checked={link.enabled}
                onChange={() => toggleReadMoreLink()}
                value={link.enabled}
                classes={{ root: classes.checkbox }}
                style={{
                  color: link.enabled ? "#565656" : "#a9a9a9",
                  padding: 0,
                }}
              />
            }
            label={
              <Button disabled variant="text" className={classes.button}>
                {isMini ? <FaLink /> : "Include Link"}
              </Button>
            }
            labelPlacement="start"
            style={{
              color: link.enabled ? "#565656" : "#a9a9a9",
              margin: 0,
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(ContentBodyControls);
