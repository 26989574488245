import React from "react";
import {
  FaFilePdf,
  FaFacebook,
  FaImage,
  FaImages,
  FaInstagram,
  FaLink,
  FaMicrophone,
  FaSoundcloud,
  FaTwitter,
  FaYoutube,
  FaVideo,
  FaVimeo,
} from "react-icons/fa";
import { withStyles } from "@mui/styles";
var _ = require("lodash");
const styles = theme => ({
  legend: {
    "& svg": {
      marginLeft: theme.spacing(0.5),
    },
  },
  fbVideo: {
    color: "#3b5998",
  },
  link: {
    color: "darkgray",
  },
  photo: {
    color: "green",
  },
  acrobat: {
    color: theme.palette.icons.acrobat,
  },
  facebook: {
    color: theme.palette.icons.facebook,
  },
  instagram: {
    color: theme.palette.icons.instagram,
  },
  soundcloud: {
    color: theme.palette.icons.soundcloud,
  },
  twitter: {
    color: theme.palette.icons.twitter,
  },
  youtube: {
    color: theme.palette.icons.youtube,
  },
});

const Legend = props => {
  const { attachments, classes } = props;
  const legend = {
    document: <FaFilePdf key="pdf" className={classes.acrobat} />,
    facebookpost: <FaFacebook key="fbpost" className={classes.facebook} />,
    facebookvideo: <FaFacebook key="fbvideo" className={classes.facebook} />,
    instagram: <FaInstagram key="instagram" className={classes.instagram} />,
    link: <FaLink key="link" className={classes.link} />,
    localaudio: <FaMicrophone key="audio" />,
    localphoto: <FaImage key="photo" className={classes.photo} />,
    localvideo: <FaVideo key="video" />,
    photoalbum: <FaImages key="photogallery" className={classes.photo} />,
    soundcloud: (
      <FaSoundcloud key="soundcloud" className={classes.soundcloud} />
    ),
    twitter: <FaTwitter key="twitter" className={classes.twitter} />,
    youtube: <FaYoutube key="youtube" className={classes.youtube} />,
    vimeo: <FaVimeo key="vimeo" className={classes.vimeo} />,
  };
  const typeArray = _.uniq(_.map(attachments, "type"));

  return (
    <div className={classes.legend}>
      {typeArray.map(type => {
        if (type) {
          return legend[type];
        } else {
          return null;
        }
      })}
    </div>
  );
};

export default withStyles(styles)(Legend);
