import React, { createContext, useState, useContext, useEffect } from "react";
import { useHistory } from "react-router";
import { Post } from "../types";
import processPost from "../../utilities/postProcessor";
import { GET_POSTS_QUERY } from "../../utilities/queries";
import { useFoundation } from "../foundationContext";
import { useTrans } from "../Trans/translationContext";
import { useUser } from "../User/userContext";
import { useAdminPost } from "../Post/adminPostContext";

var _ = require("lodash");

const getLastView = () => {
  if (typeof localStorage !== "undefined") {
    const lastView = localStorage && localStorage.getItem("admin_view");
    return lastView && lastView !== "false" ? lastView : "all";
  } else {
    return "all";
  }
};

export const MyPostsFeedContext = createContext<{
  count: number;
  draftsToLoad: Post[];
  feed: Post[];
  handleManagePostsStatusChange: (i: string, status: string) => void;
  handlePreviewAction: (i: string, action: string) => void;
  // preview: string,
  pageNumber: number;
  rowsPerPage: number;
  setFeedStatus: (status: string) => void;
  setPageNumber: (page: number) => void;

  // setPreview: (doc_id: string) => void;
  status: string;
}>({
  count: 0,
  draftsToLoad: [],
  feed: [],
  handleManagePostsStatusChange: () => {},
  handlePreviewAction: () => {},
  //preview: null,
  pageNumber: 1,
  rowsPerPage: 25,
  setFeedStatus: () => {},
  setPageNumber: () => {},

  //setPreview: () => {},
  status: "drafts",
});
export const useMyPosts = () => useContext(MyPostsFeedContext);
const MyPostsFeedContextProvider: React.FC<{ children: any }> = ({
  children,
}) => {
  let history = useHistory();
  const { preview } = useAdminPost();
  const { language, RouteTrans } = useTrans();
  const { user } = useUser();
  const { client } = useFoundation();
  const [draftsToLoad, setDraftsToLoad] = useState([]);
  const [feed, setFeed] = useState([]);
  const [status, setFeedStatus] = useState<string>(getLastView());
  const [selectedWriters, setSelectedWriters] = useState(
    _.concat(user?.manages, user)
  );
  // const [setQuery, setRefetchQuery] = useState();

  //const [topOfPage, setTopOfPage] = useState(true);
  const [myPosts, setMyPosts] = useState([]);

  const [pageNumber, setPageNumber] = useState(1);
  //const [preview, setPreview] = useState<string | null>(null);
  // const [pageInfo, setPageInfo] = useState(pageInfo_);
  // const [loading, setLoading] = useState(false);
  false && console.log(setDraftsToLoad, selectedWriters);
  false && console.log(setSelectedWriters);
  const rowsPerPage = 25;
  const count = myPosts.length;
  // const differ = count - pageNumber * rowsPerPage;

  useEffect(() => {
    const newPosts = _.orderBy(
      draftsToLoad,
      [({ updatedAt }: { updatedAt: string }) => updatedAt || ""],
      ["desc"]
    );
    setMyPosts(newPosts);
  }, [draftsToLoad]);

  useEffect(() => {
    if (myPosts.length !== 0) {
      const start = (pageNumber - 1) * rowsPerPage;
      const end = pageNumber * rowsPerPage;
      const snip = _.slice(myPosts, start, end);
      setFeed(snip);
    }
  }, [myPosts, pageNumber]);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     const scrollPosition = window.pageYOffset === 0;
  //     if (topOfPage !== scrollPosition) {
  //       setTopOfPage(scrollPosition);
  //     }
  //   };

  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, [setTopOfPage, topOfPage]);

  useEffect(() => {
    if (typeof localStorage !== "undefined")
      localStorage.setItem("admin_view", status);
  }, [status]);

  useEffect(() => {
    const where_object = status === "all" ? {} : { status };
    const writer_object = { writer: user?._id };
    const where = JSON.stringify(_.merge(where_object, writer_object));
    //const where = JSON.stringify(_.merge(where_object));

    client
      .query({
        fetchPolicy: "no-cache",
        query: GET_POSTS_QUERY,
        variables: { where },
      })
      .then((result: any) => {
        const { getPostsQuery } = result.data;

        setFeed(_.map(getPostsQuery, processPost));
      });
  }, [client, user, status]);

  function handleManagePostsStatusChange(i: string, status: string) {
    setFeedStatus(status);
  }

  function handlePreviewAction(i: any, action: string) {
    i.persist();

    const preview_actions: { [key: string]: any } = {
      Edit: () =>
        history.push(
          `/${language}/${RouteTrans["edit"][language]}/${RouteTrans["post"][language]}/${preview}`
        ),
      Publish: "function to publish/unpublish",
      Clone: "function to clone",
      Trash: "function to trash/archive",
    };
    preview_actions[i.target.outerText]();
    console.log("the preview action", i.target.outerText);
  }

  return (
    <MyPostsFeedContext.Provider
      value={{
        count,
        draftsToLoad,
        feed,
        handleManagePostsStatusChange,
        handlePreviewAction,
        pageNumber,
        rowsPerPage,
        setFeedStatus,
        setPageNumber,
        status,
      }}
    >
      {children}
    </MyPostsFeedContext.Provider>
  );
};
export default MyPostsFeedContextProvider;
