import React, { useContext } from "react";
import { InlineReactionButtons, InlineShareButtons } from "sharethis-reactjs";
import { CircularProgress, Grid } from "@mui/material";
import { withStyles } from "@mui/styles";
import { TranslationContext } from "../../../../context/Trans/translationContext";
import { getBaseURL } from "../../../../utilities/postRenders";

var _ = require("lodash");
//const shareThisEndpoint = "http://count-server.sharethis.com/v2.0/get_counts?url=https://www.sharethis.com";

const styles = theme => ({
  reactions: {
    textAlign: "center",
  },
  controls: {
    alignItems: "center",
    display: "flex",
    marginBottom: 48,
    paddingBottom: 0,
    width: "100%",
    "& h3": {
      color: "#a9a9a9",
      fontSize: "1.8rem",
      textAlign: "center",
      padding: 5,
    },
    "& h6": {
      color: "#555",
      fontWeight: "normal",
      fontSize: ".9rem",
      fontFamily: "'Helvetica Neue', Verdana, Helvetica, Arial, sans-serif",
      textAlign: "center",
    },
  },
  share_buttons: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
});

const Interactions = props => {
  const { language } = useContext(TranslationContext);
  const { classes, thisPost } = props;
  const { cover, ogCover, subtitle, title } = thisPost;

  const coverImage = ogCover ? ogCover : cover ? cover.source : "";
  const url = `https://sentinel.ht${getBaseURL(thisPost, language)}`;
  const message = subtitle + "\n \n" + url;
  // const disqusShortname = "the-haiti-sentinel";
  // const disqusConfig = {
  //   url,
  //   identifier: postID.toString(),
  //   title: title,
  // };
  // const scrollToDisqus = () => {
  //   const disqus = document && document.getElementById("sentinel-disqus");
  //   const disqusY = disqus && disqus.offsetTop;
  //   window.scrollTo(0, disqusY);
  // };
  const shareConfig = {
    alignment: "center", // alignment of buttons (left, center, right)
    color: "white", // set the color of buttons (social, white)
    enabled: true, // show/hide buttons (true, false)
    font_size: 14, // font size for the buttons
    labels: "count", // button labels (cta, counts, null)
    language: language === "ht" ? "fr" : language, // which language to use (see LANGUAGES)
    networks: [
      // which networks to include (see SHARING NETWORKS)
      "whatsapp",
      "messenger",
      "sms",
      "facebook",
      "twitter",
      "linkedin",
      "email",
    ],
    padding: 6, // padding within buttons (INTEGER)
    radius: 4, // the corner radius on each button (INTEGER)
    show_total: true,
    size: 30, // the size of each button (INTEGER)

    // OPTIONAL PARAMETERS
    url, // (defaults to current url)
    image: coverImage, // (defaults to og:image or twitter:image)
    description: subtitle, // (defaults to og:description or twitter:description)
    title: title, // (defaults to og:title or twitter:title)
    message, // (only for email sharing)
    subject: title, // (only for email sharing)
    username: "@haitisentinel", // (only for twitter sharing)
  };

  return (
    <div className={classes.controls}>
      {_.isEmpty(thisPost) ? (
        <CircularProgress />
      ) : (
        <Grid container>
          <Grid item xs={12} sm={4}>
            <div className={classes.share_buttons}>
              <InlineShareButtons config={shareConfig} />
            </div>
          </Grid>

          <Grid item xs={12} sm>
            <InlineReactionButtons
              config={{
                alignment: "center", // alignment of buttons (left, center, right)
                enabled: true, // show/hide buttons (true, false)
                language: language === "ht" ? "fr" : language, // which language to use (see LANGUAGES)
                min_count: 1, // hide react counts less than min_count (INTEGER)
                padding: 12, // padding within buttons (INTEGER)
                reactions: [
                  // which reactions to include (see REACTIONS)
                  "slight_smile",
                  "heart_eyes",
                  "laughing",
                  "astonished",
                  "sob",
                  "rage",
                ],
                size: 44, // the size of each button (INTEGER)
                spacing: 8, // the spacing between buttons (INTEGER)}}
                url,
              }}
            />
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default withStyles(styles)(Interactions);

/*<Grid
              item
              xs={12}
              style={{
                marginTop: 16,
                textAlign: "center",
              }}
            >
              <Button onClick={scrollToDisqus} variant="outlined">
                <Typography variant="button" component="p">
                  {t("static:View")}{" "}
                  <Disqus.CommentCount
                    shortname={disqusShortname}
                    config={disqusConfig}
                  >
                    {t("static:Comments")}
                  </Disqus.CommentCount>
                </Typography>
              </Button>
            </Grid> */
