import React from "react";
import { Grid } from "@mui/material";
import { withStyles } from "@mui/styles";
import { Image } from "cloudinary-react";

const styles = theme => ({
  org_img: {
    height: 24,
  },
  organization: {
    height: 23,
  },
});
const Organization = ({ classes }) => {
  return (
    <Grid item xs classes={{ root: classes.organization }}>
      <Grid
        container
        direction="column"
        alignItems="flex-start"
        justifyContent="flex-end"
        style={{ height: "100%" }}
      >
        <Grid item>
          <Image className={classes.org_img} publicId="orgs/lenational" />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(Organization);
