import React from "react";
import classNames from "classnames";
import {
  AppBar,
  ButtonBase,
  createStyles,
  Grid,
  Tab,
  Tabs,
  Theme,
  Toolbar,
  Typography,
} from "@mui/material";
import { withStyles } from "@mui/styles";
var _ = require("lodash");
const styles = (theme: Theme) =>
  createStyles({
    actionButton: {
      margin: "0 8px",
      color: "white",
    },
    active: {
      color: theme.palette.fonts.focus,
      display: "none",
    },
    back: {
      backgroundColor: theme.palette.primary.main,
      color: "white", //theme.palette.fonts.action,
      padding: "0 16px",
      textShadow: theme.palette.shadow.textShadow,
      transform: "unset",
      boxShadow: theme.palette.shadow.boxShadow,
      zIndex: 0,
    },
    header: {
      // color: theme.palette.background.default,
      fontSize: "1.6rem",
      fontWeight: "bold",
      // textShadow: theme.palette.shadow.textShadow,
    },
    indicator_draft: {
      backgroundColor: theme.palette.color.red,
    },
    indicator_published: {
      backgroundColor: theme.palette.color.green,
    },
    indicator_pending: {
      backgroundColor: theme.palette.color.yellow,
    },
    tab: { minWidth: "unset" },
    text: {
      color: "white",
    },
    text_tab: { minWidth: "unset", fontSize: ".67rem", textTransform: "none" },
    toolbar: {
      zIndex: 1,
    },
    type: {
      fontSize: "1.4rem",
      lineHeight: 1,
    },
  });

const AdminHeader: React.FC<{
  classes: any;
  base_settings: any;
}> = ({ classes, base_settings }) => {
  const { buttons, onChange, tabs, text, title, value } = base_settings;
  const name = _.find(tabs, ["value", value]).name;
  return (
    <React.Fragment>
      <Toolbar classes={{ root: classes.toolbar }}>
        <Grid container>
          <Grid item xs>
            <Grid container spacing={3}>
              {buttons.map((btn: any, idx: number) => {
                const { action, button } = btn;
                return (
                  <Grid key={idx} item>
                    <ButtonBase
                      className={classes.actionButton}
                      onClick={action}
                    >
                      <Typography
                        variant="h1"
                        classes={{ root: classes.header }}
                      >
                        {button}
                      </Typography>
                    </ButtonBase>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
          <Grid item>
            <Typography
              variant="h1"
              classes={{ root: classes.header }}
              color="primary"
            >
              {title}
            </Typography>
          </Grid>
        </Grid>
      </Toolbar>
      <AppBar classes={{ root: classes.back }} position="static">
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
        >
          <Grid item xs>
            <Tabs
              classes={{
                root: classes[text ? "text_tabs" : "tabs"],
                indicator: classes["indicator_" + value],
              }}
              onChange={(e: any, r: string) =>
                onChange(text ? e : "template", r)
              }
              // onChange={(e: any, r: any) => console.log("the prop", e, r)}
              value={value}
            >
              {(tabs || []).map((type: any) =>
                text ? (
                  <Tab
                    key={type.value}
                    classes={{
                      root: classes.text_tab,
                      selected: classes.active,
                    }}
                    value={type.value}
                    label={type.name}
                  />
                ) : (
                  <Tab
                    key={type.value}
                    classes={{ root: classes.tab, selected: classes.active }}
                    value={type.value}
                    icon={type.icon}
                  />
                )
              )}
            </Tabs>
          </Grid>
          <Grid item>
            <Typography
              variant="h2"
              classes={{ root: classNames(classes.text, classes.type) }}
              color="primary"
            >
              {name}
            </Typography>
          </Grid>
        </Grid>
      </AppBar>
    </React.Fragment>
  );
};

export default withStyles(styles)(AdminHeader);

//  let history = useHistory();
// const { language, RouteTrans } = useTrans();
// const { setViewState } = useUser();
// const { preview, setPreview } = useAdminPost();
// const { handleManagePostsStatusChange, handlePreviewAction, status } =
//   useMyPosts();
// const { feature, handleFeatureChange: handleMainTemplateChange } =
//   useFeature();
//const { template: feature_template } = feature;
// const { attachment, handleChange: handleAttachmentTemplateChange } =
//   useAttachment();
//const { post_manage_view_state } = useUser();
//const { template: attachment_template } = attachment;

// const handleTabSwitch = (event: any, newValue: string) => {
//   const tabChange: { [key: string]: any } = {
//     //  admin:handleMyPostsChange(newValue),
//     create: handleMainTemplateChange("template", newValue),
//     create_attachment: handleAttachmentTemplateChange("template", newValue),
//   };
//   return tabChange[post_manage_view_state];
// };

// const showPreview = !!preview;
//const header_view = post_manage_view_state;
// const header_tabs: { [key: string]: any } = {
//   admin: [
//     {
//       icon: <FaClock />,
//       name: <FormattedMessage id="All" />,
//       value: "all",
//     },
//     {
//       icon: <FaClock />,
//       name: <FormattedMessage id="Pending" />,
//       value: "pending",
//     },
//     {
//       icon: <FaDraftingCompass />,
//       name: <FormattedMessage id="Drafts" />,
//       value: "drafts",
//     },
//     {
//       icon: <FaGlobeAfrica />,

//       name: <FormattedMessage id="Published" />,
//       value: "published",
//     },
//   ],
//   edit: [
//     {
//       icon: <FaNewspaper />,
//       name: <FormattedMessage id="text" />,
//       value: "text",
//     },
//     {
//       icon: <FaVideo />,
//       name: <FormattedMessage id="video" />,
//       value: "video",
//     },
//     {
//       icon: <FaMicrophone />,
//       name: <FormattedMessage id="audio" />,
//       value: "audio",
//     },
//     {
//       icon: <FaComments />,
//       name: <FormattedMessage id="social" />,
//       value: "social",
//     },
//     {
//       icon: <FaImage />,
//       name: <FormattedMessage id="photo" />,
//       value: "photo",
//     },
//   ],
//   preview: [
//     {
//       icon: <FaClock />,
//       name: <FormattedMessage id="Edit" />,
//       value: "edit",
//     },
//     {
//       icon: <FaDraftingCompass />,
//       name: <FormattedMessage id="Publish" />,
//       value: "publish",
//     },
//     {
//       icon: <FaGlobeAfrica />,
//       name: <FormattedMessage id="Clone" />,
//       value: "clone",
//     },
//     {
//       icon: <FaGlobeAfrica />,
//       name: <FormattedMessage id="Trash" />,
//       value: "trash",
//     },
//     {
//       // icon: <FaGlobeAfrica />,
//       // name: <FormattedMessage id="Trash" />,
//       value: null,
//     },
//   ],
// };

// //console.log("the view", post_manage_view_state);

// const header: { [key: string]: any } = {
//   admin: {
//     action: () =>
//       history.push(
//         `/${language}/${RouteTrans["create"][language]}/${RouteTrans["post"][language]}`
//       ),
//     button: "+",
//     onChange: handleManagePostsStatusChange,
//     tabs: header_tabs.admin,
//     title: (
//       <FormattedMessage id="Manage Posts" defaultMessage="Manage Posts" />
//     ),
//     value: status,
//   },
//   // create_attachment: {
//   //   action: () => setViewState(base_view),
//   //   button: "<",
//   //   onChange: handleAttachmentTemplateChange,
//   //   tabs: header_tabs.edit,
//   //   title: <FormattedMessage id="Adding Attachment" />,
//   //   value: attachment_template,
//   // },
//   create: {
//     action: () =>
//       history.push(
//         `/${language}/${RouteTrans["manage"][language]}/${RouteTrans["posts"][language]}`
//       ),
//     button: "x",
//     onChange: handleMainTemplateChange,
//     tabs: header_tabs.edit,
//     title: <FormattedMessage id="Creating Post" />,
//     value: feature_template,
//   },
//   edit: {
//     action: () =>
//       history.push(
//         `/${language}/${RouteTrans["manage"][language]}/${RouteTrans["posts"][language]}`
//       ),
//     button: "x",
//     tabs: header_tabs.edit,
//     title: <FormattedMessage id="Editing Post" />,
//     value: feature_template,
//   },
//   edit_attachment: {
//     action: () =>
//       history.push(
//         `/${language}/${RouteTrans["manage"][language]}/${RouteTrans["posts"][language]}`
//       ),
//     button: "<",
//     title: <FormattedMessage id="Editing Attachment" />,
//   },
//   preview: {
//     action: () => setPreview(null),
//     button: "<",
//     onChange: handlePreviewAction,
//     tabs: header_tabs.preview,
//     title: <FormattedMessage id="Post Preview" />,
//     value: null,
//   },
// };
