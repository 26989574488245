import RouteTrans from "../context/Trans/routeTrans";

var _ = require("lodash");

export async function asyncForEach(array: any[], callback: any) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}

export function processPost(post: any, language: string) {
  const postObj = post;

  const title = JSON.parse(postObj.title)[language];
  const subtitle = JSON.parse(postObj.subtitle)[language];
  const body = JSON.parse(postObj.body)[language];
  const linkName = JSON.parse(postObj.link.name)[language];
  const link = { ...postObj.link, name: linkName };
  const thisPost = {
    ...postObj,
    title,
    subtitle,
    body,
    link,
  };
  return thisPost;
}

export function renderImage(image: any, attachments: any[]) {
  if (image) {
    return image;
  } else if (attachments.length > 0) {
    let attachment = attachments[0];
    let image =
      "https://res.cloudinary.com/defendhaiti/image/upload/v1549853661/Version_1/" +
      attachment.source;
    return image;
  } else {
    return "https://res.cloudinary.com/defendhaiti/image/upload/v1549853661/Version_1/2017/12/haitiansTPSprotest.png";
  }
}

export function createLink(
  language: string,
  division: any,
  category: any,
  ID: any,
  alias: string,
  status?: string
) {
  const postUrl =
    "/" +
    language +
    `/${RouteTrans["post"][language]}/` +
    (status ? status + "/" : "") +
    _.toLower(division ? division : "undivided") +
    "/" +
    _.kebabCase(
      _.toLower(category ? _.replace(category, "&", "and") : "uncategorized")
    ) +
    "/" +
    (ID ? ID + "-" + alias : "0000");
  return postUrl;
}

export function getBaseURL(
  currentPost: any,
  language: string,
  edit: boolean = false
) {
  const { _id, category, postID } = currentPost;
  const catUrl = _.split(category.url, "/");
  const divName = catUrl[0];
  const catName = catUrl[1];

  const publishUrl = `/${language}/${RouteTrans["post"][language]}/${RouteTrans[divName][language]}/${RouteTrans[catName][language]}/${postID}`;

  const editUrl = `/${language}/${RouteTrans["edit"][language]}/${RouteTrans["post"][language]}/${_id}`;
  const baseUrl = edit ? editUrl : publishUrl;

  return baseUrl;
}

export function handleDataPost(data: any, prevProps: any, action: any) {
  if (!data.loading && data.post !== prevProps.data.post) {
    action(data);
  }
}

export function handleDataPosts(obj: any, prevProps: any, action: any) {
  const { data } = obj.props;
  if (!data.loading && data.posts !== prevProps.data.posts) {
    action(data);
  }
}

export function dayCovers(posts: any[], coverText: any, lng: string) {
  var coverOverlayGrid = "";
  const { dateOfFeed, dayOfWeek } = coverText;
  const coverNamesArray = _.map(_.map(posts, "cover"), "name");
  const base = "https://res.cloudinary.com/defendhaiti/image/upload/";
  const tail = "v1583757145/SiteGraphics/homeback.jpg";

  coverNamesArray.forEach((public_id: string, idx: number) => {
    const num = idx + 1;
    const row = _.ceil(num / 4);
    const column = num - _.floor(idx / 4) * 4;
    const rowCalc = row - 1,
      colCalc = column - 1;

    const overlay = `l_${_.replace(public_id, "/", ":")},w_150,h_80,o_${
      100 - rowCalc * 25
    },c_scale,g_north_west,y_${rowCalc * 80},x_${colCalc * 150}/`;
    return (coverOverlayGrid += overlay);
  });

  const logo = `l_SiteGraphics:${lng}_logo,h_70,g_south_west,x_24,y_24/`;
  const dayOfWeekText = `l_text:Arial_26_bold:${_.upperCase(
    dayOfWeek
  )},co_rgb:FAFAFA,g_south_east,x_24,y_62/`;
  const dateOfFeedText = `l_text:Arial_38_regular:${dateOfFeed},co_rgb:FAFAFA,g_south_east,x_24,y_24/`;

  const image =
    base + coverOverlayGrid + logo + dayOfWeekText + dateOfFeedText + tail;

  return image;
}

export function feedCovers(posts: any[], coverText: any, lng: string) {
  var coverOverlayGrid = "";
  const { category, division } = coverText;
  const coverNamesArray = _.map(_.map(posts, "cover"), "name");
  const base = "https://res.cloudinary.com/defendhaiti/image/upload/";
  const tail = "v1583757145/SiteGraphics/homeback.jpg";

  coverNamesArray.forEach((public_id: any, idx: number) => {
    const num = idx + 1;
    const row = _.ceil(num / 4);
    const column = num - _.floor(idx / 4) * 4;
    const rowCalc = row - 1,
      colCalc = column - 1;

    const overlay = `l_${_.replace(public_id, "/", ":")},w_150,h_80,o_${
      100 - rowCalc * 25
    },c_scale,g_north_west,y_${rowCalc * 80},x_${colCalc * 150}/`;
    return (coverOverlayGrid += overlay);
  });

  const logo = `l_SiteGraphics:${lng}_logo,h_70,g_south_west,x_24,y_24/`;
  const categoryOverlay = category
    ? `l_text:Arial_26_bold:${_.upperCase(
        category
      )},co_rgb:FAFAFA,g_south_east,x_24,y_62/`
    : "";
  const divisionOverlay = division
    ? `l_text:Arial_38_regular:${division},co_rgb:FAFAFA,g_south_east,x_24,y_24/`
    : "";

  const image =
    base + coverOverlayGrid + logo + categoryOverlay + divisionOverlay + tail;
  return image;
}

export function latestCovers(posts: any[], img_tit: any) {
  var formattedString = "";
  const { title, body, end } = img_tit;
  const coverNameArray = _.reverse(_.map(_.map(posts, "cover"), "name"));
  const base = "https://res.cloudinary.com/defendhaiti/image/upload/";
  const tail = "v1583757145/SiteGraphics/homeback.jpg";

  coverNameArray.forEach((coverName: string, index: number) => {
    const num = index + 1;
    const column = _.ceil(num / 4) - 1;
    const row = index - 4 * column;
    const overlay = `l_${_.replace(coverName, "/", ":")},w_150,h_80,o_${
      100 - row * 25
    },c_scale,g_north_west,y_${row * 80},x_${column * 150}/`;
    return (formattedString += overlay);
  });

  const textOverlay = `${
    title
      ? `l_text:Arial_16_bold:${title},co_rgb:FAFAFA,g_south_west,x_20,y_82/`
      : ""
  }l_text:Arial_45_bold:${body},co_rgb:FAFAFA,g_south_west,x_20,y_40/l_text:Arial_16_bold:${end},co_rgb:FAFAFA,g_south_west,x_20,y_18/`;
  const image = base + formattedString + textOverlay + tail;
  return image;
}

export function latestCoversFeed(posts: any[], img_tit: any) {
  // const posts = () => {
  //   return client
  //     .query({
  //       query: gql`
  //         query($where: PostWhereInput) {
  //           posts(where: $where, first: 16, orderBy: published_DESC) {
  //             _id
  //             published
  //             cover {
  //               _id
  //               name
  //               source
  //             }
  //           }
  //         }
  //       `,
  //       variables: {
  //         where: whereObject,
  //       },
  //     })
  //     .then((response) => {
  //       return response.data.posts;
  //     });
  // };

  var formattedString = "";
  const { title, body, end } = img_tit;
  const coverNameArray = _.reverse(_.map(_.map(posts, "cover"), "name"));
  const base = "https://res.cloudinary.com/defendhaiti/image/upload/";
  const tail = "v1583757145/SiteGraphics/homeback.jpg";

  coverNameArray.forEach((coverName: string, index: number) => {
    const num = index + 1;
    const column = _.ceil(num / 4) - 1;
    const row = index - 4 * column;
    const overlay = `l_${_.replace(coverName, "/", ":")},w_150,h_80,o_${
      100 - row * 25
    },c_scale,g_north_west,y_${row * 80},x_${column * 150}/`;
    return (formattedString += overlay);
  });

  const textOverlay = `l_text:Arial_16_bold:${title},co_rgb:FAFAFA,g_south_west,x_20,y_82/l_text:Arial_45_bold:${body},co_rgb:FAFAFA,g_south_west,x_20,y_30/l_text:Arial_16_bold:${end},co_rgb:FAFAFA,g_south_west,x_20,y_18/`;
  const image = base + formattedString + textOverlay + tail;

  return image;
}
