import React, { createContext, useContext, useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
//import axios from "axios";
//import { graphql } from "react-apollo";
// import {
//   getAllCategoriesQuery,
//   getAllWritersQuery,
// } from "../components/Admin/Helpers/adminQueries";
import { setCategories } from "./setFoundation";
import { Entity, User } from "./types";
import { GET_CATEGORIES, GET_ENTITIES, GET_USERS } from "../utilities/queries";
import { useUser } from "./User/userContext";

var _ = require("lodash");

interface FoundationValues {
  categoryCollection: any[];
  categoryList: any;
  client: any;
  entitiesCollection: Entity[];
  getCategoryInfo: (item: string, _id: string) => any;
  latest_entities: any[];
  latest_users: any[];
  marginContentTop: null | number;
  minContentHeight: null | number;
  rect_header?: any;
  rect_footer?: any;
  ref_header?: any;
  ref_footer?: any;
  writersCollection: User[];
  usersCollection: User[];
}

export const FoundationContext = createContext<FoundationValues>({
  categoryCollection: [],
  categoryList: {},
  client: {},
  entitiesCollection: [],
  getCategoryInfo: () => {},
  latest_entities: [],
  latest_users: [],
  marginContentTop: 90,
  minContentHeight: 150,
  usersCollection: [],
  writersCollection: [],
});
export const useFoundation = () => useContext(FoundationContext);

const FoundationContextProvider: React.FC<{ children: any; client: any }> = ({
  children,
  client,
}) => {
  const { user } = useUser();

  const [categoryCollection, setCategoryCollection] = useState<any[]>([]);
  const [categoryList, setCategoryList] = useState({});
  const [entitiesCollection, setEntitiesCollection] = useState([]);
  const [usersCollection, setUsersCollection] = useState([]);
  const [writersCollection, setWritersCollection] = useState([]);
  false && console.log(setWritersCollection);

  const [latest_entities, setLatestEntities] = useState([]);
  const [latest_users, setLatestUsers] = useState([]);

  const [rect_header, ref_header] = useClientRect();
  const [rect_footer, ref_footer] = useClientRect();

  useEffect(() => {
    const latest_6_entities = _.sampleSize(entitiesCollection, 6);
    const latest_8_users = _.slice(
      _.sortBy(usersCollection, ["log", "desc"]),
      0,
      8
    );
    setLatestEntities(latest_6_entities);
    setLatestUsers(latest_8_users);
  }, [entitiesCollection, usersCollection]);

  useEffect(() => {
    // If user is Admin, get all users and entities

    const isAdmin = !_.includes(["administrator", "founder"], user?.role);

    client
      .query({
        query: GET_USERS,
        variables: {
          limit: isAdmin ? 8 : null,
          where: JSON.stringify({
            role: { $in: ["administrator", "founder", "member"] },
            status: "active",
          }),
        },
        fetchPolicy: "no-cache",
      })
      .then(({ data }: { data: any }) => {
        const { getUsers } = data;

        setUsersCollection(getUsers);
      });

    client
      .query({
        query: GET_ENTITIES,
        variables: {
          limit: isAdmin ? 6 : null,
        },
        fetchPolicy: "no-cache",
      })
      .then(({ data }: { data: any }) => {
        const { getEntities } = data;
        setEntitiesCollection(getEntities);
      });
  }, [client, user]);

  function useClientRect(): any {
    const [rect, setRect] = React.useState(null);
    const ref = React.useCallback(node => {
      if (node !== null) {
        setRect(node.getBoundingClientRect());
      }
    }, []);
    return [rect, ref];
  }

  const getCategoryInfo = (item: string, catID: string) => {
    const categoryObject = _.find(categoryCollection, function (o: any) {
      return o.categoryID === catID;
    });
    return categoryObject[item];
  };
  const { data: categories_ } = useQuery(GET_CATEGORIES);

  useEffect(() => {
    if (categories_) {
      const { divisionObject, categoryFindArray } = setCategories(
        categories_.getCategories
      );
      setCategoryList(divisionObject);
      setCategoryCollection(categoryFindArray);
    }
  }, [categories_]);

  // useEffect(() => {
  //   categories_ && setCategoryCollection(setCategories(categories_.getAllCategories).divisionObject);
  // }, [categories_]);
  //   const { getAllCategories, getAllWriters } = props;

  //   if (!getAllCategories.loading) {
  //     const { categories } = getAllCategories;
  //     const prevCats = prevProps.getAllCategories.categories;

  //     if (categories !== prevCats) {
  //       this.setState({
  //         ...state,
  //         categoryCollection: setCategories(categories).categoryFindArray,
  //         categoryList: setCategories(categories).divisionObject,
  //       });
  //     }
  //   }

  //   if (!getAllWriters.loading) {
  //     const { users } = getAllWriters;
  //     const prevUsers = prevProps.getAllWriters.users;
  //     if (users !== prevUsers) {
  //       this.setState({ ...state, writersCollection: users });
  //     }
  //   }
  // }

  // componentDidMount() {
  //   axios
  //     .get("http://localhost:4000/api/categories")
  //     .then((response) => {
  //       const categories = response.data;
  //       this.setState({
  //         ...this.state,
  //         categoryCollection: setCategories(categories).categoryFindArray,
  //         categoryList: setCategories(categories).divisionObject,
  //       });
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }

  const marginContentTop = _.isNumber(rect_header?.height)
    ? rect_header?.height
    : 48;

  const minContentHeight =
    window.innerHeight - marginContentTop - (rect_footer?.height || 178);

  return (
    <FoundationContext.Provider
      value={{
        categoryCollection,
        categoryList,
        client,
        entitiesCollection,
        getCategoryInfo,
        latest_entities,
        latest_users,
        marginContentTop,
        minContentHeight,
        writersCollection,
        rect_header,
        rect_footer,
        ref_header,
        ref_footer,
        usersCollection,
      }}
    >
      {children}
    </FoundationContext.Provider>
  );
};

//   categoryCollection: [],
//   categoryList: {},
//   writersCollection: [],
// };

// componentDidUpdate(prevProps) {
//   const { props, state } = this;
//   const { getAllCategories, getAllWriters } = props;

//   if (!getAllCategories.loading) {
//     const { categories } = getAllCategories;
//     const prevCats = prevProps.getAllCategories.categories;

//     if (categories !== prevCats) {
//       this.setState({
//         ...state,
//         categoryCollection: setCategories(categories).categoryFindArray,
//         categoryList: setCategories(categories).divisionObject,
//       });
//     }
//   }

//   if (!getAllWriters.loading) {
//     const { users } = getAllWriters;
//     const prevUsers = prevProps.getAllWriters.users;
//     if (users !== prevUsers) {
//       this.setState({ ...state, writersCollection: users });
//     }
//   }
// }

// componentDidMount() {
//   axios
//     .get("http://localhost:4000/api/categories")
//     .then((response) => {
//       const categories = response.data;
//       this.setState({
//         ...this.state,
//         categoryCollection: setCategories(categories).categoryFindArray,
//         categoryList: setCategories(categories).divisionObject,
//       });
//     })
//     .catch((error) => {
//       console.log(error);
//     });
// }

// render() {
//   const { props, state } = this;
//   const { categoryCollection, categoryList } = state;
//   const { children, client } = props;
//   const getCategoryInfo = (item, catID) => {
//     const categoryObject = _.find(categoryCollection, function (o) {
//       return o.categoryID === catID;
//     });
//     return categoryObject[item];
//   };

//   }
// }

export default FoundationContextProvider;
