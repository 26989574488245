import React from "react";
import { Grid, Theme } from "@mui/material";
import { createStyles, withStyles } from "@mui/styles";
import DefaultSetLink from "./default_link";

const styles = (theme: Theme) =>
  createStyles({
    divider: {
      marginTop: theme.spacing(1),
    },
    first: {
      borderRight: "1px solid " + theme.palette.ads.divider,
    },
    formField: {
      marginTop: theme.spacing(2),
      width: "100%",
      "& textarea": {
        color: theme.palette.fonts.focus,
      },
    },
    formSection: {
      marginTop: theme.spacing(1),
    },
  });

const SplitFormFields: React.FC<{ classes: any }> = ({ classes }) => {
  return (
    <Grid item xs={12}>
      <Grid container spacing={6} style={{ padding: "24px 0" }}>
        <Grid item xs={6} classes={{ root: classes.first }}>
          <DefaultSetLink />
        </Grid>
        <Grid item xs={6}>
          <DefaultSetLink isEditing />
        </Grid>
      </Grid>
    </Grid>
  );
  // }
};

export default withStyles(styles)(SplitFormFields);
