import React from "react";
import { FormattedMessage } from "react-intl";
import {
  InputLabel,
  FormControl,
  MenuItem,
  OutlinedInput,
  Select,
  Theme,
} from "@mui/material";
import { createStyles, withStyles } from "@mui/styles";
import { Entity } from "../../../../../../context/types";
import { useUser } from "../../../../../../context/User/userContext";

var _ = require("lodash");

const styles = (theme: Theme) =>
  createStyles({
    formField: {
      width: "100%",
      marginTop: theme.spacing(2),
    },
    label: {
      top: -6,
      left: 13,
    },
  });

const SelectOrganization: React.FC<{
  classes: any;
  handleChange: (type: string) => void;
  organization: Entity;
}> = ({ organization, classes, handleChange }) => {
  //const { organizations } = useAuth();

  const { user } = useUser();

  const listOrganizations = (organizationsCollection: Entity[]) => {
    return _.concat(organizationsCollection, {
      _id: "none",
      name: (
        <i>
          <FormattedMessage id="none" />
        </i>
      ),
    }).map((organization: Entity) => {
      return (
        <MenuItem key={organization._id} value={organization._id}>
          {organization.name}
        </MenuItem>
      );
    });
  };

  return (
    <FormControl className={classes.formField}>
      <InputLabel className={classes.label} id="select-organization-writer">
        <FormattedMessage id="Organization" />
      </InputLabel>
      <Select
        labelId="select-organization-writer"
        value={organization ? organization._id : "none"}
        onChange={() => handleChange("organization")}
        input={
          <OutlinedInput
            label={<FormattedMessage id="Organization" />}
            name="organization"
          />
        }
      >
        {listOrganizations(_.concat(user?.organizations))}
      </Select>
    </FormControl>
  );
};

export default withStyles(styles)(SelectOrganization);
