import React from "react";
import { Box, Button, DialogActions, Grid, Theme } from "@mui/material";
import { createStyles, withStyles } from "@mui/styles";
import AttachmentType from "./type";
import AttachmentInputMedia from "./Input";
import Info from "./info";
import { useAttachment } from "../../../../context/Post/attachmentContext";
import { useUser } from "../../../../context/User/userContext";
import { useAttachments } from "../../../../context/Post/attachmentsContext";
import PostLoader from "../../../../components/Loader";
//import { useTrans } from "../../../../../../../../context/Trans/translationContext";
import { useNewPost } from "../../../../context/Post/newPostContext";
import { defaultAttachment } from "../../../../context/types";

var _ = require("lodash");

const styles = (theme: Theme) =>
  createStyles({
    attachment_form_holder: {
      marginTop: 24,
    },
    buttonAction: {
      color: theme.palette.fonts.focus,
    },
    disabledAction: {
      color: theme.palette.fonts.detail + " !important",
    },
    desktop: {
      margin: "auto auto",
      maxWidth: 396,
      width: "calc(100vw - 48px)",
    },
    mobile: {},
  });

// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="up" ref={ref} />;
// });

const AttachmentPicker: React.FC<{ classes: any }> = ({ classes }) => {
  //const theme = useTheme();
  // const matches = useMediaQuery(theme.breakpoints.down("sm"));
  // const { language } = useTrans();
  const { app_page, setViewState } = useUser();
  const { dev } = useNewPost();
  const { handleAddAttachment } = useAttachments();
  // const { attachmentsManager } = managers;
  const {
    //  clearAttachment,
    attachment,
    // handleChangeType,
    loadAttachment,
    //  updateAttachment,
  } = useAttachment();
  // This is our Main?

  const { handle, postType, source, title, template } = attachment;

  const handleSubmitClose = () => {
    const attachmentType = { ...attachment, type: postType[template] };
    console.log("view state", app_page);
    handleAddAttachment(attachmentType);
    loadAttachment(defaultAttachment);
    setViewState(app_page);
    //  handle && clearAttachment(handle);
    // setViewState(base_view);
  };

  // const updateBody = (file: Attachment) => {
  //   const newAttObject = { ...attachment, ...file };
  //   loadAttachment(newAttObject);
  // };

  const readyToSubmit =
    handle &&
    source &&
    title &&
    title[dev] &&
    source.length > 0 &&
    handle.length === 3 &&
    title[dev].length > 0;

  if (_.isEmpty(attachment)) {
    return <PostLoader />;
  } else {
    return (
      // <Dialog
      //   fullScreen={matches}
      //   open={attachmentsManager}
      //   // TransitionComponent={<Slide direction="up" ref={ref}  />}
      //   keepMounted
      //   classes={{ root: matches ? classes.mobile : classes.desktop }}
      // >
      <Box className={classes.attachment_form_holder}>
        {/* <AttachmentPickerTabs /> */}

        <Grid container spacing={3}>
          {/* <Grid item xs={12}>
            <Header clearAll={clearAttachment} template={template} />
          </Grid> */}
          <Grid item xs={12}>
            <AttachmentType
            // handleChangeType={handleChangeType}
            // postType={postType}
            // template={template}
            />
          </Grid>
          <Grid item xs={12} style={{ marginTop: -8 }}>
            <AttachmentInputMedia
            // attPurpose={"body"}
            // attachment={attachment}
            // handleBodyChange={updateAttachment}
            // main_body={true}
            // clearAttachment={clearAttachment}
            // postType={postType}
            // template={template}
            // updateBody={updateBody}
            />
          </Grid>
          <Grid item xs={12}>
            <Info />
          </Grid>
        </Grid>

        <DialogActions>
          <Button
            onClick={() => setViewState(app_page)}
            classes={{ root: classes.buttonAction }}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            disabled={!readyToSubmit}
            onClick={handleSubmitClose}
            classes={{
              root: classes.buttonAction,
              disabled: classes.disabledAction,
            }}
            variant="outlined"
          >
            Add
          </Button>
        </DialogActions>
      </Box>
      // </Dialog>
    );
  }
};

// AttachmentPicker.propTypes = {
//   classes: PropTypes.object.isRequired,
// };

export default withStyles(styles, { withTheme: true })(AttachmentPicker);
