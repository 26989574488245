import React from "react";
import { CardMedia, Grid, Theme, Typography } from "@mui/material";
import { createStyles, withStyles } from "@mui/styles";
import { useCurrentPost } from "../../../../../context/Post/currentPostContext";
import CloudinaryImage from "./cloudinary-image";
import { useTrans } from "../../../../../context/Trans/translationContext";
var _ = require("lodash");

const styles = (theme: Theme) =>
  createStyles({
    button: {
      // boxShadow: theme.shadows[10],
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        //  background: theme.palette.primary.default,
      },
    },
    container: {
      height: "100%",
      // paddingBottom: theme.spacing(2.5),
      // paddingLeft: theme.spacing(0.5),
      //position: "absolute",
    },
    info: {
      background: "rgba(0,0,0,.3)",
      borderRadius: theme.spacing(1),
      bottom: theme.spacing(3),
      left: theme.spacing(2),
      padding: "8px 8px 8px 16px",
      position: "absolute",
    },
    cover_img: {
      width: "100%",
    },
    credit: {
      color: theme.palette.secondary.main,
      fontSize: ".5rem",
      fontStyle: "italic",
      lineHeight: 1,
      textAlign: "left",
    },
    title: {
      color: theme.palette.secondary.main,
      fontSize: ".6rem",
      fontStyle: "italic",
      fontWeight: "bold",
      lineHeight: 1,
      textAlign: "left",
    },
    media: {
      background: theme.palette.secondary.main,
      backgroundSize: "cover",
      // boxShadow: theme.palette.shadow.boxShadow,
      color: "white",
      // [theme.breakpoints.down("sm")]: {
      //   minHeight: 200,
      // },
      // [theme.breakpoints.up("sm")]: {
      //   minHeight: 275,
      // },
      marginBottom: 48,
      padding: 0,
      position: "relative",
      zIndex: -1,
      width: "100%",
      "& span": {
        color: "white",
      },
    },
  });

const Cover: React.FC<{ classes: any }> = ({ classes }) => {
  const { language } = useTrans();
  const { currentPost } = useCurrentPost();
  const { credit, description, enabled, name, title } = currentPost.cover;
  // const showImage = (thisPost) => {
  //   const { cover, version } = thisPost;
  //   const base = "https://res.cloudinary.com/defendhaiti/image/upload/";
  //   const trans = "c_scale,w_645/v1/";
  //   const name = version === 1 ? "Version_1/" + cover.source : cover.name;
  //   return base + trans + name;
  // };
  // className={classes.cover_img}

  const showInfo = () => {
    return (
      <Grid
        container
        direction="column"
        justifyContent="flex-end"
        alignItems="flex-start"
        classes={{ root: classes.container }}
      >
        <CloudinaryImage name={name} />
        {_.some([credit, title, description]) && (
          <Grid item classes={{ root: classes.info }}>
            {credit && (
              <Typography
                classes={{ root: classes.credit }}
                variant="caption"
                component="p"
              >
                {credit}
              </Typography>
            )}
            {title && (
              <Typography
                classes={{ root: classes.title }}
                variant="caption"
                component="p"
              >
                {title[language]}
              </Typography>
            )}
            {description && (
              <Typography
                classes={{ root: classes.description }}
                variant="caption"
                component="p"
              >
                {description[language]}
              </Typography>
            )}
          </Grid>
        )}
      </Grid>
    );
  };

  if (enabled) {
    return (
      <CardMedia className={classes.media} title={name}>
        {_.some([credit, description, name, title]) && showInfo()}
      </CardMedia>
    );
  } else {
    return null;
  }
};

export default withStyles(styles)(Cover);
