import React, { useEffect } from "react";
import { useApolloClient } from "@apollo/client";
import { Box } from "@mui/material";
import { useCurrentPost } from "../../../../context/Post/currentPostContext";
import { GET_POST_BY_ID_QUERY } from "../../../../utilities/queries";
import Page from "../../Post/page";
import Loader from "../../../../components/Loader";
import { useAdminPost } from "../../../../context/Post/adminPostContext";

const Post: React.FC<{ queried_post?: any }> = ({ queried_post }) => {
  const client = useApolloClient();
  const { currentPost, setCurrentPost } = useCurrentPost();
  const { preview: _id, view } = useAdminPost();

  const isReady = _id === currentPost._id;
  useEffect(() => {
    if (_id && !isReady) {
      client
        .query({
          query: GET_POST_BY_ID_QUERY,
          variables: {
            _id,
          },
          fetchPolicy: "no-cache",
        })
        .then(({ data }: { data: any }) => {
          const result = data.getPostByIdQuery;
          setCurrentPost(result);
        });
    }
  }, [_id, client, isReady, setCurrentPost]);

  console.log("in preview");

  if (isReady && view === "preview") {
    return (
      <Box style={{ paddingTop: 24 }}>
        <Page />
      </Box>
    );
  } else {
    return <Loader />;
  }
};
export default Post;
