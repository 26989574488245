import React from "react";
import { Helmet } from "react-helmet-async";
import { withStyles } from "@mui/styles";
import { useTrans } from "../../../context/Trans/translationContext";

var _ = require("lodash");
const styles = theme => ({
  attToDelete: {
    padding: "0 24px",
  },
  container: { display: "flex", minHeight: "calc(100vh - 64px)" },
  loader: { flexGrow: 1 },
  text: {
    color: theme.palette.secondary.main,
    fontSize: ".8rem",
    fontWeight: "bold",
    marginTop: theme.spacing(1),
  },
});

const PageHelmet = ({ thisPost }) => {
  const { language, paths } = useTrans();

  // const { alias, baseUrls, editContent, finalBaseUrl, thisPost } = props;

  const { category, cover, modified, publishedAt, subtitle, title, writer } =
    thisPost;
  const url = `https://sentinel.ht${paths[language] || ""}`;
  const site_name = {
    en: "The Haiti Sentinel",
    fr: "Le Sentinel d'Haïti",
    ht: "Sentinel Ayiti a",
  };

  return (
    <Helmet>
      <title>{`${site_name[language]} | ${title[language]}`}</title>
      <meta property="og:title" content={title[language]} />
      <meta property="og:description" content={subtitle[language]} />
      <meta property="og:site_name" content={site_name[language]} />
      <meta property="og:type" content="article" />
      <meta property="og:url" content={url} />
      {!_.isEmpty(cover) && <meta property="og:image" content={cover.source} />}
      <meta property="og:article:published_time" content={publishedAt} />
      <meta property="og:article:modified_time" content={modified} />
      <meta
        property="og:article:author"
        content={writer && `${writer.firstName} ${writer.lastName}`}
      />
      <meta
        property="og:article:section"
        content={category && category.division.name + " | " + category.name}
      />
      <link rel="alternate" hreflang="en" href={paths.en} />
      <link rel="alternate" hreflang="fr" href={paths.fr} />
      <link rel="alternate" hreflang="ht" href={paths.ht} />
    </Helmet>
  );
};
export default withStyles(styles)(PageHelmet);
