import React from "react";
import { FaImage, FaPortrait, FaSquare } from "react-icons/fa";
import {
  Box,
  Button,
  ButtonGroup,
  Grid,
  Theme,
  Typography,
} from "@mui/material";
import { createStyles, withStyles } from "@mui/styles";
const styles = (theme: Theme) =>
  createStyles({
    active: {
      background: theme.palette.primary.main, // theme.palette.fonts.focus,
      color: theme.palette.background.paper,
      //  color: theme.palette.primary.default
    },
    shape: {
      [theme.breakpoints.down("sm")]: {
        fontSize: ".7rem",
      },
    },
    shapeIcon: {
      background: theme.palette.background.paper,
      color: theme.palette.fonts.detail, //theme.palette.primary.main,
    },
    wrapper: {
      height: "100%",
      width: "100%",
    },
  });
const Shaper: React.FC<{
  classes: any;
  handleShapeChange: (orientation: string) => void;
  shape: string;
}> = ({ classes, handleShapeChange, shape }) => {
  return (
    <Box className={classes.wrapper}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        style={{ height: "100%" }}
      >
        <Grid item>
          <ButtonGroup variant="contained">
            <Button
              classes={{
                root:
                  shape === "landscape" ? classes.active : classes.shapeIcon,
                text:
                  shape === "landscape" ? classes.active : classes.shapeIcon,
              }}
              onClick={() => handleShapeChange("landscape")}
            >
              <FaImage />
            </Button>
            <Button
              classes={{
                root: shape === "square" ? classes.active : classes.shapeIcon,
                text: shape === "square" ? classes.active : classes.shapeIcon,
              }}
              onClick={() => handleShapeChange("square")}
            >
              <FaSquare />
            </Button>
            <Button
              classes={{
                root: shape === "portrait" ? classes.active : classes.shapeIcon,
                text: shape === "portrait" ? classes.active : classes.shapeIcon,
              }}
              onClick={() => handleShapeChange("portrait")}
            >
              <FaPortrait />
            </Button>
          </ButtonGroup>
        </Grid>
        <Grid item>
          <Typography variant="button" classes={{ root: classes.shape }}>
            {shape}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default withStyles(styles)(Shaper);
