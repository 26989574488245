import React from "react";
import { Grid, Theme, Typography } from "@mui/material";
import { createStyles, withStyles } from "@mui/styles";
import Moment from "react-moment";
import Disqus from "disqus-react";
//import PostStatistics from "../../../../components/post_stats";
import { getBaseURL } from "../../../../utilities/postRenders";
import { useTrans } from "../../../../context/Trans/translationContext";
import { useCurrentPost } from "../../../../context/Post/currentPostContext";

//import AttachmentDeleteCard from "../../../Admin/Edit/attachmentDeleteCard";
var _ = require("lodash");
//var dateFormat = require("dateformat");

const styles = (theme: Theme) =>
  createStyles({
    data: {
      color: theme.palette.primary.light,
      fontSize: ".7rem",
      lineHeight: 1,
      [theme.breakpoints.down("md")]: { fontSize: ".6rem" },
    },
    date: {
      fontFamily: "Gotham SSm A, Gotham SSm B",
      fontStyle: "normal",
      fontWeight: 400,
      paddingRight: theme.spacing(3),
    },
    postDate: {
      color: theme.palette.primary.light,
      fontFamily: "Gotham SSm A, Gotham SSm B",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: ".7rem",
      lineHeight: 1,
      [theme.breakpoints.down("md")]: { fontSize: ".6rem" },
    },
  });

const PublishDate: React.FC<{
  classes: any;
}> = ({ classes }) => {
  const { language } = useTrans();
  const { currentPost: thisPost } = useCurrentPost();
  const { postID, publishedAt, title, views } = thisPost;
  const postURL = getBaseURL(thisPost, language);

  const disqusShortname = "the-haiti-sentinel";
  const disqusConfig = {
    url: `https://sentinel.ht${postURL}`,
    identifier: postID ? postID.toString() : "unavailable",
    title: title[language],
  };

  const calendarStrings = {
    lastDay: "[Yesterday at] LT",
    sameDay: "[Today at] LT",
    nextDay: "[Tomorrow at] LT",
    lastWeek: "dddd, LL [at] LT",
    nextWeek: "dddd [at] LT",
    sameElse: "LLLL",
  };

  const published_date = publishedAt
    ? new Date(parseInt(publishedAt)).toISOString()
    : new Date().toISOString();

  return (
    <Grid item xs={12} style={{ marginBottom: 6 }}>
      <Grid container>
        <Grid item xs classes={{ root: classes.date }}>
          <Typography className={classes.postDate}>
            <Moment locale={language} calendar={calendarStrings}>
              {published_date}
            </Moment>
          </Typography>
        </Grid>
        <Grid item>
          <Grid
            classes={{ root: classes.views }}
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-start"
            spacing={2}
          >
            <Grid item classes={{ root: classes.legend }}>
              <Typography
                component="p"
                variant="caption"
                classes={{
                  root: classes.data,
                }}
              >
                {false && disqusConfig && !_.isEmpty(disqusConfig) && (
                  <Disqus.CommentCount
                    shortname={disqusShortname}
                    config={disqusConfig}
                  >
                    <span aria-label="Comments" role="img">
                      💬
                    </span>
                  </Disqus.CommentCount>
                )}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                component="p"
                variant="caption"
                classes={{
                  root: classes.data,
                }}
              >
                {views ? views : 0}{" "}
                <span aria-label="Views" role="img">
                  👁️
                </span>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(PublishDate);
