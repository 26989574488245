import React from "react";
import { useHistory } from "react-router-dom";
import { ButtonBase, Grid, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import { useTrans } from "../../../../context/Trans/translationContext";
import Users from "../../../../components/users";
//import Entities from "../../../../components/entities";
import styles from "../styles";

const Network: React.FC<{ classes: any }> = ({ classes }) => {
  let history = useHistory();
  const { language, RouteTrans } = useTrans();

  return (
    <Grid item classes={{ root: classes.wrapper_network }}>
      <ButtonBase
        onClick={() =>
          history.push(
            `/${language}/${RouteTrans["network"][language]}/${RouteTrans["directory"][language]}`
          )
        }
      >
        <Typography variant="h6" classes={{ root: classes.menu_item }}>
          Network
        </Typography>
      </ButtonBase>
      <Users wRow={3} />
      {/* <Entities wRow={6} /> */}
    </Grid>
  );
};

export default withStyles(styles)(Network);
