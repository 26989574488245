import React from "react";
import { Grid, TextField, Theme } from "@mui/material";
import { createStyles, withStyles } from "@mui/styles";
//import Submit from "../Commons/submit";
//import PostLoader from "../../../Posts/Post/Helpers/postLoader";

//var _ = require("lodash");

const styles = (theme: Theme) =>
  createStyles({
    body: {
      flex: 1,
    },
    formField: {
      width: "100%",
      "& input": {
        color: theme.palette.fonts.focus,
      },
    },
  });

// There is not a need for a "link" type of post, essential it is an article
interface AttachmentTextFieldProps {
  classes: any;
  helperText: string;
  onBlurFunction: () => void;
  onChangeFunction: () => void;
  onFocusFunction: () => void;
  info: string;
  sourceLabel: string;
  stateModifier: string;
}

const AttachmentTextField: React.FC<AttachmentTextFieldProps> = ({
  classes,
  helperText,
  info,
  onBlurFunction,
  onChangeFunction,
  onFocusFunction,
  sourceLabel,
}) => {
  console.log("the info", info);
  return (
    <Grid item className={classes.body}>
      <TextField
        id="link-url"
        variant="outlined"
        label={sourceLabel}
        value={info}
        helperText={helperText}
        classes={{ root: classes.formField }}
        margin="normal"
        //  onFocus={onFocusFunction}
        //  onBlur={onBlurFunction}
        onChange={onChangeFunction}
      />
    </Grid>
  );
};

export default withStyles(styles)(AttachmentTextField);
