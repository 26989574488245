import React, { useState } from "react";
import { withStyles } from "@mui/styles";
import { PhotoSwipe } from "react-photoswipe";
import Gallery from "react-grid-gallery";
import "react-photoswipe/lib/photoswipe.css";

const styles = theme => ({});

const PhotoAlbum = props => {
  const { photoalbum } = props;
  const { photos } = photoalbum;
  const [open, setOpen] = useState({ status: false, slide: 0 });

  const images = photos => {
    let photoCollection = [];
    photos.forEach(photo => {
      const { height, name, source, title, width } = photo;
      const thumbnail = `https://res.cloudinary.com/defendhaiti/image/upload/w_220/v1580081674/${name}`;
      const photoObj = {
        thumbnailHeight: (height * 220) / width,
        src: source,
        thumbnail,
        caption: title,
        thumbnailWidth: 220,
      };
      photoCollection.push(photoObj);
    });
    return photoCollection;
  };

  const items = photos => {
    let photoCollection = [];
    photos.forEach(photo => {
      const { height, name, title, width } = photo;
      const src = `https://res.cloudinary.com/defendhaiti/image/upload/w_700/v1580081674/${name}`;
      const photoObj = {
        h: (height * 700) / width,
        src,
        title,
        w: 700,
      };
      photoCollection.push(photoObj);
    });
    return photoCollection;
  };

  const handleClose = () => {
    setOpen({ ...open, status: false });
  };

  const handleOpen = slide => {
    setOpen({ status: true, slide });
  };

  console.log("The array", items);
  return (
    <React.Fragment>
      <Gallery
        enableImageSelection={false}
        enableLightBox={false}
        images={images(photos)}
        margin={4}
        onClickThumbnail={slide => handleOpen(slide)}
        rowHeight={110}
      />
      <PhotoSwipe
        isOpen={open.status}
        items={items(photos)}
        options={{ index: open.slide }}
        onClose={handleClose}
      />
    </React.Fragment>
  );
};

export default withStyles(styles)(PhotoAlbum);
