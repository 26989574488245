import React from "react";
import { Theme } from "@mui/material";
import { createStyles, withStyles } from "@mui/styles";
import Acrobat from "./media/document";
import FacebookPost from "./media/facebookpost";
import FacebookVideo from "./media/facebookvideo";
import Instagram from "./media/instagram";
import LocalAudio from "./media/localaudio";
import LocalPhoto from "./media/localphoto";
import LocalVideo from "./media/localvideo";
import PhotoAlbum from "./media/localphotoalbum";
import Soundcloud from "./media/soundcloud";
import Twitter from "./media/twitter";
import Youtube from "./media/youtube";
import YouTubePlaylistPlayer from "./media/youtubePlaylist";
import Vimeo from "./media/vimeo";
import { Attachment } from "../../context/types";

const styles = (theme: Theme) =>
  createStyles({
    cover: {
      margin: "8px 8px 24px",
      width: "100%",
    },
    media: {
      background: theme.palette.secondary.main,
      boxShadow: "0 4px 12px rgba(4, 4, 0, 0.36)",
      color: "white",
      [theme.breakpoints.down("sm")]: {
        minHeight: 200,
      },
      [theme.breakpoints.up("sm")]: {
        minHeight: 275,
      },
      padding: 0,
      position: "relative",
      width: "100%",
      "& span": {
        color: "white",
      },
    },
  });

const Feature: React.FC<{ classes: any; attachment: Attachment }> = ({
  classes,
  attachment,
}) => {
  const attachmentTypes: { [key: string]: any } = {
    document: <Acrobat document={attachment} />,
    facebookpost: <FacebookPost facebookpost={attachment} />,
    facebookvideo: <FacebookVideo facebookvideo={attachment} />,
    localaudio: <LocalAudio localaudio={attachment} />,
    localphoto: <LocalPhoto localphoto={attachment} />,
    localvideo: <LocalVideo localvideo={attachment} />,
    photoalbum: <PhotoAlbum photoalbum={attachment} />,
    soundcloud: <Soundcloud soundcloud={attachment} />,
    instagram: <Instagram instagram={attachment} />,
    twitter: <Twitter twitter={attachment} />,
    vimeo: <Vimeo vimeo={attachment} />,
    youtube: <Youtube youtube={attachment} />,
    youtubeplaylist: <YouTubePlaylistPlayer youtubeplaylist={attachment} />,
  };

  return (
    <div className={classes.cover}>{attachmentTypes[attachment.type]}</div>
  );
};

//<External info={thisPost.purpose} version={thisPost.version} />
export default withStyles(styles)(Feature);
