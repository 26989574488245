import React from "react";
import { render } from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import { createHttpLink } from "apollo-link-http";
import { StyledEngineProvider } from "@mui/material/styles";
import { CloudinaryContext } from "cloudinary-react";
import Application from "./application";
import FoundationContextProvider from "./application/context/foundationContext";
import UserContextProvider from "./application/context/User/userContext";
import theme from "./theme";
import * as serviceWorker from "./serviceWorker";
import "./styles/index.css";
import {
  createGenerateClassName,
  ThemeProvider as MuiThemeProvider,
} from "@mui/styles";
import { ThemeProvider } from "@emotion/react";

require("dotenv").config();

const {
  REACT_APP_AUTH0_CLIENT_ID,
  REACT_APP_AUTH0_BASE_URL,
  REACT_APP_CLOUDINARY_CLOUDNAME,
} = process.env;

const uri = `${window.location.origin}/api/graphql`;

const link = new createHttpLink({
  uri,
  credentials: "include",
});

const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
});

const generateClassName = createGenerateClassName({
  disableGlobal: true,
  productionPrefix: "sentinel",
});

function Root() {
  // useEffect(() => {
  //   const jssStyles = document.querySelector("#jss-server-side");
  //   if (jssStyles) {
  //     jssStyles.parentElement.removeChild(jssStyles);
  //   }
  // }, []);

  return (
    <StyledEngineProvider generateClassName={generateClassName} injectFirst>
      <MuiThemeProvider theme={theme}>
        <ThemeProvider theme={theme}>
          <Auth0Provider
            domain={REACT_APP_AUTH0_BASE_URL}
            clientId={REACT_APP_AUTH0_CLIENT_ID}
            redirectUri={window.location.origin}
            useRefreshTokens
            cacheLocation="localstorage"
          >
            <ApolloProvider client={client}>
              <CloudinaryContext cloudName={REACT_APP_CLOUDINARY_CLOUDNAME}>
                <Router>
                  <UserContextProvider>
                    <FoundationContextProvider client={client}>
                      <Application />
                    </FoundationContextProvider>
                  </UserContextProvider>
                </Router>
              </CloudinaryContext>
            </ApolloProvider>
          </Auth0Provider>
        </ThemeProvider>
      </MuiThemeProvider>
    </StyledEngineProvider>
  );
}

const root = document.getElementById("root");

render(<Root />, root);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
