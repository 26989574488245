import React from "react";
import ReactPlayer from "react-player";
import { Paper } from "@mui/material";
import { withStyles } from "@mui/styles";
const styles = theme => ({
  attachment_inner: {},
  button: { width: "100%", zIndex: 1 },
  container: {
    position: "relative",
  },
  icon: {
    background: "rgba(0,0,0,.3)",
    borderRadius: 2,
    bottom: theme.spacing(1),
    color: "white",
    fontSize: "1.3rem",
    padding: 2,
    position: "absolute",
    right: theme.spacing(1),
    zIndex: 2,
  },
  image: {
    borderRadius: theme.spacing(0.5),
    width: "100%",
  },
  media: {
    overflow: "hidden",
    paddingTop: "56.25%",
    position: "relative",
  },
  paper: {
    boxShadow: theme.shadows[8],
  },
  player: {
    height: "100%",
    left: 0,
    position: "absolute",
    top: 0,
    width: "100%",
  },
});

const Vimeo = props => {
  const { classes, vimeo } = props;
  const { autoPlay, name } = vimeo;
  return (
    <Paper className={classes.paper}>
      <div className={classes.attachment_inner}>
        <div className={classes.media}>
          <ReactPlayer
            className={classes.player}
            config={{
              youtube: {
                playerVars: {
                  loop: 1,
                  playlist: "https://vimeo.com/" + name,
                },
              },
            }}
            url={"https://vimeo.com/" + name}
            width="100%"
            height="100%"
            controls={true}
            playing={autoPlay}
          />
        </div>
      </div>
    </Paper>
  );
};

export default withStyles(styles)(Vimeo);
