import React from "react";
import { Image, Transformation } from "cloudinary-react";
import { withStyles } from "@mui/styles";

const styles = () => ({
  cover_img: {
    width: "100%",
  },
});

const CloudinaryCover = ({ classes, name }) => {
  return (
    <Image className={classes.cover_img} publicId={name}>
      <Transformation width="645" crop="scale" />
    </Image>
  );
};

export default withStyles(styles)(CloudinaryCover);
