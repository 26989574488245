import React from "react";
import { FormattedMessage } from "react-intl";
import {
  OutlinedInput,
  InputLabel,
  FormControl,
  Select,
  Theme,
} from "@mui/material";
import { createStyles, withStyles } from "@mui/styles";
import ReactCountryFlag from "react-country-flag";
import MenuItem from "@mui/material/MenuItem";
import { useNewPost } from "../../../../../../context/Post/newPostContext";
//import PostLoader from "../../../Posts/Post/Helpers/postLoader";
// import { useAuth } from "../../../../context/Auth/authContext";
// import { getAllWritersQuery } from "../../Helpers/adminQueries";
//var _ = require("lodash");
const styles = (theme: Theme) =>
  createStyles({
    flag: {
      height: "1em",
      width: "1em",
      marginRight: theme.spacing(1),
    },
    formField: {
      width: "100%",
      marginBottom: theme.spacing(1.5),
      textAlign: "right",
    },
  });

const SelectLanguage: React.FC<{ classes: any }> = ({ classes }) => {
  const { handlePostChange, newPost } = useNewPost();
  const { language } = newPost;
  // const langTrans = {
  //   en: "Language",
  //   fr: "Langue",
  //   ht: "Langaj",
  // };
  const languages = [
    {
      value: "ht",
      name: <FormattedMessage id="Haitian Creole" />,
      flag: (
        <ReactCountryFlag
          countryCode="HT"
          svg
          className={classes.flag}
          title="Haiti"
        />
      ),
    },
    {
      value: "fr",
      name: <FormattedMessage id="French" />,
      flag: (
        <ReactCountryFlag
          countryCode="FR"
          svg
          className={classes.flag}
          title="France"
        />
      ),
    },
    {
      value: "en",
      name: <FormattedMessage id="English" />,
      flag: (
        <ReactCountryFlag
          countryCode="US"
          svg
          className={classes.flag}
          title="USA"
        />
      ),
    },
    /* {
      value: "es",
      name: "Spanish",
      flag: (
        <ReactCountryFlag
          countryCode="DO"
          svg
          className={classes.flag}
          title="DR"
        />
      ),
    },
    {
      value: "po",
      name: "Portuguese",
      flag: (
        <ReactCountryFlag
          countryCode="BR"
          svg
          className={classes.flag}
          title="Brazil"
        />
      ),
    },*/
  ];

  const listLanguages = (languagesCollection: any[]) => {
    return languagesCollection.map(language => {
      const { flag, name, value } = language;
      return (
        <MenuItem key={value} value={value}>
          {flag}
          {name}
        </MenuItem>
      );
    });
  };

  const handleChange = (event: any) => {
    handlePostChange("language", event.target.value);
  };

  return (
    <FormControl classes={{ root: classes.formField }} variant="outlined">
      <InputLabel id="post-language-select">
        <FormattedMessage id="Language" />
      </InputLabel>
      <Select
        value={language ? language : "en"}
        labelId="post-language-select"
        onChange={handleChange}
        input={
          <OutlinedInput
            name="language"
            label={<FormattedMessage id="Language" />}
          />
        }
      >
        {listLanguages(languages)}
      </Select>
    </FormControl>
  );
};

export default withStyles(styles, { withTheme: true })(SelectLanguage);
