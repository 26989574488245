import React from "react";
import { FacebookProvider, EmbeddedPost } from "react-facebook";
import { Paper } from "@mui/material";
import { withStyles } from "@mui/styles";
//import ReactHtmlParser from "react-html-parser";

const styles = theme => ({
  attachment_inner: {},
  paper: {
    boxShadow: theme.shadows[8],
    minWidth: "100%",
  },
});

const FacebookPost = props => {
  const { facebookpost, classes } = props;
  const { source } = facebookpost;

  return (
    <Paper className={classes.paper}>
      <div className={classes.attachment_inner}>
        {" "}
        <FacebookProvider appId="1805217399708228">
          <EmbeddedPost href={source} />
        </FacebookProvider>
      </div>
    </Paper>
  );
};

/*
   <FacebookProvider appId="1805217399708228">
          <EmbeddedPost href={source} />
        </FacebookProvider>
*/

export default withStyles(styles)(FacebookPost);
