const ht_config = {
  months: "janvye_fevriye_mas_avril_me_jen_jiyè_out_septanm_oktòb_novanm_desanm".split(
    "_"
  ),
  monthsShort: "janv._févr._mas_avr._me._jen._jiyè._out_sept._okt._nov._dés.".split(
    "_"
  ),
  monthsParseExact: true,
  weekdays: "dimanch_lendi_madi_mèkredi_jedi_vandredi_samdi".split("_"),
  weekdaysShort: "dim._len._mad._mèk._jed._van._sam.".split("_"),
  weekdaysMin: "di_le_ma_mè_je_va_sa".split("_"),
  weekdaysParseExact: true,
  longDateFormat: {
    LT: "h:mm a",
    LTS: "HH:mm:ss",
    L: "DD/MM/YYYY",
    LL: "D MMMM YYYY",
    LLL: "D MMMM YYYY HH:mm",
    LLLL: "dddd D MMMM YYYY h:mm a",
  },
  calendar: {
    sameDay: "[jodi a] LT",
    nextDay: "[demen a] LT",
    nextWeek: "dddd [a] LT",
    lastDay: "[yè a] LT",
    lastWeek: "dddd [dènye a] LT",
    sameElse: "LLLL",
  },
  relativeTime: {
    future: "dans %s",
    past: "il y a %s",
    s: "quelques secondes",
    ss: "%d secondes",
    m: "une minute",
    mm: "%d minutes",
    h: "une heure",
    hh: "%d heures",
    d: "un jour",
    dd: "%d jours",
    M: "un mois",
    MM: "%d mois",
    y: "un an",
    yy: "%d ans",
  },
  dayOfMonthOrdinalParse: /\d{1,2}(er|)/,
  ordinal: function (number, period) {
    switch (period) {
      // TODO: Return 'e' when day of month > 1. Move this case inside
      // block for masculine words below.
      // See https://github.com/moment/moment/issues/3375
      case "D":
        return number + (number === 1 ? "ye" : "yèm");

      // Words with masculine grammatical gender: mois, trimestre, jour
      default:
      case "M":
      case "Q":
      case "DDD":
      case "d":
        return number + (number === 1 ? "ye" : "yèm");

      // Words with feminine grammatical gender: semaine
      case "w":
      case "W":
        return number + (number === 1 ? "ye" : "yèm");
    }
  },
  week: {
    dow: 1, // Monday is the first day of the week.
    doy: 4, // The week that contains Jan 4th is the first week of the year.
  },
};
export default ht_config;
