import React from "react";
import { Grid, Typography } from "@mui/material";
import { createStyles, withStyles } from "@mui/styles";

//import { Typography } from '@mui/material';
//import { withStyles } from '@material-ui/styles';

const style = () =>
  createStyles({
    page_slogan: {
      fontSize: ".9rem",
      fontStyle: "italic",
      textAlign: "right",
    },
    page_slogan_author: {
      fontSize: ".67rem",
      textAlign: "right",
    },
    page_title: {
      fontSize: "2rem",
    },
    section_title: {
      fontSize: "1.6rem",
    },
    wrapper: {
      padding: 24,
    },
  });

const About: React.FC<{ classes: any }> = ({ classes }) => {
  return (
    <div className={classes.wrapper}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Grid container direction="row" alignItems="center" spacing={3}>
            <Grid item>
              <Typography classes={{ h1: classes.page_title }} variant="h1">
                About
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography classes={{ root: classes.page_slogan }} variant="h1">
                "We have dared to be free."
              </Typography>
              <Typography
                classes={{ root: classes.page_slogan_author }}
                variant="h5"
              >
                Jean Jacques Dessalines
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography classes={{}} variant="body1">
            Haiti's 1801 Constitution, its first, provided for all men,
            especially those victims of the transatlantic slave trade,
            citizenship and protection if they so reached its shores.
          </Typography>
          <br />
          <Typography classes={{}} variant="body1">
            In honor of this momentous and courageous declaration, The Haiti
            Sentinel will be a place for news, not just of the Haitian
            experience, but the experience of all the descendants of the
            transatlantic slave trade.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography classes={{ h2: classes.section_title }} variant="h2">
            News
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default withStyles(style)(About);
