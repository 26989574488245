import React, { useState } from "react";
import {
  FaFacebook,
  FaInstagram,
  FaFilePdf,
  FaSoundcloud,
  FaTwitter,
  FaImage,
  FaImages,
  FaVideo,
  FaMicrophone,
  FaTrash,
  FaVimeo,
  FaYoutube,
} from "react-icons/fa";
import { Button, Grid, Theme, Typography } from "@mui/material";
import { createStyles, withStyles } from "@mui/styles";
import SelectedFile from "./_picker/selectedFile";
import PickerForm from "./_picker/index_new";
import TextFieldAttachmentInput from "./textfield_input";
import DeleteFeature from "./deleteFeature";
import Shaper from "./shaper";
import Acrobat from "../../../../../../components/Feature/media/document";
import LocalAudio from "../../../../../../components/Feature/media/localaudio";
import LocalPhoto from "../../../../../../components/Feature/media/localphoto";
import LocalVideo from "../../../../../../components/Feature/media/localvideo";
import PhotoAlbum from "../../../../../../components/Feature/media/localphotoalbum";
import Soundcloud from "../../../../../../components/Feature/media/soundcloud";
import Instagram from "../../../../../../components/Feature/media/instagram";
import Twitter from "../../../../../../components/Feature/media/twitter";
import YouTube from "../../../../../../components/Feature/media/youtube";
import FacebookPost from "../../../../../../components/Feature/media/facebookpost";
import FacebookVideo from "../../../../../../components/Feature/media/facebookvideo";
import Vimeo from "../../../../../../components/Feature/media/vimeo";
import { useManagers } from "../../../../../../context/Post/managersContext";
import { useFeature } from "../../../../../../context/Post/featureContext";

const styles = (theme: Theme) =>
  createStyles({
    form: {
      [theme.breakpoints.up("sm")]: {
        minHeight: "calc(100vh - 114px)",
      },
      [theme.breakpoints.down("sm")]: {
        minHeight: "calc(100vh - 106px)",
      },
    },
    formField: {
      width: "100%",
    },
    body: {
      flex: 1,
    },
    button: {
      borderColor: theme.palette.color.red,
      color: theme.palette.color.red,
      width: "100%",
    },
    buttonTextField: {
      maxWidth: 320,
      width: "100%",
    },
    create_holder: {
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
    },
    handle: {
      flex: "none",
      width: 120,
    },
    icon: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    textButton: {
      textShadow: "0px 2px 3px rgba(0,0,0,0.4)",
    },
    acrobat: {
      color: theme.palette.icons.acrobat,
    },
    facebook: {
      color: theme.palette.icons.facebook,
    },
    instagram: {
      color: theme.palette.icons.instagram,
    },
    soundcloud: {
      color: theme.palette.icons.soundcloud,
    },
    twitter: {
      color: theme.palette.icons.twitter,
    },
    vimeo: {
      color: theme.palette.icons.vimeo,
    },
    youtube: {
      color: theme.palette.icons.youtube,
    },
  });

const FeaturedMedia: React.FC<{ classes: any }> = ({ classes }) => {
  const { managers, toggleManager } = useManagers();
  const {
    deleteFeature,
    feature,
    handleDeleteFeature,
    handleFeatureChange,
    loadFeature,
    removeAlbumPhoto,
    restoreAlbumPhoto,
    restoreFeature,
    updateFeature,
    updateAlbumPhotoTitle,
  } = useFeature();

  const [featureState, setFeatureState] = useState(feature);
  const [editing, setEditing] = useState(true);
  false && console.log(editing);
  const { featureManager } = managers;
  const { postType, shape, template } = feature;
  const type = postType[template] || "article";

  // useEffect(() => {
  //   if (!_.isEqual(featureState, feature)) {
  //     setFeatureState(feature);
  //   }
  // }, [feature, featureState]);

  //console.log("feature state", featureState);

  const sourceCheck = () => {
    const exist = featureState.source && featureState.source !== "";
    return exist ? exist : false;
  };
  const nameCheck = (template?: string) => {
    if (template === "youtube") {
      return feature.name && feature.name !== "";
    } else {
      return feature.name && feature.name !== "";
    }
  };
  const albumCheck = () => {
    const exist = featureState.photos && featureState.photos.length !== 0;
    return exist;
  };

  // const showClear = () => {
  //   if (formType[type]) {
  //     const { form } = formType[type];
  //     if (form === "button") {
  //       return !formType[type].showDemo;
  //     } else if (form === "textfield") {
  //       return editing;
  //     }
  //   }
  // };

  const handleShapeChange = (shape: string) => {
    setFeatureState({ ...featureState, shape });
    handleFeatureChange("shape", shape);
  };

  const handleAttachmentSourceChange = () => (event: any) => {
    setFeatureState({
      ...featureState,
      name: event.target.value,
      source: event.target.value,
    });
  };

  const handleInstagramAttachmentIDChange = () => (event: any) => {
    setFeatureState({
      ...featureState,
      name: event.target.value,
      source: "https://instagram.com/p/" + event.target.value,
    });
  };

  const handleVimeoAttachmentIDChange = () => (event: any) => {
    setFeatureState({
      ...featureState,
      name: event.target.value,
      source: "https://vimeo.com/" + event.target.value,
    });
  };

  const handleYoutubeAttachmentIDChange = (event: any) => {
    setFeatureState({
      ...featureState,
      name: event.target.value,
      source: "https://youtu.be/" + event.target.value,
    });
  };

  const blurPush = () => {
    loadFeature({ ...featureState, postType, type });
    setEditing(false);
  };

  const submitFile = (changes: any) => {
    const newFeatureState = { ...featureState, ...changes };
    setFeatureState(newFeatureState);
    updateFeature(newFeatureState);
    toggleManager("featureManager");
  };

  // const buttonTextField = () => {
  //   if (type) {
  //     if (formType[type].form === "textfield") {
  //       const {
  //         helperText,
  //         info,
  //         onBlurFunction,
  //         onChangeFunction,
  //         onFocusFunction,
  //         sourceLabel,
  //         stateModifier,
  //       } = formType[type];
  //       return (
  //         <GeneralAttachment
  //           onChangeFunction={onChangeFunction}
  //           stateModifier={stateModifier}
  //           helperText={helperText}
  //           info={info} // Value of Concern is name
  //           onBlurFunction={onBlurFunction}
  //           onFocusFunction={onFocusFunction}
  //           sourceLabel={sourceLabel}
  //         />
  //       );
  //     } else if (formType[type].form === "button") {
  //       const { activeButtonText, buttonText, icon, showDemo } = formType[type];
  //       return (
  //         <Grid item className={classes.body}>
  //           <Button
  //             variant="outlined"
  //             classes={{ root: classes.button }}
  //             //onClick={() => toggleManager("featureManager")}
  //             onClick={() => console.log("upload pdf clicked")}
  //           >
  //             {showDemo ? activeButtonText : buttonText}
  //             {icon}
  //           </Button>
  //         </Grid>
  //       );
  //     }
  //   } else {
  //     return <PostLoader />;
  //   }
  // };

  const formType: { [key: string]: any } = {
    document: {
      button: true,
      buttonText: "Upload PDF",
      activeButtonText: "Change PDF",
      icon: <FaFilePdf className={classes.icon} />,
      header: "PDF Document",
      preset: "SentinelDocument",
      shape: "portrait",
      showShape: false,
      showDemo: sourceCheck(),
    },
    facebookpost: {
      onChangeFunction: handleAttachmentSourceChange,
      state_modifier: "source",
      helperText: "Copy & Paste Full Facebook Post URL (link)",
      helperTextBrief: "Facebook",
      info: featureState.source,
      onBlurFunction: blurPush,
      onFocusFunction: () => setEditing(true),
      sourceLabel: "Post URL",
      showShape: false,
      showDemo: sourceCheck(),
    },
    facebookvideo: {
      onChangeFunction: handleAttachmentSourceChange,
      state_modifier: "source",
      helperText: "Copy & Paste Full Facebook Video URL (link)",
      helperTextBrief: "Facebook",
      info: featureState.source,
      onBlurFunction: blurPush,
      onFocusFunction: () => setEditing(true),
      sourceLabel: "Video URL",
      showDemo: sourceCheck(),
      showShape: true,
    },
    instagram: {
      onChangeFunction: handleInstagramAttachmentIDChange,
      state_modifier: "name",
      helperText: "Copy & Paste Instagram Post ID",
      helperTextBrief: "Instagram",
      info: featureState.name,
      onBlurFunction: blurPush,
      onFocusFunction: () => setEditing(true),
      sourceLabel: "Post ID",
      showDemo: nameCheck(),
      showShape: false,
    },
    localaudio: {
      button: true,
      buttonText: "Upload Audio",
      activeButtonText: "Change Audio",
      icon: <FaMicrophone className={classes.icon} />,
      header: "Audio",
      preset: "SentinelAudio",
      shape: "landscape",
      showDemo: sourceCheck(),
      showShape: false,
    },
    localphoto: {
      button: true,
      buttonText: "Upload Photo",
      activeButtonText: "Change Photo",
      icon: <FaImage className={classes.icon} />,
      header: "Photo",
      preset: "SentinelPhoto",
      shape: "portrait",
      showDemo: sourceCheck(),
      showShape: false,
    },
    localvideo: {
      button: true,
      buttonText: "Upload Video",
      activeButtonText: "Change Video",
      icon: <FaVideo className={classes.icon} />,
      header: "Video",
      preset: "SentinelVideo",
      shape: "landscape",
      showDemo: sourceCheck(),
      showShape: true,
    },
    photoalbum: {
      button: true,
      activeButtonText: "Add/Edit/Delete Photos",
      buttonText: "Upload Photos",
      header: "Photo Album",
      preset: "SentinelAlbum",
      shape: "landscape",
      showDemo: albumCheck(),
      showShape: false,
      showUpload: true,
    },
    soundcloud: {
      onChangeFunction: handleAttachmentSourceChange,
      state_modifier: "source",
      helperText: "Copy & Paste Full Soundcloud Audio URL (link)",
      helperTextBrief: "Soundcloud",
      info: featureState.source,
      onBlurFunction: blurPush,
      onFocusFunction: () => setEditing(true),
      sourceLabel: "Audio URL",
      showDemo: sourceCheck(),
      showShape: false,
    },
    twitter: {
      onChangeFunction: handleAttachmentSourceChange,
      state_modifier: "source",
      helperText: "Copy & Paste Full Twitter Tweet URL (link)",
      helperTextBrief: "Twitter",
      info: featureState.source,
      onBlurFunction: blurPush,
      onFocusFunction: () => setEditing(true),
      sourceLabel: "Tweet URL",
      showDemo: sourceCheck(),
      showShape: false,
    },
    vimeo: {
      onChangeFunction: handleVimeoAttachmentIDChange,
      state_modifier: "name",
      helperText: "Copy & Paste Vimeo Video ID",
      helperTextBrief: "Vimeo",
      info: featureState.name,
      onBlurFunction: blurPush,
      onFocusFunction: () => setEditing(true),
      sourceLabel: "Video ID",
      showDemo: nameCheck(),
      showShape: false,
    },
    youtube: {
      onChangeFunction: handleYoutubeAttachmentIDChange,
      state_modifier: "name",
      helperText: "Copy & Paste YouTube Video ID",
      helperTextBrief: "YouTube",
      info: featureState.name,
      onBlurFunction: blurPush,
      onFocusFunction: () => setEditing(true),
      sourceLabel: "Video ID",
      showDemo: nameCheck("youtube"),
      showShape: false,
    },
  };

  const createLabel: { [key: string]: any } = {
    document: (
      <Typography>
        <FaFilePdf className={classes.acrobat} /> Acrobat Document
      </Typography>
    ),
    facebookpost: (
      <Typography>
        <FaFacebook className={classes.facebook} /> Facebook Post
      </Typography>
    ),
    facebookvideo: (
      <Typography>
        <FaFacebook className={classes.facebook} /> Facebook Video
      </Typography>
    ),
    instagram: (
      <Typography>
        <FaInstagram className={classes.instagram} /> Instagram
      </Typography>
    ),
    localaudio: (
      <Typography>
        <FaMicrophone /> Upload Audio
      </Typography>
    ),
    localphoto: (
      <Typography>
        <FaImage /> Upload Photo
      </Typography>
    ),
    localvideo: (
      <Typography>
        <FaVideo /> Upload Video
      </Typography>
    ),
    photoalbum: (
      <Typography>
        <FaImages /> Upload Photo Album
      </Typography>
    ),
    soundcloud: (
      <Typography>
        <FaSoundcloud className={classes.soundcloud} /> Soundcloud
      </Typography>
    ),
    twitter: (
      <Typography>
        <FaTwitter className={classes.twitter} /> Twitter
      </Typography>
    ),
    vimeo: (
      <Typography>
        <FaVimeo className={classes.vimeo} /> Vimeo
      </Typography>
    ),
    youtube: (
      <Typography>
        <FaYoutube className={classes.youtube} /> YouTube
      </Typography>
    ),
  };

  const createType: { [key: string]: any } = {
    document: <Acrobat document={featureState} />,
    facebookpost: <FacebookPost facebookpost={featureState} />,
    facebookvideo: <FacebookVideo start={false} facebookvideo={featureState} />,
    instagram: <Instagram instagram={featureState} />,
    localaudio: <LocalAudio localaudio={featureState} />,
    localphoto: <LocalPhoto localphoto={featureState} />,
    localvideo: <LocalVideo localvideo={featureState} />,
    photoalbum: <PhotoAlbum photoalbum={featureState} />,
    soundcloud: <Soundcloud soundcloud={featureState} />,
    twitter: <Twitter twitter={featureState} />,
    vimeo: <Vimeo vimeo={featureState} />,
    youtube: <YouTube start={false} youtube={featureState} />,
  };

  // function OldForm() {
  //   return (
  //     <Grid item xs={12}>
  //       {showClear() ? (
  //         <Grid
  //           container
  //           direction="row-reverse"
  //           justifyContent="center"
  //           alignItems="center"
  //         >
  //           <Grid
  //             item
  //             classes={{ root: classes.buttonTextField }}
  //             //style={{ marginBottom: -8 }}
  //           >
  //             {buttonTextField()}
  //           </Grid>
  //         </Grid>
  //       ) : (
  //         <Grid container spacing={2}>
  //           {shape && formType[type].showShape && (
  //             <Grid item xs={12} sm>
  //               <Shaper shape={shape} handleShapeChange={handleShapeChange} />
  //             </Grid>
  //           )}
  //           <Grid item xs={12} sm>
  //             {buttonTextField()}
  //           </Grid>
  //           <Grid item xs={12} sm>
  //             <Grid
  //               container
  //               direction="row"
  //               justifyContent="center"
  //               alignItems="center"
  //               style={{ height: "100%" }}
  //             >
  //               <Grid item>
  //                 <Button
  //                   variant="outlined"
  //                   classes={{ root: classes.button }}
  //                   size="small"
  //                   onClick={() => handleDeleteFeature(postType, template)}
  //                 >
  //                   <FaTrash className={classes.icon} /> Remove / Clear
  //                 </Button>
  //               </Grid>
  //             </Grid>
  //           </Grid>
  //         </Grid>
  //       )}
  //     </Grid>
  //   );
  // }

  const showSelected = formType[type] && formType[type].showDemo;

  function SelectedMedia() {
    return (
      <>
        {shape && formType[type].showShape && (
          <Grid item xs={12} sm style={{ marginTop: 20, padding: 4 }}>
            <Shaper shape={shape} handleShapeChange={handleShapeChange} />
          </Grid>
        )}
        <Grid item classes={{ root: classes.create_holder }}>
          {formType[type].showUpload ? (
            <SelectedFile
              attachment={feature}
              // changeOrientation={changeOrientation}
              removeAlbumPhoto={removeAlbumPhoto}
              restoreAlbumPhoto={restoreAlbumPhoto}
              updateAlbumPhotoTitle={updateAlbumPhotoTitle}
            />
          ) : (
            createType[type]
          )}
        </Grid>
      </>
    );
  }

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={3}
      style={{ paddingBottom: 32 }}
    >
      <Grid item>{createLabel[type]}</Grid>
      {showSelected && (
        <>
          <SelectedMedia />
          <Grid item xs={12} sm>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              style={{ height: "100%" }}
            >
              <Grid item>
                <Button
                  variant="outlined"
                  classes={{ root: classes.button, text: classes.button }}
                  size="small"
                  onClick={() => handleDeleteFeature(postType, template)}
                >
                  <FaTrash className={classes.icon} /> Remove / Clear
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
      {formType[type]?.button ? (
        <PickerForm
          attachmentObject={feature}
          closeManager={() => toggleManager("featureManager")}
          header={formType[type].header}
          isFeature
          open={featureManager}
          preset={formType[type].preset}
          submitFile={submitFile}
        />
      ) : (
        <TextFieldAttachmentInput
          onChangeFunction={formType[type].onChangeFunction}
          state_modifier={formType[type].state_modifier}
          helperText={formType[type].helperText}
          info={formType[type].info || ""} // Value of Concern is name
          onBlurFunction={blurPush}
          onFocusFunction={formType[type].onFocusFunction}
          sourceLabel={formType[type].sourceLabel}
        />
      )}
      <DeleteFeature
        deleteFeature={deleteFeature}
        restoreItem={restoreFeature}
      />
    </Grid>
  );
};

export default withStyles(styles)(FeaturedMedia);
