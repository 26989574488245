import React, { useEffect } from "react";
//import isNode from "detect-node";
//import { ApolloConsumer } from "react-apollo";
// import { CssBaseline } from "@mui/material";

import { HelmetProvider } from "react-helmet-async";
import { IntlProvider } from "react-intl";
import { Redirect, Route, Switch } from "react-router-dom";
import MainLayout from "./main_layout";
// import Main from "./components/main";
// import AuthContextProvider, { AuthContext } from "./context/Auth/authContext";
// import config from "./context/Auth/auth_config";
import TranslationContextProvider from "./context/Trans/translationContext";
// import ScrollContextProvider from "./context/scrollContext";

// import CurrentPageProvider from "./context/currentPageContext";
// import CurrentPostProvider from "./context/currentPostContext";
import FeatureContextProvider from "./context/Post/featureContext";
import OldPostContextProvider from "./context/Post/oldPostContext";
import NewPostContextProvider from "./context/Post/newPostContext";

//import ManagersContextProvider from "./components/Admin/Post/ManagersContext";
// import MessagesContextProvider from "./components/Messages/Context";
// import NotificationsContextProvider from "./components/Notifications/Context";
import en_msg from "./context/Trans/messages/en.json";
import fr_msg from "./context/Trans/messages/fr.json";
import ht_msg from "./context/Trans/messages/ht.json";
import ht_config from "./context/Trans/moment/ht";
import en_config from "./context/Trans/moment/en";
import fr_config from "./context/Trans/moment/fr";
import AttachmentContextProvider from "./context/Post/attachmentContext";
import AttachmentsContextProvider from "./context/Post/attachmentsContext";

//var _ = require("lodash");
var moment = require("moment");

type SelectObjectOptions = {
  [key: string]: any;
};

const Langs: SelectObjectOptions = {
  ht: ht_config,
  en: en_config,
  fr: fr_config,
};

const Trans: SelectObjectOptions = {
  en: en_msg,
  fr: fr_msg,
  ht: ht_msg,
};

// const dashText = {
//   en: "dashboard",
//   fr: "tableau-de-bord",
//   ht: "tablodbo",
// };
const Application = () => {
  useEffect(() => {
    document.body.classList.remove("loading");
    //  document.querySelector(".loading--container").remove();
  }, []);

  return (
    <>
      <Switch>
        <Route
          path="/:pathLang(ht|en|fr)"
          render={(props: any) => {
            const { pathLang } = props.match.params;

            moment.updateLocale(pathLang, Langs[pathLang]);
            return (
              <HelmetProvider>
                <TranslationContextProvider>
                  <IntlProvider locale={pathLang} messages={Trans[pathLang]}>
                    <OldPostContextProvider>
                      <NewPostContextProvider>
                        <FeatureContextProvider>
                          <AttachmentsContextProvider>
                            <AttachmentContextProvider>
                              <MainLayout pathLang={pathLang} />
                            </AttachmentContextProvider>
                          </AttachmentsContextProvider>
                        </FeatureContextProvider>
                      </NewPostContextProvider>
                    </OldPostContextProvider>
                  </IntlProvider>
                </TranslationContextProvider>
              </HelmetProvider>
            );
          }}
        />
        <Redirect to="/en/posts" />
      </Switch>
    </>
  );
};
export default Application;
//<Main pathLang={pathLang || "en"} />
/* <AuthContextProvider
                    domain={config.domain}
                    client_id={config.clientId}
                    audience={config.audience}
                    redirect_uri={`${orig}/${pathLang}/${dashText[pathLang]}`}
                    onRedirectCallback={onRedirectCallback}
                  >
                    <AuthContext.Consumer>
                      {({ user }) => (
                         <UserContextProvider user={user}>
                          
                            <CurrentPostProvider>
                              <CurrentPageProvider>
                                
                                  <ScrollContextProvider>
                                    <ManagersContextProvider>
                                      <CssBaseline />
                                      <Main pathLang={pathLang} />
                                    </ManagersContextProvider>
                                  </ScrollContextProvider>
                               
                              </CurrentPageProvider>
                            </CurrentPostProvider>
                          
                        </UserContextProvider>
                      )}
                    </AuthContext.Consumer>
                  </AuthContextProvider> */
