import React from "react";
import { CircularProgress, Grid, Theme, Typography } from "@mui/material";
import { createStyles, withStyles } from "@mui/styles";
import SentinelLogo from "./sentinel_logo";

const styles = (theme: Theme) =>
  createStyles({
    loader_holder: {
      height: "100vh",
      left: 0,
      position: "absolute",
      top: 0,
      width: "100vw",
    },
    // message: {
    //   color: theme.palette.color.blue,
    //   fontSize: ".7rem",
    // },
    // progress: {
    //   color: theme.palette.color.blue,
    //   position: "absolute",
    // },
    // stretcher: {},
  });

const Loader: React.FC<{ classes: any; status?: any }> = ({
  classes,
  status,
}) => {
  return (
    <Grid
      container
      classes={{ root: classes.loader_holder }}
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <Grid item style={{ textAlign: "center" }}>
        <CircularProgress
          classes={{ root: classes.progress }}
          size={72}
          style={{ color: "#74a8e8", position: "absolute" }}
        />
        <SentinelLogo />
        {status && status.length > 1 && (
          <>
            <br />
            <Typography
              classes={{ root: classes.message }}
              variant="button"
              style={{ color: "#74a8e8", fontSize: ".7rem" }}
            >
              {status}
            </Typography>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(Loader);
