import React from "react";
import { TextField, Theme } from "@mui/material";
import { createStyles, withStyles } from "@mui/styles";
import Action from "../../../Controls/fieldAction";
import { useNewPost } from "../../../../../../../../context/Post/newPostContext";

const styles = (theme: Theme) =>
  createStyles({
    formField: {
      marginTop: theme.spacing(2),
      width: "100%",
      "& textarea": {
        color: theme.palette.fonts.focus,
      },
    },
  });

const DefaultLinkTitle: React.FC<{ classes: any; isEditing?: boolean }> = ({
  classes,
  isEditing,
}) => {
  const { dev, editing, handlePostChange, newPost, splitForm } = useNewPost();
  const { link } = newPost;

  const editing_language = isEditing ? editing : dev;

  function handleChanges(label: string, value: any) {
    const contentObj = newPost.link;
    const contentLinkObj = contentObj.title;
    if (contentLinkObj) {
      contentLinkObj[editing_language] = value;
    }
    handlePostChange(label, contentObj);
  }

  const hide = !isEditing && splitForm;

  return (
    <>
      <TextField
        key="article-link-title-input"
        disabled={!link.enabled}
        variant="outlined"
        label="Link Text"
        value={link.title?.[editing_language]}
        classes={{ root: classes.formField }}
        onChange={(e: any) => handleChanges("link", e.target.value)}
      />
      <Action hide={hide} item={"Link Text"} field={"link"} />
    </>
  );
  // }
};

export default withStyles(styles)(DefaultLinkTitle);
