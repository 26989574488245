import React from "react";
//import ReactHTMLParser from "react-html-parser";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { Grid, Theme, Typography } from "@mui/material";
import { createStyles, withStyles } from "@mui/styles";
import Social from "./social";
import { useTrans } from "../../context/Trans/translationContext";
import DHLogo from "./dh_logo";
import { useFoundation } from "../../context/foundationContext";
import { useAuth0 } from "@auth0/auth0-react";

var dateFormat = require("dateformat");

const styles = (theme: Theme) =>
  createStyles({
    back: {
      // backgroundColor: theme.palette.primary.default,
      // borderTop: "1px solid " + theme.palette.primary.dark,
      // boxShadow: "0px -4px 10px -1px rgba(0,0,0,0.25)",
      padding: theme.spacing(4),
      [theme.breakpoints.up("xs")]: {
        padding: "64px 12px 0", // theme.spacing(8),
      },
    },
    bottom: {
      padding: "0 8px",
      width: "100%",
      [theme.breakpoints.down("md")]: {
        paddingBottom: theme.spacing(2),
      },
    },
    brand: {
      height: "100%",
    },
    brand_holder: {
      [theme.breakpoints.down("sm")]: {
        padding: 0,
      },
    },
    copyright: {
      color: theme.palette.fonts.detail,
      //  fontFamily: "san-serif",
      fontSize: ".02rem",
      lineHeight: 1.21,
      marginRight: 8,
      textAlign: "right",
    },
    post_something: {
      fontFamily: "Gotham Bold",
      fontSize: "1.1rem",
      textTransform: "uppercase",
      "& a": {
        //  color: theme.palette.fonts.default,
      },
    },
    post_something_holder: {
      padding: "30px 0",
      textAlign: "center",
    },
    footer: {
      alignSelf: "flex-end",
      zIndex: 2,
    },
    logo: {
      borderRadius: 5,
      width: "100%",
    },
    logo_text: {
      color: theme.palette.fonts.main,
      fontSize: ".7rem",
      fontWeight: "bold",
      lineHeight: 1.3,
      textTransform: "uppercase",
    },
    menu: {
      paddingLeft: theme.spacing(2),
      paddingTop: theme.spacing(4),
    },
    rights: {
      color: theme.palette.fonts.detail,
    },
    social: {
      fontSize: "1rem",
    },
    support: {
      flex: 1,
      paddingLeft: theme.spacing(3),
    },
    title: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.fonts.main,
      maxWidth: 115,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(0.5),
      marginBottom: theme.spacing(2),
      textAlign: "right",
      textTransform: "uppercase",
    },
    facebook: {
      color: theme.palette.icons.facebook,
    },
    soundcloud: {
      color: theme.palette.icons.soundcloud,
    },
    twitter: {
      color: theme.palette.icons.twitter,
    },
    youtube: {
      color: theme.palette.icons.youtube,
    },
  });

const Footer: React.FC<{ classes: any }> = ({ classes }) => {
  const { ref_footer } = useFoundation();
  const { isAuthenticated } = useAuth0();
  const { language, RouteTrans } = useTrans();
  const now = new Date();

  // const site_name = {
  //   en: "The<br/>Haiti<br/>Sentinel",
  //   fr: "Le<br/>Sentinel<br/>d'Haïti",
  //   ht: "Sentinel<br/>Ayita a",
  // };
  return (
    <Grid item classes={{ root: classes.footer }} ref={ref_footer} xs={12}>
      <div className={classes.back}>
        <Grid container>
          {isAuthenticated && (
            <Grid
              item
              xs={12}
              classes={{ root: classes.post_something_holder }}
            >
              <Typography variant="h1" className={classes.post_something}>
                <Link to={`/${language}/${RouteTrans["about"][language]}`}>
                  <FormattedMessage
                    id="Post something"
                    defaultMessage="Post something"
                  />
                </Link>
              </Typography>
            </Grid>
          )}
          <Grid item xs={12}>
            <Grid
              container
              classes={{ root: classes.bottom }}
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              <Grid item xs>
                <Social />
              </Grid>
              <Grid item>
                <Typography
                  component="p"
                  variant="caption"
                  classes={{ root: classes.copyright }}
                >
                  <FormattedMessage id="Copyright" defaultMessage="Copyright" />
                  {" © "}
                  {dateFormat(now, "yyyy")}
                  <br />
                  {" Defend Haiti, LLC"}
                  <br />
                  {<FormattedMessage id="All Rights Reserved" />}
                </Typography>
              </Grid>
              <Grid item>
                <Link to="/">
                  <DHLogo className={classes.rights} />
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Grid>
  );
};

export default withStyles(styles)(Footer);
/*{" "}
                    |{" "}
                    <Link
                      to={`/${language}/${RouteTrans["about"][language]}/${RouteTrans["writers"][language]}`}
                    >
                      {t("Writers")}
                    </Link>{" "}
                    |{" "}
                    <Link
                      to={`/${language}/${RouteTrans["about"][language]}/${RouteTrans["patrons"][language]}`}
                    >
                      {t("Patrons")}
                    </Link> */
