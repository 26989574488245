import React from "react";
import { useHistory } from "react-router-dom";
import { MenuItem, Select, Theme } from "@mui/material";
import { createStyles, withStyles } from "@mui/styles";
import Translate from "./translate";
import { useTrans } from "../../context/Trans/translationContext";

const styles = (theme: Theme) =>
  createStyles({
    flag: {
      height: "1em",
      width: "1em",
    },
    formField: {
      padding: 0,
      textAlign: "center",
      // "& > div": { padding: 0 },
    },
    icon: {
      display: "none",
      position: "unset",
    },
    label: {
      top: -6,
      left: 13,
    },
    select: {
      height: 16,
      [theme.breakpoints.up("xs")]: {
        padding: "8px 24px 24px 8px",
      },
      [theme.breakpoints.up("sm")]: {
        padding: 16,
      },
    },
    transItem: {
      "& .trans_hide": {
        display: "none",
      },
    },
  });

const LanguageSelector: React.FC<{ classes: any }> = ({ classes }) => {
  const history = useHistory();
  const { language, paths } = useTrans();
  // instead of changing the language, we select the route
  // if fr, go to fr/.../ rest of route, but it has to translate it
  // about / a propos de nous
  // we have to create routing for each of these

  const setLanguage = () => (event: any) => {
    history.push(paths[event.target.value]);
  };

  const languages = [
    {
      value: "ht",
      name: "Kreyol",
      flag: <Translate countryCode="HT" title="Haiti" />,
    },
    {
      value: "fr",
      name: "French",
      flag: <Translate countryCode="FR" title="France" />,
    },
    {
      value: "en",
      name: "English",
      flag: <Translate countryCode="US" title="USA" />,
    },
    /*{
      value: "es-DO",
      name: "Spanish",
      flag: <Translate countryCode="DO" title="DR" />
    },
    {
      value: "pt",
      name: "Portuguese",
      flag: <Translate countryCode="BR" title="Brazil" />
    }*/
  ];

  console.log("this is active");

  const listLanguages = (languagesCollection: any[]) => {
    return languagesCollection.map((language: any) => {
      const { flag, value } = language;
      return (
        <MenuItem
          classes={{ root: classes.transItem }}
          key={value}
          value={value}
        >
          {flag}
        </MenuItem>
      );
    });
  };

  return (
    <Select
      classes={{
        select: classes.select,
        icon: classes.icon,
      }}
      disableUnderline
      value={language ? language : "ht"}
      variant="standard"
      onChange={setLanguage}
    >
      {listLanguages(languages)}
    </Select>
  );
};

export default withStyles(styles)(LanguageSelector);
