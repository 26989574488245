import React from "react";
import { useHistory } from "react-router-dom";
import ReactHTMLParser from "react-html-parser";
import { createStyles, withStyles } from "@mui/styles";
import {
  Card,
  CardContent,
  CardActionArea,
  CardMedia,
  Grid,
  Theme,
  Typography,
} from "@mui/material";
import PostStatistics from "../../../../../components/post_stats";
import Legend from "./legend";
//import ViewCtl from "./viewControl";
// import Can from "../../../../context/Auth/Can";
// import { useAuth } from "../../../../context/Auth/authContext";
//import { useUser } from "../../context/userContext";
import PostWriter from "./postWriter";

var _ = require("lodash");

const styles = (theme: Theme) =>
  createStyles({
    card: {
      maxWidth: "100%",
      background: "none",
      borderRadius: 0,
      //   borderBottom: "1px solid lightgray",
      boxShadow: "none",
      overflow: "visible",
      //padding: "0px 4px",
    },
    cover: {
      overflow: "hidden",
    },
    cardAction: {
      //   overflow: "visible",
    },
    // card_small: {
    //   "& .card_action": {
    //     padding: "8px 12px",
    //   },
    //   "& .cover": {
    //     boxShadow: theme.shadows[4],
    //   },
    //   "& .title": {
    //     paddingTop: 0,
    //     // paddingLeft: theme.spacing(2),
    //     textAlign: "left",
    //     "& h6": {
    //       fontSize: ".8rem",
    //       lineHeight: 1,
    //       textAlign: "left",
    //     },
    //   },
    //   "& .writer": {
    //     textAlign: "left",
    //   },
    //   "& .media_grid": {
    //     paddingBottom: "100%",
    //   },
    // },
    // card_medium: {
    //   "& .card_action": {
    //     padding: "24px 4px",
    //   },

    //   "& .media_grid": {
    //     paddingBottom: "52.5%",
    //   },
    //   "& .comments, .writer": {
    //     [theme.breakpoints.down("sm")]: {
    //       textAlign: "left",
    //     },
    //     [theme.breakpoints.up("sm")]: {
    //       textAlign: "center",
    //     },
    //   },
    // },
    // card_large: {
    //   "& .card_action": {
    //     padding: "24px 16px",
    //   },
    //   "& .cover": {
    //     boxShadow: "0 9px 20px -4px black",
    //   },
    //   "& .title": {
    //     fontSize: ".9rem",
    //     lineHeight: 1,
    //     paddingTop: theme.spacing(2),
    //     "& h6": {
    //       fontSize: "1.2rem",
    //       lineHeight: "1.2",
    //     },
    //   },
    //   "& .media_grid": {
    //     paddingBottom: "52.5%",
    //   },
    // },
    catHead: {
      fontSize: ".5rem",
      lineHeight: 1.21,
      margin: "20px 0",
      textAlign: "center",
    },
    divider: {
      //  backgroundColor: theme.palette.primary.default,
      // backgroundImage: theme.palette.primary.dividerRight,
    },
    media: {
      backgroundSize: "102%",
      // borderRadius: theme.spacing(0.5),
      position: "relative",
      paddingBottom: "52.5%",
    },
    legend: {
      //borderRight: "1px solid " + theme.palette.secondary.main,
      marginLeft: -5,
      // paddingRight: theme.spacing(0.75),
    },
    title_holder: {
      // padding: "0 !important",
      marginBottom: 4,
      marginTop: 4,
    },
    stats: {
      //   color: theme.palette.text.main,
      textAlign: "right",
      fontSize: ".6rem",
      lineHeight: 1,
      paddingTop: 2,
    },
    views_holder: {
      paddingBottom: 4,
    },
    writer: {
      fontSize: ".64rem",
      lineHeight: 1,
      marginLeft: -2,
      paddingBottom: 4,
    },
  });

interface CardProps {
  classes: any;
  category: any;
  division: any;
  getCover: any;
  postURL: string;
  thisPost: any;
  title: string;
}

const PostCard: React.FC<CardProps> = ({
  classes,
  category,
  division,
  getCover,
  postURL,
  thisPost,
  title,
}) => {
  // const { adminDisplay, user } = useAuth();

  const { attachments, cover, feature, organization, version, writer } =
    thisPost;

  const history = useHistory();
  // const coverExist = !_.isEmpty(cover);

  const legendAtt = feature ? _.concat(attachments, feature) : attachments;
  //console.log('postUrl', postURL)
  return (
    <Grid item xs={12} sm={4} classes={{ root: classes.card }}>
      <Card>
        <CardActionArea
          onClick={() => history.push(postURL)}
          classes={{ root: classes.cardAction }}
        >
          <CardMedia
            classes={{
              root: classes.media,
            }}
            image={getCover(cover, version)}
          ></CardMedia>

          {/* <Grid container>
          {coverExist && (
            <Grid item xs={12} style={{ marginBottom: 4, padding: 0 }}>
              <Paper classes={{ root: classes.cover }} elevation={6}>
                <CardMedia
                  classes={{
                    root: classes.media,
                  }}
                  image={getCover()}
                ></CardMedia>
              </Paper>
            </Grid>
          )} */}
          <CardContent classes={{ root: classes.title_holder }}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h3">{ReactHTMLParser(title)}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography classes={{ root: classes.catHead }} variant="h6">
                  {category}
                  <br />
                  <span> {division}</span>
                </Typography>
              </Grid>
              <Grid item xs={6} style={{ padding: 0 }}>
                <Grid container>
                  <Grid item classes={{ root: classes.writer }} xs={12}>
                    <PostWriter
                      center={true}
                      organization={organization}
                      writer={writer}
                    />
                  </Grid>
                  {legendAtt.length > 0 && (
                    <Grid item xs={12} classes={{ root: classes.legend }}>
                      <Legend attachments={legendAtt} />
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <PostStatistics thisPost={thisPost} />
              </Grid>
              {/* {adminDisplay && (
                <Can
                  role={user.role}
                  perform="edit:views"
                  yes={() => (
                    <ApolloConsumer>
                      {(client) => <ViewCtl _id={_id} client={client} />}
                    </ApolloConsumer>
                  )}
                />
              )} */}
            </Grid>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
};

export default withStyles(styles, { withTheme: true })(PostCard);
