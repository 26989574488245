import React from "react";
import { Grid, Typography } from "@mui/material";
import { createStyles, withStyles } from "@mui/styles";
import { useFoundation } from "../../context/foundationContext";

const styles = () =>
  createStyles({
    development: {
      minHeight: "calc(100vh - 50px)",
    },
    sentinel_wrapper: {
      marginTop: 72,
      minHeight: "calc(100vh - 220px)",
    },
  });

const MainContent: React.FC<{ classes: any }> = ({ classes }) => {
  const { marginContentTop: marginTop } = useFoundation();

  return (
    <Grid
      item
      xs={12}
      classes={{ root: classes.sentinel_wrapper }}
      style={marginTop ? { marginTop } : {}}
    >
      <Grid
        container
        classes={{ root: classes.development }}
        id="sentinel_content"
        direction="row"
        alignItems="center"
        justifyContent="center"
      >
        <Grid item>
          <Typography>Updating...</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default withStyles(styles)(MainContent);
