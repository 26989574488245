import React, { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { Box, Theme } from "@mui/material";
import { createStyles, withStyles } from "@mui/styles";
import PageHelmet from "./helmet";
import { useCurrentPost } from "../../../context/Post/currentPostContext";
import { useTrans } from "../../../context/Trans/translationContext";
import processPost from "../../../utilities/postProcessor";
import { createLink } from "../../../utilities/postRenders";
import { ADD_VIEW } from "../../../utilities/queries";
import Page from "./page";
import Loader from "../../../components/Loader";
import { useAdminPost } from "../../../context/Post/adminPostContext";

var _ = require("lodash");

const styles = (theme: Theme) =>
  createStyles({
    page_holder: {
      paddingTop: 24,
      maxWidth: 812,
      width: "100%",
      "@media (min-width: 812px)": {
        borderRight: `1px solid ${theme.palette.color.verylightgray}`,
      },
    },
  });

const Post: React.FC<{ classes: any; queried_post: any; route_params: any }> =
  ({ classes, queried_post, route_params }) => {
    let history = useHistory();

    const [addView] = useMutation(ADD_VIEW);
    const { view } = useAdminPost();
    const { getDev, language, RouteTrans, setPathArray } = useTrans();
    let match = useRouteMatch(
      `/${language}/${RouteTrans["post"][language]}/:division/:category/:postIDalias`
    );

    const { currentPost, setCurrentPost } = useCurrentPost();
    const { category, division, postIDalias } = route_params;
    const { _id, postID, title, views } = currentPost;
    const post_id_array = _.split(postIDalias, "-", 1);
    const [postAlias, setPostAlias] = useState(post_id_array[1]);
    const route_postID = parseInt(post_id_array[0]);
    const isReady = !postID || postID === route_postID;

    useEffect(() => {
      if (_id && isReady && view !== "preview") {
        const variables = { _id, views };
        addView({ variables });
      }
    }, [_id, addView, isReady, view, views]);

    useEffect(() => {
      if (
        !_.isEmpty(queried_post) &&
        (_.isEmpty(currentPost) ||
          !currentPost._id ||
          currentPost._id !== queried_post._id)
      ) {
        setCurrentPost(processPost(queried_post));
      }
    }, [currentPost, queried_post, setCurrentPost]);

    false && console.log("route match", match);

    useEffect(() => {
      // console.log("history", history);
      if (title && isReady) {
        const alias = _.kebabCase(title[language]);
        if (postAlias !== alias) {
          setPostAlias(alias);
          const link = createLink(language, division, category, postID, alias);
          console.log("rewriting");
          history.replace(link);
        }
      }
    }, [
      category,
      division,
      history,
      isReady,
      language,
      postAlias,
      postID,
      title,
    ]); // when changed

    //<Page currentJSONPost />

    // useEffect(() => {
    //   // Sets Current Post in Context
    //   setCurrentPost(processPost(currentJSONPost));
    // }, []);

    // async function addView(_id, views) {
    //   const { postView, postTestView } = props;
    //   const postViewFunc = TESTING ? postTestView : postView;

    //   return await postViewFunc({ variables: { _id, views } });
    // }

    useEffect(() => {
      if (!_.isEmpty(currentPost) && isReady) {
        const path = [
          { dir: "post", type: "route" },
          { dir: getDev(division, language), type: "route" },
          { dir: getDev(category, language), type: "route" },
          {
            dir: { postID, title },
            type: "postID",
          },
        ];

        setPathArray(path);
      }
    }, [
      category,
      currentPost,
      division,
      getDev,
      isReady,
      language,
      postID,
      route_params,
      setPathArray,
      title,
    ]);

    // useEffect(() => {
    //   if (!_.isEmpty(currentPost)) {
    //     const { _id, views } = currentPost;
    //     const viewCount = (views ? views : 0) + 1;
    //     addView(_id, viewCount);
    //   }
    // }, [currentPost]);

    if (!_.isEmpty(currentPost) && currentPost._id && isReady) {
      return (
        <Box className={classes.page_holder}>
          <PageHelmet thisPost={currentPost} />
          <Page />
        </Box>
      );
    } else {
      return <Loader />;
    }
  };
export default withStyles(styles)(Post);
