import React from "react";
import PropTypes from "prop-types";
import { Button, Grid, Theme, Typography } from "@mui/material";
import { createStyles, withStyles } from "@mui/styles";
import Image from "./cloudinary-image";
import { Attachment } from "../../../../../../../context/types";

const styles = (theme: Theme) =>
  createStyles({
    deleted: {
      color: theme.palette.color.red,
    },
    formField: {
      marginTop: theme.spacing(1),
      width: "100%",
    },
    icon: {
      color: theme.palette.fonts.action,
    },
    source: {
      color: theme.palette.fonts.main,
      fontSize: ".7rem",
      wordBreak: "break-all",
    },
    wrapper: {
      padding: 24,
    },
  });

const SelectedPhoto: React.FC<{
  classes: any;
  photo: Attachment;
  restoreAttachment: (name: string) => void;
}> = ({ classes, photo, restoreAttachment }) => {
  const { name, source } = photo;

  return (
    <div className={classes.wrapper}>
      <Grid container spacing={2}>
        <Grid item>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            style={{ height: "100%" }}
          >
            <Grid item>
              <Image name={name} width="85" />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            style={{ height: "100%" }}
          >
            <Grid item style={{ width: "100%" }}>
              <Typography classes={{ root: classes.deleted }} variant="button">
                Deleted
              </Typography>
              <Typography classes={{ root: classes.source }} variant="body1">
                {source}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            style={{ height: "100%" }}
          >
            <Grid item>
              <Button onClick={() => name && restoreAttachment(name)}>
                Restore
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

SelectedPhoto.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SelectedPhoto);
