import React from "react";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";
import {
  OutlinedInput,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Theme,
} from "@mui/material";
import { createStyles, withStyles } from "@mui/styles";
import { useFoundation } from "../../../../../../context/foundationContext";
import { useNewPost } from "../../../../../../context/Post/newPostContext";
import { Category } from "../../../../../../context/types";

var _ = require("lodash");

const styles = (theme: Theme) =>
  createStyles({
    active: {
      //  backgroundColor: theme.palette.tertiary.main + " !important",
      //  color: theme.palette.primary.default + " !important",
      fontWeight: "bold",
    },
    disabled: {
      opacity: 1 + " !important",
    },
    category: {
      color: theme.palette.fonts.main,
    },
    division: {
      background: theme.palette.primary.main,
      //  color: theme.palette.primary.default,
      fontWeight: "bold",
      opacity: 1 + " !important",
      textShadow: theme.palette.shadow.textShadow1,
      "& em": {
        color: "#FFF !important",
      },
    },
    formField: {
      width: "100%",
      marginTop: theme.spacing(1),
    },
    label: {
      top: -6,
      left: 13,
    },
  });

const SelectCategory: React.FC<{ classes: any; category: Category }> = ({
  classes,
  category,
}) => {
  const { categoryList } = useFoundation();
  const { handleCategoryChange } = useNewPost();

  let divisionList: string[] = [
    "undivided",
    "News",
    "Politics",
    "Entertainment",
    "Sports",
    "Money",
    "Lifestyle",
    "World",
    "History",
    "Opinions",
  ];

  /*
If the PostContextValue categoryList is defined and greater than 0, this renders 
It orders and creates the list
*/
  // console.log(categoryList);

  //  if (categoryList !== undefined && !_.isEmpty(categoryList)) {
  let editCategoryList: Category[] = [];

  divisionList.forEach((division: string) => {
    let divisionObject: any = { name: division, id: division };
    editCategoryList.push(divisionObject);
    let divisionCategory = _.orderBy(categoryList[division], "order", "asc");

    divisionCategory.forEach((category: Category) => {
      editCategoryList.push(category);
    });
  });

  //const editedCategoryList = _.tail(editCategoryList);
  //console.log("category list", editCategoryList);
  return (
    <FormControl classes={{ root: classes.formField }}>
      <InputLabel className={classes.label} id="select-post-category">
        <FormattedMessage id="Category" />
      </InputLabel>
      <Select
        classes={{ disabled: classes.disabled }}
        labelId="select-post-category"
        value={category._id}
        onChange={handleCategoryChange}
        input={
          <OutlinedInput
            label={<FormattedMessage id="Category" />}
            name="category"
          />
        }
      >
        {editCategoryList.map((category: Category, idx: number) => {
          const { _id, order, name } = category;
          //console.log("_ids", _id || division?._id);
          return (
            <MenuItem
              key={_id || idx}
              value={_id}
              classes={{
                root: Number.isInteger(order)
                  ? classes.category
                  : classes.division,
                selected: classNames(classes.active, classes.category),
              }}
              disabled={!Number.isInteger(order)}
            >
              {Number.isInteger(order) ? (
                <FormattedMessage id={name} />
              ) : (
                <em>
                  <FormattedMessage id={name} />
                </em>
              )}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default withStyles(styles)(SelectCategory);
