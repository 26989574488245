import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { Theme } from "@mui/material";
import { createStyles, withStyles } from "@mui/styles";
import ReactHtmlParser from "react-html-parser";
import Attachment from "./attachment";

import { useTrans } from "../../../../../context/Trans/translationContext";
import {
  Attachment as AttachmentType,
  Language,
} from "../../../../../context/types";
var _ = require("lodash");

const styles = (theme: Theme) =>
  createStyles({
    actionIcon: {
      // color: theme.palette.primary.default,
      fontSize: "1.7rem",
      padding: theme.spacing(1),
    },
    content: {
      flex: "1 0 auto",
      width: "100%",
      overflow: "hidden",
      padding: "16px 8px",
      // "& .shareaholic-canvas": {
      //   "& .shareaholic-recommendations-header": {
      //     color: theme.palette.primary.default,
      //     fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      //     fontSize: 20,
      //     fontWeight: "bold",
      //   },
      //   "& .shareaholic-recommendation": {
      //     border: "1px solid #efefef",
      //     borderRadius: 6,
      //     boxShadow: theme.shadows[8],
      //     "& .shareaholic-recommendation-article-link": {
      //       borderRadius: 6,
      //       "& .shareaholic-recommendation-article-details": {
      //         background:
      //           "linear-gradient(to bottom,rgba(0,0,0,0),rgba(0,0,0,.7),rgba(0,0,0,1)) !important",
      //         padding: "2px !important",
      //         "& .shareaholic-recommendation-article-title": {
      //           fontSize: ".65rem !important",
      //           textTransform: "none !important",
      //         },
      //       },
      //     },
      //   },
      // },
    },
    edit: {
      textAlign: "right",
    },
    postBody: {
      fontFamily: "Gotham Narrow SSm A, Gotham Narrow SSm B",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: 1.21,
      textAlign: "left",
      width: "100%",
      "& .letter": {
        padding: 20,
        fontFamily: "serif",
        "& p": {
          fontSize: "1.1rem",
        },
      },
      // color: theme.palette.text.focus,
      "& p": {
        fontSize: "1.25rem",
        lineHeight: "1.6",
        marginBottom: 24,
        marginTop: 0,
        "& a": {
          color: theme.palette.secondary.main,
          textDecoration: "none",
          fontWeight: "bold",
        },
      },
      "& h1": {
        fontSize: "2.4rem",
        fontWeight: 700,
        marginTop: 60,
        color: theme.palette.fonts.dark,
      },
      "& h2": {
        fontSize: "2rem",
        fontWeight: 700,
        marginTop: 30,
        //color: theme.palette.fonts.dark,
      },
      "& .document": {
        background: "#f7f7f7",
        boxShadow: theme.shadows[8],
        //    color: theme.palette.primary.bottom,
        fontFamily: "Courier",
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(4),
        padding: theme.spacing(3),
        "& .divider": {
          border: "1px solid lightgray",
          margin: theme.spacing(3),
        },
        "& p": {
          fontSize: "1rem",
        },
        "& ul": {
          fontSize: ".9rem",
          listStyleType: "decimal",
          "& li": {
            paddingBottom: theme.spacing(1),
          },
          "& li:last-of-type": {
            paddingBottom: 0,
          },
        },
      },
      "& .quote": {
        border: "1px solid #efefef",
        padding: theme.spacing(3),
        "& p": {
          marginBottom: theme.spacing(3),
          fontStyle: "italic",
        },
      },
      "& .citation": {
        background: "#f7f7f7",
        margin: "24px 0",
        padding: theme.spacing(2),
        "& p": {
          fontSize: ".9rem",
          fontStyle: "italic",
          lineHeight: 1.2,
          marginBottom: theme.spacing(1),
          "& a": {
            fontWeight: "bold",
            color: theme.palette.secondary.main,
            textDecoration: "underline",
          },
        },
      },
    },
  });

const Content: React.FC<{
  classes: any;
  attachments?: AttachmentType[];
  body: Language;
}> = ({ classes, attachments, body: body_ }) => {
  const { language } = useTrans();
  const body = body_[language];

  const convertHandlebars = (body: any, attachments: any[]) => {
    _.templateSettings.interpolate = /<{{([\s\S]+?)}}>/g;

    // CONVERT HANDLEBARS TO DIVS
    const HandleBarObject: { [key: string]: any } = {};

    attachments.forEach(attachment => {
      HandleBarObject[
        attachment.handle
      ] = `<div id='attachment_${attachment.handle}'></div>`;
    });

    var compiled = _.template(body);
    return compiled(HandleBarObject);
    // => 'hello mustache!'
  };

  useEffect(() => {
    const loadAttachments = (body: any, attachments?: any[]) => {
      // RENDER REACT ELEMENTS IN DIVS
      attachments?.forEach(attachment => {
        const attTagSingle =
          "<div id='attachment_" + attachment.handle + "'></div>";
        const attTagDouble =
          '<div id="attachment_' + attachment.handle + '"></div>';

        if (
          attachments &&
          attachments.length > 0 &&
          (_.includes(convertHandlebars(body, attachments), attTagSingle) ||
            _.includes(convertHandlebars(body, attachments), attTagDouble))
        ) {
          ReactDOM.render(
            <Attachment attachment={attachment} />,
            document.getElementById("attachment_" + attachment.handle)
          );
        }
      });
    };

    !_.isEmpty(body) &&
      attachments &&
      attachments.length > 0 &&
      loadAttachments(body, attachments);
  }, [attachments, body]);

  return (
    <React.Fragment>
      {body !== "" && (
        <div className={classes.postBody}>
          {ReactHtmlParser(
            attachments ? convertHandlebars(body, attachments) : body
          )}
        </div>
      )}
    </React.Fragment>
  );
};

export default withStyles(styles)(Content);
