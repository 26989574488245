import React from "react";
import { createStyles, withStyles } from "@mui/styles";
import { Attachment } from "../../../context/types";
import InstagramEmbed from "react-instagram-embed";

const styles = () =>
  createStyles({
    holder: {
      "& twitter-widget": {
        width: "100%",
      },
      "& .EmbeddedTweet": {
        maxWidth: "calc(100% - 12px)",
      },
    },
    "& .twitter-tweet": {
      width: "100%",
    },
  });

const Instagram: React.FC<{ classes: any; instagram: Attachment }> = ({
  classes,
  instagram,
}) => {
  const { source } = instagram;
  if (source) {
    return (
      <div className={classes.holder}>
        <InstagramEmbed
          clientAccessToken="a0d4ba31b858a13dbcfe3e3af8f156ab"
          url={source}
          maxWidth={645}
          hideCaption={true}
          containerTagName="div"
        />
      </div>
    );
  } else {
    return null;
  }
};

export default withStyles(styles)(Instagram);
