import React from "react";
import { Grid, TextField, Theme } from "@mui/material";
import { createStyles, withStyles } from "@mui/styles";
import Attachments from "./Body/attachments_";
import Controls from "./Body/controls";
import DefaultSet from "./Body/default";
import DefaultSetLink from "./Body/default_link";
import Previewer from "./previewer";
import SplitSet from "./Body/split";
import SplitSetLink from "./Body/split_link";
import { useNewPost } from "../../../../../../../context/Post/newPostContext";
import { useUser } from "../../../../../../../context/User/userContext";

const styles = (theme: Theme) =>
  createStyles({
    formField: {
      marginTop: theme.spacing(2),
      width: "100%",
      "& textarea": {
        color: theme.palette.fonts.focus,
      },
    },
  });

const MainFormHolder: React.FC<{ classes: any }> = ({ classes }) => {
  const { isDesktop } = useUser();
  const { editing, handlePostChange, isTranslating, newPost, splitForm } =
    useNewPost();
  const { body, link } = newPost;

  function handleChanges(label: string, value: any) {
    const contentObj = newPost.link;
    contentObj.source = value;
    handlePostChange(label, contentObj);
  }

  return (
    <Grid item xs={12}>
      <form noValidate autoComplete="off">
        <Grid container>
          {splitForm && isDesktop ? (
            <SplitSet />
          ) : (
            <DefaultSet isEditing={isTranslating} />
          )}
          <Attachments bodyInput={body?.[editing]} />
          <Controls />
          {link?.enabled && (
            <Grid item xs={12} style={{ marginTop: 16 }}>
              {splitForm ? (
                <SplitSetLink />
              ) : (
                <DefaultSetLink isEditing={isTranslating} />
              )}
              <TextField
                key="article-link-source-input"
                disabled={!link.enabled}
                variant="outlined"
                label={"Link URL"}
                value={link.source}
                classes={{ root: classes.formField }}
                onChange={(e: any) => handleChanges("source", e.target.value)}
              />
            </Grid>
          )}
        </Grid>
      </form>
      <Previewer label={"Body Preview"} />
    </Grid>
  );
  // }
};

export default withStyles(styles)(MainFormHolder);
