import React from "react";
import classNames from "classnames";
import moment from "moment";
import Moment from "react-moment";
import { useHistory } from "react-router-dom";
import ReactHTMLParser from "react-html-parser";
import {
  FaImage,
  FaEdit,
  FaEye,
  FaImages,
  FaLink,
  FaSoundcloud,
  FaTwitter,
  FaYoutube,
  FaVideo,
} from "react-icons/fa";
import {
  CardMedia,
  Collapse,
  Hidden,
  IconButton,
  Paper,
  TableCell,
  TableRow,
  Theme,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { createStyles, withStyles } from "@mui/styles";
import { useTrans } from "../../../../../context/Trans/translationContext";
import { Attachment, Post } from "../../../../../context/types";
//import { useMyPosts } from "../../../../../context/Feed/myPostsFeedContext";
import { useAdminPost } from "../../../../../context/Post/adminPostContext";
var _ = require("lodash");
const styles = (theme: Theme) =>
  createStyles({
    active: {
      background: theme.palette.color.verylightred,
    },
    cell: {},
    cell_off: { borderBottom: "none" },
    checked: {
      backgroundColor: theme.palette.color.red,
      color: "white",
    },
    cover: { minWidth: 56, padding: "12px 6px 12px 24px" },
    divider: {
      // backgroundImage: theme.palette.primary.divider,
      marginBottom: theme.spacing(1.5),
    },
    expansion: {
      color: theme.palette.fonts.main,
      fontSize: ".8rem",
    },
    info: {
      color: theme.palette.fonts.main,
      fontSize: ".64rem",
    },
    loader: {
      textAlign: "center",
      margin: theme.spacing(3),
    },
    media: {
      borderRadius: theme.spacing(0.5),
      position: "relative",
      paddingBottom: "72.25%",
    },
    noPhoto: {
      color: theme.palette.fonts.detail,
      fontSize: ".77rem",
      lineHeight: 1,
      textAlign: "center",
    },
    paginationButton: {
      fontSize: ".9rem",
    },
    pagination: {
      width: "100%",
      textAlign: "center",
      // "& .pagination": {
      //   listStyleType: "none",
      //   margin: "32px auto",
      //   width: 360,
      //   "& li": {
      //     display: "table-cell",
      //     width: 40,
      //     "& a": {
      //       color: theme.palette.text.action,
      //     },
      //   },
      //   "& li.active": {
      //     fontWeight: "bold",
      //     "& a": {
      //       color: theme.palette.text.focus,
      //     },
      //   },
      // },
    },
    sort: {
      color: theme.palette.fonts.faint,
      float: "right",
      fontSize: "1rem",
      paddingRight: 12,
    },
    subtitle: {
      color: theme.palette.fonts.focus,
      fontSize: ".64rem",
    },
    title: {
      padding: "12px 24px 12px 6px",
    },
    title_text: {
      color: theme.palette.fonts.focus,
      fontSize: ".72rem",
      fontWeight: "bold",
    },
  });

const TableItemRow: React.FC<{
  classes: any;
  deletePosts: Post[];
  draft: Post;
  setDeletePosts: any;
}> = ({ classes, deletePosts, draft, setDeletePosts }) => {
  const theme = useTheme();
  const history = useHistory();
  const { language, RouteTrans } = useTrans();
  const { setPreview, setView } = useAdminPost();

  const displayLegend = (attachments: Attachment[]) => {
    const legend: { [key: string]: any } = {
      facebookvideo: (
        <FaVideo key="facebookvideo" className={classes.fbVideo} />
      ),
      link: <FaLink key="link" className={classes.link} />,
      photo: <FaImage key="photo" className={classes.photo} />,
      photogallery: <FaImages key="photogallery" className={classes.photo} />,
      soundcloud: (
        <FaSoundcloud key="soundcloud" className={classes.soundcloud} />
      ),
      twitter: <FaTwitter key="twitter" className={classes.twitter} />,
      youtube: <FaYoutube key="youtube" className={classes.youtube} />,
    };

    const typeArray: any[] = [];
    attachments.forEach(attachment => {
      typeArray.push(attachment.type);
    });
    const types = _.uniq(typeArray);

    return types.map((type: string) => {
      return legend[type];
    });
  };
  const showFull = useMediaQuery(theme.breakpoints.up("sm"));
  const [open, setOpen] = React.useState(false);
  const {
    _id,
    attachments,
    category,
    createdAt,
    publishedAt,
    cover,
    //  updatedAt,
    writer,
    version,
  } = draft;
  const { given_name, family_name } = writer.name.user;
  const publish_date = moment.unix(parseInt(publishedAt || createdAt) / 1000);
  //const updated_date = moment.unix(parseInt(updatedAt) / 1000);

  const coverExist = !_.isEmpty(cover) && cover.source;
  const noTitle: { [key: string]: string } = {
    en: "*** No Title ***",
    fr: "** Pas de titre **",
    ht: "*** Pa gen tit ***",
  };
  const noSubtitle: { [key: string]: string } = {
    en: "*** No Subtitle ***",
    fr: "* Pas de sous-titre *",
    ht: "* Pa gen soutit *",
  };
  const title =
    draft.title[language] && draft.title[language].length > 0 ? (
      ReactHTMLParser(draft.title[language])
    ) : (
      <span style={{ color: "lightgray" }}>{noTitle[language]}</span>
    );
  const subtitle =
    draft.subtitle[language] && draft.subtitle[language].length > 0 ? (
      ReactHTMLParser(draft.subtitle[language])
    ) : (
      <span style={{ color: "lightgray" }}>{noSubtitle[language]}</span>
    );
  //const catdiv = _.split(category.url, "/");

  const link = `/${language}/${RouteTrans["edit"][language]}/${RouteTrans["post"][language]}/${_id}`;

  // const handleChange = (event: any) => {
  //   const check = event.target.checked;
  //   const delArr = deletePosts;
  //   if (check) {
  //     setDeletePosts(_.uniq(_.concat(delArr, _id)));
  //   } else {
  //     setDeletePosts(_.uniq(_.reject(delArr, _id)));
  //   }
  // };

  const checked = _.includes(deletePosts, _id);

  return (
    <React.Fragment>
      <TableRow
        classes={{
          root: classes[checked ? "active" : "row"],
          hover: classes[checked ? "active" : "row"],
        }}
      >
        {/* <TableCell classes={{ root: classes.cell }} scope="row">
          <Checkbox
            checked={checked}
            classes={{ checked: classes.checked }}
            onChange={handleChange}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </TableCell> */}
        <TableCell
          classes={{
            root: classNames(
              classes[open ? "cell_off" : "cell"],
              classes.cover
            ),
          }}
        >
          {coverExist ? (
            <Paper className="cover" elevation={4}>
              <CardMedia
                className={classNames(classes.media, "media_grid")}
                image={
                  version === 1
                    ? "https://res.cloudinary.com/defendhaiti/image/upload/w_350,f_auto/v1549853661/Version_1/" +
                      cover.source
                    : "https://res.cloudinary.com/defendhaiti/image/upload/w_350,f_auto/v1549853661/" +
                      cover.name
                }
              ></CardMedia>
            </Paper>
          ) : (
            <Typography component="p" classes={{ root: classes.noPhoto }}>
              No Photo
            </Typography>
          )}
        </TableCell>
        <TableCell
          align="left"
          classes={{
            root: classNames(
              classes[open ? "cell_off" : "cell"],
              classes.title
            ),
          }}
          onClick={() => setOpen(!open)}
        >
          <Typography classes={{ root: classes.title_text }} component="p">
            {title}
            {/* <span className={classes.sort}>
              {open ? <FaSortUp /> : <FaSortDown />}
            </span> */}
          </Typography>
          <Hidden xsDown>
            <Typography classes={{ root: classes.info }} component="p">
              <Moment format={showFull ? "LL" : "ll"}>{publish_date}</Moment> |{" "}
              {given_name + " " + family_name} | {category.name}
            </Typography>
          </Hidden>
        </TableCell>
        <TableCell classes={{ root: classes[open ? "cell_off" : "cell"] }}>
          <IconButton
            onClick={() => {
              _id && setPreview(_id);
              setView("preview");
            }}
          >
            <FaEye className={classes.expansion} />
          </IconButton>
        </TableCell>
        <TableCell classes={{ root: classes[open ? "cell_off" : "cell"] }}>
          <IconButton onClick={() => history.push(link)}>
            <FaEdit className={classes.expansion} />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={4} style={{ border: 0, padding: 0 }}>
          <Collapse in={open} unmountOnExit>
            <div
              style={{
                borderBottom: "1px solid rgba(224, 224, 224, 1)",
                padding: "0 24px 12px",
              }}
            >
              <Hidden smUp>
                <Typography classes={{ root: classes.info }} component="p">
                  <Moment date={publish_date} format={showFull ? "LL" : "ll"} />{" "}
                  | {given_name + " " + family_name} | {category.name}
                </Typography>
              </Hidden>
              <div className={classes.legend}>{displayLegend(attachments)}</div>
              <Typography classes={{ root: classes.subtitle }} component="p">
                {subtitle}
              </Typography>
            </div>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default withStyles(styles, { withTheme: true })(TableItemRow);
