import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { FormattedMessage } from "react-intl";
import { ButtonBase, Grid, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import { useTrans } from "../../../../context/Trans/translationContext";
import styles from "../styles";

//var _ = require("lodash");

const AdminMenu: React.FC<{
  classes: any;
}> = ({ classes }) => {
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();
  const { language } = useTrans();
  const returnTo = `${window.location.origin}/${language}`;
  return (
    <Grid item xs={12} style={{ textAlign: "right" }}>
      {isAuthenticated ? (
        <ButtonBase onClick={() => logout({ returnTo })}>
          <Typography
            variant="h6"
            classes={{ root: classes.menu_item }}
            style={{ lineHeight: 1.21, textAlign: "right" }}
          >
            <span className={classes.green}>
              <FormattedMessage id="Signed In" defaultMessage="Signed In" />
            </span>
            <br />
            <span>
              <FormattedMessage id="Sign Out" defaultMessage="Sign Out" />
            </span>
          </Typography>
        </ButtonBase>
      ) : (
        <ButtonBase onClick={async () => await loginWithRedirect()}>
          <Typography
            variant="h6"
            classes={{ root: classes.menu_item }}
            style={{ lineHeight: 1.21, textAlign: "right" }}
          >
            <span className={classes.red}>
              <FormattedMessage id="Signed Out" defaultMessage="Signed Out" />
            </span>
            <br />
            <span>
              <FormattedMessage
                id="Sign In"
                defaultMessage="Sign In / Register"
              />
            </span>
          </Typography>
        </ButtonBase>
      )}
    </Grid>
  );
};

export default withStyles(styles)(AdminMenu);
