import React from "react";
//import classNames from "classnames";
import { Image, Transformation } from "cloudinary-react";
//import { withStyles } from "@mui/material";

// const styles = theme => ({
//   avatar: {
//     border: "1px solid " + theme.palette.secondary.main,
//     boxShadow: theme.shadows[6], //"0 1px 5px 1px lightgrey"
//   },
//   avatarButton: {
//     [theme.breakpoints.down("sm")]: {
//       margin: "0 0 0 8px",
//     },
//     [theme.breakpoints.up("sm")]: {
//       margin: "0 8px",
//     },
//     padding: 0,
//   },
//   cover_img: {
//     width: "100%",
//   },
// });

const CloudinaryCover = ({ name, width }) => {
  return (
    <Image
      // className={classes.avatar}
      publicId={name}
      // className={classNames("avatar_reflect")}
      style={{ width }}
    >
      <Transformation width={width} crop="scale" />
    </Image>
  );
};

export default CloudinaryCover;
