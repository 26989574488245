import React, { useState } from "react";
import { Grid, TextField } from "@mui/material";
import { createStyles, withStyles } from "@mui/styles";
import Action from "../../Controls/fieldAction";
import { useNewPost } from "../../../../../../../context/Post/newPostContext";

const styles = () =>
  createStyles({
    contextField: {
      width: "100%",
    },
  });

const CoverContextForm: React.FC<{ classes: any; isEditing?: boolean }> = ({
  classes,
  isEditing,
}) => {
  // const { toggleManager } = useManagers();

  const { dev, editing, handlePostChange, newPost, splitForm } = useNewPost();
  const { cover } = newPost;
  const { credit, description, title } = cover;

  const editing_language = isEditing ? editing : dev;

  const [creditState, setCreditState] = useState(credit);
  const [titleState, setTitleState] = useState(title?.[editing_language]);
  const [descriptionState, setDescriptionState] = useState(
    description?.[editing_language]
  );

  const handleChange = (field: string, event: any) => {
    const setFunctions: { [key: string]: any } = {
      credit: setCreditState,
      title: setTitleState,
      description: setDescriptionState,
    };

    setFunctions[field](event.target.value);
  };

  function saveChanges(label: string) {
    const setStates: { [key: string]: any } = {
      title: titleState,
      description: descriptionState,
    };

    if (label === "credit") {
      handlePostChange("cover", { ...cover, credit: creditState });
    } else {
      const cover_field = (cover as any)[label];
      const lang_obj = { ...cover_field, [editing_language]: setStates[label] };
      handlePostChange("cover", { ...cover, [label]: lang_obj });
    }
  }

  const hide = !isEditing && splitForm;

  return (
    <>
      <Grid item xs={12}>
        <TextField
          id="cover-context-title"
          margin="dense"
          onBlur={() => saveChanges("title")}
          variant="outlined"
          multiline
          label={"Title"}
          classes={{ root: classes.contextField }}
          value={titleState || ""}
          onChange={(e: any) => handleChange("title", e)}
        />
        <Action hide={hide} item={"Title"} field="cover-title" />
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="cover-context-description"
          onBlur={() => saveChanges("description")}
          margin="dense"
          variant="outlined"
          label={"Description"}
          multiline
          value={descriptionState || ""}
          classes={{ root: classes.contextField }}
          onChange={(e: any) => handleChange("description", e)}
        />
        <Action hide={hide} item={"Description"} field="cover-description" />
      </Grid>
    </>
  );
};
export default withStyles(styles)(CoverContextForm);
