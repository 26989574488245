import React, { useState } from "react";
import { FaCompress, FaExpand } from "react-icons/fa";
import classNames from "classnames";
import {
  Button,
  IconButton,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { createStyles, withStyles } from "@mui/styles";
import Feature from "../../../../../components/Feature";
import { Attachment } from "../../../../../context/types";
import { useTrans } from "../../../../../context/Trans/translationContext";
var _ = require("lodash");
const styles = (theme: Theme) =>
  createStyles({
    attachment_back: {
      marginBottom: theme.spacing(1),
      width: "100%",
    },
    attachment_card: {
      marginBottom: theme.spacing(3),
      transition: "width .4s linear",
      [theme.breakpoints.down("sm")]: {
        width: "50%",
      },
      [theme.breakpoints.up("sm")]: {
        width: "33%",
      },
    },
    button: {},
    close: {
      textAlign: "right",
    },
    credit: {
      background: "#e00000",
      boxShadow: theme.shadows[2],
      color: "white",
      fontWeight: "bold",
      marginBottom: theme.spacing(1.5),
      marginTop: theme.spacing(2),
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(2.5),
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      width: "fit-content",
    },
    description: {
      color: "#636363",
      fontWeight: "normal",
      lineHeight: "1",
    },
    formControl: {
      width: "100%",
    },
    full: {
      marginBottom: theme.spacing(3),
      width: "100%",
      transition: "width .4s linear",
    },
    left: {
      float: "left",
      marginRight: theme.spacing(2),
      [theme.breakpoints.down("sm")]: {
        width: "50%",
      },
      [theme.breakpoints.up("sm")]: {
        width: "33%",
      },
      "& .info": {
        paddingLeft: 0,
      },
      "& .credit_left": {
        //  marginLeft: -theme.spacing(1)
      },
      "& .title_left": {
        fontSize: ".8rem",
      },
      "& .description_left": {
        fontSize: ".67rem",
        lineHeight: 1.4,
      },
    },
    switch_left: {
      textAlign: "left",
      width: "100%",
    },
    expand_left: {
      textAlign: "left",
      color: "lightgray",
      fontSize: "1.4rem",
      padding: 0,
    },
    right: {
      float: "right",
      marginLeft: theme.spacing(2),
      marginBottom: theme.spacing(2),
      [theme.breakpoints.down("sm")]: {
        width: "50%",
      },
      [theme.breakpoints.up("sm")]: {
        width: "33%",
      },
      "& .info": {
        paddingLeft: 8,
      },
      "& .credit_right": {
        marginLeft: -theme.spacing(1),
      },
      "& .title_right": {
        fontSize: ".8rem",
      },
      "& .description_right": {
        fontSize: ".67rem",
        lineHeight: 1.4,
      },
    },
    switch_right: {
      textAlign: "right",
      width: "100%",
    },
    expand_right: {
      textAlign: "right",
      color: "lightgray",
      fontSize: "1.4rem",
      padding: 0,
    },
    text: {
      fontSize: ".7rem",
      textAlign: "left",
    },
    title: {
      lineHeight: "1.3",
      marginBottom: 2,
    },
  });

const AttachmentDisplay: React.FC<{ classes: any; attachment: Attachment }> = ({
  classes,
  attachment,
}) => {
  const width_checks: { [key: string]: string } = {
    twitter: "(max-width:666px)",
  };

  const { language } = useTrans();

  const [isOpen, setIsOpen] = useState(false);

  const toggleAttachment = () => {
    setIsOpen(!isOpen);
  };

  const { alignment, credit, description, title, type } = attachment;

  const matches = useMediaQuery(width_checks[type] || "");
  const permanentlyOpen = type === "twitter" && matches;
  function openAttachment() {
    return isOpen || alignment === "center" || permanentlyOpen;
  }

  const open = openAttachment();

  return (
    <div className={open ? classes.full : classes[alignment || "right"]}>
      {isOpen && (
        <div className={classes.close}>
          <Button onClick={() => toggleAttachment()} className={classes.button}>
            Close
          </Button>
        </div>
      )}

      <div>
        <div className={classes.attachment_back}>
          <Feature attachment={attachment} />
        </div>
        <div className="info">
          {credit && (
            <Typography
              className={classNames(classes.credit, "credit_" + alignment)}
              variant="caption"
            >
              {credit}
            </Typography>
          )}
          {title && !_.isEmpty(title) && (
            <Typography
              className={classNames(classes.title, "title_" + alignment)}
              variant="subtitle2"
            >
              {title[language]}
            </Typography>
          )}
          {description && !_.isEmpty(description) && (
            <Typography
              className={classNames(
                classes.description,
                "description_" + alignment
              )}
              variant="body1"
            >
              {description[language]}
            </Typography>
          )}
          {!permanentlyOpen && (
            <div className={classes["switch_" + alignment]}>
              <IconButton
                onClick={toggleAttachment}
                className={classes["expand_" + alignment]}
              >
                {isOpen ? <FaCompress /> : <FaExpand />}
              </IconButton>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default withStyles(styles)(AttachmentDisplay);
