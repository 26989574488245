export const AttachmentReducer = (state: any, action: any) => {
  switch (action.type) {
    case "LOAD_ATTACHMENT":
      return action.post;
    case "HANDLE_CHANGE":
      return {
        ...state,
        [action.post.stateName]: action.post.target,
      };
    case "HANDLE_TYPE_CHANGE":
      return {
        ...state,
        type: { [action.post.stateName]: action.post.target },
      };
    case "CLEAR_ALL":
      return action.post;
    default:
      return state;
  }
};
