import React from "react";
//import PropTypes from "prop-types";
import { Grid, Theme } from "@mui/material";
import { createStyles, withStyles } from "@mui/styles";
import Document from "../../../../../../../components/Feature/media/document";
import Audio from "../../../../../../../components/Feature/media/localaudio";
import Photo from "../../../../../../../components/Feature/media/localphoto";
import { Attachment, Language } from "../../../../../../../context/types";
import PhotoAlbum from "./selectedAlbum";
import Video from "../../../../../../../components/Feature/media/localvideo";

const styles = (theme: Theme) => createStyles({});

const SelectedFile: React.FC<{
  attachment: Attachment;
  removeAlbumPhoto: (name: string) => void;
  restoreAlbumPhoto: (name: string) => void;
  updateAlbumPhotoTitle: (name: string, title: Language) => void;
}> = ({
  attachment,
  removeAlbumPhoto,
  restoreAlbumPhoto,
  updateAlbumPhotoTitle,
}) => {
  const { postType, purpose, template } = attachment;
  const type = purpose === "cover" ? "localphoto" : postType[template];

  const fileDisplay: { [key: string]: any } = {
    document: <Document document={attachment} />,
    localaudio: <Audio localaudio={attachment} />,
    localphoto: <Photo localphoto={attachment} />,
    localvideo: <Video localvideo={attachment} />,
    photoalbum: (
      <PhotoAlbum
        photoalbum={attachment}
        removeAttachment={removeAlbumPhoto}
        restoreAttachment={restoreAlbumPhoto}
        updateAlbumPhotoTitle={updateAlbumPhotoTitle}
      />
    ),
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
    >
      <Grid item style={{ width: "100%" }}>
        {fileDisplay[type]}
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(SelectedFile);
