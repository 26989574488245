import { createTheme } from "@mui/material/styles";

type AdsType = {
  background: string;
  border: string;
  dark: string;
  divider: string;
  light: string;
  main: string;
  text: string;
};

type ColorType = {
  blue: string;
  darkblue: string;
  green: string;
  green_gradient: string;
  lightblue: string;
  verylightblue: string;
  verylightred: string;
  verylightgray: string;
  red: string;
  yellow: string;
};

type ShadowType = {
  textShadow: string;
  textShadow1: string;
  textFeed: string;
  title: string;
  transparent: string;
  background1: string;
  border: string;
  boxShadow: string;
  boxShadow1: string;
  boxShadow2: string;
  dividerLeft: string;
};

type FontType = {
  main: string;
  action: string;
  dark: string;
  faint: string;
  focus: string;
  detail: string;
  highlight: string;
};

type IconsType = {
  acrobat: string;
  facebook: string;
  instagram: string;
  rss: string;
  soundcloud: string;
  twitter: string;
  vimeo: string;
  youtube: string;
};

declare module "@mui/material/styles" {
  interface Palette {
    ads: AdsType;
    color: ColorType;
    shadow: ShadowType;
    fonts: FontType;
    icons: IconsType;
  }

  interface PaletteOptions {
    ads: AdsType;
    color: ColorType;
    shadow: ShadowType;
    fonts: Partial<FontType>;
    icons: IconsType;
  }
}
const primary_main = "#2b2b2b";
const fonts_main = "#797979";
// const myBlackTop = "#131313";
// const myBlackMiddle = "#1d1d1d";
// const myBlackBottom = "#2d2d2d";
const theme = createTheme({
  palette: {
    ads: {
      background: "rgba(244,244,244,.3)",
      border: "1px solid rgba(240,240,240,.8)",
      dark: "1px solid rgba(240,240,240,.8)",
      divider: "rgba(0, 0, 0, 0.12)",
      main: "rgba(244,244,244,.3)",
      light: "rgba(220,220,220,1)",
      text: "rgba(220,220,220,1)",
    },
    background: {
      paper: "#FFFFFF", //"rgb(254,254,254)",
      default: "rgb(250,250,250)",
    },
    color: {
      darkblue: "#4c56ea",
      blue: "#74a8e8", //"#408cea",
      green: "#4bbf4b", //"#78ad78",
      green_gradient: "linear-gradient(#79c379,#528e52)",
      lightblue: "rgba(154, 186, 228, .3)",
      verylightblue: "rgba(154, 186, 228, .1)",
      verylightred: "rgba(241, 76, 76, 0.1)",
      verylightgray: "rgba(220,220,220,0.3)",
      red: "#d25f5f",
      yellow: "#e8e519",
    },
    primary: {
      main: primary_main,
      //"#4e4e4e" "linear-gradient(to right, rgba(255,255,255,0.5), #fafafa, #fafafa, rgba(255,255,255,0.5))"
      // default: "#fafafa",
      // two: "#f8f8f8",
      // three: "#f3f3f3",
      // border: "#f3f3f3",
      // divider: "linear-gradient(to right, #fafafa, #e0e0e0, #fafafa)",

      // dividerRight: "linear-gradient(to left, #e0e0e0,#e0e0e0, #fafafa)",
      // top: myBlackTop,
      // middle: myBlackMiddle,
      // bottom: myBlackBottom,
      // darker: "rgba(100,100,100,1)",
      // dark: "rgba(208,208,208,.5)",
      // light: "rgba(140,140,140,.85)",
      // faint: "rgba(208,208,208,.25)",
      // veryFaint: "rgba(208,208,208,.15)",
    },
    secondary: {
      main: "#74a8e8", // "#ececec",
      // border: "#e4e4e4",
      // old: "#bf0000",

      // background2: "linear-gradient(to right, #fafafa,#f5f5f5, #f5f5f5)",
    },
    // tertiary: {
    //   main: "#adadad",
    //   // middle: myBlackMiddle,
    //   // bottom: myBlackBottom,
    // },
    shadow: {
      textShadow: "1px 4px 10px rgba(0,0,0,.5)",
      textShadow1: "2px 2px 8px rgba(0,0,0,.5)",
      textFeed: "1px 1px 1px rgba(128,128,128,0.18)",
      title: "1px 4px 6px rgba(0,0,0,.5)",
      transparent: "rgba(255,255,255,0.5)",
      background1: "linear-gradient(165deg, #fafafa 10%, #ececec 60%)",
      border: "#f3f3f3",
      boxShadow: "0px 4px 10px -1px rgba(0,0,0,0.25)",
      boxShadow1: "1px 2px 12px -4px rgba(2,2,2,.35)",
      boxShadow2: "1px 8px 30px 2px rgba(2,2,2,.7)",
      dividerLeft: "linear-gradient(to right, #e0e0e0,#e0e0e0, #fafafa)",
    },
    icons: {
      acrobat: "#ff0000",
      facebook: "#3b5998",
      instagram: "#C13584",
      rss: "#F99000",
      soundcloud: "#FF7700",
      twitter: "#00ACEE",
      vimeo: "#4EBBFF",
      youtube: "#c4302b",
    },
    fonts: {
      main: fonts_main,
      dark: "#494949",
      action: "#a7a7a7",
      faint: "#e0e0e0",
      focus: "#616161",
      detail: "#adadad",
      highlight: "#6b6ba0",
    },
    //divider: "rgba(0, 0, 0, 0.85)"
  },
  breakpoints: {
    values: {
      //  xxs: 0,
      xs: 325,
      sm: 645,
      md: 845,
      lg: 960,
      xl: 1160,
      // xxl: 1660,
    },
  },
  typography: {
    fontFamily: "Gotham SSm A, Gotham SSm B",
    body1: {
      fontFamily: "Gotham SSm A, Gotham SSm B",
      lineHeight: 1.21,
    },
    h1: {
      color: primary_main,
      fontFamily: "Gotham SSm A, Gotham SSm B !important",
      fontSize: "2rem",
      fontStyle: "normal",
      fontWeight: 700,
      textTransform: "uppercase",
    },
    h2: {
      color: "#4e4e4e",
      fontFamily: "Gotham Bold",
      fontSize: 19,
      textTransform: "uppercase",
    },
    h3: {
      color: primary_main, //"#4e4e4e",
      fontFamily: "Gotham SSm A, Gotham SSm B",
      fontSize: ".8rem",
      fontStyle: "normal",
      fontWeight: 700,
    },
    h4: {
      color: "#4e4e4e",
      fontFamily: "Gotham SSm A, Gotham SSm B",
      // fontFamily: "Gotham Bold",
      fontSize: ".9rem",
      textTransform: "uppercase",
      //fontWeight: "bold",
    },
    h5: {
      color: "#4e4e4e",
      fontFamily: "Gotham SSm A, Gotham SSm B",
      fontSize: ".74rem",
      lineHeight: 1.21,
    },
    h6: {
      color: fonts_main,
      fontFamily: "Gotham SSm A, Gotham SSm B",
      textTransform: "uppercase",
      fontSize: ".7rem",
      letterSpacing: 2,
      lineHeight: "1.167rem",
      "& span": {
        color: "#adadad",
        letterSpacing: 0.5,
      },
      // marginBottom: mdAndUp ? 44 : 28,
    },
    caption: {
      color: "#656565",
      fontFamily: "sans-serif",
      textAlign: "center",
      fontSize: ".8rem",
      "& svg": {
        color: "#656565",
      },
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        text: {
          color: "#565656",
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: ".8rem",
          textAlign: "left",
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: ".68rem",
        },
      },
    },
  },
});

export default theme;
