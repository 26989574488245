import React, {
  createContext,
  // useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import createAuth0Client, { Auth0Client } from "@auth0/auth0-spa-js";
import { useAuth0 } from "@auth0/auth0-react";
import { useMutation } from "@apollo/client";
import { useMediaQuery, useTheme } from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";
import { LOGIN_USER } from "../../utilities/queries";
import { User } from "../types";
import Can from "./Can";
//import { graphql } from "react-apollo";
//import { useSubscription } from "@apollo/react-hooks";
//import { getUsersMessagesQuery } from "../components/Admin/Helpers/adminQueries";
//import { useAuth } from "./Auth/authContext";

// import { split } from "apollo-link";
// import { HttpLink } from "apollo-link-http";
// import { WebSocketLink } from "apollo-link-ws";
// import { getMainDefinition } from "apollo-utilities";
//import Auth from "./Auth/auth";

var _ = require("lodash");

// const httpLink = new HttpLink({
//   uri: `http://localhost:4000/api/graphql`,
// });

// const wsLink = new WebSocketLink({
//   uri: `ws://localhost:5000`,
//   options: {
//     reconnect: true,
//   },
// });

// const link = split(
//   // split based on operation type
//   ({ query }) => {
//     const definition = getMainDefinition(query);
//     return (
//       definition.kind === "OperationDefinition" &&
//       definition.operation === "subscription"
//     );
//   },
//   wsLink,
//   httpLink
// );

const defaultManagers = {
  admin_view: false,
  attachmentManager: false,
  attachmentsManager: false,
  bodyPreview: false,
  closeEditorDialogOpen: false,
  coverManager: false,
  featureManager: false,
  loadDraft: true,
  saving: false,
  postPreview: false,
};

interface UserValues {
  // user_messages: any[];
  admin_view: boolean;
  app_page: string;
  auth0: any;
  Can: any;
  dashboard: { [key: string]: boolean };
  goToLink: (link: string) => void;
  isAdmin: boolean;
  isDesktop: boolean;
  isMobile: boolean;
  managers: { [key: string]: boolean };
  pathname: string;
  setAdminView: (admin_view: boolean) => void;
  setManagersState: (stateName: string, value: boolean) => void;
  setViewState: (state: string) => void;
  toggleManager: (manager: string) => void;
  toggleDashboard: (a: string) => void;
  user?: User;
  post_manage_view_state: string;
}

export const UserContext = createContext<UserValues>({
  // user_messages: [],
  admin_view: false,
  app_page: "posts",
  auth0: {},
  Can,
  dashboard: { main: true, settings: false },
  goToLink: () => {},
  isAdmin: false,
  isDesktop: false,
  isMobile: true,
  managers: defaultManagers,
  pathname: window.location.pathname,
  setAdminView: () => {},
  setManagersState: () => {},
  setViewState: () => {},
  toggleManager: () => {},
  toggleDashboard: () => {},
  post_manage_view_state: "create",
});
export const useUser = () => useContext(UserContext);

const UserContextProvider: React.FC<{ children: any }> = ({ children }) => {
  let history = useHistory();
  const { pathname } = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const [auth0, setAuth0] = useState<Auth0Client | null>(null);

  const [loginUser, { data: data_user }] = useMutation(LOGIN_USER);

  const {
    getAccessTokenSilently,
    isAuthenticated,
    user: auth0_user,
  } = useAuth0();

  const [admin_view, setAdminView] = useState(false);
  const [post_manage_view_state, setViewState] = useState<string>("create");
  const [user, setUser] = useState<User | undefined>();
  const [dashboard, setDashboard] = useState<{ [key: string]: boolean }>({
    main: false,
    settings: false,
  });
  const [managers, setManagers] =
    useState<{ [key: string]: any }>(defaultManagers);

  const isAdmin = _.includes(["administrator", "founder"], user?.role);

  const toggleManager = (manager: string) => {
    const flip = !managers[manager];
    setManagers({ ...managers, [manager]: flip });
  };

  const setManagersState = (stateName: string, value: boolean) => {
    setManagers({ ...managers, [stateName]: value });
  };

  useEffect(() => {
    if (user && isAdmin && typeof localStorage !== "undefined") {
      const admin_view_ = isAdmin ? admin_view : false;
      localStorage.setItem("admin_view", admin_view_.toString());
    }
  }, [admin_view, isAdmin, user]);

  useEffect(() => {
    if (user && typeof localStorage !== "undefined") {
      const admin_view_string = localStorage.getItem("admin_view");
      const setting = admin_view_string === "true";
      const setting_ = isAdmin ? setting : false;
      setAdminView(setting_);
    }
  }, [admin_view, isAdmin, user]);

  useEffect(() => {
    const initOptions = {
      //  audience: "https://www.sentinel.ht/users",
      domain: "sentinel-ht.auth0.com",
      client_id: "a4W7xAE0TxVOkme3n9dYwrrT6hmEC266",
      scope: "openid profile email",
    };

    async function createAuthClient() {
      try {
        const auth0 = await createAuth0Client(initOptions);
        setAuth0(auth0);
      } catch (err) {
        console.log("CREATE AUTH CLIENT ERROR", err);
      }
    }

    async function checkTheSession() {
      try {
        await getAccessTokenSilently();
      } catch (err) {
        console.log("FAILED");
      }
    }

    !auth0 && createAuthClient();
    auth0 && !isAuthenticated && checkTheSession();
  }, [auth0, getAccessTokenSilently, isAuthenticated]);

  // useEffect(() => {
  //   async function getToken() {
  //     try {
  //       const check_session = await auth0.checkSession();
  //       const get_token = await auth0.getTokenSilently(initOptions);

  //       console.log("SESSION", check_session, "TOKEN", get_token);
  //     } catch (err) {
  //       console.log("error in silent token", err);
  //     }
  //   }
  //   console.log("USER TOKEN EFFECT");
  //   getToken();
  // }, [auth0, initOptions]);

  const toggleDashboard = (state: string) => {
    const flip = !dashboard[state];
    setDashboard({ ...dashboard, [state]: flip });
  };

  const goToLink = (link: string) => {
    history.push(link);
    toggleDashboard("main");
  };

  useEffect(() => {
    if (data_user) {
      const { loginUser } = data_user;
      !_.isEmpty(loginUser) && setUser(loginUser);
    }
  }, [data_user]);

  useEffect(() => {
    if (auth0_user) {
      const { email, email_verified, family_name, given_name, name, picture } =
        auth0_user;

      email &&
        email_verified &&
        loginUser({
          variables: { given_name, family_name, login: email, name, picture },
        });
    }
  }, [auth0_user, loginUser]);

  const app_page = _.split(pathname, "/", 3)[2];
  return (
    <UserContext.Provider
      value={{
        // user_messages,
        admin_view,
        app_page,
        auth0,
        Can,
        dashboard,
        goToLink,
        isAdmin,
        isDesktop,
        isMobile,
        managers,
        pathname,
        setAdminView,
        setManagersState,

        setViewState,
        toggleManager,
        // changeDisplay,
        // feedDisplay,
        toggleDashboard,
        user,
        // unreadMessagesCount,
        post_manage_view_state,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;

// function unreadMessagesCount() {
//   const firstMessages = _.uniqBy(allMessages, msg => {
//     const isReply = msg.from._id === props.user._id;
//     return isReply ? msg.to._id : msg.from._id;
//   });

//   const data = _.filter(firstMessages, message => {
//     const unread =
//       message.read === false && message.from._id !== props.user._id;
//     return unread;
//   });
//   return data.length;
// }

// useEffect(() => {
//   if (typeof localStorage !== "undefined") {
//     localStorage.setItem("feedDisplay", JSON.stringify(feedDisplay));
//   }
// }, [feedDisplay]);

// useEffect(() => {
//   if (getUsersReceivedMessages && getUsersSentMessages) {
//     const {
//       loading: toLoading,
//       error: toError,
//       messages: toMessages,
//     } = getUsersReceivedMessages;
//     const {
//       loading: fromLoading,
//       error: fromError,
//       messages: fromMessages,
//     } = getUsersSentMessages;

//     if (toError) return console.log("to error", toError);
//     if (fromError) return console.log("from error", fromError);
//     if (!toLoading && !fromLoading) {
//       setAllMessages(
//         _.orderBy(_.concat(toMessages, fromMessages), "createdAt", "desc")
//       );
//     }
//   }
// }, [getUsersReceivedMessages, getUsersSentMessages]);

// const changeDisplay = size => {
//   setFeedDisplay(size);
//   toggleDashboard("settings");
// };

//  const [user_messages, setUserMessages] = useState([]);
// const { getUsersReceivedMessages, getUsersSentMessages } = props;

// const setCachedDisplay = () => {
//   if (typeof localStorage !== "undefined") {
//     const feedDisplay = JSON.parse(
//       localStorage ? localStorage.getItem("feedDisplay") : "large"
//     );
//     return feedDisplay;
//   }
// };

//  const [feedDisplay, setFeedDisplay] = useState(setCachedDisplay()); // small, mediumsmall, mediumlarge or large

// export default _.flowRight(
//   graphql(getUsersMessagesQuery, {
//     name: "getUsersReceivedMessages",
//     skip: ({ user }) => {
//       return !user._id;
//     },
//     options: ({ user }) => {
//       const { _id } = user;
//       return {
//         variables: {
//           whereObject: { to: { _id } },
//         },
//       };
//     },
//   }),
//   graphql(getUsersMessagesQuery, {
//     name: "getUsersSentMessages",
//     skip: ({ user }) => {
//       return !user._id;
//     },
//     options: ({ user }) => {
//       const { _id } = user;
//       return {
//         variables: {
//           whereObject: { from: { _id } },
//         },
//       };
//     },
//   })
// )(UserContextProvider);
