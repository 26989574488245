import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { useHistory, useLocation } from "react-router";
import { useMutation } from "@apollo/client";
import { getBaseURL } from "../../utilities/postRenders";
import { SAVE_POST_BY_ID_MUTATION } from "../../utilities/queries";
import { defaultPost } from "../types";
//import { useHistory } from "react-router-dom";
// import { graphql } from "react-apollo";
// import {
//   deleteAttachmentMutation,
//   upsertAttachmentMutation,
//   getPostTestByDocIDQuery,
//   getPostByDocIDQuery,
//   upsertPostMutation,
//   upsertPostTestMutation,
//   getPostTestIDsQuery,
//   getPostIDsQuery,
//   getPostTestQuery,
//   getPostQuery,
// } from "../Helpers/adminQueries";

import { useAttachments } from "./attachmentsContext";
import { useFeature } from "./featureContext";
import { useNewPost } from "./newPostContext";
import { useOldPost } from "./oldPostContext";
import RouteTrans from "../Trans/routeTrans";
import { useTrans } from "../Trans/translationContext";
import { useUser } from "../User/userContext";

// import { useManagers } from "./ManagersContext";
//import { useTrans } from "../Trans/translationContext";

var _ = require("lodash");
var isEqual = require("deep-equal");
//const TESTING = process.env.REACT_APP_TESTING;

export const AdminPostContext = createContext<{
  blank_post_has_changes: boolean;
  clearPost: () => void;
  current_post: any;
  has_changes: boolean;
  preview?: string | null;
  redirect_on_publish: boolean;
  reloadOldPost: () => void;
  setPreview: (state: string | null) => void;
  setRedirectPublish: Dispatch<SetStateAction<boolean>>;
  setView: (state: string) => void;
  submitPost: (func: string) => void;
  view: string;
}>({
  blank_post_has_changes: true,
  clearPost: () => {},
  current_post: {},
  has_changes: false,
  redirect_on_publish: true,
  reloadOldPost: () => {},
  setPreview: () => {},
  setRedirectPublish: () => {},
  setView: () => {},
  submitPost: () => {},
  view: "preview",
});
export const useAdminPost = () => useContext(AdminPostContext);
const AdminPostContextProvider: React.FC<{ children: any }> = ({
  children,
}) => {
  // [][][] -------------------------------------------------------
  // POST SET UP DATA
  let history = useHistory();
  let { pathname } = useLocation();
  // // ACTUAL POST DATA
  const { oldPost, setCacheState, setOldPost } = useOldPost();
  const { feature } = useFeature();
  const { newPost } = useNewPost();
  const { attachments } = useAttachments();
  const { language } = useTrans();
  const { user } = useUser();
  const [savePostByIdMutation, { data }] = useMutation(
    SAVE_POST_BY_ID_MUTATION
  );
  const [redirect_on_publish, setRedirectPublish] = useState(true);
  const [view, setView] = useState("root");
  const [preview, setPreview] = useState<string | null | undefined>();
  const feat_comp = _.omit(feature, ["deletePhotos", "post_id"]);

  const current_post = {
    ...newPost,
    attachments,
    feature: feat_comp,
    postType: feature?.postType,
    template: feature?.template,
    type: feature?.type,
  };

  // console.log("current post", current_post);

  const default_post = {
    ...defaultPost,
    writer: user,
  };

  const has_changes = !isEqual(oldPost, current_post);
  const blank_post_has_changes = !isEqual(default_post, current_post);

  useEffect(() => {
    if (data?.savePostByIdMutation) {
      const { savePostByIdMutation } = data;
      const { language, status } = savePostByIdMutation;
      // If isCreating - should redirect
      const isCreating = _.startsWith(
        pathname,
        `/${language}/${RouteTrans["create"][language]}/${RouteTrans["post"][language]}`
      );

      const goToPublishedPost = redirect_on_publish && status === "published";

      // If redirect is true and publishing
      const shouldRedirect = goToPublishedPost || isCreating;

      setOldPost(savePostByIdMutation);
      const link = getBaseURL(
        savePostByIdMutation,
        language,
        status === "draft"
      );

      shouldRedirect && history.push(link);
    }
  }, [data, history, language, pathname, redirect_on_publish, setOldPost]);

  async function submitPost(action: string) {
    savePostByIdMutation({
      variables: {
        newPost: JSON.stringify(current_post),
      },
    });
  }

  function clearPost() {
    oldPost &&
      setCacheState({
        _id: oldPost._id,
        newPost: { ...defaultPost, postID: oldPost.postID },
        feature: defaultPost.feature,
        attachments: defaultPost.attachments,
      });
  }

  function reloadOldPost() {
    oldPost &&
      setCacheState({
        _id: oldPost._id,
        newPost: oldPost,
        feature: oldPost.feature,
        attachments: oldPost.attachments,
      });
  }

  return (
    <AdminPostContext.Provider
      value={{
        blank_post_has_changes,
        clearPost,
        current_post,
        has_changes,
        preview,
        redirect_on_publish,
        reloadOldPost,
        setPreview,
        setRedirectPublish,
        setView,
        submitPost,
        view,
      }}
    >
      {children}
    </AdminPostContext.Provider>
  );
};
export default AdminPostContextProvider;
// export default _.flowRight(
//   graphql(deleteAttachmentMutation, { name: "deleteAttachment" }),
//   graphql(upsertAttachmentMutation, { name: "upsertAttachment" }),
//   graphql(upsertPostMutation, { name: "upsertPost" }),
//   graphql(upsertPostTestMutation, { name: "upsertPostTest" })
// )(AdminPostContextProvider);

// const categoryURL = newPost.category && newPost.category.url;
// const history = useHistory();
// //const { pathname } = useLocation();

// const { fetchTranslation, RouteTrans } = useTrans();
// const { setManagersState } = useManagers();

// const {
//   client,
//   deleteAttachment,
//   upsertAttachment,
//   upsertPost,
//   upsertPostTest,
// } = props;

// // const isCreating =
// //   pathname ===
// //   `/${language}/${RouteTrans["admin"][language]}/${RouteTrans["create"][language]}}`;

// // [][][] -------------------------------------------------------------
// // PUBLISH POST

// // false && submitPostAxios();
// const setPublishSettings = async (status) => {
//   setManagersState("saving", "Preparing");
//   async function getIDsProcess() {
//     return client
//       .query({
//         query: TESTING ? getPostTestIDsQuery : getPostIDsQuery,
//         fetchPolicy: "no-cache",
//       })
//       .then((response) => {
//         const nextID =
//           _.head(
//             _.compact(
//               _.map(
//                 _.orderBy(
//                   response.data[TESTING ? "post2s" : "posts"],
//                   ["postID"],
//                   ["desc"]
//                 ),
//                 "postID"
//               )
//             )
//           ) + 1;
//         return nextID;
//       });
//   }
//   const postID =
//     status === "published" && !newPost.postID
//       ? await getIDsProcess()
//       : newPost.postID;
//   newPost.status = status === "save" ? newPost.status : status;
//   newPost.postID = postID;

//   const msg = newPost.postID ? `Preparing ${postID}` : `Preparing Draft`;
//   setManagersState("saving", msg);
// };

// const setLanguageSettings = async () => {
//   const msg = newPost.postID
//     ? `${newPost.postID} Language Check`
//     : `Draft Language Check`;
//   setManagersState("saving", msg);
//   const translatedNewPost = newPost;
//   const checkLangs = _.pull(["en", "fr", "ht"], translatedNewPost.language);
//   const checkFields = ["title", "subtitle", "body", "link"];

//   await asyncForEach(checkFields, async (field) => {
//     if (field === "link") {
//       await asyncForEach(checkLangs, async (lang) => {
//         const dev = translatedNewPost.link.name[translatedNewPost.language];
//         let trans = translatedNewPost.link.name[lang];
//         if (dev && !trans) {
//           setManagersState("saving", `Translating ${field} to ${lang}`);
//           translatedNewPost.link.name[lang] = await fetchTranslation(
//             dev,
//             lang
//           );
//         }
//       });
//     } else {
//       await asyncForEach(checkLangs, async (lang) => {
//         const dev = translatedNewPost[field][translatedNewPost.language];
//         let trans = translatedNewPost[field][lang];
//         if (dev && !trans) {
//           setManagersState("saving", `Translating ${field} to ${lang}`);
//           translatedNewPost[field][lang] = await fetchTranslation(dev, lang);
//         }
//       });
//     }
//   });
//   return translatedNewPost;
// };

// const stringifyPost = () => {
//   const checkFields = ["title", "subtitle", "body", "link"];
//   _.map(checkFields, (f) => {
//     if (f === "link") {
//       const stringed = JSON.stringify(newPost.link.name);
//       newPost.link.name = stringed;
//     } else {
//       const stringed = JSON.stringify(newPost[f]);
//       newPost[f] = stringed;
//     }
//   });
// };

// const upsertPostProcess = async (attachmentsToConnect) => {
//   await setManagersState("saving", "Saving Post");

//   const {
//     _id,
//     writer,
//     body,
//     category,
//     language,
//     organization,
//     postID,
//     published,
//     status,
//     subtitle,
//     title,
//   } = newPost;

//   await setManagersState(
//     "saving",
//     `Saving ${status === "published" ? "Published" : "Draft"} Post`
//   );
//   const { postType, template } = feature;
//   const type = postType && postType[template];

//   function getConnectObject(ID) {
//     if (ID) {
//       return { connect: { _id: ID } };
//     } else {
//       return {};
//     }
//   }

//   const coverObj = _.find(attachmentsToConnect, { purpose: "cover" });
//   const featureObj = _.find(attachmentsToConnect, { purpose: "feature" });
//   const linkObj = _.find(attachmentsToConnect, { purpose: "link" });

//   const coverID = coverObj && coverObj._id !== "" ? coverObj._id : null;
//   const featureID =
//     featureObj && featureObj._id !== "" ? featureObj._id : null;
//   const linkID = linkObj && linkObj._id !== "" ? linkObj._id : null;
//   const organizationID =
//     organization && organization._id !== "none" ? organization._id : null;

//   const coverConnect = getConnectObject(coverID);
//   const featureConnect = getConnectObject(featureID);
//   const linkConnect = getConnectObject(linkID);
//   const organizationConnect = getConnectObject(organizationID);

//   const attachmentsConnect = _.map(
//     _.filter(attachmentsToConnect, { purpose: "body" }),
//     function (object) {
//       return _.pick(object, "_id");
//     }
//   );

//   const now = new Date();
//   const _idDB = _id ? _id : "0a0000000000000000000000";
//   const publishedTime = new Date(published);
//   const publishedID = Math.round(publishedTime.valueOf() / 1000);
//   const upsertFunc = TESTING ? upsertPostTest : upsertPost;

//   const whereObject = (categoryURL, postID) => {
//     const whereData = {
//       postID,
//       category: { url: categoryURL },
//     };
//     const whereStatus = {
//       status: "published",
//     };
//     return _.merge(whereData, whereStatus);
//   };

//   const refetchQueries = [
//     {
//       query: getPostByDocIDQuery,
//       variables: {
//         _id: _idDB,
//         categoryURL,
//       },
//     },
//     {
//       query: getPostQuery,
//       variables: {
//         whereObject: whereObject(categoryURL, postID),
//       },
//     },
//   ];

//   const refetchTestingQueries = [
//     {
//       query: getPostTestByDocIDQuery,
//       variables: {
//         _id: _idDB,
//         categoryURL,
//       },
//     },
//     {
//       query: getPostTestQuery,
//       variables: {
//         whereObject: whereObject(categoryURL, postID),
//       },
//     },
//   ];

//   const variables = {
//     variables: {
//       _id: _idDB,
//       attachments: attachmentsConnect,
//       writer: writer._id,
//       body: body,
//       category: category._id,
//       cover: coverConnect,
//       coverCreate: coverConnect,
//       now,
//       nowID: Math.round(now.valueOf() / 1000),
//       organization: organizationConnect,
//       organizationCreate: organizationConnect,
//       language,
//       link: linkConnect,
//       linkCreate: linkConnect,
//       postID,
//       published: publishedTime,
//       publishedID,
//       feature: featureConnect,
//       featureCreate: featureConnect,
//       status,
//       subtitle,
//       template,
//       title,
//       type,
//     },
//     refetchQueries: TESTING ? refetchTestingQueries : refetchQueries,
//   };

//   return upsertFunc(variables).then((response) => {
//     setManagersState("saving", "Saved");

//     const { _id, category, language, postID } = response.data[
//       TESTING ? "upsertPost2" : "upsertPost"
//     ];

//     const catArray = _.split(category.url, "/");
//     const urlCore = `${RouteTrans["post"][language]}/${
//       RouteTrans[catArray[0]][language]
//     }/${RouteTrans[catArray[1]][language]}`;

//     if (status === "published") {
//       return `/${language}/${urlCore}/${postID}`;
//     } else {
//       return `/${language}/${RouteTrans["edit"][language]}/${urlCore}/${_id}`;
//     }
//   });
// };

// const upsertAttachmentsProcess = async (AttsToAdd) => {
//   await setManagersState("saving", "Saving Attachments");

//   const UpsertAttachment = async (attachment) => {
//     const {
//       _id,
//       alignment,
//       autoPlay,
//       credit,
//       description,
//       enabled,
//       handle,
//       height,
//       photos,
//       postType,
//       purpose,
//       name,
//       shape,
//       source,
//       template,
//       title,
//       width,
//     } = attachment;

//     const getType = (purpose) => {
//       if (
//         purpose === "cover" ||
//         purpose === "link" ||
//         purpose === "photoalbum"
//       ) {
//         return purpose;
//       } else {
//         return postType[template];
//       }
//     };

//     const photosToConnect = _.map(photos, function (object) {
//       return _.pick(object, "_id");
//     });

//     const _idDB = _id ? _id : "0a0000000000000000000000";
//     const attObj = await upsertAttachment({
//       variables: {
//         _id: _idDB,
//         alignment,
//         autoPlay,
//         credit,
//         description,
//         enabled,
//         handle,
//         height,
//         photos: photosToConnect,
//         purpose,
//         name,
//         shape,
//         source,
//         template,
//         title,
//         type: getType(purpose),
//         width,
//       },
//     }).then(async (response) => {
//       return response.data.upsertAttachment;
//     });
//     return attObj;
//   };

//   const AttachmentsToConnect = [];

//   await asyncForEach(AttsToAdd, async (attachment) => {
//     if (!_.isEmpty(attachment)) {
//       const AttObj = await UpsertAttachment(attachment);
//       AttachmentsToConnect.push(AttObj);
//     }
//   });

//   return AttachmentsToConnect;
// };

// const setAttachments = async () => {
//   await setManagersState("saving", "Setting Attachments");
//   async function processPhotoAlbums(attachmentsArray) {
//     await asyncForEach(attachmentsArray, async (attachment) => {
//       if (attachment.postType[attachment.template] === "photoalbum") {
//         (await attachment.deletePhotos) &&
//           deleteAttachmentsProcess(attachment.deletePhotos);
//         const attachmentsToConnect = await upsertAttachmentsProcess(
//           attachment.photos
//         );
//         attachment.photos = attachmentsToConnect;
//       }
//     });
//     return attachmentsArray;
//   }

//   async function checkAttUpsert(albumFeature, albumAttachments) {
//     const { cover, link } = newPost;
//     const returnAction = (att, cho) => {
//       const choice = cho ? att : {};
//       return choice;
//     };
//     const addingCover = cover.source && cover.source.length > 0;
//     const addingFeature =
//       (albumFeature.source && albumFeature.source.length > 0) ||
//       (albumFeature.photos && albumFeature.photos.length !== 0);
//     const addingLink = link.enabled && link.source.length > 0;
//     const AttsToAdd = _.concat(
//       albumAttachments,
//       returnAction(cover, addingCover),
//       returnAction(albumFeature, addingFeature),
//       returnAction(link, addingLink)
//     );
//     return AttsToAdd;
//   }

//   async function checkAttDelete() {
//     const { link } = newPost;
//     const returnAction = (att, cho) => {
//       const choice = cho ? att : {};
//       return choice;
//     };
//     const deletingCover = deleteCover.source && deleteCover.source.length > 0;
//     const deletingFeature =
//       deleteFeature.source && deleteFeature.source.length > 0;
//     const deletingLink = !link.enabled && link._id && link._id.length > 0;
//     const AttsToDelete = _.concat(
//       deleteAttachments,
//       returnAction(deleteCover, deletingCover),
//       returnAction(deleteFeature, deletingFeature),
//       returnAction(link, deletingLink)
//     );
//     return AttsToDelete;
//   }

//   async function deleteAttachmentsProcess(AttsToDelete) {
//     setManagersState("saving", "Deleting Attachments");
//     await asyncForEach(AttsToDelete, async (attachment) => {
//       if (attachment && attachment._id) {
//         await deleteAttachment({
//           variables: { _id: attachment._id },
//         });
//       }
//     });
//   }

//   const albumFeature = await processPhotoAlbums([feature]);
//   const albumAttachments = await processPhotoAlbums(attachments);

//   // GET ATTACHMENTS TO UPDATE AND/OR DELETE
//   // WILL SET cover, link, and any album attachments
//   const AttsToAdd = await checkAttUpsert(albumFeature[0], albumAttachments);
//   const AttsToDelete = await checkAttDelete();

//   await deleteAttachmentsProcess(AttsToDelete);

//   return AttsToAdd;
// };

// async function asyncForEach(array, callback) {
//   for (let index = 0; index < array.length; index++) {
//     await callback(array[index], index, array);
//   }
// }

// Two types of save functions, one that updates and one that saves?

// await setManagersState("saving", "Processing Request"); // Change to Saving - Managers
// await setPublishSettings(action);
// await setLanguageSettings();
// await stringifyPost();
// const attachmentsToAdd = await setAttachments();
// const attachmentsToConnect = await upsertAttachmentsProcess(
//   attachmentsToAdd
// );
//   const postLink = await upsertPostProcess(attachmentsToConnect);
//   if (typeof localStorage !== "undefined") {
//     localStorage.removeItem("_id");
//     localStorage.removeItem("newPost");
//     localStorage.removeItem("feature");
//     localStorage.removeItem("attachments");
//   }
//   await setManagersState("saving", "Done");
//   history.push(postLink);
//   await setManagersState("saving", null);
//   // if (action === "published" || newPost.status === "published") {
//   //   if (typeof localStorage !== "undefined") {
//   //     localStorage.removeItem("_id");
//   //     localStorage.removeItem("newPost");
//   //     localStorage.removeItem("feature");
//   //     localStorage.removeItem("attachments");
//   //   }
//   //   await setManagersState("saving", "Done");
//   //   history.push(postLink);
//   //   await setManagersState("saving", null);
//   // } else {
//   //   await setManagersState("saving", "Done");
//   //   if (typeof localStorage !== "undefined") {
//   //     localStorage.removeItem("_id");
//   //     localStorage.removeItem("newPost");
//   //     localStorage.removeItem("feature");
//   //     localStorage.removeItem("attachments");
//   //   }
//   //   await setManagersState("saving", "Done");
//   //   history.push(postLink);
//   //   await setManagersState("saving", null);
//   //   // isCreating
//   //   //   ? history.push(postLink)
//   //   //   : await setManagersState("saving", null);
//   //   // await refetch(); //refetch(isCreating && postLink);
//   // }
