import React from "react";
//import { FaSortUp, FaSortDown } from "react-icons/fa";
import { Avatar, Grid, Theme } from "@mui/material";
import { createStyles, withStyles } from "@mui/styles";
//import { useTheme } from "@mui/material/styles";
// import useMediaQuery from "@mui/material/useMediaQuery";
// import { Image, Transformation } from "cloudinary-react";

//var _ = require("lodash");

const styles = (theme: Theme) =>
  createStyles({
    icon: {
      //   color: theme.palette.text.detail,
      marginTop: theme.spacing(0.5),
    },
    info: {
      paddingLeft: 32,
      paddingRight: 24,
      marginTop: 20,
    },
    postCategory: {
      //  color: theme.palette.text.main,
      fontWeight: "normal",
      textTransform: "none",
      fontSize: "0.8rem",
    },
    postDivision: {
      fontWeight: "bold",
      textTransform: "uppercase",
      //  color: theme.palette.text.main,
      fontSize: "0.8rem",
    },
    organizationAvatar: {
      borderRadius: 0,
      maxHeight: 36,
    },
    writerAvatar: {
      background: theme.palette.background.paper,
      border: "2px solid rgb(254,254,254)",
      height: 44,
      width: 44,
      //    [theme.breakpoints.up("sm")]: { boxShadow: theme.shadows[8] },
    },
    writerAvatarSM: {
      background: theme.palette.background.paper,
      marginRight: theme.spacing(1),
      height: 26,
      width: 26,
      [theme.breakpoints.up("sm")]: { boxShadow: theme.shadows[8] },
    },
  });

const WriterAvatar: React.FC<{ classes: any; thisPost: any }> = ({
  classes,
  thisPost,
}) => {
  const { avatar, name } = thisPost.writer;
  const { given_name, family_name } = name.user;
  return (
    <Grid item>
      <Avatar
        aria-label={given_name + " " + family_name}
        src={`https://res.cloudinary.com/defendhaiti/image/upload/h_200,c_fit/v1560908245/${avatar.user}.jpg`}
        classes={{ root: classes.writerAvatar }}
      />
    </Grid>
  );
};

export default withStyles(styles)(WriterAvatar);
