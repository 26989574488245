import React from "react";
import classNames from "classnames";
import { FaArrowAltCircleRight } from "react-icons/fa";
import { Button, Grid, Theme, Typography } from "@mui/material";
import { createStyles, withStyles } from "@mui/styles";
import { useNewPost } from "../../../../../../context/Post/newPostContext";

const styles = (theme: Theme) =>
  createStyles({
    action_holder: {
      fontSize: ".8rem",
    },
    button: {
      fontSize: ".77rem",
      minWidth: "unset",
      // padding: "0 12px 0 0",
    },
    disabled: {
      color: theme.palette.fonts.detail,
    },
    active: {
      background: theme.palette.color.lightblue,
      minWidth: "unset",
      padding: theme.spacing(1),
    },
    flag: {
      height: "1.5em !important",
      width: "1.5em !important",
    },
    icon: {
      margin: "-2px 4px",
    },
    label: {
      fontSize: ".9em",
    },
    langOpts: {
      textAlign: "right",
    },
    left: {
      marginRight: 8,
    },
  });

const ActionControls: React.FC<{ classes: any }> = ({ classes }) => {
  const { actionFunctions, dev, editing } = useNewPost();
  const {
    setAllPost,
    clearAll,
    clearAllLangs,
    copyAllLangs,
    translateAll,
    translateAllLangs,
  } = actionFunctions;

  const isTranslating = dev !== editing;
  const transFunc = isTranslating ? translateAll : translateAllLangs;
  const copyFunc = isTranslating ? setAllPost : copyAllLangs;
  const clearFunc = isTranslating ? clearAll : clearAllLangs;
  return (
    <Grid item xs={12}>
      <Grid container>
        {isTranslating && (
          <Grid item xs>
            <Typography
              variant="button"
              classes={{ root: classNames(classes.button, classes.disabled) }}
            >
              All {dev} <FaArrowAltCircleRight className={classes.icon} />{" "}
              {editing}
            </Typography>
          </Grid>
        )}
        <Grid item>
          <Button
            classes={{ root: classNames(classes.button, classes.left) }}
            onClick={() => clearFunc(editing)}
          >
            Clear
          </Button>
        </Grid>
        <Grid item>
          <Button
            classes={{ root: classNames(classes.button, classes.left) }}
            onClick={() => copyFunc("", editing)}
          >
            Copy
          </Button>
        </Grid>
        <Grid item>
          <Button
            classes={{ root: classNames(classes.button, classes.left) }}
            onClick={() => transFunc(isTranslating ? editing : dev)}
          >
            Translate
          </Button>
        </Grid>
        {!isTranslating && (
          <Grid item xs style={{ textAlign: "right" }}>
            <Typography
              variant="button"
              classes={{ root: classNames(classes.button, classes.disabled) }}
            >
              {dev} <FaArrowAltCircleRight className={classes.icon} /> All
            </Typography>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(ActionControls);
