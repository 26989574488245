import React from "react";
import { Button, Theme } from "@mui/material";
import { createStyles, withStyles } from "@mui/styles";
import { useTrans } from "../../../../../context/Trans/translationContext";
import { Post } from "../../../../../context/types";
const styles = (theme: Theme) =>
  createStyles({
    button: {
      color: theme.palette.fonts.focus,
      borderColor: theme.palette.fonts.detail,
      marginBottom: 48,
      // background: theme.palette.secondary.background,
      //borderRadius: "0 0 8px 8px",
    },
    button_text: { fontWeight: "bold" },
    holder: {},
  });

const Link: React.FC<{ classes: any; thisPost: Post }> = ({
  classes,
  thisPost,
}) => {
  const { language } = useTrans();
  const { link } = thisPost;
  const { source, title } = link;

  return (
    <Button
      variant="outlined"
      classes={{ root: classes.button }}
      onClick={() => window.open(source)}
    >
      <span>{title?.[language]}</span>
    </Button>
  );
};

export default withStyles(styles)(Link);
