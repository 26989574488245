import React from "react";
import { Button, Grid, TextField, Theme } from "@mui/material";
import { createStyles, withStyles } from "@mui/styles";
import ButtonGroup from "@mui/material/ButtonGroup";
import Tabs from "../Edit/Post/Controls/tabs";
import BulkActions from "../Edit/Post/Controls/action";
import FieldActions from "../Edit/Post/Controls/fieldAction";
import { useAttachment } from "../../../../context/Post/attachmentContext";
import { useNewPost } from "../../../../context/Post/newPostContext";
//var dateFormat = require("dateformat");

const styles = (theme: Theme) =>
  createStyles({
    active: {
      background: theme.palette.primary.main + " !important",
      "& span": { color: "#FFF !important", fontWeight: "bold" },

      //color:
    },
    inActive: {
      //  background: theme.palette.primary.default + " !important",
    },
    container: {
      //  padding: theme.spacing.unit * 2
    },
    datePicker: {
      marginTop: theme.spacing(3),
      width: "100%",
    },
    image: {
      marginBottom: theme.spacing(3),
      marginTop: theme.spacing(1),
      "& img": {
        width: "100%",
      },
    },
    flex: {
      flex: 1,
    },
    header: {
      textShadow: "0px 4px 4px rgba(0,0,0,0.4)",
      marginBottom: 0,
    },
    selectedPhoto: {
      padding: theme.spacing(1),
    },
    textField: {
      width: "100%",
    },
  });

const AttachmentInfo: React.FC<{ classes: any }> = ({ classes }) => {
  const { editing } = useNewPost();
  const { attachment, handleChange, handleInputChange } = useAttachment();
  const { alignment, credit, description, handle, title } = attachment;

  return (
    <form className={classes.container} noValidate autoComplete="off">
      <Grid container>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <TextField
                variant="outlined"
                label="Handle"
                className={classes.textField}
                value={handle || ""}
                onChange={handleInputChange("handle")}
              />
            </Grid>
            <Grid item xs={8}>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                style={{ height: "100%" }}
              >
                <Grid item>
                  <ButtonGroup
                    variant="contained"
                    //  color="primary"
                    size="small"
                    aria-label="contained primary button group"
                  >
                    <Button
                      onClick={() => handleChange("alignment", "left")}
                      className={
                        alignment === "left" ? classes.active : classes.inActive
                      }
                    >
                      Left
                    </Button>
                    <Button
                      onClick={() => handleChange("alignment", "center")}
                      className={
                        alignment === "center"
                          ? classes.active
                          : classes.inActive
                      }
                    >
                      Center
                    </Button>
                    <Button
                      onClick={() => handleChange("alignment", "right")}
                      className={
                        alignment === "right"
                          ? classes.active
                          : classes.inActive
                      }
                    >
                      Right
                    </Button>
                  </ButtonGroup>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12}>
              <TextField
                id="standard-credit"
                label="Credit"
                variant="outlined"
                className={classes.textField}
                value={credit || ""}
                onChange={handleInputChange("credit")}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} style={{ paddingTop: 24 }}>
              <Tabs />
              <BulkActions />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="attachment-title"
                variant="outlined"
                label="Title"
                className={classes.textField}
                value={title?.[editing] || ""}
                onChange={handleInputChange("title")}
                margin="normal"
              />
              <FieldActions item="Title" field="attachment-title" />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="attachment-description"
                label="Description"
                variant="outlined"
                multiline
                maxRows="4"
                value={description?.[editing] || ""}
                onChange={handleInputChange("description")}
                className={classes.textField}
                margin="normal"
              />
              <FieldActions item="Description" field="attachment-description" />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default withStyles(styles)(AttachmentInfo);
