import React from "react";
import { FormattedMessage } from "react-intl";
import {
  InputLabel,
  FormControl,
  OutlinedInput,
  MenuItem,
  Select,
  Theme,
} from "@mui/material";
import { createStyles, withStyles } from "@mui/styles";
import { useUser } from "../../../../../../context/User/userContext";
import { useNewPost } from "../../../../../../context/Post/newPostContext";
import { User } from "../../../../../../context/types";
import { useFoundation } from "../../../../../../context/foundationContext";
//import { getAllWritersQuery } from "../../Helpers/adminQueries";

var _ = require("lodash");

const styles = (theme: Theme) =>
  createStyles({
    formField: {
      width: "100%",
      marginTop: theme.spacing(1),
    },
    label: {
      top: -6,
      left: 13,
    },
  });

const SelectWriter: React.FC<{
  classes: any;
  writer: User;
}> = ({ classes }) => {
  const { admin_view, user } = useUser();
  const { usersCollection } = useFoundation();
  const { handleInputChange, newPost } = useNewPost();
  const { writer } = newPost;

  const user_and_team = _.compact(_.concat(user?.manages, user));

  const list_of_writers = _.uniqBy(
    admin_view ? _.concat(user_and_team, usersCollection) : user_and_team,
    "_id"
  );

  const listWriters = (writersCollection: User[]) => {
    return _.reject(writersCollection, { name: null }).map((writer: User) => {
      const { _id, name } = writer;
      const { given_name, family_name } = name.user;
      return (
        <MenuItem key={_id} value={_id}>
          {given_name + " " + family_name}
        </MenuItem>
      );
    });
  };

  const value = writer?._id;

  // console.log(
  //   "is included",
  //   _.includes(list_of_writers, writer?._id),
  //   "value",
  //   value
  // );

  return (
    <FormControl classes={{ root: classes.formField }}>
      <InputLabel className={classes.label} id="select-post-writer">
        <FormattedMessage id="Writer" />
      </InputLabel>
      <Select
        labelId="select-post-writer"
        value={value}
        onChange={handleInputChange("writer")}
        input={
          <OutlinedInput
            label={<FormattedMessage id="Writer" />}
            name="writer"
          />
        }
      >
        {listWriters(list_of_writers)}
      </Select>
    </FormControl>
  );
};

export default withStyles(styles)(SelectWriter);
