import React from "react";
import { Image } from "cloudinary-react";
import { Paper } from "@mui/material";
import { withStyles } from "@mui/styles";
const styles = theme => ({
  image: {
    width: "100%",
  },
  paper: {
    boxShadow: theme.shadows[8],
    padding: theme.spacing(0.5),
  },
});

const LocalPhoto = props => {
  const { localphoto, classes } = props;

  return (
    <Paper className={classes.paper}>
      <Image
        cloudName="defendhaiti"
        publicId={localphoto.name}
        className={classes.image}
      />
    </Paper>
  );
};

export default withStyles(styles, { withTheme: true })(LocalPhoto);
