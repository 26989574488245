import React from "react";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import { withStyles } from "@mui/styles";
const styles = theme => ({
  cardContent: {
    padding: theme.spacing(1),
  },
  deletedCoverFile: {
    color: theme.palette.text.detail,
    fontSize: ".6rem",
    textAlign: "left",
  },
  deleted: {
    color: theme.palette.color.red,
    fontSize: ".75rem",
    fontWeight: "bold",
  },
  deleteCoverTitle: {
    color: theme.palette.text.action,
    fontSize: ".6rem",
    wordBreak: "break-all",
  },
});
const DeleteFeature = props => {
  const { classes, deleteFeature, restoreItem } = props;
  const { source } = deleteFeature;
  if (source && source.length > 0) {
    return (
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Grid
          item
          xs={12}
          sm={8}
          style={{ marginTop: 24, textAlign: "center", width: "100%" }}
        >
          <Card>
            <CardActionArea onClick={restoreItem}>
              <CardContent classes={{ root: classes.cardContent }}>
                <Box>
                  <Typography
                    className={classes.deleteCoverTitle}
                    variant="body1"
                  >
                    <span className={classes.deleted}>Deleted Previous</span>
                    <br />
                    {source}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    className={classes.deletedCoverFile}
                    variant="caption"
                  >
                    Click to Restore
                  </Typography>
                </Box>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      </Grid>
    );
  } else {
    return null;
  }
};
export default withStyles(styles)(DeleteFeature);
