import React from "react";
import ReactHTMLParser from "react-html-parser";
import { Grid, Theme, Typography } from "@mui/material";
import { createStyles, withStyles } from "@mui/styles";
import { useTrans } from "../../../../context/Trans/translationContext";
import { useCurrentPost } from "../../../../context/Post/currentPostContext";
//import AttachmentDeleteCard from "../../../Admin/Edit/attachmentDeleteCard";

const styles = (theme: Theme) =>
  createStyles({
    grid: {
      // paddingLeft: theme.spacing(4),
      // paddingRight: theme.spacing(4),
    },
    postTitle: {
      fontFamily: "Gotham Narrow SSm A, Gotham Narrow SSm B",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: 1.21,
      textTransform: "unset",
      [theme.breakpoints.up("xs")]: {
        fontSize: "1.67rem",
        marginBottom: theme.spacing(4),
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: "2.45rem",
        marginBottom: 42,
      },

      //   color: theme.palette.text.focus,
    },
  });

const Title: React.FC<{ classes: any }> = ({ classes }) => {
  const { language } = useTrans();
  const { currentPost } = useCurrentPost();
  const { title } = currentPost;

  return (
    <Grid item xs={12} classes={{ root: classes.grid }}>
      <Typography variant="h1" className={classes.postTitle}>
        {ReactHTMLParser(title[language])}
      </Typography>
    </Grid>
  );
};
//
export default withStyles(styles)(Title);
