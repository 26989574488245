function checkParse(item) {
  if (typeof item === "object") {
    return item;
  } else if (typeof item === "string") {
    return JSON.parse(item);
  }
  // } else {
  //   return {
  //     en: "translation missing",
  //     fr: "traduction manquante",
  //     ht: "tradiksyon manke",
  //   };
  // }
}

function processPost(postJSON) {
  const postLink = postJSON.link || {
    enabled: false,
    name: { en: "", fr: "", ht: "" },
    purpose: "link",
    template: "link",
    type: "text",
  };
  const linkName = postLink.name || { en: "", fr: "", ht: "" };
  const title = checkParse(postJSON.title);
  const subtitle = checkParse(postJSON.subtitle);
  const body = checkParse(postJSON.body);
  const link = checkParse(linkName);
  const thisPost = {
    ...postJSON,
    title,
    subtitle,
    body,
    link: { ...postLink, name: link },
  };
  return thisPost;
}

export default processPost;
