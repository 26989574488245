import React, { createContext, useContext, useEffect, useReducer } from "react";
import { AttachmentReducer } from "./attachmentReducer";
import {
  defaultAttachment,
  Attachment,
  PostType,
  StateEventFunction,
} from "../types";
import { useNewPost } from "./newPostContext";
var _ = require("lodash");
const defaultContext = {
  clearAttachment: () => {},
  attachment: defaultAttachment,
  handleChange: () => {},
  handleChangeType: () => () => {},
  handleInputChange: () => () => {},
  loadAttachment: (attachment: Attachment) => {},
  updateAttachment: (changes: any) => {},
};

export const AttachmentContext = createContext<{
  clearAttachment: (handle: string) => void;
  attachment: Attachment;
  handleChange: (stateName: string, target: string) => void;
  handleChangeType: StateEventFunction;
  handleInputChange: (stateName: string) => (event: any) => void;
  loadAttachment: (attachment: Attachment) => void;
  updateAttachment: (changes: any) => void;
}>(defaultContext);
export const useAttachment = () => useContext(AttachmentContext);
const AttachmentContextProvider: React.FC<{ children: any }> = ({
  children,
}) => {
  const { editing } = useNewPost();

  const [attachment, dispatch] = useReducer(
    AttachmentReducer,
    defaultAttachment,
    () => {
      if (typeof localStorage !== "undefined") {
        const localData = localStorage && localStorage.getItem("newAttachment");
        const localDataObject = localData
          ? JSON.parse(localData)
          : defaultAttachment;
        return localDataObject;
      }
    }
  );

  useEffect(() => {
    if (typeof localStorage !== "undefined")
      localStorage.setItem("newAttachment", JSON.stringify(attachment));
  }, [attachment]);

  const handleInputChange = (stateName: string) => (event: any) => {
    // console.log("Changing attachmentcontext", stateName, event.target.value);
    if (_.includes(["description", "title"], stateName)) {
      const lang_obj = {
        ...attachment[stateName],
        [editing]: event.target.value,
      };

      console.log("title description change", lang_obj);
      dispatch({
        type: "HANDLE_CHANGE",
        post: {
          stateName,
          target: lang_obj,
        },
      });
    } else {
      dispatch({
        type: "HANDLE_CHANGE",
        post: {
          stateName,
          target: event.target.value,
        },
      });
    }
  };

  const handleChangeType: StateEventFunction = () => event => {
    const postTypeObj = attachment.postType;
    postTypeObj[attachment.template] = event.target.value;
    dispatch({
      type: "HANDLE_CHANGE",
      post: {
        stateName: "postType",
        target: postTypeObj,
      },
    });
  };

  const handleChange = (stateName: string, target: string) => {
    console.log("Changing attachment template", stateName, target);
    dispatch({
      type: "HANDLE_CHANGE",
      post: {
        stateName,
        target,
      },
    });
  };

  const updateAttachment = (changes: any) => {
    const newState = { ...attachment, ...changes };
    dispatch({
      type: "CLEAR_ALL",
      post: newState,
    });
  };

  const getPostType = (attachment: Attachment) => {
    const { template, type } = attachment;
    const postType: PostType = {
      text: "document",
      video: "youtube",
      audio: "soundcloud",
      social: "twitter",
      photo: "localphoto",
    };
    postType[template] = type;
    return postType;
  };

  const loadAttachment = (attachment: Attachment) => {
    const attachmentPostType = {
      ...attachment,
      postType: getPostType(attachment),
    };

    dispatch({
      type: "LOAD_ATTACHMENT",
      post: attachmentPostType,
    });
  };

  const clearAttachment = () => {
    dispatch({ type: "CLEAR_ALL", post: defaultAttachment });
  };

  return (
    <AttachmentContext.Provider
      value={{
        clearAttachment,
        attachment,
        handleChange,
        handleChangeType,
        handleInputChange,
        loadAttachment,
        updateAttachment,
      }}
    >
      {children}
    </AttachmentContext.Provider>
  );
};
export default AttachmentContextProvider;
