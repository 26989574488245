import React from "react";
import { withStyles } from "@mui/styles";
import { TwitterTweetEmbed } from "react-twitter-embed";

const styles = theme => ({
  holder: {
    "& twitter-widget": {
      width: "100%",
    },
    "& .EmbeddedTweet": {
      margin: "0 auto !important",
      maxWidth: "calc(100% - 12px)",
    },
  },
  "& .twitter-tweet": {
    width: "100%",
  },
});

const Tweet = props => {
  const { classes, twitter } = props;
  const { name } = twitter;
  const renderTweet = ID => {
    return (
      <TwitterTweetEmbed
        tweetId={ID}
        options={{
          width: "100%",
          "& .EmbeddedTweet": {
            maxWidth: "calc(100% - 12px)",
          },
        }}
      />
    );
  };
  return <div className={classes.holder}>{renderTweet(name)}</div>;
};

export default withStyles(styles)(Tweet);
