import React from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import moment from "moment";
import { Grid, Theme, Typography } from "@mui/material";
import { createStyles, withStyles } from "@mui/styles";
import Moment from "react-moment";
import PostCard from "./Card";
import AdComponent from "../../../../components/Advertisements/adsense";
import { useFeed } from "../../../../context/Feed/feedSettingsContext";
import { useTrans } from "../../../../context/Trans/translationContext";
import { FormattedMessage } from "react-intl";
import { useUser } from "../../../../context/User/userContext";

const styles = (theme: Theme) =>
  createStyles({
    day_holder: {
      height: "fit-content",
      lineHeight: ".8",
      margin: "32px 0",
      textAlign: "center",
      textTransform: "uppercase",
    },
    day_of_week: {
      fontSize: "2rem",
    },
    day_of_year: {
      color: theme.palette.fonts.main,
      fontSize: ".9rem",
      textTransform: "unset",
      "& span": {
        color: theme.palette.fonts.detail,
      },
    },
    day_wrapper: {
      flexGrow: 1,
      marginBottom: 100,
    },
    container: {
      margin: "24px auto",
      maxWidth: 990,
      //  padding: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        padding: theme.spacing(3),
      },
    },
  });

const PostsDay: React.FC<{ classes: any; day: any; showAd: boolean }> = ({
  classes,
  day,
  showAd,
}) => {
  const { days } = useFeed();
  const { language, RouteTrans } = useTrans();
  const { isMobile } = useUser();
  const daysNews = days[day];
  const today = moment(day).isSame(new Date(), "day");
  moment.locale(language);
  const month = moment(day).format("M");
  const dayOfMonth = moment(day).format("D");
  const dayYear = moment(day).format("YYYY");

  const linkDay =
    language === "en" ? `${month}/${dayOfMonth}` : `${dayOfMonth}/${month}`;
  const dayLink = `/${language}/${RouteTrans["day"][language]}/${linkDay}/${dayYear}`;

  const Header = () => (
    <div className={classes.day_holder}>
      <Link to={dayLink}>
        <Typography classes={{ h3: classes.day_of_week }} variant="h3">
          {today ? <FormattedMessage id="Today" /> : moment(day).format("dddd")}
        </Typography>
        <Typography classes={{ root: classes.day_of_year }} variant="h3">
          <Moment format={language === "en" ? "MMMM Do" : "Do MMMM"}>
            {day}
          </Moment>{" "}
          <span>
            <Moment format="YYYY">{day}</Moment>
          </span>
        </Typography>
      </Link>
    </div>
  );

  const Feed = () => {
    return (
      <Grid
        container
        className={classes.container}
        style={{ width: "unset" }}
        spacing={isMobile ? undefined : 3}
      >
        {daysNews &&
          daysNews.map((post: any) => {
            return <PostCard key={post.postID} thisPost={post} />;
          })}
      </Grid>
    );
  };

  return (
    <Grid
      classes={{ root: classNames(classes.day_wrapper, "day_component") }}
      item
      xs={12}
    >
      <Header />
      <Feed />
      {showAd && <AdComponent />}
    </Grid>
  );
};

export default withStyles(styles, { withTheme: true })(PostsDay);
