import React, { useState } from "react";
import { FaYoutube } from "react-icons/fa";
import ReactPlayer from "react-player";
import { ButtonBase, Paper, Theme } from "@mui/material";
import { createStyles, withStyles } from "@mui/styles";
import { Attachment } from "../../../context/types";

const styles = (theme: Theme) =>
  createStyles({
    attachment_inner: {},
    button: { width: "100%", zIndex: 1 },
    container: {
      position: "relative",
    },
    icon: {
      background: "rgba(0,0,0,.3)",
      borderRadius: 2,
      bottom: theme.spacing(1),
      color: "white",
      fontSize: "1.3rem",
      padding: 2,
      position: "absolute",
      right: theme.spacing(1),
      zIndex: 2,
    },
    image: {
      borderRadius: theme.spacing(0.5),
      width: "100%",
    },
    media: {
      overflow: "hidden",
      paddingTop: "56.25%",
      position: "relative",
    },
    paper: {
      boxShadow: theme.shadows[8],
    },
    player: {
      height: "100%",
      left: 0,
      position: "absolute",
      top: 0,
      width: "100%",
    },
  });

const YoutubePlayer: React.FC<{
  classes: any;
  start?: boolean;
  youtube: Attachment;
}> = ({ classes, youtube }) => {
  const [open, setOpen] = useState(false);
  const { name, purpose } = youtube;

  const toggleAttachment = () => {
    const flip = !open;
    setOpen(flip);
  };

  // console.log("in feature", youtube);
  return (
    <Paper className={classes.paper}>
      <div className={classes.attachment_inner}>
        {purpose !== "body" || open ? (
          <div className={classes.media}>
            <ReactPlayer
              className={classes.player}
              config={{
                youtube: {
                  playerVars: {
                    loop: 1,
                    playlist: `https://www.youtube.com/watch?v=${name}`,
                  },
                },
              }}
              url={`https://www.youtube.com/watch?v=${name}`}
              width="100%"
              height="100%"
              playing={false}
            />
          </div>
        ) : (
          <div className={classes.container}>
            <FaYoutube className={classes.icon} />
            <ButtonBase
              classes={{ root: classes.button }}
              onClick={() => toggleAttachment()}
            >
              <img
                src={"https://img.youtube.com/vi/" + name + "/0.jpg"}
                alt="youtube"
                className={classes.image}
              />
            </ButtonBase>
          </div>
        )}
      </div>
    </Paper>
  );
};

export default withStyles(styles)(YoutubePlayer);
