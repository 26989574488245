import React from "react";
import { useQuery } from "@apollo/client";
import { createStyles, withStyles } from "@mui/styles";
//import { useAuth } from "../context/Auth/authContext";
//import { useTrans } from "../../../context/Trans/translationContext";
import Loader from "../../../components/Loader";
import User from "./profile";
import { GET_USER } from "../../../utilities/queries";
//var _ = require("lodash");
//import { useManagers } from "./Admin/Post/ManagersContext";
//import MainRoutes from "./mainRoutes";

const styles = () =>
  createStyles({
    sentinel_wrapper: {
      marginTop: 72,
      minHeight: "calc(100vh - 220px)",
    },
  });

const UserQuery: React.FC<{ route_params: any }> = ({ route_params }) => {
  //const { getDev, language } = useTrans();
  const { handleID: handle } = route_params;

  //const { currentJSONPost, infoURL } = props;
  // const titleAlias = _.kebabCase(t(`title`, title));

  const variables = {
    handle,
  };

  // RETRIEVE POST AS IT EXISTS IN DATABASE

  // const GET_USER_QUERY = gql`
  //   query($handle: String) {
  //     getUserQuery(handle: $handle) {
  //       _id
  //       login
  //       handle
  //       status
  //       order
  //       role
  //       title
  //       name
  //       firstName
  //       middleName
  //       lastName
  //       suffix
  //       email
  //       avatar
  //       picture
  //       social
  //       # organizations {
  //       #   _id
  //       #   name
  //       #   logo
  //       # }
  //       avatar_baby
  //       # birthDate
  //       biography
  //     }
  //   }
  // `;

  const { data, error, loading } = useQuery(GET_USER, {
    fetchPolicy: "no-cache",
    variables,
  });

  if (loading) return <Loader />; //<Loader status={"Getting Post Data"} />;
  if (error) return <div>{`Error! ${error}`}</div>;
  const result = data.getUser;
  return <User queried_user={result} />;
};
export default withStyles(styles)(UserQuery);
