import React from "react";
import { Grid, Theme, Typography } from "@mui/material";
import { createStyles, withStyles } from "@mui/styles";
import { CommentCount } from "disqus-react";
import { useTrans } from "../context/Trans/translationContext";
import { getBaseURL } from "../utilities/postRenders";

var _ = require("lodash");

const styles = (theme: Theme) =>
  createStyles({
    data: {
      color: theme.palette.primary.light,
      fontSize: ".7rem",
      lineHeight: 1,
      [theme.breakpoints.down("md")]: { fontSize: ".6rem" },
    },
    legend: {
      borderRight: "1px solid " + theme.palette.secondary.main,
      marginRight: theme.spacing(0.75),
      paddingRight: theme.spacing(0.75),
    },
    views: {
      //  paddingTop: 5,
    },
  });

const PostStatistics: React.FC<{
  classes: any;
  thisPost: any;
}> = ({ classes, thisPost }) => {
  const { language } = useTrans();
  const { postID, title, views } = thisPost;
  const postURL = getBaseURL(thisPost, language);

  const disqusShortname = "the-haiti-sentinel";
  const disqusConfig = {
    url: `https://sentinel.ht${postURL}`,
    identifier: postID ? postID.toString() : "unavailable",
    title: title[language],
  };

  // const checkDisqus =
  //   !_.isEmpty(disqusConfig) &&
  //   typeof Object.keys(disqusConfig) !== "undefined" &&
  //   Object.keys(disqusConfig).length > 0;

  const checkDisqus =
    disqusConfig.url &&
    disqusConfig.identifier &&
    disqusConfig.title &&
    typeof disqusConfig.url === "string" &&
    typeof disqusConfig.identifier === "string" &&
    typeof disqusConfig.title === "string";

  // console.log(
  //   typeof title,
  //   "disqusConfig",
  //   typeof disqusConfig,
  //   "checking",
  //   checkDisqus,
  //   disqusConfig
  // );

  return (
    <Grid
      classes={{ root: classes.views }}
      container
      direction="row"
      justifyContent="flex-end"
      alignItems="flex-start"
    >
      <Grid item classes={{ root: classes.legend }}>
        <Typography
          component="p"
          variant="caption"
          classes={{
            root: classes.data,
          }}
        >
          {!_.isEmpty(disqusConfig) &&
            typeof disqusConfig === "object" &&
            checkDisqus && (
              <CommentCount shortname={disqusShortname} config={disqusConfig}>
                <span aria-label="Comments" role="img">
                  💬
                </span>
              </CommentCount>
            )}
        </Typography>
      </Grid>
      <Grid item>
        <Typography
          component="p"
          variant="caption"
          classes={{
            root: classes.data,
          }}
        >
          {views ? views : 0}{" "}
          <span aria-label="Views" role="img">
            👁️
          </span>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(PostStatistics);
