import React from "react";
import { FormattedMessage } from "react-intl";
import { Grid, Theme, Typography } from "@mui/material";
import { createStyles, withStyles } from "@mui/styles";
import { useCurrentPost } from "../../../../context/Post/currentPostContext";

const styles = (theme: Theme) =>
  createStyles({
    category: {
      [theme.breakpoints.up("xs")]: {
        marginBottom: theme.spacing(3.5),
      },
      [theme.breakpoints.up("sm")]: {
        marginBottom: 44,
      },
      textTransform: "uppercase",
    },
    postCategory: {
      fontFamily: "Gotham SSm A, Gotham SSm B",
      fontSize: ".7rem",
      fontStyle: "normal",
      fontWeight: 700,
      letterSpacing: "unset",
      "& span": {
        fontFamily: "Gotham Cond SSm A, Gotham Cond SSm B",
        fontStyle: "normal",
        fontWeight: 400,
        marginLeft: 4,
      },
      [theme.breakpoints.up("sm")]: { fontSize: ".9rem" },
    },
  });
const Category: React.FC<{ classes: any }> = ({ classes }) => {
  const { currentPost } = useCurrentPost();
  const { division, name: cat_name } = currentPost.category;
  const { name: div_name } = division;

  return (
    <Grid item className={classes.category}>
      <Typography variant="h6" className={classes.postCategory}>
        <FormattedMessage id={cat_name || "uncategorized"} />
        <span>
          <FormattedMessage id={div_name || "undivided"} />
        </span>
      </Typography>
    </Grid>
  );
};

export default withStyles(styles, { withTheme: true })(Category);
