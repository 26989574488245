export const NewPostReducer = (state: any, action: any) => {
  switch (action.type) {
    case "LOAD_POST":
      return action.post;
    case "INIT_POST":
      return {
        ...state,
        category: action.post.category,
        //category: { _id: postContext.categoryList.undivided[0].id },
        published: action.post.published,
        publishedID: action.post.publishedID,
        postID: action.post.postID,
      };
    case "HANDLE_CHANGE":
      return {
        ...state,
        [action.post.stateName]: action.post.target,
      };
    case "CLEAR_ALL":
      return action.post;
    default:
      return state;
  }
};

export const ScrollReducer = (state: any, action: any) => {
  switch (action.type) {
    case "SCROLL_CHANGE":
      return action.scroll;
    default:
      return state;
  }
};
