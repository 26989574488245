import React from "react";
//import moment from "moment";
import classNames from "classnames";
import { Grid, Theme } from "@mui/material";
import { createStyles, withStyles } from "@mui/styles";
import PostsDay from "./Days";
import { useFeed } from "../../../context/Feed/feedSettingsContext";
import { useFoundation } from "../../../context/foundationContext";
// import { useFoundation } from "../../../context/foundationContext";
// import { useTrans } from "../../../context/Trans/translationContext";

var _ = require("lodash");
const styles = (theme: Theme) =>
  createStyles({
    box: {
      marginBottom: theme.spacing(3),
    },
    chip: {
      // background: theme.palette.text.main,
      // color: theme.palette.primary.default,
      height: "unset",
      marginRight: theme.spacing(1),
      padding: 2,
    },
    chip_filter: {
      //    background: theme.palette.primary.default,
      boxShadow: theme.shadows[4],
      height: "unset",
      marginRight: theme.spacing(1),
      padding: 2,
    },
    chip_label: {
      //   color: theme.palette.primary.default,
      fontSize: ".7rem",
      padding: "0 8px",
    },
    chip_filter_label: {
      //   color: theme.palette.text.main,
      fontSize: ".7rem",
      padding: "0 8px",
    },
    container: {
      marginTop: theme.spacing(3),
    },
    text: {
      // background: theme.palette.primary.three,
      // color: theme.palette.text.main,
      fontSize: ".8rem",
      fontStyle: "italic",
      marginBottom: theme.spacing(0.5),
    },
    pagination: {
      textAlign: "center",
      width: "100%",
      "& .pagination": {
        listStyleType: "none",
        margin: "32px auto",
        width: 360,
        "& li": {
          display: "table-cell",
          width: 40,
          "& a": {
            //    color: theme.palette.text.action,
          },
        },
        "& li.active": {
          fontWeight: "bold",
          "& a": {
            //       color: theme.palette.text.focus,
          },
        },
      },
    },
  });

const PublishedFeed: React.FC<{ classes: any }> = ({ classes }) => {
  const { minContentHeight: minHeight } = useFoundation();

  const {
    // clearDateFilter,
    // clearFilter,
    // date,
    days,
    // display,
    // selected,
  } = useFeed();
  //const { language } = useTrans();

  //const { categoryCollection } = useFoundation();
  // const { type, start, end } = date;

  //const mobileFill = [<Hidden mdUp></Hidden>];

  // const DateFiltered = ({ date }) => {
  //   const dateType = {
  //     day: [start],
  //     range: [start, end],
  //   };

  //   const dateText = {
  //     desc: {
  //       en: "Date Filter",
  //       fr: "Filtre de date",
  //       ht: "Dat filtre a",
  //     },
  //     clear: {
  //       en: "Clear",
  //       fr: "Clair",
  //       ht: "Klè",
  //     },
  //   };
  //   return (
  //     <Box classes={{ root: classes.box }}>
  //       <Grid container classes={{ root: classes.chips }}>
  //         <Grid item>
  //           <ButtonBase onClick={clearDateFilter}>
  //             <Chip
  //               label={`${dateText.desc[language]} | ${dateText.clear[language]}`}
  //               classes={{
  //                 root: classes.chip_filter,
  //                 text: classes.chip_filter_label,
  //               }}
  //             />
  //           </ButtonBase>
  //         </Grid>
  //         {dateType[type].map((item) => {
  //           return (
  //             <Grid key={item} item>
  //               <Chip
  //                 label={moment(item).format("LL")}
  //                 classes={{
  //                   root: classes.chip,
  //                   text: classes.chip_label,
  //                 }}
  //               />
  //             </Grid>
  //           );
  //         })}
  //       </Grid>
  //     </Box>
  //   );
  // };

  // const Filtered = ({ filter }) => {
  //   const filterText = {
  //     desc: {
  //       en: "Category Filter",
  //       fr: "Filtre de catégorie",
  //       ht: "Kategori Filtre a",
  //     },
  //     clear: {
  //       en: "Clear",
  //       fr: "Clair",
  //       ht: "Klè",
  //     },
  //   };

  //   return (
  //     <Box classes={{ root: classes.box }}>
  //       <Grid container classes={{ root: classes.chips }}>
  //         <Grid item>
  //           <ButtonBase onClick={clearFilter}>
  //             <Chip
  //               label={`${filterText.desc[language]} | ${filterText.clear[language]}`}
  //               classes={{
  //                 root: classes.chip_filter,
  //                 text: classes.chip_filter_label,
  //               }}
  //             />
  //           </ButtonBase>
  //         </Grid>
  //         {selected.map((item) => {
  //           const name = _.find(categoryCollection, { categoryID: item })
  //             .categoryName;
  //           return (
  //             <Grid key={item} item>
  //               <Chip
  //                 label={name}
  //                 classes={{
  //                   root: classes.chip,
  //                   text: classes.chip_label,
  //                 }}
  //               />
  //             </Grid>
  //           );
  //         })}
  //       </Grid>
  //     </Box>
  //   );
  // };

  return (
    <Grid
      container
      classes={{ root: classNames(classes.container, "published_component") }}
      style={minHeight ? { minHeight } : {}}
    >
      {/* {selected.length > 0 && <Filtered filter={selected} />}
      {date.type && <DateFiltered date={date} />} */}
      {_.keys(days).map((day: string) => {
        const showAd = false;

        return (
          <PostsDay
            key={day}
            day={day}
            showAd={showAd}
            //         display={display}
            //   mobileFill={mobileFill}
            //       order={idx}
          />
        );
      })}
      {/* <Grid item classes={{ root: classes.pagination }}>
        {false ? (
          <Pagination
            activePage={pageView}
            itemsCountPerPage={5}
            totalItemsCount={totalDays}
            pageRangeDisplayed={5}
            onChange={goToPageView}
          />
        ) : (
          <Button>Load more</Button>
        )}
      </Grid> */}
    </Grid>
  );
};

export default withStyles(styles, { withTheme: true })(PublishedFeed);
/*
          <Typography variant="h5" classes={{ root: classes.text }}>
          {`${t("Date Filter")}:`}
        </Typography>
  <Typography variant="h5" classes={{ root: classes.text }}>
{`${t("Category Filter")}:`}
</Typography> */
