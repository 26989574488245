import React, { useEffect } from "react";
// import { Query } from "react-apollo";
// import { Image, Transformation } from "cloudinary-react";
import { Theme } from "@mui/material";
import { createStyles, withStyles } from "@mui/styles";
//import { getAllOrganizationsQuery } from "../Admin/Helpers/adminQueries";
import { useCurrentProfile } from "../../../context/Profile/currentProfileContext";
import Filtered from "../Feed/filtered";
import Loader from "../../../components/Loader";

//var _ = require("lodash");

const styles = (theme: Theme) =>
  createStyles({
    logo: {
      width: "100%",
    },
    logo_mobile: {
      paddingBottom: 3,
      width: 10,
      marginRight: theme.spacing(0.5),
    },
    name: {
      color: theme.palette.fonts.detail,
      fontSize: ".65rem",
    },
  });

interface UserProps {
  queried_user: any;
}

const UserProfile: React.FC<UserProps> = ({ queried_user: user }) => {
  const { currentProfile, setCurrentProfile } = useCurrentProfile();
  const { _id, handle } = currentProfile;
  const path = [
    { dir: "user", type: "route" },
    { dir: handle, type: "docID" },
  ];
  const isProfile = handle && handle === user.handle;
  useEffect(() => {
    !isProfile && setCurrentProfile(user);
  }, [isProfile, setCurrentProfile, user]);
  if (isProfile) {
    return (
      <>
        <Filtered path={path} user={_id} />
      </>
    );
  } else {
    return <Loader />;
  }
};

export default withStyles(styles)(UserProfile);

/*
<Query query={getAllOrganizationsQuery}>
        {({ loading, error, data }) => {
          if (loading) return <Loader status={"Compiling Organizations"} />;
          if (error) return `Error! ${error}`;
          const { organizations } = data;
          return (
            <Grid container spacing={5} style={{ padding: 24 }}>
              {_.orderBy(organizations, [
                (organization) => organization.name.toLowerCase(),
              ]).map((org) => {
                const { _id, logo, name } = org;
                return (
                  <Grid key={_id} item xs={6} sm={3}>
                    <Grid
                      container
                      direction="column"
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <Grid item>
                        <Image className={classes.logo} publicId={logo}>
                          <Transformation width="200" crop="scale" />
                        </Image>
                      </Grid>
                      <Grid item>
                        <Grid container>
                          <Grid item>
                            <Image
                              className={classes.logo_mobile}
                              publicId={logo + "_m"}
                            >
                              <Transformation width="20" crop="scale" />
                            </Image>
                          </Grid>
                          <Grid item>
                            <Typography
                              component="p"
                              classes={{ root: classes.name }}
                            >
                              {name}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          );
        }}
      </Query>
*/
