import React, { useState } from "react";
//import cloudinary from "cloudinary";
import { Box, Button, Grid, Theme } from "@mui/material";
import { createStyles, withStyles } from "@mui/styles";
import { FaUpload } from "react-icons/fa";
import { useUser } from "../../../../../context/User/userContext";
//import { Attachment } from "../../../../../../../../../context/types";
import { useAttachment } from "../../../../../context/Post/attachmentContext";
//import SelectedFile from "./selectedFile";

var _ = require("lodash");

const {
  REACT_APP_CLOUDINARY_API_KEY,
  //  REACT_APP_CLOUDINARY_API_SECRET,
  REACT_APP_CLOUDINARY_CLOUD_NAME,
  REACT_APP_CLOUDINARY_USER_NAME,
} = process.env;

const timeStamp = _.floor(_.now() / 1000);

const styles = (theme: Theme) =>
  createStyles({
    appBar: {
      background: theme.palette.secondary.main,
      position: "relative",
    },
    button: {
      color: theme.palette.fonts.main,
      maxWidth: 350,
      width: "100%",
      marginTop: theme.spacing(1),
    },
    icon: {
      marginLeft: theme.spacing(1),
    },
    dialog_content: {
      margin: "0 auto",
      maxWidth: 645,
      padding: theme.spacing(3),
      width: "100%",
    },
    flex: {
      flex: 1,
    },
    header: {
      //  color: theme.palette.primary.default,
      fontWeight: "bold",
      textAlign: "center",
      textShadow: theme.palette.shadow.textShadow,
    },
  });

// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });
const useForceUpdate = () => useState()[1];
const FilePicker: React.FC<{
  classes: any;
  //closeManager: () => void;
  header: string;
  //open: boolean;
  preset: string;
  submitFile: (changes: any) => void;
}> = ({
  classes,
  //closeManager,
  header,
  // open,
  preset,
  submitFile,
}) => {
  const forceUpdate = useForceUpdate();
  // const { cloudinary } = window;
  const { Can, user } = useUser();
  const { attachment, loadAttachment } = useAttachment();
  // const [attachment, setAttachment] = useState(attachmentObject);

  // useEffect(() => {
  //   if (!_.isEqual(attachment, attachmentObject)) {
  //     loadAttachment(attachmentObject);
  //   }
  // }, [attachment, attachmentObject, loadAttachment]);

  // const album = preset === "SentinelAlbum";

  // const showWidget = () => {
  //   const widgetSigned = window.cloudinary.createUploadWidget(
  //     {
  //       cloudName: REACT_APP_CLOUDINARY_CLOUD_NAME,
  //       uploadPreset: preset,
  //       //apiKey: REACT_APP_CLOUDINARY_API_KEY,
  //       //uploadSignature: generateSignature,
  //       multiple: album,
  //       cropping: album ? false : "server",
  //       cropping_coordinates_mode: "custom",
  //       sources: ["local", "url", "dropbox"],
  //     },
  //     (error: any, result: any) => {
  //       if (album) {
  //         processMultipleResult("upload", result, error);
  //       } else {
  //         console.log("showWidget", "result", result, "error", error);
  //         processResult("upload", result, error);
  //       }
  //     }
  //   );
  //   widgetSigned.open();
  // };

  const showWidget = () => {
    const widgetSigned = window.cloudinary.createUploadWidget(
      {
        cloudName: REACT_APP_CLOUDINARY_CLOUD_NAME,
        sources: ["local", "url", "dropbox"],
        uploadPreset: preset,
      },
      (error: any, result: any) => {
        if (!error && result && result.event === "success") {
          console.log(
            "Done! Here is the image info: ",
            result.info,
            "result object",
            result
          );
          const { public_id, secure_url } = result;
          loadAttachment({
            ...attachment,
            name: public_id,
            source: secure_url,
          });
        }
      }
    );
    widgetSigned.open();
  };

  const processResult = (public_id: string, secure_url: string) => {
    console.log("name", public_id, "source", secure_url);

    loadAttachment({
      ...attachment,
      name: public_id,
      source: secure_url,
    });

    // if (source === "upload") {
    //   if (error) {
    //     console.log(error);
    //   } else if (result.event === "success") {
    //     setAttachment({
    //       ...attachment,
    //       name: result.info.public_id,
    //       source: result.info.secure_url,
    //     });
    //   }
    // } else {
    //   setAttachment({
    //     ...attachment,
    //     name: result.public_id,
    //     source: result.secure_url,
    //   });
    // }
    forceUpdate(undefined);
  };

  const showLibrary = () =>
    //  processResult: (source: string, result: any, error: any) => void
    {
      window.cloudinary.openMediaLibrary(
        {
          cloud_name: REACT_APP_CLOUDINARY_CLOUD_NAME,
          api_key: REACT_APP_CLOUDINARY_API_KEY,
          username: REACT_APP_CLOUDINARY_USER_NAME,
          timestamp: timeStamp,
        },
        {
          insertHandler: function (data: any) {
            console.log("on insert data ", data);
            data.assets.forEach((asset: any) => {
              //  const { source, result, error } = asset;
              const { public_id, secure_url } = asset;
              // console.log("showLibrary", "asset", asset);
              //processResult('library',asset)
              processResult(public_id, secure_url);
            });
          },
        }
      );
    };

  // const processMultipleResult = (source: string, result: any, error: any) => {
  //   console.log("THe Result", result);
  //   if (source === "upload") {
  //     if (error) {
  //       console.log(error);
  //     } else if (result.event === "queues-end") {
  //       console.log("Before", attachment.photos);
  //       const newArray = _.concat(
  //         attachment.photos,
  //         setPhotosCollection(result.info.files)
  //       );
  //       console.log("The new array", newArray);
  //       setAttachment({
  //         ...attachment,
  //         photos: newArray,
  //       });
  //     }
  //   } else {
  //     const newArray = _.concat(
  //       attachment.photos,
  //       setPhotosCollection(result.info.files)
  //     );
  //     setAttachment({
  //       ...attachment,
  //       photos: newArray,
  //     });
  //   }
  //   forceUpdate(undefined);
  // };

  // const setPhotosCollection = (photos: any[]) => {
  //   let photoCollection: any[] = [];
  //   photos.forEach((photo: any) => {
  //     const { height, public_id, secure_url, width } = photo.uploadInfo;
  //     const photoObj = {
  //       height,
  //       name: public_id,
  //       source: secure_url,
  //       purpose: "photoalbum",
  //       width,
  //     };
  //     console.log("Photo Objects", photoObj);
  //     photoCollection.push(photoObj);
  //   });
  //   return photoCollection;
  // };

  // const changeOrientation = (shape: string) => {
  //   setAttachment({ ...attachment, shape });
  // };

  // const updateAlbumPhotoTitle = (name: string, title: Language) => {
  //   const photoCollection = attachment.photos;
  //   const photoObject = _.find(photoCollection, ["name", name]);
  //   photoObject.title = title;
  //   setAttachment({ ...attachment, photos: photoCollection });
  // };

  // const removeAttachment = (name: string) => {
  //   const deletedAttachment = _.find(attachment.photos, ["name", name]);
  //   const deletePhotos = attachment.deletePhotos;
  //   if (deletePhotos && deletedAttachment._id) {
  //     deletePhotos.push(deletedAttachment);
  //     setAttachment({
  //       ...attachment,
  //       deletePhotos,
  //       photos: _.reject(attachment.photos, ["name", name]),
  //     });
  //   } else {
  //     setAttachment({
  //       ...attachment,
  //       photos: _.reject(attachment.photos, ["name", name]),
  //     });
  //   }
  // };

  // const restoreAttachment = (name: string) => {
  //   const restoredAttachment = _.find(attachment.deletePhotos, ["name", name]);
  //   const newArray = _.concat(attachment.photos, restoredAttachment);
  //   setAttachment({
  //     ...attachment,
  //     deletePhotos: _.reject(attachment.deletePhotos, ["name", name]),
  //     photos: newArray,
  //   });
  // };

  return (
    <Box className={classes.dialog_content}>
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
        style={{ width: "100%" }}
        spacing={2}
      >
        <Grid item xs={12}>
          <Button
            ref={el => el}
            variant="outlined"
            className={classes.button}
            onClick={() => showWidget()}
            //onClick={() => console.log("show widget clicked")}
          >
            Upload {header}
            <FaUpload className={classes.icon} />
          </Button>
        </Grid>
        <Can
          role={user?.role}
          perform="manage:users"
          yes={() => (
            <Grid item xs={12}>
              <Button
                ref={el2 => el2}
                variant="outlined"
                className={classes.button}
                onClick={() => showLibrary()}
              >
                Choose from Library
              </Button>
            </Grid>
          )}
        />
      </Grid>
    </Box>
  );
};

export default withStyles(styles)(FilePicker);

/* <Dialog
fullScreen
open={open}
//  TransitionComponent={<Slide direction="up" />}
>
<AppBar className={classes.appBar}>
  <Toolbar>
    <IconButton color="inherit" onClick={closeManager} aria-label="Close">
      <FaTimes className={classes.icon} />
    </IconButton>
    <Typography
      variant="h4"
      className={classNames(classes.flex, classes.header)}
    >
      {header}
    </Typography>

    <IconButton
      color="inherit"
      disabled={
        !attachment.source &&
        attachment.photos &&
        attachment.photos.length === 0
      }
      onClick={() => submitFile(attachment)}
    >
      <FaPlus className={classes.icon} />
    </IconButton>
  </Toolbar>
</AppBar>     </Dialog>*/
