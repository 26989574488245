import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { Route, Switch } from "react-router-dom";
import {
  FaBars,
  // FaClock,
  // FaComments,
  // FaDraftingCompass,
  // FaGlobe,
  // FaGlobeAfrica,
  // FaImage,
  // FaMicrophone,
  // FaNewspaper,
  // FaVideo,
} from "react-icons/fa";
import { AppBar, createStyles, Grid, IconButton, Theme } from "@mui/material";
import { withStyles } from "@mui/styles";
//import { FormattedMessage } from "react-intl";
import UserProfile from "./User";
import ManageHeader from "./header";
import Loader from "../../components/Loader";
import LanguageSelector from "./languageSelector";
import { useCurrentProfile } from "../../context/Profile/currentProfileContext";
// import { useFeature } from "../../context/Post/featureContext";
// import { useMyPosts } from "../../context/Feed/myPostsFeedContext";
import { useUser } from "../../context/User/userContext";
import { useTrans } from "../../context/Trans/translationContext";
import { useFoundation } from "../../context/foundationContext";
import TitleBar from "./TitleBar";

const styles = (theme: Theme) =>
  createStyles({
    appBar: {
      background: "none",
      boxShadow: "none",
      right: "unset",
      width: "100%",
      [theme.breakpoints.up("xs")]: {
        height: theme.spacing(6),
      },
      [theme.breakpoints.up("sm")]: {
        height: 52,
      },
      [theme.breakpoints.up("sm")]: {
        maxWidth: 645,
      },
      [theme.breakpoints.up("md")]: {
        maxWidth: 845,
      },
      [theme.breakpoints.up("lg")]: {
        maxWidth: 960,
      },
      [theme.breakpoints.up("xl")]: {
        maxWidth: 1160,
      },
    },
    appBar_closed: {
      transform: "translateY(-64px)",
      transition: theme.transitions.create("transform", {
        easing: theme.transitions.easing.easeOut,
        duration: 1000, //theme.transitions.duration.enteringScreen
      }),
    },
    appBar_open: {
      transform: "translateY(0px)",
      transition: theme.transitions.create("transform", {
        easing: theme.transitions.easing.easeOut,
        duration: 200, //theme.transitions.duration.enteringScreen
      }),
    },
    appbar_background: {
      background: "rgb(254 254 254 / 85%)",
    },
    brand: {
      // height: 38,
      position: "absolute",
      width: "100%",
      zIndex: 2,
      [theme.breakpoints.up("xs")]: {
        minHeight: 38,
      },
      [theme.breakpoints.up("sm")]: {
        minHeight: 52,
      },
    },
    logo: {
      width: 115,
    },
    logo_svg: {
      paddingBottom: 16,
      width: 0,
      "& svg": {
        height: 18,
      },
    },
    menu: {
      color: theme.palette.primary.main,
      flexBasis: "0 | content",
      fontSize: 18,
      marginLeft: theme.spacing(1),
      [theme.breakpoints.up("xs")]: {
        padding: "8px 8px 24px 24px",
      },
      [theme.breakpoints.up("sm")]: {
        padding: 16,
      },
      /*[theme.breakpoints.up("xl")]: {
      display: "none"
    }*/
    },
    sitebar_holder: {
      height: 62,
      [theme.breakpoints.down("md")]: {
        height: theme.spacing(6),
      },
    },
    site_menu: {
      height: "100%",
    },
    spacer: {},
    title: {
      fontWeight: 700,
      [theme.breakpoints.down("sm")]: {
        fontSize: 18,
        height: 8,
        //textShadow: theme.palette.shadow.title,
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: 28,
        paddingTop: 14,
      },
      // fontWeight: "bold",
      textTransform: "uppercase",
      "& a": {
        //   color: TESTING ? theme.palette.primary.focus : theme.palette.text.focus,
      },
    },
    title_mini: {
      [theme.breakpoints.down("sm")]: {
        fontSize: ".8rem",
        height: 8,
        //textShadow: theme.palette.shadow.title,
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: 28,
        paddingTop: 14,
      },
      // fontWeight: "bold",
      textTransform: "uppercase",
      "& a": {
        //   color: TESTING ? theme.palette.primary.focus : theme.palette.text.focus,
      },
    },
    title_bar: {
      height: 50,
    },
    title_logo: {
      margin: "0 8px",
      "& a": {
        height: 0,
      },
    },
  });

const SiteAppBar: React.FC<{ classes: any; language: string }> = ({
  classes,
  language,
}) => {
  // let history = useHistory();

  // const { feature, handleFeatureChange: handleMainTemplateChange } =
  //   useFeature();
  const { ref_header } = useFoundation();
  const { RouteTrans } = useTrans();
  const { toggleDashboard } = useUser();
  const { currentProfile } = useCurrentProfile();
  // const { handleManagePostsStatusChange, status } =
  //   useMyPosts();

  const [direction, setDirection] = useState(true);

  //const { template: feature_template } = feature;

  useEffect(() => {
    let prevScroll = window.scrollY;
    let curScroll;

    function checkScroll() {
      curScroll = window.scrollY;

      if (curScroll > prevScroll && curScroll > 50) {
        //scrolled up
        setDirection(false);
      } else if (curScroll < prevScroll) {
        //scrolled down
        setDirection(true);
      }
      prevScroll = curScroll;
    }

    window.addEventListener("scroll", checkScroll);
    return () => {
      window.removeEventListener("scroll", checkScroll);
    };
  }, []);

  // const header_tabs: { [key: string]: any } = {
  //   admin: [
  //     {
  //       icon: <FaGlobe />,
  //       name: <FormattedMessage id="All" />,
  //       value: "all",
  //     },
  //     {
  //       icon: <FaClock />,
  //       name: <FormattedMessage id="Pending" />,
  //       value: "pending",
  //     },
  //     {
  //       icon: <FaDraftingCompass />,
  //       name: <FormattedMessage id="Drafts" />,
  //       value: "drafts",
  //     },
  //     {
  //       icon: <FaGlobeAfrica />,
  //       name: <FormattedMessage id="Published" />,
  //       value: "published",
  //     },
  //   ],
  //   edit: [
  //     {
  //       icon: <FaNewspaper />,
  //       name: <FormattedMessage id="text" />,
  //       value: "text",
  //     },
  //     {
  //       icon: <FaVideo />,
  //       name: <FormattedMessage id="video" />,
  //       value: "video",
  //     },
  //     {
  //       icon: <FaMicrophone />,
  //       name: <FormattedMessage id="audio" />,
  //       value: "audio",
  //     },
  //     {
  //       icon: <FaComments />,
  //       name: <FormattedMessage id="social" />,
  //       value: "social",
  //     },
  //     {
  //       icon: <FaImage />,
  //       name: <FormattedMessage id="photo" />,
  //       value: "photo",
  //     },
  //   ],
  // };

  // const header: { [key: string]: any } = {
  //   manage: {
  //     action: () =>
  //       history.push(
  //         `/${language}/${RouteTrans["create"][language]}/${RouteTrans["post"][language]}`
  //       ),
  //     button: "+",
  //     onChange: handleManagePostsStatusChange,
  //     tabs: header_tabs.admin,
  //     title: (
  //       <FormattedMessage id="Manage Posts" defaultMessage="Manage Posts" />
  //     ),
  //     value: status,
  //   },
  //   create: {
  //     action: () =>
  //       history.push(
  //         `/${language}/${RouteTrans["manage"][language]}/${RouteTrans["posts"][language]}`
  //       ),
  //     button: "x",
  //     onChange: handleMainTemplateChange,
  //     tabs: header_tabs.edit,
  //     title: <FormattedMessage id="Creating Post" />,
  //     value: feature_template,
  //   },
  //   edit: {
  //     action: () =>
  //       history.push(
  //         `/${language}/${RouteTrans["manage"][language]}/${RouteTrans["posts"][language]}`
  //       ),
  //     button: "x",
  //     tabs: header_tabs.edit,
  //     title: <FormattedMessage id="Editing Post" />,
  //     value: feature_template,
  //   },
  // };

  return (
    <AppBar
      color="inherit"
      position="fixed"
      className={classNames(
        classes.appBar,
        direction ? classes.appBar_open : classes.appBar_closed
      )}
    >
      <Grid
        container
        classes={{ root: classes.appbar_background }}
        ref={ref_header}
      >
        <Grid item classes={{ root: classes.sitebar_holder }} xs={12}>
          <Grid container className={classes.brand}>
            <Grid item>
              <LanguageSelector />
            </Grid>
            <TitleBar />
            <Grid item>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={() => {
                  toggleDashboard("main");
                }}
                classes={{ root: classes.menu }}
              >
                <FaBars />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
        <Switch>
          <Route
            path={`/${language}/(${RouteTrans["create"][language]}|${RouteTrans["edit"][language]}|${RouteTrans["manage"][language]})`}
            render={() => <ManageHeader />}
          />
          {/* <Route
            path={`/${language}/${RouteTrans["edit"][language]}/${RouteTrans["post"][language]}`}
            render={() => {
              return (
                <Can
                  role={user?.role}
                  perform={"edit:post"}
                  yes={() => (
                    <Grid item xs={12}>
                      <Header base_settings={header.edit} />
                    </Grid>
                  )}
                />
              );
            }}
          />
          <Route
            path={`/${language}/${RouteTrans["manage"][language]}/${RouteTrans["posts"][language]}`}
            render={() => {
              return (
                <Can
                  role={user?.role}
                  perform="view:drafts"
                  yes={() => (
                    <Grid item xs={12}>
                      <Header base_settings={header.manage} />
                    </Grid>
                  )}
                />
              );
            }}
          /> */}
          <Route
            path={`/${language}/${RouteTrans["user"][language]}/:handleID`}
            render={(props: any) => {
              const { handle } = currentProfile;
              const { handleID } = props.match.params;

              const isProfile = handle && handle === handleID;
              if (isProfile) {
                return (
                  <Grid item xs={12}>
                    <UserProfile profile={currentProfile} />
                  </Grid>
                );
              } else {
                return <Loader />;
              }
            }}
          />
        </Switch>
      </Grid>
    </AppBar>
  );
};

export default withStyles(styles)(SiteAppBar);
