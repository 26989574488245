import React from "react";
import { Button, ButtonGroup, Grid, IconButton, Theme } from "@mui/material";
import { createStyles, withStyles } from "@mui/styles";
import { FaColumns, FaEye, FaSquare, FaTimesCircle } from "react-icons/fa";
import ReactCountryFlag from "react-country-flag";
import { useNewPost } from "../../../../../../context/Post/newPostContext";
import { useUser } from "../../../../../../context/User/userContext";

var _ = require("lodash");

const styles = (theme: Theme) =>
  createStyles({
    button: {
      minWidth: "unset",
      padding: "0 0 0 12px",
      borderRight: "0 !important",
    },
    active: {
      minWidth: "unset",
      padding: "0 0 0 12px",
      borderRight: "0 !important",
      //  background: theme.palette.color.verylightblue,
      // borderRadius: "6px 6px 0 0",
      // borderRight: "0 !important",
      // minWidth: "unset",
      // padding: 0,
    },
    inactive: {
      minWidth: "unset",
      padding: "0 0 0 12px",
      borderRight: "0 !important",
      opacity: 0.2,
    },
    flag: {
      height: "1.5em !important",
      width: "1.5em !important",
    },
    icons: {
      color: theme.palette.fonts.detail,
    },
    label: {
      fontSize: ".9em",
    },
    langOpts: {
      textAlign: "right",
    },
    preButton: {
      fontSize: "1.1rem",
      padding: theme.spacing(1),
    },
    preview: {
      textAlign: "center",
    },
  });

const SelectControls: React.FC<{
  classes: any;
}> = ({ classes }) => {
  const {
    actionFunctions,
    dev,
    editing,
    isTranslating,
    setShowDev,
    setSplitForm,
    showDev,
    splitForm,
  } = useNewPost();
  const { isDesktop } = useUser();

  const { setEditing } = actionFunctions;

  const devButton: { [key: string]: any } = {
    en: (
      <ReactCountryFlag
        countryCode="US"
        svg
        className={classes.flag}
        title="USA"
      />
    ),
    fr: (
      <ReactCountryFlag
        countryCode="FR"
        svg
        className={classes.flag}
        title="French"
      />
    ),
    ht: (
      <ReactCountryFlag
        countryCode="HT"
        svg
        className={classes.flag}
        title="Haiti"
      />
    ),
  };

  function togglePreview() {
    const flip = !showDev;
    setShowDev(flip);
  }

  function toggleSplitView() {
    const flip = !splitForm;
    setSplitForm(flip);
  }

  function mainActive() {
    const result = isTranslating && !splitForm ? "inactive" : "active";
    return result;
  }

  function alternativeActive(key: string) {
    const isActive = editing === key ? "active" : "inactive";
    const result = isTranslating || splitForm ? isActive : "active";
    return result;
  }

  return (
    <Grid item xs={12}>
      <Grid container>
        <Grid item xs={4}>
          <Button
            classes={{
              root: classes[mainActive()],
            }}
            disabled={splitForm}
            onClick={() => setEditing(dev)}
          >
            {devButton[dev]}
          </Button>
        </Grid>
        <Grid item xs={4} classes={{ root: classes.preview }}>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            {dev !== editing && !splitForm && (
              <Grid item>
                <IconButton
                  onClick={() => togglePreview()}
                  classes={{ root: classes.preButton }}
                >
                  {showDev ? (
                    <FaTimesCircle className={classes.icons} />
                  ) : (
                    <FaEye className={classes.icons} />
                  )}
                </IconButton>
              </Grid>
            )}
            {isDesktop && (
              <Grid item>
                <IconButton
                  onClick={() => toggleSplitView()}
                  classes={{ root: classes.preButton }}
                >
                  {splitForm ? (
                    <FaSquare className={classes.icons} />
                  ) : (
                    <FaColumns className={classes.icons} />
                  )}
                </IconButton>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={4} classes={{ root: classes.langOpts }}>
          <ButtonGroup variant="text" aria-label="text primary button group">
            {_.map(devButton, (b: any, key: string) => {
              if (key !== dev)
                return (
                  <Button
                    key={key}
                    classes={{
                      root: classes[alternativeActive(key)],
                    }}
                    onClick={() => setEditing(key)}
                  >
                    {b}
                  </Button>
                );
            })}
          </ButtonGroup>
        </Grid>
      </Grid>
    </Grid>
  );
  // } else {
  //   return <>"Loading..."</>;
  // }
};

/* <Button
classes={{ root: editing === "fr" ? classes.active : classes.button }}
onClick={() => setEditing("fr")}
> */

export default withStyles(styles)(SelectControls);
