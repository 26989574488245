import React from "react";
import { Grid, TextField, Theme } from "@mui/material";
import { withStyles } from "@mui/styles";
//import Submit from "../Commons/submit";
//import PostLoader from "../../../Posts/Post/Helpers/postLoader";

//var _ = require("lodash");

const styles = (theme: Theme) => ({
  controls: {
    height: "inherit",
  },
  form: {
    [theme.breakpoints.up("sm")]: {
      minHeight: "calc(100vh - 114px)",
    },
    [theme.breakpoints.down("sm")]: {
      minHeight: "calc(100vh - 106px)",
    },
  },
  formField: {
    width: "100%",
    "& input": {
      color: theme.palette.fonts.focus,
    },
  },
  body: {
    flex: 1,
  },
  handle: {
    flex: "none",
    width: 120,
  },
  textButton: {
    textShadow: "0px 2px 3px rgba(0,0,0,0.4)",
  },
});

// There is not a need for a "link" type of post, essential it is an article

const TextFieldAttachmentInput: React.FC<{
  classes: any;
  helperText: string;
  onBlurFunction: () => void;
  onChangeFunction: (e: any, state_modifier: string) => void;
  onFocusFunction: () => void;
  disabled?: boolean;
  info: string;
  sourceLabel: string;
  state_modifier: string;
}> = ({
  classes,
  helperText,
  onBlurFunction,
  onChangeFunction,
  onFocusFunction,
  disabled,
  info,
  sourceLabel,
  state_modifier,
}) => {
  return (
    <Grid item className={classes.body}>
      <TextField
        id="link-url"
        disabled={disabled}
        variant="outlined"
        label={sourceLabel}
        value={info}
        helperText={helperText}
        className={classes.formField}
        margin="normal"
        onFocus={onFocusFunction}
        onBlur={onBlurFunction}
        onChange={(e: any) => onChangeFunction(e, state_modifier)}
      />
    </Grid>
  );
};

export default withStyles(styles)(TextFieldAttachmentInput);
