import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { Box, Grid, Theme } from "@mui/material";
import { createStyles, withStyles } from "@mui/styles";
import { useFoundation } from "../../context/foundationContext";
import { useTrans } from "../../context/Trans/translationContext";
import About from "./About";
import Feed from "./Feed";
import Footer from "../Footer";
import Manage from "./Manage";
import PostQuery from "./Post";
import UserQuery from "./User";

const styles = (theme: Theme) =>
  createStyles({
    sentinel_wrapper: {
      marginTop: 72,
      minHeight: "calc(100vh - 220px)",
    },
  });

const MainContent: React.FC<{ classes: any }> = ({ classes }) => {
  //const { loading } = useAuth();
  const { marginContentTop: marginTop, minContentHeight: minHeight } =
    useFoundation();
  const { language, RouteTrans } = useTrans();

  return (
    <Grid
      item
      xs={12}
      classes={{ root: classes.sentinel_wrapper }}
      style={marginTop ? { marginTop } : {}}
    >
      <Grid container>
        <Box
          className={classes.sentinel_content}
          style={minHeight ? { minHeight } : {}}
        >
          <Switch>
            <Route
              path={`/${language}/${RouteTrans["about"][language]}`}
              render={() => <About />}
            />
            <Route
              path={`/${language}/${RouteTrans["posts"][language]}`}
              render={() => <Feed />}
            />
            <Route
              path={`/${language}/${RouteTrans["post"][language]}/:division/:category/:postIDalias`}
              render={props => <PostQuery route_params={props.match.params} />}
            />
            <Route
              path={`/${language}/${RouteTrans["user"][language]}/:handleID`}
              render={props => <UserQuery route_params={props.match.params} />}
            />
            <Route
              path={`/${language}/(${RouteTrans["manage"][language]}|${RouteTrans["create"][language]}|${RouteTrans["edit"][language]})`}
              render={() => <Manage />}
            />
            <Redirect to={`/${language}/${RouteTrans["posts"][language]}`} />
          </Switch>
        </Box>
        <Footer />
      </Grid>
    </Grid>
  );
};
export default withStyles(styles)(MainContent);
