import React from "react";
import { Grid, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
//var _ = require("lodash");

const styles = theme => ({
  postCategory: {
    color: theme.palette.text.focus,
    fontFamily: "Gotham SSm A, Gotham SSm B",
    fontStyle: "normal",
    fontWeight: 400,
    paddingTop: 2,
    paddingRight: 12,
    textTransform: "none",
    fontSize: "0.7rem",
  },

  writer_name: {
    height: 30,
  },
});
const Writer = props => {
  const { classes, thisPost } = props;
  const { writer } = thisPost;
  const { given_name, family_name } = writer.name.user;
  return (
    <Grid item classes={{ root: classes.writer_name }}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="flex-end"
        style={{ height: "100%" }}
      >
        <Grid item>
          <Typography variant="h3" className={classes.postCategory}>
            {given_name} {family_name}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(Writer);
