import React, { createContext, useEffect, useState } from "react";
import { useContext } from "react";
import { Post } from "../types";
var _ = require("lodash");

export const OldPostContext = createContext<{
  //  admin_page: string;
  cacheCheck: (new_post_from_old: any, cache_name: string) => any;
  cacheState: any;
  clearAll: () => void;
  form_config: string;
  oldPost: Post | null;
  setCacheState: (cache: any) => void;
  setFormConfig: (state: string) => void;
  setOldPost: (post: Post) => void;
}>({
  //  admin_page: "admin",
  cacheCheck: () => {},
  cacheState: {},
  clearAll: () => {},
  form_config: "",
  oldPost: null,
  setCacheState: () => {},
  setFormConfig: () => {},
  setOldPost: () => {},
});

export const useOldPost = () => useContext(OldPostContext);

const OldPostContextProvider: React.FC<{
  children: any;
  // oldPost: UnprocessedPost | Post;
}> = ({
  children,
  //oldPost: oldPost_
}) => {
  //const { refetch, refetchData } = props;
  const [oldPost, setOldPost] = useState<Post | null>(null);
  // const [oldPostQuery, setOldPostQuery] = useState({});
  const [cacheState, setCacheState] = useState({});
  const [form_config, setFormConfig] = useState("");

  useEffect(() => {
    if (
      form_config &&
      !_.isEmpty(cacheState) &&
      typeof localStorage !== "undefined"
    ) {
      localStorage.setItem("form_config", form_config);
    }
  }, [cacheState, form_config]);

  // useEffect(() => {
  //   setOldPost(unstringPost(oldPost_));
  //   //   setOldPostQuery(refetchData);
  // }, []);

  const cacheCheck = (post_data: any, cache_name: string) => {
    //  const isFeat = cache_name === "feature";

    if (oldPost && typeof localStorage !== "undefined") {
      //  const local_id = localStorage && localStorage.getItem("_id");
      const cached_data_string = localStorage.getItem(cache_name);

      const cached_data =
        cached_data_string && cached_data_string !== "undefined"
          ? JSON.parse(cached_data_string)
          : {};

      const storage_id =
        cache_name === "newPost" ? cached_data._id : cached_data.post_id;

      const matching = oldPost._id === storage_id;

      if (matching && cache_name !== "feature") {
        //  console.log("it matches", cached_data);
        return cached_data;
      } else {
        //  console.log("it doesnt match");
        return post_data;
      }
    }
  };

  // useEffect(() => {
  //   oldPost && !_.isEmpty(oldPost) && cacheCheck(oldPost);
  // }, [oldPost]);

  const clearAll = () => {
    oldPost &&
      setCacheState({
        _id: oldPost._id,
        newPost: oldPost,
        feature: oldPost.feature,
        attachments: oldPost.attachments,
      });
  };

  // const admin_page = (pathname:string) => {
  //   if (pathname === create) {
  //     return "create";
  //   } else if (_.startsWith(pathname, edit)) {
  //     return "edit";
  //   } else {
  //     return "admin";
  //   }
  // };

  // useEffect(() => {
  //   setAdminPage(admin_page(pathname))
  // },[pathname])

  return (
    <OldPostContext.Provider
      value={{
        // admin_page,
        // defaultPostType,
        cacheCheck,
        cacheState,
        clearAll,
        form_config,
        oldPost,
        // oldPostQuery,
        // refetch,
        setCacheState,
        setFormConfig,
        setOldPost,
        // setOldPostQuery,
      }}
    >
      {children}
    </OldPostContext.Provider>
  );
};

export default OldPostContextProvider;

// const cacheCheck = (oldPost: Post) => {
//   if (typeof localStorage !== "undefined") {
//     //  const local_id = localStorage && localStorage.getItem("_id");
//     const newPost_ = localStorage.getItem("newPost");
//     const stored_post =
//       newPost_ && newPost_ !== "undefined" ? JSON.parse(newPost_) : {};

//     const { _id: storage_id, updatedAt: storage_updatedAt } = stored_post;
//     const matching =
//       oldPost._id === storage_id && oldPost.updatedAt === storage_updatedAt;

//     const feature_ = localStorage.getItem("feature");
//     const attachments_ = localStorage.getItem("attachments");
//     const form_config_ = localStorage.getItem("form_config") || "template";

//     if (matching) {
//       const localNewPost = newPost_ && JSON.parse(newPost_);
//       const localFeature = feature_ && JSON.parse(feature_);
//       const localAttachments = attachments_ && JSON.parse(attachments_);

//       setCacheState({
//         newPost: localNewPost,
//         feature: localFeature,
//         attachments: localAttachments,
//       }); // is match, get the local storage object and set it as cache
//       setFormConfig(form_config_);
//     } else {
//       setCacheState({
//         newPost: oldPost,
//         feature: oldPost.feature,
//         attachments: oldPost.attachments,
//       }); // if not matching, set it as oldpost
//       setFormConfig(form_config_);
//     }
//   } else {
//     setCacheState({
//       newPost: oldPost,
//       feature: oldPost.feature,
//       attachments: oldPost.attachments,
//     });
//   }
// };

// function parseCheck(item: any) {
//   if (item) {
//     if (typeof item === "string") {
//       return JSON.parse(item);
//     } else {
//       return item;
//     }
//   } else {
//     item = { en: "", fr: "", ht: "" };
//     return item;
//   }
// }

// function parseCheckAttachment(
//   attachment: Attachment | UnprocessedAttachment,
//   template: string
// ) {
//   const processed_attachment = {
//     ...attachment,
//     description: parseCheck(attachment.description),
//     template,
//     title: parseCheck(attachment.title),
//     postType: defaultPostType,
//   };
//   return processed_attachment;
// }

// function unstringPost(post: UnprocessedPost | Post) {
//   const postObj = post;
//   const title = parseCheck(postObj.title);
//   const subtitle = parseCheck(postObj.subtitle);
//   const body = parseCheck(postObj.body);
//   const link = parseCheckAttachment(postObj.link, "text");
//   const cover = parseCheckAttachment(postObj.cover, "photo");
//   const feature =
//     postObj.feature &&
//     parseCheckAttachment(postObj.feature, postObj.feature.template || "text");
//   const attachments = _.map(postObj.attachments, parseCheckAttachment);

//   const thisPost = {
//     ...postObj,
//     title,
//     subtitle,
//     body,
//     link,
//     attachments,
//     cover,
//     feature,
//     //      postType: defaultPostType,
//   };
//   return thisPost;
// }
