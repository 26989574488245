import React from "react";
import {
  FaFacebook,
  FaFilePdf,
  FaImage,
  FaImages,
  FaInstagram,
  FaMicrophone,
  FaSoundcloud,
  FaTwitter,
  FaVideo,
  FaVimeo,
  FaYoutube,
} from "react-icons/fa";
import {
  Button,
  Card,
  CardActionArea,
  Grid,
  Theme,
  Typography,
} from "@mui/material";
import { createStyles, withStyles } from "@mui/styles";
import { useAttachment } from "../../../../context/Post/attachmentContext";
import { useManagers } from "../../../../context/Post/managersContext";
import { useAttachments } from "../../../../context/Post/attachmentsContext";
import { Attachment } from "../../../../context/types";
import { useNewPost } from "../../../../context/Post/newPostContext";
var _ = require("lodash");
const styles = (theme: Theme) =>
  createStyles({
    card_root: {
      margin: theme.spacing(1) + "px 0px",
    },
    card_root_trash: {
      background: theme.palette.secondary.main,
      color: theme.palette.background.paper,
    },
    content_root: {
      //backgroundImage: theme.palette.secondary.background,
      border: "1px solid " + theme.palette.fonts.detail,
      padding: theme.spacing(1) + "px !important",
      textAlign: "center",
    },
    deleteButton: {
      color: theme.palette.fonts.main,
    },
    deletedButton: {
      borderColor: theme.palette.color.red,
      color: theme.palette.color.red,
    },
    title: {
      lineHeight: 1,
      [theme.breakpoints.up("sm")]: {
        fontSize: ".9rem",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: ".75rem",
      },
    },
    acrobat: {
      color: theme.palette.icons.acrobat,
    },
    facebook: {
      color: theme.palette.icons.facebook,
    },
    instagram: {
      color: theme.palette.icons.instagram,
    },
    soundcloud: {
      color: theme.palette.icons.soundcloud,
    },
    twitter: {
      color: theme.palette.icons.twitter,
    },
    vimeo: {
      color: theme.palette.icons.vimeo,
    },
    youtube: {
      color: theme.palette.icons.youtube,
    },
  });

const AttachmentCard: React.FC<{
  attachment: Attachment;
  bodyInput: string;
  classes: any;
}> = ({ attachment, bodyInput, classes }) => {
  const { toggleManager } = useManagers();
  const { editing } = useNewPost();
  const {
    attachments,
    deleteAttachments,
    removeAttachment,
    restoreAttachment,
  } = useAttachments();
  const { loadAttachment } = useAttachment();
  const openAttachment = async (handle: string) => {
    const attachment = _.find(attachments, ["handle", handle]);
    toggleManager("attachmentsManager");
    loadAttachment(attachment);
    removeAttachment(handle);
  };

  const { handle, title, type } = attachment;
  const activated = _.includes(bodyInput, `{{${handle}}}`);
  const typeIcon: { [key: string]: any } = {
    document: <FaFilePdf className={classes.acrobat} />,
    facebookpost: <FaFacebook className={classes.facebook} />,
    facebookvideo: <FaFacebook className={classes.facebook} />,
    instagram: <FaInstagram className={classes.instagram} />,
    localaudio: <FaMicrophone />,
    localphoto: <FaImage />,
    localvideo: <FaVideo />,
    photoalbum: <FaImages />,
    soundcloud: <FaSoundcloud className={classes.soundcloud} />,
    twitter: <FaTwitter className={classes.twitter} />,
    vimeo: <FaVimeo className={classes.vimeo} />,
    youtube: <FaYoutube className={classes.youtube} />,
  };
  const deleted = _.includes(_.map(deleteAttachments, "handle"), handle);

  return (
    <Grid container spacing={2} style={{ width: "100%" }}>
      <Grid item xs={10}>
        <Card classes={{ root: classes.card_root }}>
          <CardActionArea
            onClick={() => handle && openAttachment(handle)}
            classes={{ root: classes.content_root }}
          >
            <Grid container>
              <Grid item xs={2}>
                <Typography
                  variant="button"
                  classes={{
                    root: deleted ? classes.deletedButton : classes.handle,
                  }}
                >
                  {deleted ? "Deleted" : `<{{${handle}}}>`}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  style={{ height: "100%" }}
                >
                  <Grid item classes={{ root: classes.icon }}>
                    {typeIcon[type]}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={9}>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  style={{ height: "100%" }}
                >
                  <Grid item>
                    <Typography
                      variant="body1"
                      classes={{ root: classes.title }}
                    >
                      {title?.[editing]}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardActionArea>
        </Card>
      </Grid>
      <Grid item xs={2}>
        <div style={{ padding: "8px 0", height: "100%" }}>
          {activated ? (
            <Button
              variant="outlined"
              disabled
              style={{
                borderColor: "green",
                color: "green",
                height: "100%",
                padding: 0,
                width: "100%",
              }}
            >
              Active
            </Button>
          ) : (
            <Button
              onClick={
                deleted
                  ? () => handle && restoreAttachment(handle)
                  : () => handle && removeAttachment(handle)
              }
              classes={{
                root: deleted ? classes.deletedButton : classes.deleteButton,
              }}
              variant="outlined"
              style={{ height: "100%", padding: 0, width: "100%" }}
            >
              {deleted ? "Restore" : "Delete"}
            </Button>
          )}
        </div>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(AttachmentCard);
