import React from "react";
import { FormattedMessage } from "react-intl";
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Theme,
} from "@mui/material";
import { createStyles, withStyles } from "@mui/styles";
import SelectLanguage from "./selectLanguage";
import { Attachment } from "../../../../../../context/types";
// import { useTrans } from "../../../../../../context/Trans/translationContext";
// import { useNewPost } from "../../../../../../context/Post/newPostContext";
import { useFeature } from "../../../../../../context/Post/featureContext";

var _ = require("lodash");
//import { createCoverProcess, createPostProcess } from "../Helpers/adminFunctions";

const styles = (theme: Theme) =>
  createStyles({
    background: {
      //  backgroundImage: theme.palette.secondary.background
    },
    button: {
      color: theme.palette.fonts.action,
      borderColor: theme.palette.fonts.action,
    },
    formControl: {
      width: "100%",
    },
    typeSelect: {
      textAlign: "right",
    },
    post: {
      color: theme.palette.fonts.main,
      fontSize: "2rem",
      lineHeight: 1,
      fontWeight: "bold",
      textAlign: "left",
      textTransform: "capitalize",
    },
  });

const Template: React.FC<{
  classes: any;

  // handlePostChange,
  // handleTypeChange,
  attachment: Attachment;
  // language: string;
}> = ({
  classes,
  // admin_page,
  // handlePostChange,
  // handleTypeChange,
  attachment,
  // language,
}) => {
  // const { language } = useTrans();
  // const { admin_page, clearAll } = useOldPost();
  // const { handlePostChange } = useNewPost();
  const { handleTypeChange } = useFeature();
  const { postType, template } = attachment;

  const createType: { [key: string]: any } = {
    text: (
      <Select
        value={postType?.text || "article"}
        onChange={handleTypeChange}
        input={
          <OutlinedInput
            label={<FormattedMessage id="Text Type" />}
            name="text"
          />
        }
        labelId="template-type-select"
      >
        <MenuItem value="article">
          <FormattedMessage id="Article" />
        </MenuItem>
        <MenuItem value="document">
          <FormattedMessage id="Document" />
        </MenuItem>
      </Select>
    ),
    video: (
      <Select
        // classes={{ root: classes.background }}
        value={postType?.video || "youtube"}
        onChange={handleTypeChange}
        input={
          <OutlinedInput
            label={<FormattedMessage id="Video Type" />}
            name="video"
          />
        }
        labelId="template-type-select"
      >
        <MenuItem value="youtube">YouTube</MenuItem>
        <MenuItem value="facebookvideo">
          <FormattedMessage id="Facebook Video" />
        </MenuItem>
        <MenuItem value="vimeo">Vimeo</MenuItem>
        <MenuItem value="localvideo">
          <FormattedMessage id="Upload Video" />
        </MenuItem>
      </Select>
    ),
    audio: (
      <Select
        value={postType?.audio || "soundcloud"}
        onChange={handleTypeChange}
        input={
          <OutlinedInput
            label={<FormattedMessage id="Audio Type" />}
            name="audio"
          />
        }
        labelId="template-type-select"
      >
        <MenuItem value="soundcloud">SoundCloud</MenuItem>
        <MenuItem value="localaudio">
          <FormattedMessage id="Upload Audio" />
        </MenuItem>
      </Select>
    ),
    social: (
      <Select
        value={postType?.social || "twitter"}
        onChange={handleTypeChange}
        input={
          <OutlinedInput
            label={<FormattedMessage id="Social Type" />}
            name="social"
          />
        }
        labelId="template-type-select"
      >
        <MenuItem value="twitter">Twitter</MenuItem>
        <MenuItem value="instagram">Instagram</MenuItem>
        <MenuItem value="facebookpost">
          <FormattedMessage id="Facebook Post" />
        </MenuItem>
      </Select>
    ),
    photo: (
      <Select
        value={postType?.photo || "localphoto"}
        onChange={handleTypeChange}
        input={
          <OutlinedInput
            label={<FormattedMessage id="Photo Type" />}
            name="photo"
          />
        }
        labelId="template-type-select"
      >
        <MenuItem value="localphoto">
          <FormattedMessage id="Upload Photo" />
        </MenuItem>
        <MenuItem value="photoalbum">
          <FormattedMessage id="Photo Album" />
        </MenuItem>
      </Select>
    ),
  };

  // const label_text:{[key:string]:string} ={
  //   text: 'Text Type',
  //   video: 'Video Type',
  // }

  return (
    <Box style={{ marginTop: 8 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} className={classes.typeSelect}>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="template-type-select">
              <FormattedMessage id={`${_.upperFirst(template)} Type`} />
            </InputLabel>
            {createType[template]}
          </FormControl>
        </Grid>
        <Grid item sm={6} xs={12}>
          <SelectLanguage />
        </Grid>
      </Grid>
    </Box>
  );
};

export default withStyles(styles)(Template);

// {false && (
//   <Grid item xs={12}>
//     <Grid container>
//       <Grid item xs={6}>
//         <Grid
//           container
//           direction="row"
//           justifyContent="flex-start"
//           alignItems="center"
//         >
//           <Grid item>
//             <Typography variant="h5" className={classes.post}>
//               <FormattedMessage id={template} />
//             </Typography>
//           </Grid>
//         </Grid>
//       </Grid>

//       <Grid item xs={6}>
//         <Grid
//           container
//           direction="row"
//           justifyContent="flex-end"
//           alignItems="flex-start"
//         >
//           <Grid item>
//             <Button
//               onClick={() => clearAll()}
//               variant="outlined"
//               classes={{ root: classes.button }}
//               size="small"
//             >
//               {admin_page === "edit" ? (
//                 <FormattedMessage id="Clear Changes" />
//               ) : (
//                 <FormattedMessage id="Clear All" />
//               )}
//             </Button>
//           </Grid>
//         </Grid>
//       </Grid>
//     </Grid>
//   </Grid>
// )}
