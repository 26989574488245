import React from "react";
import { useQuery } from "@apollo/client";
import { Theme } from "@mui/material";
import { createStyles, withStyles } from "@mui/styles";
//import { useAuth } from "../context/Auth/authContext";
import { GET_POST_QUERY } from "../../../utilities/queries";
import { useTrans } from "../../../context/Trans/translationContext";
import Loader from "../../../components/Loader";
import Post from "./post";
//import { Post as PostI } from "../../../context/types";
var _ = require("lodash");
//import { useManagers } from "./Admin/Post/ManagersContext";
//import MainRoutes from "./mainRoutes";

const styles = (theme: Theme) =>
  createStyles({
    sentinel_wrapper: {
      marginTop: 72,
      minHeight: "calc(100vh - 220px)",
    },
  });

const PostQuery: React.FC<{ route_params: any }> = ({ route_params }) => {
  // const [queried_post, setQueriedPost] = useState<PostI | null>(null);

  const { getDev, language } = useTrans();
  const { category, division, postIDalias } = route_params;

  const divDev = getDev(division, language);
  const catDev = getDev(category, language);
  const postIDString = _.split(postIDalias, "-", 1)[0];
  const postID = Number(postIDString);

  //const { currentJSONPost, infoURL } = props;
  // const titleAlias = _.kebabCase(t(`title`, title));

  const variables = {
    url: `${divDev}/${catDev}`,
    postID,
    status: "published",
  };

  // RETRIEVE POST AS IT EXISTS IN DATABASE

  const { data, error, loading } = useQuery(GET_POST_QUERY, {
    fetchPolicy: "no-cache",
    variables,
  });

  if (loading) return <Loader />; //<Loader status={"Getting Post Data"} />;
  if (error) return <div>{`Error! ${error}`}</div>;
  const result = data.getPostQuery;

  return <Post queried_post={result} route_params={route_params} />;
};
export default withStyles(styles)(PostQuery);
