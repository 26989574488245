import React from "react";
import ReactPlayer from "react-player";
import { withStyles } from "@mui/styles";

const styles = theme => ({
  player: {
    "& audio": {
      borderRadius: 25,
      boxShadow: theme.palette.shadow.boxShadow,
    },
  },
  wrapper: {
    height: 50,
  },
});

const LocalAudioPlay = props => {
  const { classes, localaudio } = props;
  const { autoPlay, source } = localaudio;

  return (
    <div className={classes.wrapper}>
      <ReactPlayer
        className={classes.player}
        config={{
          youtube: {
            playerVars: {
              loop: 1,
              playlist: source,
            },
          },
        }}
        url={source}
        width="100%"
        height="50px"
        controls={true}
        playing={autoPlay}
      />
    </div>
  );
};

export default withStyles(styles)(LocalAudioPlay);
